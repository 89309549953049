import React from "react";
import Select from "react-draggable-multi-select";
function getSelectStyles(color) {
  let highlightColor = color || "var(--brand)";

  return {
    container: (styles) => ({ ...styles }),
    control: (styles) => ({
      ...styles,
      textAlign: "left",
      "& > div:first-of-type": { minHeight: 40 },
      "&:hover, &:focus": {
        borderColor: highlightColor,
        boxShadow: `0 0 0 1px ${highlightColor}`,
      },
    }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles) => ({ ...styles }),
    input: (styles) => ({
      ...styles,
      boxShadow: "none",
      position: "absolute",
      "& input": { height: 40, boxShadow: "none" },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      cursor: "pointer",
      fontWeight: state.isSelected ? "600" : "400",
      color: "inherit",
      "&:not(:last-child)": { borderBottom: "solid 1px #efefef" },
    }),
  };
}

export default function SelectList({
  isMulti,
  options,
  onChange,
  value,
  placeholder = "Select...",
  onMenuOpen,
  disabled,
  customCSSProperty,
  ...rest
}) {
  return (
    <Select
      placeholder={placeholder}
      styles={getSelectStyles(customCSSProperty)}
      value={value}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      onMenuOpen={onMenuOpen}
      isDisabled={disabled}
      {...rest}
    />
  );
}
