import { loadStripe } from "@stripe/stripe-js";

class Stripe {
  constructor() {
    this.key = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
    this.stripe = this.load();
  }
  async load() {
    this.stripe = await loadStripe(this.key);
  }

  async redirectToCheckout(sessionId) {
    return await this.stripe.redirectToCheckout({ sessionId });
  }
}

export default new Stripe();
