import React from "react";
import DietaryConsiderationsForm from "../../components/Forms/DietaryConsiderationsForm";

import withDashboardLayout from "../../Layouts/AdminPanel";

function DietaryConsiderations() {
  return <DietaryConsiderationsForm />;
}

export default withDashboardLayout(DietaryConsiderations);
