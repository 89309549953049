import React from "react";
import AddRestaurantForm from "../../components/Forms/AddRestaurantForm";

import withDashboardLayout from "../../Layouts/AdminPanel";

function AddRestaurant() {
  return <AddRestaurantForm />;
}

export default withDashboardLayout(AddRestaurant);
