import React from "react";
import { Checkbox as CustomCheckbox } from "@material-ui/core";

export default function Checkbox({ checked, onChange, name, color, size, disabled }) {
  return (
    <CustomCheckbox
      disabled={disabled ? true : false}
      size={size}
      style={{
        padding: size === "small" ? 2 : 9,
        backgroundColor: "transparent",
        color: disabled ? "#ddd" : color || "#f46d25",
      }}
      checked={checked}
      name={name}
      onChange={onChange}
      inputProps={{ "aria-label": "primary checkbox" }}
    />
  );
}
