import React from "react";
import Checkbox from "../../Checkbox";
import styles from "./MenuForm.module.scss";
import { Form } from "react-bootstrap";

const DAYS = {
  sunday: true,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
};

export default function Days({ valid, setValid, days, disabled }) {
  const onChangeCheckBox = (day) => {
    let temp = { ...valid };
    temp[day] = !temp[day];
    setValid(temp);
  };

  if (!days) {
    days = DAYS;
  }

  const allSelected = Object?.keys(days)?.every((day) => {
    return valid[day] === true;
  });

  const toggleAll = () => {
    if (!allSelected) setValid(days);
    else {
      let temp = { ...days };
      Object.keys(temp).forEach((elem) => {
        temp[elem] = false;
      });

      setValid(temp);
    }
  };

  return (
    <>
      {Object.keys(valid).map((day, index) => (
        <React.Fragment key={index}>
          {day in days && (
            <div className={styles.day}>
              <Checkbox
                size="small"
                color="#808080"
                checked={valid[day]}
                onChange={(e) => onChangeCheckBox(day, e.target.checked)}
                name={day}
                disabled={disabled}
              />
              <Form.Label onClick={() => onChangeCheckBox(day)}>{day}</Form.Label>
            </div>
          )}
        </React.Fragment>
      ))}

      <div className={styles.allDays}>
        <Checkbox size="small" onChange={toggleAll} checked={allSelected} name="all-days" disabled={disabled} />
        <Form.Label onClick={toggleAll}>All Days</Form.Label>
      </div>
    </>
  );
}
