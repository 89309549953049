import React, { useEffect, useState, useRef } from "react";
import styles from "../Admin/Admin.module.scss";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Api, Endpoint } from "../../api";
import { useHistory } from "react-router-dom";
import Helpers from "../../helpers/general";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import registerFormStyle from "../Register/Register.module.scss";
import { Form, Button, Alert } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";

const { passwordRegex, passwordMessage } = Helpers.getPasswordRegex();

export default function TransferOwnership() {
  const token = new URLSearchParams(window.location.search).get("code");

  const { register, handleSubmit, errors, watch } = useForm();

  const history = useHistory();

  const password = useRef({});
  password.current = watch("password", "");

  const [data, setData] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("+1");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [formError, setFormError] = useState(null);

  const fetchUser = async () => {
    setLoading(true);
    const response = await Api.call(Endpoint.transfer_ownership_status(token));

    if (response?.statusCode !== 200) {
      setLoading(false);
      setError(response?.message);
      setData({});
      return;
    }

    setData(response?.message);

    setLoading(false);
    setError(null);
  };

  const submit = async (formData) => {
    setSubmitLoading(true);

    if (!formData.email) {
      formData.email = data?.account?.email;
    }

    formData.phoneNumber = phoneNumber;

    const query = { token };
    const options = {
      ...formData,
    };

    const response = await Api.call(Endpoint.accept_transfer_request, { data: options, query });

    if (response?.statusCode !== 200) {
      setSubmitLoading(false);
      setFormError(response?.message);
      return;
    }

    setFormError(null);
    setSubmitLoading(false);

    toast("Transfer has been taken successfully");
    history.push("/login");
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className={styles.bodyWrapper}>
        {loading ? (
          <>
            <LoadingSpinner />
            <p className={styles.loadingText}>Please wait...</p>
          </>
        ) : error ? (
          <>
            <i className="fas fa-exclamation-circle fa-7x"></i>
            <h3 className={styles.errorText}>{error}</h3>
          </>
        ) : (
          <>
            <div className={registerFormStyle.loginPageWrapper}>
              <div className={registerFormStyle.pageContainer}>
                <Form.Group>
                  <h3>
                    Take ownership of <strong>{data?.restaurant?.name}</strong> restaurant
                  </h3>
                  <p>
                    <strong style={{ fontWeight: "bold" }}>
                      {data?.owner?.username} ({data?.owner?.email})
                    </strong>{" "}
                    wants to transfer <strong>{data?.restaurant?.name}</strong> restaurant ownership to you
                  </p>

                  {formError && (
                    <Alert variant={"danger"}>
                      <p style={{ margin: "0px" }}>{formError}</p>
                    </Alert>
                  )}
                </Form.Group>
                <Form onSubmit={handleSubmit(submit)}>
                  <Form.Group>
                    <i className="fal fa-user"></i>
                    <Form.Control
                      ref={register}
                      defaultValue={data?.account?.firstName || ""}
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                    />
                  </Form.Group>
                  <Form.Group>
                    <i className="fal fa-user"></i>
                    <Form.Control
                      ref={register}
                      defaultValue={data?.account?.lastName || ""}
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                    />
                  </Form.Group>
                  <Form.Group>
                    <i className="fal fa-envelope"></i>
                    <Form.Control
                      type="text"
                      name="email"
                      ref={register({
                        required: "Email cannot be empty",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      disabled={data?.account?.email?.length > 0}
                      defaultValue={data?.account?.email}
                      placeholder="Email"
                    />
                    {errors?.email?.message && <small className={registerFormStyle.registerErrorText}>{errors?.email?.message}</small>}
                  </Form.Group>
                  <Form.Group>
                    <i className="fal fa-user"></i>
                    <Form.Control
                      ref={register({ required: "Username cannot be empty" })}
                      type="text"
                      name="username"
                      placeholder="Username"
                    />
                    {errors?.username?.message && (
                      <small className={registerFormStyle.registerErrorText}>{errors?.username?.message}</small>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <i className="fal fa-user"></i>
                    <PhoneInput
                      inputStyle={{ width: "100%" }}
                      country={"us"}
                      value={phoneNumber}
                      onChange={(phone) => setPhoneNumber(phone)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <i className="fal fa-lock"></i>
                    <Form.Control
                      ref={register({
                        required: "Password cannot be empty",
                        pattern: {
                          value: passwordRegex,
                          message: passwordMessage,
                        },
                      })}
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                    {errors?.password?.message && (
                      <small className={registerFormStyle.registerErrorText}>{errors?.password?.message}</small>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <i className="fal fa-lock"></i>
                    <Form.Control
                      ref={register({
                        required: "Confirm password cannot be empty",
                        validate: (value) => value === password.current || "The password confirmation does not match.",
                      })}
                      type="password"
                      name="confirm_password"
                      placeholder="Confirm Password"
                    />
                    {errors?.confirm_password?.message && (
                      <small className={registerFormStyle.registerErrorText}>{errors?.confirm_password?.message}</small>
                    )}
                  </Form.Group>
                  <Button
                    type="submit"
                    disabled={submitLoading || Object.keys(errors).length > 0 ? true : false}
                    style={{ width: "100%" }}
                    onClick={handleSubmit(submit)}
                  >
                    {submitLoading ? <LoadingSpinner size="sm" /> : "Submit"}
                  </Button>
                </Form>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
