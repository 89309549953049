import React, { useState, useEffect } from "react";
import CategoryForm from "../../components/Forms/CategoryForm";
import Modal from "../../components/Modal";
import Section from "../../components/Section/Section";
import { connect } from "react-redux";
import DeleteConfirmation from "../../components/Forms/DeleteConfirmation";
import CSVForm from "../../components/Forms/CSVForm";
import { Button } from "react-bootstrap";
import styles from "./CreateCategory.module.scss";
import Tooltip from "@material-ui/core/Tooltip";
import Categories from "../../components/Table/Categories";

import withDashboardLayout from "../../Layouts/AdminPanel";
import { toast } from "react-toastify";

function CreateCategory({ Auth, Category }) {
  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });
  const { categories } = Category;

  useEffect(() => {
    Category.load();
    // eslint-disable-next-line
  }, []);

  const updateCategory = (category = {}) => {
    setModal({
      ...modal,
      title: "Update category",
      visible: true,
      Component: (
        <CategoryForm update={true} categoryID={category?._id || null} closeModal={() => setModal({ ...modal, visible: false })} />
      ),
    });
  };

  const deleteCategories = (ids, name) => {
    let customButtonMessage = Array?.isArray(ids)
      ? `Delete ${ids?.length} ${ids?.length === 1 ? "category" : "categories"}`
      : `Delete categories`;
    let customMessage = `Are you sure you want to delete ${
      ids?.length <= 1 ? `${name ? name : "this category"}` : `${ids?.length} categories`
    }?`;

    setModal({
      ...modal,
      visible: true,
      confirmation: true,
      Component: (
        <DeleteConfirmation
          type="Category"
          customButtonMessage={customButtonMessage}
          customMessage={customMessage}
          funk={async (id) => {
            const response = await Category.delete(ids);
            return response;
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const showCSVModal = () => {
    setModal({
      ...modal,
      visible: true,
      title: "Upload categories",
      Component: (
        <CSVForm
          Controller={Category}
          closeModal={() => setModal({ ...modal, visible: false })}
          downloadExampleLink={process.env.REACT_APP_CATEGORY_CSV_EXAMPLE}
        />
      ),
    });
  };

  const clone = async (category) => {
    const newCategory = {
      name: `${category.name} (copy)`,
      isAvailable: false,
      dishes: category.dishes.map((dish) => dish._id),
    };

    const response = await Category.create(newCategory, null); // null for selected menu
    if (response?.confirmation === "Fail") {
      if (response?.statusCode === 412) {
        // if it fails because of duplicate name, add one more "(copy)" and try again
        clone(newCategory);
      } else {
        toast("Category can not be created");
      }
      return;
    } else {
      toast("Category created successfully");
    }
  };

  return (
    <React.Fragment>
      <Modal {...modal} />
      <CategoryForm />
      <br />
      <Section title={`Categories ${categories?.length > 0 ? `(${categories?.length})` : ``}`}>
        <Categories Auth={Auth} data={categories} updateCategory={updateCategory} deleteCategories={deleteCategories} clone={clone} />
      </Section>
      <div className={styles.uploadCSVButtonParent}>
        <Tooltip title="You can add categories one at a time, or upload all of them at once." enterTouchDelay={0}>
          <Button type="button" onClick={() => showCSVModal()} style={{ alginSelf: "center" }}>
            Upload CSV
          </Button>
        </Tooltip>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({ Auth: state?.Auth, Category: state?.Category });
export default connect(mapStateToProps)(withDashboardLayout(CreateCategory));
