import React, { useState, useEffect } from "react";
import CustomizationForm from "../../components/Forms/CustomizationForm";
import { connect } from "react-redux";

import withDashboardLayout from "../../Layouts/AdminPanel";

function Customizations({ Restaurant, Ingredient }) {
  const [customizations, setCustomizations] = useState(null);
  const { restaurant } = Restaurant;
  const { ingredients } = Ingredient;

  const fetchRestaurant = async () => {
    const response = await Restaurant?.find();
    if (response?.confirmation) {
      if (response?.confirmation === "Success" && response?.message?.[0]?.customizations) {
        setCustomizations(response?.message?.[0]?.customizations);
        return;
      }
      setCustomizations([]);
      return;
    }
    if (typeof response === "object" && !Array.isArray(response) && response?.customizations) {
      setCustomizations(response?.customizations);
      return;
    }
    setCustomizations([]);
  };

  useEffect(() => {
    Ingredient.load({ dismissTree: true });
    fetchRestaurant();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      {customizations && (
        <CustomizationForm
          stateCustomizations={customizations}
          ingredients={ingredients}
          Restaurant={Restaurant}
          metadata={{
            fromRestaurant: true,
            restaurant: restaurant,
          }}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({ Restaurant: state?.Restaurant, Ingredient: state?.Ingredient });
export default connect(mapStateToProps)(withDashboardLayout(Customizations));
