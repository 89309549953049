import React, { useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Form, Button, ButtonGroup } from "react-bootstrap";

export default function EmbedMenuOptions({ closeModal, restaurantId, url = `${window.location.origin}/menu/${restaurantId}` }) {
  const { register, handleSubmit } = useForm();

  const initialState = Object.freeze({
    default: {
      label: "Default",
      active: false,
    },
    px: {
      label: "px",
      active: false,
    },
    percentage: {
      label: "%",
      active: false,
    },
  });

  const [options, setOptions] = useState({ ...initialState, default: { ...initialState.default, active: true } });

  const generateEmbedMenu = (data, type, url) => {
    const width = type !== "default" && data.width && data.width + type;
    const height = type !== "default" && data.height && data.height + (type === "%" ? "vh" : type);

    let widthStyle = "";
    if (width) {
      widthStyle = `width: ${width};`;
    }
    let heightStyle = "";
    if (height) {
      heightStyle = `height: ${height};`;
    }

    const iframeCode = `<iframe style="max-width: 100%; max-height: 85vh; ${widthStyle} ${heightStyle}" frameborder="0" marginwidth="0" marginheight="0" width="400" height="844" src="${url}"></iframe>`;

    navigator.clipboard.writeText(iframeCode).then(
      function () {
        toast("Embedded Menu has been copied successfully to clipboard");
      },
      function (err) {
        toast("Error copying Embedded Menu url to clipboard. Please try again later");
      }
    );
  };

  const submit = async (data) => {
    const type = Object.entries(options)
      .filter((opt) => opt?.[1]?.active)?.[0]?.[1]
      .label?.toLowerCase();
    generateEmbedMenu(data, type, url);
    closeModal();
  };

  const handleChangeOption = (type) => {
    const newState = { ...initialState };
    newState[type] = { ...newState[type], active: true };
    setOptions(newState);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(submit)}>
        <Form.Group className="d-flex justify-content-center">
          <ButtonGroup>
            {Object.entries(options).map((btn) => (
              <Button key={btn[0]} variant={btn[1].active ? "primary" : "secondary"} onClick={() => handleChangeOption(btn[0])}>
                {btn[1].label}
              </Button>
            ))}
          </ButtonGroup>
        </Form.Group>

        <Form.Group>
          <div className="d-flex justify-content-between">
            <Form.Label>Width</Form.Label>
            {!options.default.active && (
              <small style={{ fontWeight: "bold", color: "var(--text-color)" }}>
                {Object.entries(options).map((opt) => (opt[1].active ? opt[1].label : null))}
              </small>
            )}
          </div>
          <Form.Control
            ref={register()}
            type="number"
            name="width"
            placeholder="Width"
            disabled={options.default.active}
            style={{ opacity: options.default.active ? "0.5" : 1 }}
          />
        </Form.Group>

        <Form.Group>
          <div className="d-flex justify-content-between">
            <Form.Label>Height</Form.Label>
            {!options.default.active && (
              <small style={{ fontWeight: "bold", color: "var(--text-color)" }}>
                {Object.entries(options).map((opt) => (opt[1].active ? opt[1].label : null))}
              </small>
            )}
          </div>
          <Form.Control
            ref={register()}
            type="number"
            name="height"
            placeholder="height"
            disabled={options.default.active}
            style={{ opacity: options.default.active ? "0.5" : 1 }}
          />
        </Form.Group>
        <Form.Group className="d-flex justify-content-end">
          {typeof closeModal === "function" && (
            <Button style={{ marginRight: "10px" }} variant="secondary" onClick={closeModal}>
              Close
            </Button>
          )}
          <Button variant="primary" type="submit">
            Generate Code
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}
