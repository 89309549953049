import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

function PublicNotAuthRoute({ children, Auth, ...route }) {
  if (!Auth?.user) {
    return <Route {...route} />;
  }
  return <Redirect to={`/`} />;
}

const mapStateToProps = (state) => ({ Auth: state.Auth });
export default connect(mapStateToProps)(PublicNotAuthRoute);
