import React from "react";
import { useForm } from "react-hook-form";
import ColorInput from "./ColorInput";

export default function Colors({ meta, colors, setColors, onChange }) {
  const { register } = useForm();

  const handleData = (name, value) => {
    let temp = { ...colors };
    temp[name] = value;
    setColors(temp);
    onChange();
  };

  return (
    <div className="mr-4">
      {meta?.colors?.primary?.enabled && (
        <ColorInput
          label="Primary color"
          initValue={colors?.primary || meta?.colors?.primary?.default}
          onChange={handleData}
          register={register}
          name="primary"
          description={meta?.colors?.primary?.description}
        />
      )}
      {meta?.colors?.secondary?.enabled && (
        <ColorInput
          label="Secondary color"
          initValue={colors?.secondary || meta?.colors?.secondary?.default}
          onChange={handleData}
          register={register}
          name="secondary"
          description={meta?.colors?.secondary?.description}
        />
      )}
      {meta?.colors?.tertiary?.enabled && (
        <ColorInput
          label="Tertiary color"
          initValue={colors?.tertiary || meta?.colors?.tertiary?.default}
          onChange={handleData}
          register={register}
          name="tertiary"
          description={meta?.colors?.tertiary?.description}
        />
      )}
    </div>
  );
}
