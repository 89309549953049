import * as TYPES from "../actions/category"
import { methods } from "../actions/category"

const initialState = {
    ...methods,
    categories: [],
}

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.CATEGORY_CREATED_SUCCESSFULLY:
            let tempCategories = [...state.categories]
            tempCategories.unshift(action.category)
            return { ...state, categories: tempCategories }
        case TYPES.CATEGORIES_LOADED_SUCCESSFULLY:
            return { ...state, categories: action.categories }
        case TYPES.CATEGORIES_RESET:
            return { ...state, categories: [] }
        default: 
            return { ...state }
    }
} 

export { categoryReducer }
