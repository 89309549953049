import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import LoadingSpinner from "../../LoadingSpinner";
import registerStyle from "../../../Views/Register/Register.module.scss";
import Checkbox from "@material-ui/core/Checkbox";
import { useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ModalFooter from "../../Modal/ModalFooter";
import SelectDishes from "../../SelectDishes";
import { toast } from "react-toastify";
import Modal from "../../Modal";
import GeneralModal from "../../../components/Modal/General";

function CreateCategory({ Category, Dish, update, closeModal, categoryID, menuSelected }) {
  const [loading, setLoading] = useState(false);
  const [selectedDishes, setSelectedDishes] = useState([]);
  const [error, setError] = useState(null);
  const [checked, setChecked] = useState(true);
  const [category, setCategory] = useState({});

  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const match = useRouteMatch("/update-category/:id");
  const { dishes } = Dish;
  const history = useHistory();
  const { register, handleSubmit, errors, reset } = useForm();

  const getCategoryId = () => {
    if (match?.params?.id) return match?.params?.id;
    else if (categoryID) return categoryID;
    return null;
  };

  const addToSelect = (id) => {
    let temp = [...selectedDishes];
    temp?.includes(id) ? temp.splice(temp.indexOf(id), 1) : temp.push(id);
    setSelectedDishes(temp);
  };

  useEffect(() => {
    Dish.load();
    async function getData() {
      if (update) {
        const id = getCategoryId();
        if (!id) return history.push("/");
        const response = await Category.findById(id);
        if (response?.confirmation === "Success") {
          setCategory(response?.message);
          update && setChecked(response?.message?.isAvailable);
          if (response?.message?.dishes) setSelectedDishes(response?.message?.dishes?.map((dish) => dish?._id));
        } else if (response?.confirmation === "Fail") return history.push("/");
      }
    }
    getData();
    //eslint-disable-next-line
  }, []);

  const resetFormAfterCreating = () => {
    reset();
    setSelectedDishes([]);
    setChecked(true);
    setCategory({});
  };

  const showGeneralModal = (submitFunc) => {
    setModal({
      ...modal,
      title: "Confirm modal",
      visible: true,
      confirmation: true,
      Component: (
        <GeneralModal
          {...modal}
          customMessage="You didn't select any dish!"
          customButtonMessage="Continue anyway"
          toastMessage={update ? "Category updated successfully" : "Category created successfully"}
          funk={async (id) => {
            const response = await submitFunc();
            typeof closeModal === "function" && closeModal();
            return response;
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const submit = async (data) => {
    data.isAvailable = checked;
    data.dishes = selectedDishes;

    data.menu = menuSelected?._id ? menuSelected?._id : null;

    if (data?.dishes?.length === 0) {
      return showGeneralModal(async () => {
        const response = update ? await Category.update(getCategoryId(), data) : await Category.create(data, data.menu);
        setModal({ ...modal, visible: false });
        return response;
      });
    }

    setLoading(true);
    const response = update ? await Category.update(getCategoryId(), data) : await Category.create(data, data.menu);

    if (response?.confirmation === "Fail") {
      setError(response?.message);
      setLoading(false);
      return;
    }

    resetFormAfterCreating();
    setError(null);
    setLoading(false);

    update ? toast("Category updated successfully") : toast("Category created successfully");
    typeof closeModal === "function" && closeModal();
  };

  return (
    <div>
      <Modal {...modal} />
      <Form onSubmit={handleSubmit(submit)}>
        <Form.Group>
          <Form.Label>Category name</Form.Label>
          <Form.Control
            defaultValue={category?.name}
            ref={register({ required: "Category name cannot be empty" })}
            name="name"
            placeholder="Category Name"
          />
          {(errors?.name?.message || error) && <small className={registerStyle.registerErrorText}>{errors?.name?.message || error}</small>}
        </Form.Group>
        {dishes && dishes?.length > 0 && (
          <Form.Group>
            <Form.Label>Dishes</Form.Label>
            <SelectDishes addToSelect={addToSelect} selectedDishes={selectedDishes} dishes={dishes} />
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>Category Available</Form.Label>
          <Checkbox
            style={{ backgroundColor: "transparent", color: "#f46d25" }}
            checked={checked}
            onChange={(event) => setChecked(event.target.checked)}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </Form.Group>
        <ModalFooter>
          {update ? (
            <Button disabled={loading || Object.keys(errors).length > 0 ? true : false} onClick={handleSubmit(submit)}>
              {loading ? <LoadingSpinner /> : "Update"}
            </Button>
          ) : (
            <Button disabled={loading || Object.keys(errors).length > 0 ? true : false} onClick={handleSubmit(submit)}>
              {loading ? <LoadingSpinner /> : "Create"}
            </Button>
          )}
          {typeof closeModal === "function" && (
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          )}
        </ModalFooter>
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => ({ Category: state.Category, Dish: state.Dish });
export default connect(mapStateToProps)(CreateCategory);
