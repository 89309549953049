import React from "react";
import Modal from "react-bootstrap/Modal";

export default function CustomModal({
  visible,
  closeModal,
  title,
  Component,
  style,
  size = "md",
  dialogClassName,
  confirmation = false,
}) {
  return (
    <Modal
      style={{ backgroundColor: "rgba(0,0,0,0.45)" }}
      dialogClassName={dialogClassName ? dialogClassName : ""}
      size={size}
      show={visible}
      onHide={() => {
        typeof closeModal === "function" && closeModal();
      }}
      className={confirmation ? "confirmation-modal" : ""}
    >
      {!confirmation && (
        <Modal.Header closeButton>
          <Modal.Title>{title ? title : ""}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{Component}</Modal.Body>
    </Modal>
  );
}
