import React, { useState } from "react";
import styles from "./PageHeader.module.scss";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Modal from "../Modal";
import SwitchAccountForm from "../Forms/SwitchAccountForm";
import { profileRoutes, adminRoutes, DMARoutes } from "../../constants/routes";
import general from "../../helpers/general";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteConfirmation from "../Forms/DeleteConfirmation";

function PageHeader({ Auth }) {
  const history = useHistory();
  const [dropdownActive, setDropdownActive] = useState(false);

  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const toggleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  const checkSubMenuPermissions = (route, $connector = "$and") => {
    if (!route || !route?.subs || route?.subs?.length === 0) {
      return false;
    }
    if ($connector === "$and") {
      return route?.subs?.every((subRoute) =>
        general?.checkPermissions({
          exceptPermissions: subRoute?.permissions?.except,
          $connector: subRoute?.permissions?.$connector,
          originalPermissions: Auth?.user?.permissions,
        })
      );
    }
    return route?.subs?.some((subRoute) =>
      general?.checkPermissions({
        exceptPermissions: subRoute?.permissions?.except,
        $connector: subRoute?.permissions?.$connector,
        originalPermissions: Auth?.user?.permissions,
      })
    );
  };

  const renderRoutes = (routes) => {
    //eslint-disable-next-line
    return (
      <>
        {/* eslint-disable-next-line array-callback-return */}
        {routes?.map((route, i) => {
          if (route?.showInDropdown) {
            if (Auth.LOGGED_IN()) {
              if (!route?.permissions || typeof route?.permissions !== "object") {
                if (route?.private) {
                  return (
                    <li key={i}>
                      <Link to={route.path}>
                        <i className={route.icon} />
                        <span>{route.name}</span>
                      </Link>
                    </li>
                  );
                }
              } else {
                if (route?.private) {
                  if (
                    general?.checkPermissions({
                      exceptPermissions: route?.permissions?.except,
                      $connector: route?.permissions?.$connector,
                      originalPermissions: Auth?.user?.permissions,
                    }) ||
                    checkSubMenuPermissions(route, "$or")
                  ) {
                    return (
                      <li key={i}>
                        <Link
                          to={
                            general?.checkPermissions({
                              exceptPermissions: route?.permissions?.except,
                              $connector: route?.permissions?.$connector,
                              originalPermissions: Auth?.user?.permissions,
                            })
                              ? route.path
                              : history?.location?.pathname || "/"
                          }
                        >
                          <i className={route.icon} />
                          <span>{route.name}</span>
                        </Link>
                      </li>
                    );
                  }
                }
              }
            } else {
              if (!route?.private) {
                return (
                  <li key={i}>
                    <Link to={route.path}>{route.name}</Link>
                  </li>
                );
              }
            }
          } else {
            return null;
          }
        })}
      </>
    );
  };

  return (
    <>
      <Modal {...modal} />
      <header className={styles.header2}>
        <h3>{/* TODO: page title here */}</h3>
        <div className={styles.headerDropdown}>
          <div className={styles.header}>
            <span>Hi {Auth?.user?.role === "SUPER_ADMIN" ? "Admin" : Auth.user.firstName}</span>
            <div className={styles.actions}>
              <Tooltip title="Logout" enterTouchDelay={0}>
                <button
                  className={styles.logoutBtn}
                  onClick={async () => {
                    setModal({
                      ...modal,
                      title: "Save changes",
                      visible: true,
                      confirmation: true,
                      Component: (
                        <DeleteConfirmation
                          {...modal}
                          customMessage="Are you sure you want to Logout?"
                          customButtonMessage="Logout"
                          toastMessage=""
                          funk={async () => {
                            await Auth.LOGOUT();
                            history.push("/login");
                          }}
                          closeModal={() => setModal({ ...modal, visible: false })}
                        />
                      ),
                    });
                  }}
                >
                  <i className="far fa-sign-out"></i>
                </button>
              </Tooltip>

              <button onClick={toggleDropdown} className={`${styles.toggleBtn} ${dropdownActive ? styles.active : ""}`}>
                <i className="far fa-chevron-down"></i>
              </button>
            </div>
          </div>
          <div className={`${styles.body} ${dropdownActive ? styles.active : ""}`}>
            <ul>
              {renderRoutes(profileRoutes)}
              {renderRoutes(adminRoutes)}
              {renderRoutes(DMARoutes)}
              {Auth?.user?.accounts?.length > 1 && (
                <li>
                  <i className="fal fa-user-lock" />
                  {Auth.LOGGED_IN() && Auth?.user?.accounts?.length > 1 && (
                    <span
                      className={styles?.switchAccountText}
                      onClick={() => {
                        setModal({
                          ...modal,
                          visible: true,
                          Component: <SwitchAccountForm {...modal} />,
                          title: "Switch account",
                        });
                        setDropdownActive(false);
                      }}
                    >
                      Switch account
                    </span>
                  )}
                </li>
              )}
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}

const mapStateToProps = (state) => ({ Auth: state.Auth });
export default connect(mapStateToProps)(PageHeader);
