import React from "react";
import { Alert } from "react-bootstrap";

export default function ProcessCompleted({ processCompletedResponse }) {
  return (
    <div>
      {processCompletedResponse?.success?.length > 0 && (
        <div>
          <Alert variant="success">
            <Alert.Heading>Success!</Alert.Heading>
            <p>The following clients ({processCompletedResponse?.success?.length}) have been subscribed successfully:</p>
            <ul>
              {processCompletedResponse?.success?.map((client, index) => (
                <li key={index}>
                  <strong>
                    {client?.username} ({client?.email})
                  </strong>
                </li>
              ))}
            </ul>
          </Alert>
        </div>
      )}
      {processCompletedResponse?.fail?.length > 0 && (
        <div>
          <Alert variant="danger">
            <Alert.Heading>Error!</Alert.Heading>
            <p>
              {processCompletedResponse?.fail?.length === 1
                ? `${processCompletedResponse?.fail?.length} client failed to subscribe.`
                : `${processCompletedResponse?.fail?.length} clients failed to subscribe.`}
            </p>
            <p>Please check your payment method or contact the support.</p>
            <p>{processCompletedResponse?.fail?.length === 1 ? "Client" : "Clients"} that failed to subscribe:</p>
            <ul>
              {processCompletedResponse?.fail?.map((client, index) => (
                <li key={index}>
                  <strong>
                    {client?.username} ({client?.email})
                  </strong>
                </li>
              ))}
            </ul>
          </Alert>
        </div>
      )}
    </div>
  );
}
