import React, { useEffect, useState } from "react";
import { Suspense } from "react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import withMenu from "../../hoc/withMenu";
import withSelectedMenu from "../../hoc/withSelectedMenu";
import backimg from "../../images/foodi-footer.png";
import { store } from "../../store-redux/connect/store";
import { useLocation } from "react-router-dom";
import styles from "./MenuWrapper.module.scss";
import useInterval from "../../hoc/useInterval";
import general from "../../helpers/general";
import IncomingMenus from "../Alerts/IncomingMenus";
import { IKImage } from "imagekitio-react";

function MenuWrapper(props) {
  const { selectedMenu, setSelectedMenu } = props;
  const location = useLocation();
  const [LazyLoadedTemplate, setLazyLoadedTemplate] = useState(null);
  const { Menu, Auth } = store.getState();
  const [notifyMenus, setNotifyMenus] = useState(null);

  const delay = 30000;
  const interval = useInterval(checkClosestMenu, delay);

  function checkClosestMenu() {
    const menus = props?.data?.menus;
    const notifyMenus = general?.findClosestMenus(props?.menusTimeline, menus, props?.data?.menu_period_transition);
    if (notifyMenus?.length > 0) {
      interval?.reset();
      setNotifyMenus(notifyMenus);
    }
  }

  useEffect(() => {
    if (location?.state?.menu && location?.state?.menu?._id !== selectedMenu?._id) {
      setSelectedMenu(props?.data?.menus?.find((menu) => menu?._id?.toString() === location?.state?.menu?._id?.toString()));
      window.scrollTo({ top: 0 });
    }
    if (location?.state?.menuIDSelectedFromCustomer && props?.data?.menus?.length > 0) {
      setSelectedMenu(
        props?.data?.menus?.find((menu) => menu?._id?.toString() === location?.state?.menuIDSelectedFromCustomer?.toString())
      );
    }
    location.state = undefined;
    if (window?.history?.replaceState) {
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    let template = "Classic Template";
    if (selectedMenu?._id && selectedMenu?.selectedTemplate) {
      if (selectedMenu?.selectedTemplate !== "undefined") {
        template = selectedMenu?.selectedTemplate;
      }
      setLazyLoadedTemplate(React.lazy(() => import(`../../Templates/${template}/Menu`)));
    }
    // eslint-disable-next-line
  }, [selectedMenu]);

  const onCloseIncomingMenu = () => {
    setNotifyMenus([]);
    interval?.start();
  };

  const onSwitchIncomingMenu = async () => {
    if (notifyMenus?.length === 1) {
      const menu = notifyMenus?.[0];
      if (menu && typeof menu === "object") {
        const response = await props?.getCustomerMenu(props?.data?._id);
        if (response?.message?.restaurant?.menus?.length) {
          const toAddMenu = response?.message?.restaurant?.menus?.find((mn) => mn?._id?.toString() === menu?._id?.toString());
          setTimeout(() => {
            setSelectedMenu(toAddMenu);
          }, 500);
        }
      }
    } else {
      await props?.getCustomerMenu(props?.data?._id);
      setSelectedMenu(null);
    }
    setNotifyMenus([]);
    interval?.start();
  };

  if (props?.data?.menus?.length > 1 && !selectedMenu) {
    return (
      <>
        <div className={styles.introPage}>
          {notifyMenus && notifyMenus?.length > 0 && (
            <IncomingMenus
              currentMenu={selectedMenu}
              menus={notifyMenus}
              minutes={props?.data?.menu_period_transition}
              onClose={onCloseIncomingMenu}
              onSwitch={onSwitchIncomingMenu}
            />
          )}
          <div className={styles.introHeader}>
            {props?.data?.logo ? (
              <IKImage
                path={props?.data?.logo}
                transformation={[{ width: 300 }]}
                loading="lazy"
                lqip={{ active: true }}
                alt={props?.data?.name}
                className={styles.restaurantLogo}
              />
            ) : (
              <img className={styles.restaurantLogo} src={backimg} alt={props?.data?.name} />
            )}
            <div className={styles.menuDescription}>{props?.data?.description}</div>
            <h5>Choose a menu:</h5>
          </div>
          <div className={styles.multipleMenus}>
            {props?.data?.menus?.map((menu) => (
              <div
                key={menu?._id}
                className={styles.menu}
                onClick={() => {
                  setSelectedMenu(menu);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  if (Auth?.LOGGED_IN()) {
                    Menu?.setMenuSelectedLocally(menu);
                    localStorage.setItem("menuSelected", JSON.stringify(menu));
                  }
                }}
              >
                {menu?.cover ? (
                  <IKImage
                    path={menu?.cover}
                    transformation={[{ width: 300, height: 450 }]}
                    loading="lazy"
                    lqip={{ active: true }}
                    alt={menu?.name}
                  />
                ) : (
                  <img src={backimg} alt={menu.name} />
                )}
                <div className={styles.menuContent}>
                  <h3>{menu.name}</h3>
                </div>
              </div>
            ))}
            {props?.data?.parentRestaurant?.menus.map((menu) => (
              <div
                key={menu?._id}
                className={styles.menu}
                onClick={() => {
                  setSelectedMenu(menu);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  if (Auth?.LOGGED_IN()) {
                    Menu?.setMenuSelectedLocally(menu);
                    localStorage.setItem("menuSelected", JSON.stringify(menu));
                  }
                }}
              >
                {menu?.cover ? (
                  <IKImage
                    path={menu?.cover}
                    transformation={[{ width: 300, height: 450 }]}
                    loading="lazy"
                    lqip={{ active: true }}
                    alt={menu?.name}
                  />
                ) : (
                  <img src={backimg} alt={menu.name} />
                )}
                <div className={styles.menuContent}>
                  <h3>{menu.name}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        {LazyLoadedTemplate && (
          <Suspense fallback={<></>}>
            {notifyMenus && notifyMenus?.length > 0 && (
              <IncomingMenus
                currentMenu={selectedMenu}
                menus={notifyMenus}
                minutes={props?.data?.menu_period_transition}
                onClose={onCloseIncomingMenu}
                onSwitch={onSwitchIncomingMenu}
              />
            )}
            <LazyLoadedTemplate {...props} />
          </Suspense>
        )}
      </>
    );
  }
}

export default withMenu(withSelectedMenu(MenuWrapper));
