import { Api, Endpoint } from "../../api";
import { store } from "../connect/store";

export const GET_RESTAURANT = "GET_RESTAURANT";
export const RESTAURANT_FETCHED_SUCCESSFULLY = "RESTAURANT_FETCHED_SUCCESSFULLY";
export const RESTAURANT_FETCHED_FAILED = "RESTAURANT_FETCHED_SUCCESSFULLY";
export const RESTAURANT_RESET = "RESTAURANT_RESET";
export const RESTAURANT_MEMBERS_LOADED_SUCCESSFULLY = "RESTAURANT_MEMBERS_LOADED_SUCCESSFULLY";
export const QR_TEMPLATES_LOADED_SUCCESSFULLY = "QR_TEMPLATES_LOADED_SUCCESSFULLY ";

export const methods = {
  find: async (obj = false) => {
    const state = store.getState().Restaurant;
    const forceRender = typeof obj === "object" && obj.forceRender ? true : false;

    if (forceRender) {
      const response = await Api.call(Endpoint.getRestaurant);
      if (response?.confirmation === "Success" && response?.message?.length > 0) {
        store.dispatch({ type: RESTAURANT_FETCHED_SUCCESSFULLY, restaurant: response?.message[0] });
        return response;
      }
      store.dispatch({ type: RESTAURANT_FETCHED_FAILED });
      return response;
    }

    if (state?.restaurant && Object.keys(state?.restaurant)?.length === 0) return await methods.find({ forceRender: true });
    return state?.restaurant;
  },
  update: async (data) => {
    const response = await Api.call(Endpoint.updateRestaurant, { data });
    store.dispatch({ type: RESTAURANT_FETCHED_SUCCESSFULLY, restaurant: response?.message });
    return response;
  },
  loadMembers: async (params = {}) => {
    const state = store.getState().Restaurant;
    if (params?.forceRender) {
      const response = await Api.call(Endpoint.getMembers);
      if (response?.confirmation === "Success") {
        store.dispatch({
          type: RESTAURANT_MEMBERS_LOADED_SUCCESSFULLY,
          members: response?.message?.members ? response?.message.members : [],
        });
      }
      return response;
    }

    if (state?.members?.length === 0) return await methods.loadMembers({ forceRender: true });
  },
};
