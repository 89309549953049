import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import general from "../../helpers/general";
import styles from "./IngredientAttribution.module.scss";

export default function IngredientAttribution({ Auth, addIngredient, ingredients, selectedIngredients, setSelectedIngredients }) {
  const [ingredientsCopy, setIngredientsCopy] = useState(ingredients);

  useEffect(() => {
    setIngredientsCopy(ingredients);
  }, [ingredients]);

  const addToSelected = (ingredient) => {
    setSelectedIngredients([...selectedIngredients, ingredient]);
  };

  const removeFromSelected = (ingredient) => {
    let temp = [...selectedIngredients];
    temp = temp.filter((ing) => ing?._id !== ingredient?._id);
    setSelectedIngredients(temp);
  };

  const filterIngredients = (e) => {
    let temp = [...ingredients];
    temp = temp.filter((ing) => ing?.name.toLowerCase().includes(e.target.value.toLowerCase()));
    setIngredientsCopy(temp);
  };

  return (
    <Form.Group>
      <Row style={{ marginBottom: 15 }}>
        <Col md={6} sm={12}>
          <Form.Label>Ingredients</Form.Label>
        </Col>
        <Col md={6} sm={12} className="text-right">
          <div>
            {general?.checkPermissions({ exceptPermissions: ["ingredient"], originalPermissions: Auth?.user?.permissions }) && (
              <Button size="sm" type="button" onClick={() => addIngredient()}>
                Add ingredients
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12} className="mobile-order-1">
          <p style={{ marginTop: 54, fontSize: 14, color: "var(--text-color)" }}>Selected Ingredients</p>
          <div className={styles.ingredientBox}>
            <ul>
              {selectedIngredients &&
                selectedIngredients.length !== 0 &&
                selectedIngredients.map((ing) => (
                  <li className={styles.notSelectable} key={ing._id} onClick={() => removeFromSelected(ing)}>
                    {ing?.name}
                  </li>
                ))}

              {selectedIngredients && selectedIngredients.length === 0 && (
                <p style={{ fontSize: 14, color: "var(--text-color)", margin: 20, textAlign: "center" }}>
                  There aren't any selected ingredients on this dish!
                </p>
              )}
            </ul>
          </div>
        </Col>
        <Col md={6} sm={12} className="mobile-order-0">
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <Button className="fas fa-search"></Button>
            </InputGroup.Prepend>
            <FormControl placeholder="Search" aria-label="Search" aria-describedby="basic-addon1" onChange={filterIngredients} />
          </InputGroup>

          <p style={{ fontSize: 14, color: "var(--text-color)" }}>All Ingredients</p>

          <div className={styles.ingredientBox}>
            <ul>
              {ingredientsCopy &&
                ingredientsCopy
                  .filter((ingredient) => !selectedIngredients.map((selected) => selected._id).includes(ingredient._id))
                  .map((ing) => (
                    <li className={styles.notSelectable} key={ing._id} onClick={() => addToSelected(ing)}>
                      {ing?.name}
                    </li>
                  ))}
            </ul>
          </div>
        </Col>
      </Row>
    </Form.Group>
  );
}
