import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import LoadingSpinner from "../../LoadingSpinner";
import ModalFooter from "../../Modal/ModalFooter";
import SelectDishes from "../../SelectDishes";

function AddDishToCategory({ Menu, Category, Dish, categoryID, closeModal, category }) {
  const [selectedDishes, setSelectedDishes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { dishes } = Dish;

  const addToSelect = (id) => {
    let temp = [...selectedDishes];
    temp?.includes(id) ? temp.splice(temp.indexOf(id), 1) : temp.push(id);
    setSelectedDishes(temp);
  };

  const submit = async () => {
    setLoading(true);
    await Category.addDishes(categoryID, selectedDishes);
    closeModal();
    setLoading(false);
  };

  useEffect(() => {
    Dish.load();
    if (typeof category === "object" && category?.dishes) setSelectedDishes(category?.dishes?.map((dish) => dish?._id));
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <h5>Select dishes </h5>
      <SelectDishes addToSelect={addToSelect} selectedDishes={selectedDishes} dishes={dishes} />
      <ModalFooter>
        <Button onClick={submit}>{loading ? <LoadingSpinner /> : "Save Changes"}</Button>
        {typeof closeModal === "function" && (
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        )}
      </ModalFooter>
    </div>
  );
}

const mapStateToProps = (state) => ({ Menu: state.Menu, Category: state.Category, Dish: state.Dish });
export default connect(mapStateToProps)(AddDishToCategory);
