import React, { useRef, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Api, Endpoint } from "../../api";
import LoadingSpinner from "../../components/LoadingSpinner";
import Helpers from "../../helpers/general";

const { passwordRegex, passwordMessage } = Helpers.getPasswordRegex();

export default function EnterPasswordAndVerify({ token, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const submit = async (data) => {
    data.token = token;
    setLoading(true);
    const response = await Api.call(Endpoint.verifyAccountAndEnterPassword, { data });
    if (response?.statusCode !== 200) {
      setLoading(false);
      setError(response?.message);
      return;
    }
    setLoading(false);
    setError(null);
    onSuccess();
  };

  return (
    <div>
      <div className="mb-4">
        <h2>Verify your account</h2>
        <p>Please enter an password</p>
      </div>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit(submit)}>
        <Form.Group>
          <i className="fal fa-lock"></i>
          <Form.Control
            ref={register({
              required: "Password cannot be empty",
              pattern: { value: passwordRegex, message: passwordMessage },
            })}
            type="password"
            name="password"
            placeholder="Password *"
          />
          {errors?.password?.message && <small className="form-error-text">{errors?.password?.message}</small>}
        </Form.Group>
        <Form.Group>
          <i className="fal fa-lock"></i>
          <Form.Control
            ref={register({
              required: "Confirm password cannot be empty",
              validate: (value) => value === password.current || "The password confirmation does not match.",
            })}
            type="password"
            name="confirm_password"
            placeholder="Confirm Password *"
          />
          {errors?.confirm_password?.message && <small className="form-error-text">{errors?.confirm_password?.message}</small>}
        </Form.Group>

        <div className="d-flex justify-content-end">
          <Button disabled={Object.keys(errors).length > 0 || loading ? true : false} type="submit">
            {loading ? <LoadingSpinner size="sm" /> : "Verify"}
          </Button>
        </div>
      </Form>
    </div>
  );
}
