import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Checkbox from "../../../components/Checkbox";
import { toast } from "react-toastify";
import { Api, Endpoint } from "../../../api";
import styles from "./DietaryConsiderations.module.scss";
import Modal from "../../Modal";
import DeleteConfirmation from "../DeleteConfirmation";
import EditDietary from "./EditDietary";
import LoadingSpinner from "../../LoadingSpinner";

export default function DietaryConsiderations({ register, setValue, Dish, Restaurant, update, Admin }) {
  // We will use dietaryConsiderations to render in the view.
  const [dietaryConsiderations, setDietaryConsiderations] = useState({
    admin: { diets: [], foodTypes: [], other: [] },
  });

  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  // When user adds custom
  const [restaurantDietary, setRestaurantDietary] = useState({ diets: [], foodTypes: [], other: [] });

  /* We will use dietaryConsiderationsApi to send directly to the api. */
  const [dietaryConsiderationsApi, setDietaryConsiderationsApi] = useState({
    admin: { diets: [], foodTypes: [], other: [] } /* we store id-s in admin field */,
    selected: { diets: [], foodTypes: [], other: [] } /* we store names in selected field */,
  });

  /* 
        When user checked an admin dietary field 
        Example structure: diets: { [id]: boolean }
    */
  const [checkedAdminDietary, setCheckedAdminDietary] = useState({ diets: {}, foodTypes: {}, other: {} });

  /* 
        When user checked an dietary that he creates. 
        Example structure: diets: { [id]: boolean }
    */
  const [checkedDefaultDietary, setCheckedDefaultDietary] = useState({ diets: {}, foodTypes: {}, other: {} });

  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  useEffect(() => {
    setValue("restaurantDietaryConsiderations", JSON.stringify(restaurantDietary));
    // eslint-disable-next-line
  }, [restaurantDietary]);

  useEffect(() => {
    /* When user toggle admin dietary checkbox */
    let temp = { ...checkedAdminDietary };
    for (const key in temp) {
      temp[key] = Object.keys(temp[key]).filter((el) => temp[key][el]);
    }
    setDietaryConsiderationsApi({ ...dietaryConsiderationsApi, admin: temp });
    // eslint-disable-next-line
  }, [checkedAdminDietary]);

  useEffect(() => {
    /* When user toggle restaurant dietary checkbox */
    let temp = { ...checkedDefaultDietary };
    for (const key in temp) {
      temp[key] = Object.keys(temp[key]).filter((el) => temp[key][el]);
    }
    setDietaryConsiderationsApi({ ...dietaryConsiderationsApi, selected: temp });
    // eslint-disable-next-line
  }, [checkedDefaultDietary]);

  useEffect(() => {
    setValue("dietaryConsiderations", JSON.stringify(dietaryConsiderationsApi));
    // eslint-disable-next-line
  }, [dietaryConsiderationsApi]);

  useEffect(() => {
    if (Dish?._id && Dish?.dietaryConsiderations) {
      let tempDietaryConsiderations = { ...Dish?.dietaryConsiderations };
      let tempDefaultDietary = { ...checkedDefaultDietary };
      let tempAdminDietary = { ...checkedAdminDietary };

      for (const key in tempDietaryConsiderations?.selected) {
        if (tempDietaryConsiderations?.selected[key] && tempDietaryConsiderations?.selected[key]?.length) {
          tempDietaryConsiderations.selected[key].forEach((el) => (tempDefaultDietary[key][el] = true));
        }
      }

      setCheckedDefaultDietary(tempDefaultDietary);

      for (const key in tempDietaryConsiderations?.admin) {
        if (tempDietaryConsiderations?.admin[key]?.length) {
          tempDietaryConsiderations.admin[key]?.forEach((el) => (tempAdminDietary[key][el?._id?.toString()] = true));
        }
      }

      setTimeout(() => setCheckedAdminDietary(tempAdminDietary), 500);
    }
    // eslint-disable-next-line
  }, [Dish]);

  useEffect(() => {
    if (Restaurant?.dietaryConsiderations) {
      setRestaurantDietary(Restaurant?.dietaryConsiderations);
    }
    // eslint-disable-next-line
  }, [Restaurant]);

  useEffect(() => {
    if (Admin?.dietaryConsiderations) {
      setDietaryConsiderations({ ...dietaryConsiderations, admin: Admin?.dietaryConsiderations });
    }
    // eslint-disable-next-line
  }, [Admin?.dietaryConsiderations]);

  useEffect(() => {
    register({ name: "dietaryConsiderations", value: JSON.stringify(dietaryConsiderationsApi) });
    register({ name: "restaurantDietaryConsiderations", value: JSON.stringify(restaurantDietary) });
    // eslint-disable-next-line
  }, []);

  const handleOnChangeAdminDietary = (type, id, checked) => {
    let temp = { ...checkedAdminDietary };
    if (!temp[type]) {
      temp[type] = {};
    }
    temp[type] = { ...temp[type], [id]: checked };
    setCheckedAdminDietary(temp);
  };

  const handleOnChangeRestaurantDietary = (type, name, checked) => {
    let temp = { ...checkedDefaultDietary };
    if (!temp[type]) {
      temp[type] = {};
    }
    temp[type] = { ...temp[type], [name]: checked };
    setCheckedDefaultDietary(temp);
  };

  const onChangeAdminDietaryExists = (item, val, except = false) => {
    let tempAdminDietary = { ...dietaryConsiderations };
    let array = tempAdminDietary?.admin[item];
    for (let i = 0; i < array?.length; i++) {
      if (array[i]?.name?.toString()?.toLowerCase()?.trim() === val?.toString()?.toLowerCase()?.trim()) {
        return true;
      }
    }
    return false;
  };

  const onChangeRestaurantDietaryExists = (item, val, except) => {
    let temp = { ...restaurantDietary };
    let array = temp[item];
    for (let i = 0; i < array?.length; i++) {
      if (array[i]?.toString()?.toLowerCase()?.trim() === val?.toString()?.toLowerCase()?.trim()) {
        return true;
      }
    }
    return false;
  };

  const handleKeyDown = (e, item) => {
    if (e.key === "Enter" || e?.key === "Tab") {
      let temp = { ...restaurantDietary };
      const value = e?.target?.value;
      if (onChangeRestaurantDietaryExists(item, value) || onChangeAdminDietaryExists(item, value)) {
        toast(`${e.target.value} is already in ${item} list!`);
        e.target.value = "";
        return;
      }

      temp[item].push(e.target.value);
      handleOnChangeRestaurantDietary(item, e?.target?.value, true);
      e.target.value = "";
      setRestaurantDietary(temp);
    }
  };

  const removeDietary = (dietary, key, index) => {
    setModal({
      ...modal,
      visible: true,
      title: "Delete dietary",
      Component: (
        <DeleteConfirmation
          id={""}
          type="Dietary"
          funk={async (id) => {
            const data = { type: key, name: dietary };
            const response = await Api.call(Endpoint?.deleteDietary, { data });
            if (response?.confirmation === "Success") {
              let checkedTemp = { ...checkedDefaultDietary };
              let restaurantDietaryTemp = { ...restaurantDietary };
              let typeTemp = { ...checkedTemp[key] };

              delete typeTemp[dietary];
              checkedTemp[key] = typeTemp;
              restaurantDietaryTemp[key].splice(index, 1);

              setRestaurantDietary(restaurantDietaryTemp);
              setCheckedDefaultDietary(checkedTemp);
              toast("Dietary deleted successfully!");
              return;
            }
            toast(response?.message || "Something went wrong.");
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const prepareEditDietary = async (dietary, type, index) => {
    setModal({
      ...modal,
      visible: true,
      title: "Edit dietary",
      Component: (
        <EditDietary
          {...modal}
          restaurantDietary={restaurantDietary}
          adminDietary={dietaryConsiderations}
          defaultValue={dietary}
          name={dietary}
          type={type}
          dietaryEditedSuccessfully={(newDietaryName) => dietaryEditedSuccessfully(dietary, newDietaryName, type, index)}
          Dish={Dish}
        />
      ),
    });
  };

  const dietaryEditedSuccessfully = (oldDietaryName, newDietaryName, type, index) => {
    let checkedTemp = { ...checkedDefaultDietary };
    let restaurantDietaryTemp = { ...restaurantDietary };
    let typeTemp = { ...checkedTemp[type] };

    let checkedDietary = typeTemp[oldDietaryName] || false;
    delete typeTemp[oldDietaryName];
    typeTemp[newDietaryName] = checkedDietary;
    checkedTemp[type] = typeTemp;

    restaurantDietaryTemp[type].splice(index, 1);
    restaurantDietaryTemp[type].push(newDietaryName);

    setRestaurantDietary(restaurantDietaryTemp);
    setCheckedDefaultDietary(checkedTemp);
  };

  const renderRestaurantDietary = (key) => {
    if (!restaurantDietary || !restaurantDietary[key]) return null;
    return restaurantDietary[key].map((dietary, index) => (
      <div key={index} className={styles?.dishDietaryWrapper}>
        <Form.Label>
          <Checkbox
            size="small"
            label="small"
            checked={checkedDefaultDietary[key] && checkedDefaultDietary[key][dietary] ? true : false}
            onChange={(e) => handleOnChangeRestaurantDietary(key, dietary, e?.target?.checked)}
          />
          <span style={{ fontWeight: "normal", textTransform: "none" }}>{dietary}</span>
        </Form.Label>

        <i className="far fa-edit" onClick={() => prepareEditDietary(dietary, key, index)}></i>

        <i className="far fa-trash-alt" onClick={() => removeDietary(dietary, key, index)}></i>
      </div>
    ));
  };

  return (
    <div>
      <Modal {...modal} />
      <Form.Group>
        <div className="d-flex justify-content-between mobile-direction-column" style={{ marginBottom: 10 }}>
          <Form.Label>Dietary Considerations</Form.Label>
          <div>
            <small style={{ color: "rgb(149, 144, 144)" }}>Add multiple items by hitting Tab, Enter, or Return after each item.</small>
          </div>
        </div>
        {loading ? (
          <>
            <div className={styles?.loadingWrapper}>
              <LoadingSpinner />
              <p>Loading dietary...</p>
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-between pl-2 pr-2 mobile-direction-column has-gap">
            {dietaryConsiderations &&
              dietaryConsiderations?.admin &&
              Object?.keys(dietaryConsiderations?.admin).map((dietaryKey, dietaryKeyIndex) => (
                <Form.Group key={dietaryKeyIndex}>
                  <Form.Label>{dietaryKey}</Form.Label>
                  <br />
                  {dietaryConsiderations?.admin[dietaryKey]?.length > 0 &&
                    dietaryConsiderations?.admin[dietaryKey].map((dietary, index) => (
                      <div key={index}>
                        <Form.Label>
                          <Checkbox
                            size="small"
                            label="small"
                            checked={checkedAdminDietary[dietaryKey] && checkedAdminDietary[dietaryKey][dietary?._id] ? true : false}
                            onChange={(e) => {
                              handleOnChangeAdminDietary(dietaryKey, dietary?._id, e?.target?.checked);
                            }}
                          />
                          <span style={{ fontWeight: "normal", textTransform: "none" }}>{dietary?.name}</span>
                        </Form.Label>
                      </div>
                    ))}
                  {renderRestaurantDietary(dietaryKey)}
                  <div className="d-flex">
                    <Checkbox size="small" label="small" disabled />
                    <Form.Control
                      size="small"
                      type="text"
                      style={{ padding: "0 .75em", height: "auto", maxWidth: 120 }}
                      placeholder="Other"
                      onKeyDown={(e) => {
                        handleKeyDown(e, dietaryKey);
                      }}
                    />
                  </div>
                </Form.Group>
              ))}
          </div>
        )}
      </Form.Group>
    </div>
  );
}
