import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ModalFooter from "./ModalFooter";
import { toast } from "react-toastify";
import LoadingSpinner from "../LoadingSpinner";

function GeneralModal({ id, funk, closeModal, customMessage, customButtonMessage, toastMessage }) {
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);
    const response = await funk(id);
    if (response?.confirmation === "Success") {
      toast(toastMessage);
    } else {
      toast(response?.message || "Something went wrong.");
    }
    setLoading(false);
  };

  return (
    <div>
      <div style={{ marginBottom: 20, textAlign: "center" }}>{customMessage}</div>
      <ModalFooter>
        <Button disabled={loading} onClick={submit}>
          {loading ? <LoadingSpinner /> : customButtonMessage}
        </Button>
        {typeof closeModal === "function" && (
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        )}
      </ModalFooter>
    </div>
  );
}

export default GeneralModal;
