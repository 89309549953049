import styles from "../../Layouts/AdminPanel.module.scss";
import React, { useState } from "react";

import Header from "../Header";
import TopBar from "../Header/TopBar";

export default function NotFound() {
  const [navCollapsed, setNavCollapsed] = useState(false);
  return (
    <section className={styles.panel}>
      <TopBar />
      <Header navCollapsed={navCollapsed} setNavCollapsed={setNavCollapsed} />
      <div className={styles.mainWrapper}>
        <main>
          <div className="container">
            <div className="bodyWrapper white">
              <h1>404 Page Not Found</h1>
            </div>
          </div>
        </main>
      </div>
    </section>
  );
}
