import React, { useEffect, useState } from "react";
import { Api, Endpoint } from "../../api";
import Section from "../../components/Section/Section";
import DeleteConfirmation from "../../components/Forms/DeleteConfirmation";
import Modal from "../../components/Modal";
import MediaForm from "../../components/Forms/MediaForm";
import styles from "./MediaSection.module.scss";
import LoadingSpinner from "../../components/LoadingSpinner";
import EditMedia from "./EditMedia";
import { Tooltip } from "@material-ui/core";
import Pagination from "../../components/Pagination";
import withDashboardLayout from "../../Layouts/AdminPanel";
import { IKImage } from "imagekitio-react";

function MediaSection() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const [pagination, setPagination] = useState({});

  const getData = async (query = {}) => {
    const response = await Api.call(Endpoint.listMedia, { query });
    if (response?.message?.data && response?.message?.pagination) {
      setData(response?.message?.data);
      setPagination(response?.message?.pagination);
    }
    setLoading(false);
  };

  const deleteFile = async (_, fileName) => {
    setModal({
      ...modal,
      visible: true,
      confirmation: true,
      Component: (
        <DeleteConfirmation
          id={fileName}
          type="Media"
          funk={async (id) => {
            await Api.call(Endpoint.deleteMedia, { data: { fileName: fileName } });
            if (data?.length === 1 && pagination && pagination?.page > 1) {
              setPagination({ ...pagination, page: pagination?.page - 1 });
              await getData({ currentPage: pagination?.page - 1 });
            } else {
              await getData({ currentPage: pagination?.page });
            }
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const uploadFile = async () => {
    setModal({
      ...modal,
      title: "Add Media",
      visible: true,
      Component: <MediaForm getData={getData} pagination={pagination} closeModal={() => setModal({ ...modal, visible: false })} />,
    });
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);

  const editFile = (img) => {
    setModal({
      ...modal,
      title: "Edit/Crop media",
      visible: true,
      Component: (
        <EditMedia getData={getData} pagination={pagination} img={img} closeModal={() => setModal({ ...modal, visible: false })} />
      ),
    });
  };

  const changePage = async (e, page) => {
    setPagination({ ...pagination, page: page + 1 });
    await getData({ currentPage: page + 1 });
  };

  return (
    <React.Fragment>
      <Modal {...modal} />
      <Section
        tooltip={{ title: "You can upload medias one at a time, or upload all of them at once." }}
        title={`Media section ${pagination?.total !== undefined ? `(${pagination?.total})` : ""}`}
        action={{ name: "Upload media", funk: () => uploadFile() }}
      >
        <div>
          <div className={styles.mediaSection}>
            {loading ? (
              <div className={styles.loadingSpinnerParent}>
                <LoadingSpinner />
                <p>Loading data...</p>
              </div>
            ) : data && data.length > 0 ? (
              data?.map((img, i) => (
                <div className={styles.imageWrapper} key={i}>
                  <div style={{ paddingBottom: "100%", position: "relative", width: "100%" }}>
                    <IKImage
                      path={img?.key}
                      transformation={[{ width: 300, height: 300 }]}
                      loading="lazy"
                      lqip={{ active: true }}
                      alt={img.fileName}
                    />

                    <div className={styles.actions}>
                      <Tooltip title="Delete image" enterTouchDelay={0}>
                        <button className={styles.deleteBtn} onClick={() => deleteFile(img, img.fileName)}>
                          <i className={`${styles.icon} far fa-trash-alt`}></i>
                        </button>
                      </Tooltip>
                      <Tooltip title="Edit/Crop image" enterTouchDelay={0}>
                        <button className={styles.editBtn} onClick={() => editFile(img, img.fileName)}>
                          <i className={`${styles.icon} far fa-pen`}></i>
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.loadingSpinnerParent}>
                <h5>No data available</h5>
              </div>
            )}
          </div>

          {data && data?.length > 0 && (
            <div className={styles?.loadMoreWrapper}>
              <Pagination changePage={changePage} currentPage={pagination?.page - 1} totalPages={pagination?.total_pages} />
            </div>
          )}
        </div>
      </Section>
    </React.Fragment>
  );
}

export default withDashboardLayout(MediaSection);
