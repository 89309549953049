import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Api, Endpoint } from "../../../api";
import stripe from "../../../utils/stripe";
import LoadingSpinner from "../../LoadingSpinner";
import ChoosePlan from "../DMA/ClientsSubscribe/ChoosePlan";

const STEPS = {
  ADD_USER: "ADD_USER",
  MAKE_PURCHASE: "MAKE_PURCHASE",
};

export default function AddUserForm({ closeModal, loadUsers }) {
  const { register, handleSubmit, errors } = useForm();
  const [step, setStep] = useState(STEPS.ADD_USER);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userIdCreated, setUserIdCreated] = useState(null);
  const [baseProduct, setBaseProduct] = useState(null);
  const [planSelected, setPlanSelected] = useState(null);

  // TODO: Split the steps ADD_USER and MAKE_PURCHASE into different components.

  const getBaseProduct = async () => {
    const response = await Api.call(Endpoint.subscription.baseProduct);
    if (response?.statusCode === 200) {
      setBaseProduct(response?.message);
    }
  };

  const submit = async (data) => {
    setLoading(true);
    const response = await Api.call(Endpoint.admin.user.add, { data });
    if (response?.statusCode !== 200) {
      setLoading(false);
      setError(response?.message);
      return;
    }
    setUserIdCreated(response?.message?.newUser?._id);
    await getBaseProduct();
    loadUsers();
    setError(null);
    setLoading(false);
    setStep(STEPS.MAKE_PURCHASE);
  };

  const makePurchase = async () => {
    if (!planSelected) {
      setError("You must select an plan");
      return;
    }
    setLoading(true);
    const data = {
      plan_id: planSelected,
      user_id: userIdCreated,
    };
    const response = await Api.call(Endpoint.admin.user.subscribe, { data });
    setLoading(false);
    if (response?.message?.id) {
      stripe.redirectToCheckout(response?.message?.id);
    } else {
      setError(response?.message);
    }
  };

  if (step === STEPS.ADD_USER) {
    return (
      <div>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit(submit)}>
          <Form.Group>
            <i className="fal fa-user"></i>
            <Form.Control ref={register} type="text" name="firstName" placeholder="First Name" />
          </Form.Group>
          <Form.Group>
            <i className="fal fa-user"></i>
            <Form.Control ref={register} type="text" name="lastName" placeholder="Last Name" />
          </Form.Group>
          <Form.Group>
            <i className="fal fa-user"></i>
            <Form.Control ref={register({ required: "Username cannot be empty" })} type="text" name="username" placeholder="Username *" />
            {errors?.username?.message && <small className="form-error-text">{errors?.username?.message}</small>}
          </Form.Group>
          <Form.Group>
            <i className="fal fa-envelope"></i>
            <Form.Control
              ref={register({
                required: "Email cannot be empty",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              type="email"
              name="email"
              placeholder="E-mail *"
            />
            {errors?.email?.message && <small className="form-error-text">{errors?.email?.message}</small>}
          </Form.Group>

          <Form.Group>
            <i className="fal fa-lock"></i>
            <Form.Control
              ref={register({ required: "Restaurant name cannot be empty" })}
              type="text"
              name="restaurantName"
              placeholder="Restaurant Name *"
            />
            {errors?.restaurantName?.message && <small className="form-error-text">{errors?.restaurantName?.message}</small>}
          </Form.Group>

          <div className="d-flex justify-content-end">
            <Button disabled={Object.keys(errors).length > 0 || loading ? true : false} type="submit">
              {loading ? <LoadingSpinner size="sm" /> : "Add user"}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
  return (
    <div>
      <div className={error ? `mb-4` : ""}>
        <h5>Do you want to subscribe for this user?</h5>
      </div>

      {error && <Alert variant="danger">{error}</Alert>}

      <div className="mb-3">
        <ChoosePlan plans={baseProduct} planSelected={planSelected} setPlanSelected={setPlanSelected} planDesc="per user" />
      </div>
      <div className="d-flex justify-content-end">
        <Button variant="secondary" style={{ marginRight: "20px" }} onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button disabled={Object.keys(errors).length > 0 || loading ? true : false} onClick={makePurchase}>
          {loading ? <LoadingSpinner size="sm" /> : "Subscribe"}
        </Button>
      </div>
    </div>
  );
}
