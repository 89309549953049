import React, { useState, useRef } from "react";
import LoadingSpinner from "../../LoadingSpinner";
import registerStyle from "../../../Views/Register/Register.module.scss";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Form, Button, Alert } from "react-bootstrap";
import Helpers from "../../../helpers/general";

import { Api, Endpoint } from "../../../api";

import ReCaptchaComponent from "../../../components/ReCaptcha";
import useReCaptcha from "../../../hoc/useReCaptcha";

const { passwordRegex, passwordMessage } = Helpers.getPasswordRegex();

export default function UpdatePassword() {
  const { reCaptchaRef, executeReCaptchaToken, onChangeReCaptcha } = useReCaptcha();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { register, handleSubmit, errors, watch, reset } = useForm();

  const new_password = useRef({});
  new_password.current = watch("new_password", "");

  const submit = async (data) => {
    setLoading(true);

    const reCaptchaToken = await executeReCaptchaToken();
    data.reCaptchaToken = reCaptchaToken;

    const response = await Api.call(Endpoint.updatePassword, { data });
    setLoading(false);

    if (response?.statusCode !== 200) {
      setError(response?.message);
      return;
    }

    setError(null);
    reset();
    toast.success("Password updated successfully");
  };
  return (
    <div>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit(submit)}>
        <ReCaptchaComponent innerRef={reCaptchaRef} onChange={onChangeReCaptcha} />
        <Form.Group className="mb-4">
          <Form.Group>
            <Form.Label>Current Password</Form.Label>
            <Form.Control
              name="current_password"
              type="password"
              ref={register({
                required: "Current password cannot be empty",
              })}
              placeholder="Current password"
            />
            {errors?.current_password?.message && (
              <small className={registerStyle.registerErrorText}>{errors?.current_password?.message}</small>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>New Password</Form.Label>
            <Form.Control
              name="new_password"
              type="password"
              ref={register({
                required: "New password cannot be empty",
                pattern: { value: passwordRegex, message: passwordMessage },
              })}
              placeholder="New password"
            />
            {errors?.new_password?.message && (
              <small className={registerStyle.registerErrorText}>{errors?.new_password?.message}</small>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              name="confirm_new_password"
              type="password"
              ref={register({
                required: "Confirm new password cannot be empty",
                pattern: { value: passwordRegex, message: passwordMessage },

                validate: (value) => value === new_password?.current || "The password confirmation does not match.",
              })}
              placeholder="Confirm New password"
            />
            {errors?.confirm_new_password?.message && (
              <small className={registerStyle.registerErrorText}>{errors?.confirm_new_password?.message}</small>
            )}
          </Form.Group>
        </Form.Group>

        <Button disabled={loading} style={{ width: "100%" }} variant="primary" type="submit">
          {loading ? <LoadingSpinner size="sm" /> : "Update Password"}
        </Button>
      </Form>
    </div>
  );
}
