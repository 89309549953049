import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemIcon, ListItemText, Radio } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: "white",
    },
  })
);

export default function ChoosePlan({ plans, planSelected, setPlanSelected, plansLoading, planDesc = "" }) {
  const classes = useStyles();

  if (!plans?.monthly_product || !plans?.yearly_product) {
    return null;
  }

  return (
    <List className={classes.root}>
      <ListItem dense button onClick={() => setPlanSelected(plans?.monthly_product?.price?.id)}>
        <ListItemIcon>
          <Radio
            style={{ color: "var(--brand)" }}
            checked={planSelected === plans?.monthly_product?.price?.id}
            onChange={() => { }}
            size="small"
          />
        </ListItemIcon>
        <ListItemText
          primary={plans?.monthly_product?.price?.recurring?.interval === "year" ? "Yearly" : "Monthly"}
          secondary={
            plansLoading ? "Loading..." : `$${(plans?.monthly_product?.price?.unit_amount / 100)?.toFixed(2)} ${planDesc ? planDesc : 'per license'}`
          }
        />
      </ListItem>

      <ListItem dense button onClick={() => setPlanSelected(plans?.yearly_product?.price?.id)}>
        <ListItemIcon>
          <Radio
            style={{ color: "var(--brand)" }}
            checked={planSelected === plans?.yearly_product?.price?.id}
            onChange={() => { }}
            size="small"
          />
        </ListItemIcon>
        <ListItemText
          primary={plans?.yearly_product?.price?.recurring?.interval === "year" ? "Yearly" : "Monthly"}
          secondary={
            plansLoading ? "Loading..." : `$${(plans?.yearly_product?.price?.unit_amount / 100)?.toFixed(2)} ${planDesc ? planDesc : 'per license'}`
          }
        />
      </ListItem>
    </List>
  );
}
