import * as TYPES from "../actions/auth";
import { methods } from "../actions/auth";

const getCurrentUserFromLocalStorage = () => {
  try {
    return JSON.parse(localStorage.getItem("user"));
  } catch (err) {
    return null;
  }
};

const initialState = {
  ...methods,
  user: getCurrentUserFromLocalStorage(),
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LOGIN_SUCCEED:
      return { ...state, user: action?.user };
    case TYPES.LOGOUT:
      return { ...state, user: null };
    case TYPES.USER_UPDATED_SUCCESSFULLY:
      return { ...state, user: action?.user };
    case TYPES.REGISTER_FAILED:
      return { ...state, user: null };
    default:
      return { ...state };
  }
};

export { authReducer };
