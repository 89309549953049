import * as TYPES from "../actions/admin"
import { methods } from "../actions/admin"

const initialState = {
    ...methods,
    users: [],
    admins: [],
    restaurants: [],
    statistics: {},
    dietaryConsiderations: {}
}

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES?.ADMIN_USERS_LOADED_SUCCESSFULLY:
            return { ...state, users: action?.users }
        case TYPES?.ADMIN_RESTAURANTS_LOADED_SUCCESSFULLY:
            return { ...state, restaurants: action?.restaurants }
        case TYPES?.ADMIN_STATISTICS_LOADED_SUCCESSFULLY:
            return { ...state, statistics: action?.statistics }
        case TYPES?.ADMIN_LOADED_SUCCESSFULLY:
            return { ...state, admins: action?.admins }
        case TYPES?.ADMIN_DIETARY_CONSIDERATIONS_LOADED:
            return { ...state, dietaryConsiderations: action?.dietaryConsiderations }
        case TYPES?.ADMIN_RESET_DATA:
            return { ...state, users: [], restaurants: [], statistics: {}, admins: [], dietaryConsiderations: {} }
        default: 
            return { ...state }
    }
} 

export { adminReducer }