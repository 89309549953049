import React from "react";
import Table from "./Table";
import general from "../../helpers/general";

export default function Ingredients({ Auth, data, addIngredient, deleteIngredients, clone }) {
  const columns = [
    {
      field: "name",
      access: "name",
    },
    general?.checkPermissions({ exceptPermissions: ["ingredient"], originalPermissions: Auth?.user?.permissions }) && {
      field: "Actions",
      render: ({ row, column, index }) => (
        <div className={`actionsWrapper`}>
          <>
            {!row?.fromParent && <i onClick={() => addIngredient(row)} className={`edit-icon fal fa-edit`} />}
            <i onClick={() => clone(row)} className={`edit-icon fal fa-copy`} />
            {!row?.fromParent && (
              <i
                onClick={() => {
                  if (row?._id) {
                    deleteIngredients([row?._id], row?.name);
                    return;
                  }
                  deleteIngredients(row);
                }}
                className={`delete-icon far fa-trash-alt`}
              />
            )}
          </>
        </div>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      rows={data || []}
      enableLocalPagination={true}
      enableSelectMultiple={true}
      onDeleteMultiple={(ids) => deleteIngredients(ids)}
    />
  );
}
