import React from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Editor = {};

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link"];

export default function TextEditor(props) {
  return (
    <ReactQuill
      {...props}
      style={{ opacity: props?.disabled ? 0.7 : 1, pointerEvents: props?.disabled ? "none" : "all" }}
      theme="snow"
      modules={Editor.modules}
      formats={Editor.formats}
    />
  );
}
