import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

export default function HorizontalLabelPositionBelowStepper({ activeStep, steps, handleNext, handleBack, handleReset }) {
  return (
    <div>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label} style={{ color: "red" }}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div></div>
    </div>
  );
}
