import React, { useState, useRef } from "react";
import { Button } from "react-bootstrap";
import AvatarEditor from "react-avatar-editor";
import styles from "./MediaSection.module.scss";
import { Api, Endpoint } from "../../api";
import LoadingSpinner from "../../components/LoadingSpinner";

export default function EditMedia({ img, getData, pagination, closeModal }) {
  const canvas = useRef();
  const [scale, setScale] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (canvas && canvas.current) {
      let rect = canvas.current.getCroppingRect();
      const response = await Api.call(Endpoint?.editMedia, { data: { img, rect } });
      if (response?.confirmation === "Success") getData({ currentPage: pagination?.page });
      setLoading(false);
      closeModal();
      scrollBottom();
    }
  };

  const scrollBottom = () => {
    setTimeout(() => {
      const main = document.getElementById("dashboard-app");
      main?.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }, 100);
  };

  const handleScroll = (e) => {
    let s = scale;
    s += e.deltaY * -0.001;
    s = Math.min(Math.max(1, s), 2);
    setScale(s);
  };

  return (
    <div className="d-flex">
      {img && (
        <div onWheel={handleScroll}>
          <AvatarEditor scale={scale} ref={canvas} image={process.env.REACT_APP_IMAGE_CDN_ENDPOINT + img.image} width={125} height={225} />
        </div>
      )}
      <div className={styles.imageEditDesc}>
        <p>Drag the image to change the focus</p>
        <p>Scroll up/down to zoom in/out</p>
        <Button onClick={handleClick}>{loading ? <LoadingSpinner /> : "Crop"}</Button>
      </div>
    </div>
  );
}
