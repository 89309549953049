import { createStore, combineReducers } from 'redux'
import { authReducer } from "../reducers/auth"
import { restaurantReducer } from "../reducers/restaurant"
import { menuReducer } from "../reducers/menu"
import { categoryReducer } from "../reducers/category"
import { dishReducer } from "../reducers/dish"
import { ingredientReducer } from "../reducers/ingredient"
import { adminReducer } from "../reducers/admin"

const rootReducer = combineReducers({
    Auth: authReducer,
    Restaurant: restaurantReducer,
    Menu: menuReducer,
    Category: categoryReducer,
    Dish: dishReducer,
    Ingredient: ingredientReducer,
    Admin: adminReducer
})

const store = createStore(rootReducer)

export { store }
