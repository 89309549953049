import React, { useState, useEffect } from "react";
import { Api, Endpoint } from "../api";
import { useRouteMatch } from "react-router-dom";
import { store } from "../store-redux/connect/store";
import styles from "../Templates/Classic Template/SingleMenuPage/SingleMenuPage.module.scss";
import LoadingSpinner from "../components/LoadingSpinner";
import backimg from "../images/foodi-footer.png";
import { IKImage } from "imagekitio-react";
import general from "../helpers/general";

const withMenu = (BaseComponent) => (props) => {
  const [loading, setLoading] = useState(true);
  const [customerMenu, setCustomerMenu] = useState(null);
  const [data, setData] = useState(null);
  const [menusTimeline, setMenusTimeline] = useState(null);

  // eslint-disable-next-line
  const {
    Auth,
    Restaurant: { restaurant, find },
  } = store.getState();

  const match = useRouteMatch("/menu/:id");
  const matchMap = useRouteMatch("/menu/:restaurantId/:mapId");

  const getCustomerMenu = async (id, mapId) => {
    const params = {
      params: [id],
    };
    if (mapId) {
      params.params.push(mapId);
    }

    const resp = await Api.call(Endpoint.getCustomerMenu, params);

    if (!resp || resp?.confirmation === "Fail" || !resp?.message || !resp?.message?.restaurant) {
      // setMenuNotFound(true);
      return resp;
    }

    // if (resp?.message?.menuNotFound) {
    //   // setMenuNotFound(true);
    // } else {
    //   // setMenuNotFound(false);
    // }

    setCustomerMenu(resp?.message);
    setMenusTimeline(resp?.message?.menusTimeline);
    adminSocialFallback(resp?.message?.restaurant);

    document.title = resp?.message?.restaurant?.name;
    return resp;
  };

  const adminSocialFallback = (data) => {
    const adminSocials = [
      { media: "facebook", icon: "fab fa-facebook-f", url: process.env.REACT_APP_FOODI_FB },
      { media: "instagram", icon: "fab fa-instagram", url: process.env.REACT_APP_FOODI_IN },
      { media: "twitter", icon: "fab fa-twitter", url: process.env.REACT_APP_FOODI_TW },
      { media: "yelp", icon: "fab fa-yelp", url: process.env.REACT_APP_FOODI_YE },
      { media: "website", icon: "fab fa-web", url: process.env.REACT_APP_FOODI_WEB },
    ];

    if (data && data?.social) {
      adminSocials.forEach((adminMedia, index) => {
        if (data?.parentRestaurant) {
          data?.parentRestaurant?.social.forEach((media) => {
            if (adminMedia.media === media.media && media.url !== "") {
              adminMedia.url = media.url;
            }
          });
        }
        data?.social.forEach((media) => {
          if (adminMedia.media === media.media && media.url !== "") {
            adminMedia.url = media.url;
          }
        });
      });
      const tempData = { ...data };
      tempData.social = adminSocials;
      setData(tempData);
    }
  };

  const init = async () => {
    setLoading(true);
    if (Auth.LOGGED_IN()) {
      await find();
    }
    if (match?.params?.id) {
      const resp = await getCustomerMenu(match?.params?.id);
      setCustomerMenu(resp?.message);
    }
    if (matchMap?.params?.restaurantId && matchMap?.params?.mapId) {
      const resp = await getCustomerMenu(matchMap?.params?.restaurantId, matchMap?.params?.mapId);
      setCustomerMenu(resp?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log({ customerMenu });
  }, [customerMenu]);

  useEffect(() => {
    if (restaurant && typeof restaurant === "object" && restaurant?._id && !match) {
      getCustomerMenu(restaurant?._id);
    }
    //eslint-disable-next-line
  }, [restaurant]);

  if (loading) {
    return (
      <div className={styles.centeredWrapper}>
        <LoadingSpinner text={true} />
      </div>
    );
  } else if (customerMenu?.menuNotFound) {
    return (
      <div className={styles.centeredWrapper}>
        {customerMenu?.restaurant?.logo ? (
          <IKImage
            path={customerMenu?.restaurant?.logo}
            transformation={[{ width: 100, height: 100 }]}
            style={{ width: "100px", height: "100px", objectFit: "contain" }}
            loading="lazy"
            lqip={{ active: true }}
            alt={customerMenu?.restaurant?.title}
          />
        ) : (
          <div style={{ padding: 10, marginBottom: 40, width: 100, height: 100, borderRadius: "50%", backgroundColor: "var(--brand)" }}>
            <img src={backimg} style={{ width: "100%", height: "100%", objectFit: "contain" }} alt="" />
          </div>
        )}
        <div style={{ padding: "0 30px", textAlign: "center" }}>
          <h2>There are no published menus</h2>
          <p style={{ margin: "30px" }}>
            Please{" "}
            <a href={`mailto:${customerMenu?.user?.email}`} target="_blank" style={{ color: "var(--brand)" }}>
              notify
            </a>{" "}
            the {general.convertToReadableRestaurantType(customerMenu?.restaurant?.restaurantType)} that they need to publish it
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <BaseComponent
        {...props}
        data={data}
        loading={loading}
        menuNotFound={customerMenu?.menuNotFound || true}
        menusTimeline={menusTimeline}
        getCustomerMenu={getCustomerMenu}
        preview={props.preview}
      />
    </>
  );
};

export default withMenu;
