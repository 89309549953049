export default function EditLocationButton({ color, mode }) {
  return (
    <>
      {mode ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
        >
          <path
            d="M14.4805 2.30859L13.4414 1.26953C13.1133 0.941406 12.6484 0.75 12.2109 0.75C11.7734 0.75 11.3086 0.941406 10.9805 1.26953L1.32812 10.9219L1 14.0391C0.945312 14.4219 1.24609 14.75 1.62891 14.75C1.65625 14.75 1.68359 14.75 1.71094 14.75L4.82812 14.4219L14.4805 4.76953C15.1641 4.08594 15.1641 2.99219 14.4805 2.30859ZM4.44531 13.5742L1.90234 13.8477L2.17578 11.3047L9.39453 4.08594L11.6641 6.35547L4.44531 13.5742ZM13.8516 4.16797L12.2656 5.75391L9.99609 3.48438L11.582 1.89844C11.7461 1.73438 11.9648 1.625 12.2109 1.625C12.457 1.625 12.6758 1.73438 12.8398 1.89844L13.8516 2.91016C14.207 3.26562 14.207 3.8125 13.8516 4.16797Z"
            fill={color ? color : "#DEDEDE"}
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
        >
          <path
            d="M12.9062 0.875L4.15625 9.625L1.0625 6.53125C0.90625 6.40625 0.6875 6.40625 0.53125 6.53125L0.1875 6.90625C0.03125 7.03125 0.03125 7.28125 0.1875 7.4375L3.90625 11.1562C4.03125 11.2812 4.28125 11.2812 4.40625 11.1562L13.7812 1.78125C13.9375 1.625 13.9375 1.375 13.7812 1.25L13.4375 0.875C13.2812 0.75 13.0625 0.75 12.9062 0.875Z"
            fill={color ? color : "#DEDEDE"}
          />
        </svg>
      )}
    </>
  );
}
