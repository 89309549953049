import { Api, Endpoint } from "../../api";
import { store } from "../connect/store";

export const INGREDIENT_CREATED_SUCCESSFULLY = "INGREDIENT_CREATED_SUCCESSFULLY";
export const INGREDIENTS_LOADED_SUCCESSFULLY = "INGREDIENTS_LOADED_SUCCESSFULLY";
export const TREE_INGREDIENTS_LOADED_SUCCESSFULLY = "TREE_INGREDIENTS_LOADED_SUCCESSFULLY";
export const INGREDIENTS_RESET = "INGREDIENTS_RESET";

export const methods = {
  create: async (data) => {
    const response = await Api.call(Endpoint.createIngredient, { data });
    if (response?.confirmation === "Success") {
      store.dispatch({ type: INGREDIENT_CREATED_SUCCESSFULLY, ingredient: response?.message });
    }
    return response;
  },
  update: async (data, id) => {
    const response = await Api.call(Endpoint.updateIngredient, { data, params: [id] });
    methods.load({ forceRender: true });
    return response;
  },
  delete: async (ids) => {
    if (Array.isArray(ids)) {
      const response = await Api.call(Endpoint.deleteIngredient, { data: { ids } });
      await methods.load({ forceRender: true });
      await store.getState().Dish.load({ forceRender: true });
      return response;
    }
    return {};
  },
  load: async (obj = false) => {
    const { Ingredient } = store.getState();

    const forceRender = typeof obj === "object" && obj.forceRender ? true : false;

    if (forceRender) {
      const query = obj && obj.query ? { exceptChildren: true, exceptParentID: obj.query.exceptParentID } : {};
      const response = await Api.call(Endpoint.loadIngredients, { query });
      if (response?.confirmation === "Success") {
        store.dispatch({ type: INGREDIENTS_LOADED_SUCCESSFULLY, ingredients: response?.message });
      }
      return response;
    }

    if (!Ingredient?.ingredients?.length) return methods.load({ ...obj, forceRender: true });
  },
  uploadCSV: async (data) => {
    const response = await Api.call(Endpoint.uploadIngredientCSV, { data, query: { csvFile: true } });
    methods.load({ forceRender: true });
    return response;
  },
};
