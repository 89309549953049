import Settings from "../Views/Settings";
import Login from "../Views/Login";
import Register from "../Views/Register";
import Dashboard from "../Views/Dashboard";
import VerifyAccount from "../Views/VerifyAccount";
import ForgotPassword from "../Views/ForgotPassword";
import ResetPassword from "../Views/ResetPassword/";
import CreateMenu from "../Views/CreateMenu/";
import CreateCategory from "../Views/CreateCategory/";
import CreateDish from "../Views/CreateDish";
import CreateIngredient from "../Views/CreateIngredient";
import MediaSection from "../Views/MediaSection";
import MapsSection from "../Views/MapsSection";
import { Members, VerifyAccountMember } from "../Views/Members";
import UpdateAccount from "../Views/UpdateAccount";
import AddRestaurant from "../Views/AddRestaurant";
import MenuInteractions from "../Views/MenuInteractions/MenuInteractions";

import Logs from "../Views/Admin/Logs";
import TransferOwnership from "../Views/TransferOwnership";
import CONSTANTS from "../utils/constants";
import { Dashboard as AdminDashboard } from "../Views/Admin";

import { CompleteAndVerifyAccount as AdminCompleteAndVerifyAccount } from "../Views/Admin/CompleteAndVerifyAccount";
import DietaryConsiderations from "../Views/Admin/DietaryConsiderations";
import Purchase from "../Views/PaymentSystem/Purchase";
import Timeline from "../Views/Timeline";
import Toggle from "../Views/Toggle";
import MenuWrapper from "../components/Wrappers/MenuWrapper";
import SingleMenuWrapper from "../components/Wrappers/SingleMenuWrapper";
import Customizations from "../Views/Customizations";

import DMARegister from "../Views/DMA/register";
import DMADashboard from "../Views/DMA/dashboard";
import DMAClientVerifyInvitation from "../Views/DMA/client-verify-invitations";
import DMASingleClient from "../Views/DMA/single-client";
import Analytics from "../Views/Admin/Analytics";
import Filters from "../Views/Filters";
import EmailTemplates from "../Views/Admin/EmailTemplates";
import EditEmailTemplate from "../Views/Admin/EditEmailTemplate";

const menuSubs = [
  {
    path: "/create-dish",
    name: "Dishes",
    component: CreateDish,
    exact: true,
    private: true,
    icon: "fal fa-utensils-alt",
    permissions: { except: ["dish"], $connector: "$and" },
  },
  {
    path: "/create-category",
    name: "Categories",
    component: CreateCategory,
    exact: true,
    private: true,
    icon: "fal fa-hat-chef",
    permissions: { except: ["category"], $connector: "$and" },
    // tour: "create-category",
  },
  {
    path: "/create-ingredient",
    name: "Ingredients",
    component: CreateIngredient,
    exact: true,
    private: true,
    icon: "far fa-carrot",
    permissions: { except: ["ingredient"], $connector: "$and" },
  },
];

const settingsSubs = [
  {
    path: "/timeline",
    name: "Timeline",
    component: Timeline,
    exact: true,
    private: true,
    icon: "fal fa-clock",
    permissions: { except: ["menu"], $connector: "$and" },
    tour: "nav-timeline",
  },
  {
    path: "/toggle-list",
    name: "Quick edit",
    component: Toggle,
    exact: true,
    private: true,
    icon: "fal fa-pen",
    permissions: { except: ["menu", "category", "dish", "ingredient"], $connector: "$or" },
    tour: "nav-toggle",
  },
  {
    path: "/customizations",
    name: "Customizations",
    component: Customizations,
    exact: true,
    private: true,
    icon: "fal fa-burger-soda",
    permissions: { except: ["restaurant"], $connector: "$and" },
    tour: "nav-customizations",
  },
];

/* DMA routes */
export const DMARoutes = [
  {
    path: "/dma/dashboard",
    name: "Dashboard",
    component: DMADashboard,
    exact: true,
    private: true,
    showInMenu: true,
    icon: "fal fa-th-large",
    permissions: { except: ["dma"], $connector: "$and" },
  },
  {
    path: "/dma/client",
    name: "DMA Single Client",
    component: DMASingleClient,
    exact: true,
    private: true,
    showInMenu: false,
    permissions: { except: ["dma"], $connector: "$and" },
  },
  {
    path: "/dma/update-account",
    name: "Account",
    component: UpdateAccount,
    exact: true,
    private: true,
    showInMenu: true,
    showInDropdown: true,
    icon: "fal fa-user",
    permissions: { except: ["dma"], $connector: "$and" },
  },
];

/* Admin routes */
export const adminRoutes = [
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    component: AdminDashboard,
    exact: true,
    private: true,
    showInMenu: true,
    showInDropdown: false,
    icon: "fal fa-th-large",
    permissions: { except: ["admin", "superAdmin"], $connector: "$or" },
  },
  {
    path: "/admin/dietary-considerations",
    name: "Dietary Considerations",
    component: DietaryConsiderations,
    private: true,
    exact: true,
    showInMenu: true,
    showInDropdown: false,
    icon: "fal fa-burger-soda",
    permissions: { except: ["admin", "superAdmin"], $connector: "$or" },
  },
  {
    path: "/admin/update-profile",
    name: "Account",
    component: UpdateAccount,
    private: true,
    exact: true,
    showInMenu: true,
    showInDropdown: true,
    icon: "fal fa-user",
    permissions: { except: ["admin", "superAdmin"], $connector: "$or" },
  },
  {
    path: "/admin/logs",
    name: "Logs",
    component: Logs,
    private: true,
    exact: true,
    showInMenu: true,
    showInDropdown: false,
    icon: "fal fa-file-search",
    permissions: { except: ["admin", "superAdmin"], $connector: "$or" },
  },
  {
    path: "/admin/analytics",
    name: "Analytics",
    component: Analytics,
    private: true,
    exact: true,
    showInMenu: true,
    showInDropdown: false,
    icon: "fal fa-chart-bar",
    permissions: { except: ["admin", "superAdmin"], $connector: "$or" },
  },
  {
    path: "/admin/email-templates",
    name: "Email Templates",
    component: EmailTemplates,
    private: true,
    exact: true,
    showInMenu: true,
    showInDropdown: false,
    icon: "fal fa-file-search",
    permissions: { except: ["admin", "superAdmin"], $connector: "$or" },
  },
  {
    path: "/admin/edit-email-template",
    name: "Edit Email Template",
    component: EditEmailTemplate,
    private: true,
    exact: true,
    showInMenu: false,
    showInDropdown: false,
    icon: "fal fa-file-search",
    permissions: { except: ["admin", "superAdmin"], $connector: "$or" },
  },
];

/* Public routes */
export const publicRoutes = [
  {
    path: "/admin-complete-account",
    name: "Admin Complete Account",
    component: AdminCompleteAndVerifyAccount,
    exact: true,
    private: false,
  },
  {
    path: "/user-verify-account",
    name: "VerifyAccount",
    component: VerifyAccount,
    exact: true,
    private: false,
  },
  {
    path: "/dma-client-verify-invitation",
    name: "DMA Client Verify Invitation",
    component: DMAClientVerifyInvitation,
    exact: true,
    private: false,
  },
];

/* No Authenticated routes */
export const noAuthenticatedRoutes = [
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    private: false,
    exact: true,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    exact: true,
    private: false,
  },
  {
    path: "/member-verify-account",
    name: "VerifyAccountMember",
    component: VerifyAccountMember,
    exact: true,
    private: false,
    showInMenu: false,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    exact: true,
    private: false,
    showInMenu: true,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    exact: true,
    private: false,
    showInMenu: true,
  },
  {
    path: "/transfer-ownership",
    name: "Transfer ownership",
    component: TransferOwnership,
    exact: true,
  },
  {
    path: "/dma-register",
    name: "DMA Register",
    component: DMARegister,
    exact: true,
    private: false,
  },
];

export const accountSubs = [
  {
    path: "/members",
    name: "Members",
    component: Members,
    exact: true,
    private: true,
    icon: "fal fa-users",
    showInMenu: false,
    showInDropdown: true,
    permissions: { except: ["member"], $connector: "$and" },
    tour: "nav-members",
  },
  {
    path: "/subscribe",
    name: "Subscription",
    component: Purchase,
    exact: true,
    private: true,
    showInMenu: false,
    showInDropdown: true,
    icon: "fal fa-user-clock",
    permissions: { except: ["restaurant"], $connector: "$and" },
    roles: [CONSTANTS.USER.ROLES.RESTAURANT],
    tour: "nav-subscribe",
  },
];

export const profileRoutes = [
  {
    path: "/update-account",
    name: "Account",
    component: UpdateAccount,
    exact: true,
    private: true,
    showInMenu: true,
    showInDropdown: true,
    tour: "nav-account",
    icon: "fal fa-user",
    permissions: { except: ["restaurant", "member"], $connector: "$or" }, // TODO: Need to check if these permissions are correct
    subs: accountSubs,
  },
  ...accountSubs,
];

/* Private routes */
export const privateRoutes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    exact: true,
    private: true,
    icon: "fal fa-th-large",
    showInMenu: true,
  },
  {
    path: "/create-menu",
    name: "Menu",
    component: CreateMenu,
    exact: true,
    showInMenu: true,
    private: true,
    permissions: { except: ["menu"], $connector: "$and" },
    tour: "nav-create-menu",
    icon: "fal fa-book-open",
    subs: menuSubs,
  },
  ...menuSubs,
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    exact: true,
    private: true,
    showInMenu: true,
    permissions: { except: ["restaurant"], $connector: "$and" },
    icon: "fal fa-cog",
    tour: "nav-settings",
    subs: settingsSubs,
  },
  ...settingsSubs,
  ...profileRoutes,
  {
    path: "/filters",
    name: "Filter Options",
    component: Filters,
    exact: true,
    showInMenu: true,
    private: true,
    permissions: { except: ["menu"], $connector: "$and" },
    icon: "fal fa-filter",
  },
  {
    path: "/media-section",
    name: "Media",
    component: MediaSection,
    exact: false,
    private: true,
    showInMenu: true,
    icon: "fal fa-camera",
    permissions: { except: ["dish"], $connector: "$and" },
    tour: "nav-media",
  },
  {
    path: "/menu-display",
    name: "Menu Display",
    component: MapsSection,
    exact: false,
    private: true,
    showInMenu: true,
    icon: "fal fa-qrcode",
    tour: "nav-maps",
  },
  {
    path: "/add-restaurant",
    name: "Add location",
    component: AddRestaurant,
    exact: true,
    showInMenu: true,
    icon: "fal fa-map-marker-plus",
    private: true,
  },
  {
    path: "/menu-interaction-tracking",
    name: "Menu Interaction Tracking",
    component: MenuInteractions,
    exact: true,
    showInMenu: true,
    icon: "fal fa-chart-bar",
    private: true,
  },
];

export const menuRoutes = [
  {
    // path: ["/menu", "/menu/:id"], // /menu may not be needed
    path: ["/menu/:id", "/menu/:restaurantId/:mapId"], // /menu may not be needed
    name: "Menu",
    component: MenuWrapper,
    exact: true,
    private: false,
  },
  {
    path: "/menu/:restaurantId/dish/:dishId/menuSelected/:menuId",
    name: "Menu",
    component: SingleMenuWrapper,
    exact: true,
    private: false,
  },
];
