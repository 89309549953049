import { connect } from "react-redux";
import styles from "./MenuPreviewBtn.module.scss";

function MenuPreviewBtn({ closed, preview, setPreview, Menu }) {
  if (!closed || !setPreview) return null;

  return (
    <button
      disabled={!Menu?.selected}
      className={`${styles.previewBtn} ${preview ? styles.active : ""}`}
      onClick={() => setPreview(!preview)}
    >
      <i className="fal fa-eye"></i>
    </button>
  );
}

const mapStateToProps = (state) => ({ Menu: state.Menu });
export default connect(mapStateToProps)(MenuPreviewBtn);
