import { ResponsiveLine } from "@nivo/line";

const CustomLineChart = ({ data }) => (
  <div style={{ height: 300 }}>
    <ResponsiveLine
      data={data}
      colors={["#f46d25", "#808080"]}
      margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      enableArea={true}
      enableSlices="x"
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabel="data.yFormatted"
      pointLabelYOffset={-12}
      enableTouchCrosshair={true}
      useMesh={true}
      enableGridX={false}
    />
  </div>
);

export default CustomLineChart;
