import * as TYPES from "../actions/menu"
import { methods } from "../actions/menu"

const initialState = {
    ...methods,
    menus: [],
    templates: [],
    selected: methods.getMenuFromSessionStorage()
}

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.MENU_CREATED_SUCCESSFULLY:
            let tempMenus = [...state.menus]
            tempMenus.push(action.menu)
            return { ...state, menus: tempMenus }
        case TYPES.MENUS_LOADED_SUCCESSFULLY:
            return { ...state, menus: action.menus }
        case TYPES.TEMPLATES_LOADED_SUCCESSFULLY:
            return { ...state, templates: action.templates }
        case TYPES.MENU_SELECTED_LOCALLY:
            return { ...state, selected: action?.menu }
        case TYPES.MENU_RESET:
            return { ...state, menus: [], templates: [], selected: {} }
        default: 
            return { ...state }
    }
} 

export { menuReducer }
