import React, { useEffect, useState } from "react";
import { Suspense } from "react";
import withMenuSingle from "../../hoc/withMenuSingle";
import useInterval from "../../hoc/useInterval";
import general from "../../helpers/general";
import IncomingMenus from "../Alerts/IncomingMenus";
import withSelectedMenu from "../../hoc/withSelectedMenu";
import { useHistory } from "react-router-dom";

function SingleMenuWrapper(props) {
  const { restaurant } = props;
  const history = useHistory();

  const delay = 30000;
  const interval = useInterval(checkClosestMenu, delay);

  const [DishLazyLoadedTemplate, setDishLazyLoadedTemplate] = useState(null);
  const [notifyMenus, setNotifyMenus] = useState(null);

  function checkClosestMenu() {
    const notifyMenus = general?.findClosestMenus(props?.menusTimeline, restaurant?.menus, restaurant?.menu_period_transition);
    if (notifyMenus?.length > 0) {
      interval?.reset();
      setNotifyMenus(notifyMenus);
    }
  }

  useEffect(() => {
    let template = "Classic Template";
    if (props?.menu?.selectedTemplate && props?.menu?.selectedTemplate !== "undefined") {
      template = props?.menu?.selectedTemplate;
    }
    setDishLazyLoadedTemplate(React.lazy(() => import(`../../Templates/${template}/SingleMenuPage`)));
    // eslint-disable-next-line
  }, []);

  const onCloseIncomingMenu = () => {
    setNotifyMenus([]);
    interval?.start();
  };

  const onSwitchIncomingMenu = async () => {
    if (notifyMenus?.length === 1) {
      const menu = notifyMenus?.[0];
      history.push(`/menu/${restaurant?._id}`, { menuIDSelectedFromCustomer: menu?._id });
    } else {
      history.push(`/menu/${restaurant?._id}`, { menuIDSelectedFromCustomer: null });
    }
    setNotifyMenus([]);
  };

  return (
    <>
      {DishLazyLoadedTemplate && (
        <>
          {notifyMenus && notifyMenus?.length > 0 && (
            <IncomingMenus
              menus={notifyMenus}
              minutes={restaurant?.menu_period_transition}
              onClose={onCloseIncomingMenu}
              onSwitch={onSwitchIncomingMenu}
              currentMenu={props?.menu}
            />
          )}
          <Suspense fallback={<></>}>
            <DishLazyLoadedTemplate {...props} />
          </Suspense>
        </>
      )}
    </>
  );
}

export default withMenuSingle(withSelectedMenu(SingleMenuWrapper));
