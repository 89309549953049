import React from "react";
import styles from "./SwitchAccountForm.module.scss";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

import CONSTANTS from "../../../utils/constants";

const { USER } = CONSTANTS;

//eslint-disable-next-line
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

function SwitchAccountForm({ Auth, closeModal }) {
  const history = useHistory();
  if (Auth?.user?.accounts?.length > 1) {
    return (
      <div>
        {Auth?.user?.accounts?.map((user, index) => {
          return (
            <div
              onClick={async () => {
                if (Auth?.user?._id?.toString() === user?._id?.toString()) {
                  return;
                }
                const response = await Auth.SWITCH_ACCOUNT({
                  accountID: user._id,
                });
                if (!response || !response.confirmation || response?.confirmation === "Fail") {
                  typeof closeModal === "function" && closeModal();
                  toast(response?.message);
                  return;
                }
                localStorage?.removeItem("menuSelected");
                window.location.replace("/");
              }}
              key={index}
              className={styles.account}
              style={Auth?.user?._id?.toString() === user?._id?.toString() ? { backgroundColor: "#f1f1f1" } : {}}
            >
              <div className={styles?.firstRow}>
                <div className={styles?.radius}>
                  <div>
                    <small>{user?.username ? user?.username?.charAt(0)?.capitalize() : ""}</small>
                  </div>
                </div>
                <div className={styles?.accountData}>
                  <small>{user?.username}</small>
                  <small>{user?.email}</small>
                  <small>
                    {user?.role === USER.ROLES.RESTAURANT ? "OWNER" : user?.role === USER.ROLES.MEMBER ? USER.ROLES.MEMBER : ""}
                  </small>
                </div>
              </div>
              <div>
                {Auth?.user?._id?.toString() === user?._id?.toString() ? (
                  <Button variant="secondary">Logged in</Button>
                ) : (
                  <Button>Switch</Button>
                )}
              </div>
            </div>
          );
        })}
        <div
          className={styles?.addRestaurant}
          onClick={() => {
            typeof closeModal === "function" && closeModal();
            history.push("/add-restaurant");
          }}
        >
          <small>Add {Auth?.user?.restaurants?.[0]?.restaurantType === "FOOD_TRUCK" ? "Food Truck" : "Restaurant"} / Location</small>
        </div>
      </div>
    );
  }
  return null;
}

const mapStateToProps = (state) => ({ Auth: state?.Auth });
export default connect(mapStateToProps)(SwitchAccountForm);
