import { Api, Endpoint } from "../../api";
import { store } from "../connect/store";

export const MENU_CREATED_SUCCESSFULLY = "MENU_CREATED_SUCCESSFULLY";
export const MENUS_LOADED_SUCCESSFULLY = "MENUS_LOADED_SUCCESSFULLY";
export const TEMPLATES_LOADED_SUCCESSFULLY = "TEMPLATES_LOADED_SUCCESSFULLY";
export const MENU_SELECTED_LOCALLY = "MENU_SELECTED_LOCALLY";
export const MENU_RESET = "MENU_RESET";

export const methods = {
  create: async (data, query = {}) => {
    const response = await Api.call(Endpoint.createMenu, { data, query });
    if (response?.confirmation === "Success") {
      store.dispatch({ type: MENU_CREATED_SUCCESSFULLY, menu: response?.message });
      store.getState().Restaurant.find({ forceRender: true });
    }
    return response;
  },
  getMenuFromSessionStorage: () => {
    try {
      const menu = localStorage.getItem("menuSelected");
      const firstTimeLoggedIn = localStorage.getItem("first_time_logged_in");
      if (!menu || !firstTimeLoggedIn) return null;
      return JSON.parse(menu);
    } catch (err) {
      return null;
    }
  },
  setMenuSelectedLocally: (menu, params = {}) => {
    if (typeof menu === "object") {
      store.dispatch({ type: MENU_SELECTED_LOCALLY, menu: menu });
    }
  },
  findSingle: async (id) => {
    const response = await Api.call(Endpoint.findSingleMenu, { params: [id] });
    return response;
  },
  update: async (id, data) => {
    const response = await Api.call(Endpoint.updateMenu, { data, params: [id] });
    if (response?.confirmation === "Success" && typeof response?.message === "object") {
      const menuFromStorage = methods?.getMenuFromSessionStorage();
      if (menuFromStorage && menuFromStorage?._id?.toString() === response?.message?._id?.toString()) {
        localStorage.setItem("menuSelected", JSON.stringify(response?.message));
        methods.setMenuSelectedLocally(response?.message);
      }
      store.getState().Restaurant.find({ forceRender: true });
      methods.load({ forceRender: true });
    }
    return response;
  },
  selectCategories: async (data) => {
    const response = await Api.call(Endpoint.selectCategories, { data });
    if (response?.confirmation === "Success") {
      const menuFromStorage = methods?.getMenuFromSessionStorage();
      if (menuFromStorage && menuFromStorage?._id?.toString() === response?.message?._id?.toString()) {
        localStorage.setItem("menuSelected", JSON.stringify(response?.message));
        methods.setMenuSelectedLocally(response?.message);
      }
      store.getState().Restaurant.find({ forceRender: true });
      methods.load({ forceRender: true });
    }
    return response;
  },
  removeCategory: async (data) => {
    const response = await Api.call(Endpoint.removeCategoryFromMenu, { data });
    if (response?.confirmation === "Success") {
      const menuFromStorage = methods?.getMenuFromSessionStorage();
      if (menuFromStorage && menuFromStorage?._id?.toString() === response?.message?._id?.toString()) {
        localStorage.setItem("menuSelected", JSON.stringify(response?.message));
        methods.setMenuSelectedLocally(response?.message);
      }
      store.getState().Restaurant.find({ forceRender: true });
      methods.load({ forceRender: true });
    }
    return response;
  },
  reorder: async (data) => {
    const response = await Api.call(Endpoint?.reorderMenu, { data });
    if (response?.confirmation === "Success") {
      store.getState().Restaurant.find({ forceRender: true });
      methods.load({ forceRender: true });
    }
    return response;
  },
  load: async (obj = false) => {
    const { Menu, Auth } = store.getState();
    if (Auth?.IS_ADMIN()) return;
    const forceRender = typeof obj === "object" && obj.forceRender ? true : false;

    if (forceRender) {
      const response = await Api.call(Endpoint.loadMenus);
      if (response?.message && Array.isArray(response?.message)) {
        response?.message?.forEach((menu) => {
          if (menu && menu?._id && Menu?.selected?._id && menu?._id?.toString() === Menu?.selected?._id?.toString()) {
            Menu.setMenuSelectedLocally(menu);
          }
        });
      }
      if (response?.confirmation === "Success") {
        store.dispatch({ type: MENUS_LOADED_SUCCESSFULLY, menus: response?.message });
      }
      return;
    }

    if (!Menu?.menus?.length) return methods.load({ forceRender: true });
  },
  delete: async (id) => {
    const response = await Api.call(Endpoint.deleteMenu, { params: [id] });
    localStorage?.removeItem("menuSelected");
    store.dispatch({ type: MENU_SELECTED_LOCALLY, menu: null });
    store.getState().Restaurant.find({ forceRender: true });
    methods.load({ forceRender: true });
    return response;
  },
};
