import styles from "./TopBar.module.scss";

export default function TopBar() {
  return (
    <div className={styles.topBar}>
      <div className={styles.topBarItem}></div>
      <div className={styles.topBarItem}></div>
      <div className={styles.topBarItem}></div>
      <div className={styles.topBarItem}></div>
      <div className={styles.topBarItem}></div>
      <div className={styles.topBarItem}></div>
      <div className={styles.topBarItem}></div>
      <div className={styles.topBarItem}></div>
      <div className={styles.topBarItem}></div>
      <div className={styles.topBarItem}></div>
      <div className={styles.topBarItem}></div>
      <div className={styles.topBarItem}></div>
    </div>
  );
}
