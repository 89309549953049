import React, { useEffect, useState } from "react";
import { Collapse, Button, Form } from "react-bootstrap";
import menuStyles from "../../components/Forms/MenuForm/MenuForm.module.scss";
import Days from "../../components/Forms/MenuForm/Days";
import styles from "./Timeline.module.scss";
import Checkbox from "../../components/Checkbox";
import { useForm } from "react-hook-form";
import general from "../../helpers/general";
import { Api, Endpoint } from "../../api/index";
import LoadingSpinner from "../../components/LoadingSpinner";
import { toast } from "react-toastify";

export default function Categories({ selectedMenu, closeModal, menuCategories }) {
  const [categoriesAdjustments, setCategoriesAdjustments] = useState(menuCategories);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(0);

  const { register, handleSubmit, setValue } = useForm();

  const getRepeaterTimelineData = (data) => {
    for (const key in data) {
      const value = data[key];
      if (typeof value === "string") {
        const index = key.substr(key.length - 1);
        const keyRenamed = key.substr(0, key.length - 2);
        const timeAsDate = general.timeAsDate(value);

        if (timeAsDate) {
          data.categoriesAdjustments[index].valid[keyRenamed] = timeAsDate;
        } else {
          delete data.categoriesAdjustments[index].valid[keyRenamed];
        }
      }
    }
    return data;
  };

  const submit = async (data) => {
    setLoading(true);
    data = getRepeaterTimelineData(data);
    data = { categoriesAdjustments: data.categoriesAdjustments, menu_id: selectedMenu._id };
    const response = await Api.call(Endpoint?.updateCategoriesTimeline, { data });
    if (response?.confirmation === "Success") {
      setLoading(false);
      toast("Changes have been saved successfully!");
      typeof closeModal === "function" && closeModal();
      return;
    }
    setLoading(false);
    toast(response?.message);
  };

  const onChangeValid = (index, valid) => {
    let temp = [...categoriesAdjustments];
    temp[index].valid = { ...temp[index].valid, ...valid };
    setCategoriesAdjustments(temp);
  };

  useEffect(() => {
    setCategoriesAdjustments(menuCategories);
    //eslint-disable-next-line
  }, [menuCategories]);

  useEffect(() => {
    setValue("categoriesAdjustments", categoriesAdjustments);
    //eslint-disable-next-line
  }, [categoriesAdjustments]);

  useEffect(() => {
    register({ name: "categoriesAdjustments", value: categoriesAdjustments });
    //eslint-disable-next-line
  }, []);

  return (
    <Form onSubmit={handleSubmit(submit)}>
      {categoriesAdjustments &&
        categoriesAdjustments?.length > 0 &&
        categoriesAdjustments?.map((schema, index) => (
          <div key={index} className={styles?.categoryTimeline}>
            {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
            <h3
              aria-controls={`categories-${index}`}
              aria-expanded={expanded === index}
              className={styles.categoryName}
              style={{
                color: `${expanded === index ? "var(--brand)" : "inherit"}`,
              }}
              onClick={() => setExpanded(index)}
            >
              {schema?.category?.name}
              <i
                style={{ transform: `${expanded === index ? "rotate(180deg)" : "rotate(0deg)"}` }}
                className="far fa-chevron-down"
              ></i>
            </h3>
            <Collapse in={expanded === index}>
              <div id={`categories-${index}`}>
                <Form.Group>
                  <div className={menuStyles.daysOfTheWeek}>
                    <Days valid={schema?.valid} setValid={(valid) => onChangeValid(index, valid)} />
                    <div className={styles?.timelineWrapper}>
                      <div className={styles?.timelineDates}>
                        {!schema?.valid?.twentyFourHoursAvailable && (
                          <>
                            <div>
                              <Form.Group>
                                <Form.Label>Start Time</Form.Label>
                                <Form.Control
                                  type="time"
                                  defaultValue={general.dateAsTime(schema?.valid?.startTime)}
                                  ref={register}
                                  name={`startTime_${index}`}
                                  placeholder="Start Time"
                                />
                              </Form.Group>
                            </div>
                            <div>
                              <Form.Group>
                                <Form.Label>End Time</Form.Label>
                                <Form.Control
                                  type="time"
                                  defaultValue={general.dateAsTime(schema?.valid?.endTime)}
                                  ref={register}
                                  name={`endTime_${index}`}
                                  placeholder="End Time"
                                />
                              </Form.Group>
                            </div>
                          </>
                        )}
                      </div>
                      <div>
                        <Form.Label>
                          <Checkbox
                            size="small"
                            checked={schema?.valid?.twentyFourHoursAvailable ? true : false}
                            onChange={() => {
                              const valid = { ...schema?.valid };
                              valid.twentyFourHoursAvailable = valid.twentyFourHoursAvailable ? false : true;
                              onChangeValid(index, valid);
                            }}
                          />
                          <span>24HR Category available</span>
                        </Form.Label>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </div>
            </Collapse>
          </div>
        ))}
      <Button disabled={loading} type="Submit">
        {loading ? <LoadingSpinner /> : "Save changes"}
      </Button>
    </Form>
  );
}
