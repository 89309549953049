import styles from "./Location.module.scss";
import Zone from "./Zone";
import { Draggable, Droppable } from "react-beautiful-dnd";

export default function Location({
  location,
  location_index,
  findAndUpdateMap,
  findAndDeleteZone,
  findAndDeleteMap,
  findAndUpdateZone,
  findAndDeleteMenu,
  findMap,
  findMenu,
  updateMaps,
}) {
  return (
    <Draggable draggableId={`${location?._id}`} key={location?._id} index={location_index}>
      {(provided) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className={styles.locationItem}>
          <Droppable droppableId={`${location?._id}`} type="zone" direction="horizontal">
            {(provided) => (
              <section className={styles.zonesWrapper} {...provided.droppableProps} ref={provided.innerRef}>
                {location?.zones?.map((zone, index) => (
                  <Zone
                    findAndUpdateZone={findAndUpdateZone}
                    findAndDeleteZone={findAndDeleteZone}
                    findAndUpdateMap={findAndUpdateMap}
                    findAndDeleteMap={findAndDeleteMap}
                    findAndDeleteMenu={findAndDeleteMenu}
                    location_index={location_index}
                    findMap={findMap}
                    findMenu={findMenu}
                    key={index}
                    zone={zone}
                    zone_index={index}
                    updateMaps={updateMaps}
                  />
                ))}
                {provided.placeholder}
              </section>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
}
