import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { List, ListItem, ListItemIcon, ListItemText, Radio, Tooltip } from "@material-ui/core";

import { Api, Endpoint } from "../../../../api/index";

import Stripe from "../../../../utils/stripe";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: "white",
    },
  })
);

export default function SelectPaymentMethod({ paymentMethods, paymentMethodSelected, setPaymentMethodSelected }) {
  const classes = useStyles();

  const handleSelectPaymentMethod = function (elem) {
    setPaymentMethodSelected(elem?.id);
  };

  const addPaymentMethod = async function () {
    const response = await Api.call(Endpoint.subscription.savePayment, { data: { url: window.location.href } });

    if (response.statusCode === 200 && response?.message?.url) {
      await Stripe.redirectToCheckout(response.message.id);
      return;
    }
    toast("We are sorry, but something went wrong. Please try again or contact the support.");
  };

  if (!paymentMethods || paymentMethods?.data?.length === 0) {
    return (
      <div className="p-3 mb-2">
        <p>You must add at least one payment method in order to process this step.</p>
        <p>
          You can add a payment method by clicking{" "}
          <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={addPaymentMethod}>
            here
          </span>
          .
        </p>
      </div>
    );
  }

  return (
    <List className={classes.root}>
      {paymentMethods?.data?.length > 0 &&
        paymentMethods?.data?.map((elem, index) => (
          <Tooltip
            key={index}
            enterTouchDelay={0}
            title={
              elem?.metadata?.dma_id && elem?.metadata?.client_id
                ? "If you select this card, the plan must be selected again on the last step."
                : ""
            }
          >
            <ListItem role={undefined} dense button onClick={() => handleSelectPaymentMethod(elem)}>
              <ListItemIcon>
                <Radio
                  style={{ color: "var(--brand)" }}
                  onChange={() => handleSelectPaymentMethod(elem)}
                  checked={paymentMethodSelected === elem?.id}
                  size="small"
                />
              </ListItemIcon>
              <ListItemText
                id={index}
                children={
                  <div>
                    <p className="mb-0">XXXX-XXXX-XXXX-{elem?.card?.last4}</p>

                    <p className="mb-0 MuiTypography-body2 MuiTypography-colorTextSecondary">
                      {elem?.billing_details?.name} - {elem?.card?.brand?.charAt(0).toUpperCase() + elem?.card?.brand?.slice(1)} -{" "}
                      {elem?.card?.exp_month}/{elem?.card?.exp_year}
                    </p>
                  </div>
                }
              />
            </ListItem>
          </Tooltip>
        ))}
    </List>
  );
}
