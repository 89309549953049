import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

export default function MenuCTA({ user, menuCTA, setMenuCTA, onChange, disabled }) {
  const [emailsTags, setEmailsTags] = useState(Array.isArray(menuCTA?.emails) ? menuCTA?.emails : []);

  useEffect(() => {
    if (emailsTags && Array.isArray(emailsTags)) {
      const tempMenuCta = { ...menuCTA };
      tempMenuCta.emails = emailsTags;
      setMenuCTA(tempMenuCta);
    }
    //eslint-disable-next-line
  }, [emailsTags]);

  useEffect(() => {
    if (menuCTA?.emails && Array.isArray(menuCTA?.emails)) {
      if (menuCTA?.emails?.length > 0) {
        setEmailsTags(menuCTA?.emails);
      } else {
        setEmailsTags([user?.email]);
      }
    }
    //eslint-disable-next-line
  }, [menuCTA]);

  return (
    <div>
      <Form.Group>
        <Form.Label>Emails to receive issue reports</Form.Label>
        <TagsInput
          className="react-tagsinput custom-emails-tag-input"
          inputProps={{ placeholder: disabled ? "" : "Add an email" }}
          disabled={disabled}
          onlyUnique={true}
          value={emailsTags}
          onChange={(tags) => {
            if (emailsTags?.length >= 1) {
              setEmailsTags(tags);
              onChange();
            }
          }}
        />
        {!disabled && (
          <small style={{ color: "rgb(149, 144, 144)" }}>Add multiple emails by hitting Tab, Enter, or Return after each email.</small>
        )}
        <br />
      </Form.Group>
    </div>
  );
}
