import { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import styles from "./Menu.module.scss";
import placeholderFood from "../../assets/img/placeholder_food.png";
import { IKImage } from "imagekitio-react";
import DeleteItem from "./DeleteItem";
import Modal from "../../components/Modal";
import DeleteConfirmation from "../../components/Forms/DeleteConfirmation";

export default function Menu({ menu, menu_index, map_index, zone_index, location_index, findAndDeleteMenu }) {
  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const deleteItem = (id) => {
    showConfirmationModal(id);
  };

  const showConfirmationModal = (id) => {
    setModal({
      ...modal,
      title: "Delete content",
      visible: true,
      confirmation: true,
      Component: (
        <DeleteConfirmation
          {...modal}
          customMessage="Are you sure you want to delete this menu"
          customButtonMessage="Delete"
          toastMessage="Menu has been deleted"
          funk={() => {
            findAndDeleteMenu(id, map_index, zone_index, location_index);
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  return (
    <>
      <Modal {...modal} />
      <Draggable draggableId={`${menu?._id}`} key={menu?._id} index={menu_index}>
        {(provided) => (
          <div {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef} className={styles.theMenu}>
            <button
              className={styles.deleteMenu}
              title="Remove menu from this section"
              onClick={(e) => {
                e.preventDefault();
                deleteItem(menu?._id);
              }}
            >
              <DeleteItem />
            </button>
            <div className={styles.typeMenu}>
              {menu?.menu?.cover ? (
                <IKImage
                  path={menu?.menu?.cover}
                  style={{ borderRadius: "4px", objectFit: "cover" }}
                  transformation={[{ width: 125, height: 225 }]}
                  loading="lazy"
                  alt={menu?.menu?.name}
                  lqip={{ active: true }}
                />
              ) : (
                <img src={placeholderFood} alt={menu?.menu?.name} loading="lazy" />
              )}
              <div className={styles.menuName}>
                <p>{menu?.menu?.name}</p>
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Draggable>
    </>
  );
}
