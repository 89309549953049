import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import styles from "./CustomizationForm.module.scss";
import { Tooltip } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Collapse from "react-bootstrap/Collapse";
import Modal from "../../Modal";
import { Api, Endpoint } from "../../../api/index";
import { toast } from "react-toastify";
import LoadingSpinner from "../../LoadingSpinner";
import Checkbox from "../../Checkbox";
import SelectList from "../../SelectList";
import DefaultData from "./DefaultData";
import LoadGlobalTemplates from "./LoadGlobalTemplates";
import Alert from "react-bootstrap/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

export default function CustomizationForm({
  closeModal,
  setValue,
  stateCustomizations,
  metadata,
  ingredients,
  customizationsObject,
  Restaurant,
}) {
  const [modal, setModal] = useState({
    dialogClassName: styles?.customModalStyle,
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const [duplicateValidationsError, setDuplicateValidationErrors] = useState([]);
  const [validationIngredientErrors, setValidationIngredientError] = useState({});

  const [duplicateIngredientsError, setDuplicateIngredientsError] = useState([]);

  const [saveChangesLoading, setSaveChangesLoading] = useState(false);
  const [collapseIndex, setCollapseIndex] = useState(0);
  const [customizations, setCustomizations] = useState(
    stateCustomizations && stateCustomizations?.length > 0 ? stateCustomizations : [DefaultData?.initialCustomization()]
  );
  const { register, handleSubmit, errors, clearErrors } = useForm();

  const loadFromGlobalTemplates = () => {
    setModal({
      ...modal,
      visible: true,
      title: "Load menu-wide customizations",
      Component: (
        <LoadGlobalTemplates
          {...modal}
          setCustomizations={setCustomizations}
          customizations={customizations}
          Restaurant={Restaurant}
          setCollapseIndex={setCollapseIndex}
        />
      ),
    });
  };

  const transformIngredients = (ingredientsArr) => {
    if (!ingredientsArr || ingredientsArr?.length === 0) return [];
    return ingredientsArr?.map((ingredient) => {
      if (typeof ingredient === "string" && ingredients && ingredients?.length > 0) {
        // TODO: Update this if we are using pagination. It might be wrong.
        const foundIngredient = ingredients.find((ing) => ing?._id?.toString() === ingredient?.toString());
        if (foundIngredient) {
          return { value: foundIngredient?._id, label: foundIngredient?.name };
        }
      }
      if (ingredient?._id && ingredient?.name && !ingredient?.value) {
        return { value: ingredient?._id, label: ingredient?.name };
      } else {
        return { value: ingredient?.value, label: ingredient?.label };
      }
    });
  };

  const addOption = (index) => {
    const tempCustomizations = [...customizations];
    tempCustomizations[index].options.push(DefaultData?.initialCustomizationOptions());
    setCustomizations(tempCustomizations);
  };

  const clearIngredientsError = (parentIndex, index) => {
    const tempIngredientsError = { ...validationIngredientErrors };
    if (tempIngredientsError?.[parentIndex]?.[index]) {
      if (Object?.keys(tempIngredientsError?.[parentIndex]).length === 1) {
        delete tempIngredientsError?.[parentIndex];
      } else {
        delete tempIngredientsError?.[parentIndex]?.[index];
      }
    }
    setValidationIngredientError(tempIngredientsError);
  };

  const removeOption = (parentIndex, index) => {
    const tempCustomizations = [...customizations];
    if (tempCustomizations?.[parentIndex]?.options?.length > 1) {
      tempCustomizations[parentIndex].options.splice(index, 1);
      setCustomizations(tempCustomizations);
      clearIngredientsError(parentIndex, index);
    }
  };

  const displayError = (parentIndex, optionIndex, input) => {
    if (errors?.options?.[parentIndex]?.[input]?.[optionIndex]?.message) {
      return errors?.options?.[parentIndex]?.[input]?.[optionIndex]?.message;
    }
    return null;
  };

  const handleOnChangeCustomizationOptions = (parentIndex, input, index, value, params = {}) => {
    const tempCustomizations = [...customizations];
    tempCustomizations[parentIndex].options[index][input] = value;
    setCustomizations(tempCustomizations);
    if (params?.ingredient) {
      validateIngredients(tempCustomizations);
    }
  };

  const handleCustomizationInput = (parentIndex, input, value, metadata) => {
    const tempCustomizations = [...customizations];
    if (metadata?.isCustom) {
      // Clear form error
      clearErrors(`options.${parentIndex}.names`);
      clearErrors(`options.${parentIndex}.prices`);

      // Clear ingredients validation error
      const tempIngredientsError = { ...validationIngredientErrors };
      delete tempIngredientsError?.[parentIndex];
      setValidationIngredientError(tempIngredientsError);

      tempCustomizations[parentIndex]["options"] = [DefaultData?.initialCustomizationOptions()];
    }

    tempCustomizations[parentIndex][input] = value;
    setCustomizations(tempCustomizations);
  };

  const validateIngredients = (data) => {
    let temp = {};
    data.forEach((elem, parentIndex) => {
      if (!elem?.isCustom && elem?.options?.length > 0) {
        elem.options.forEach((option, index) => {
          if (!option?.ingredient) {
            if (!temp[parentIndex]) temp[parentIndex] = {};
            temp[parentIndex][index] = true;
          } else {
            if (temp[parentIndex]) {
              temp[parentIndex][index] = null;
            }
          }
        });
      }
    });
    setValidationIngredientError(temp);
    return temp;
  };

  const hasDuplicates = (customizationData) => {
    const seenDuplicates = {};
    customizationData.forEach((customization) => {
      if (customization?.name && !customization?.ingredient) {
        const name = customization?.name?.trim();
        !seenDuplicates?.[name] ? (seenDuplicates[name] = 1) : seenDuplicates[name]++;
      }
    });

    if (seenDuplicates) {
      const foundDuplicates = Object?.keys(seenDuplicates)?.filter((duplicate) => seenDuplicates[duplicate] > 1);
      if (foundDuplicates?.length > 0) {
        setDuplicateValidationErrors(foundDuplicates);
        return true;
      } else {
        setDuplicateValidationErrors([]);
        return false;
      }
    }
    return false;
  };

  const hasDuplicateIngredients = (customizations) => {
    const seenDuplicates = {};
    for (const customization of customizations) {
      if (customization?.options?.length > 0) {
        const index = customization.name;
        for (const option of customization?.options) {
          const id = option?.ingredient?.value ? option?.ingredient?.value : option?.ingredient?._id ? option?.ingredient?._id : null;
          if (id) {
            if (!seenDuplicates[index]) {
              seenDuplicates[index] = {};
              seenDuplicates[index]["count"] = 1;
              seenDuplicates[index]["id"] = id;
            } else {
              if (id && seenDuplicates[index]["id"] && id.toString() === seenDuplicates?.[index]?.["id"]?.toString()) {
                seenDuplicates[index]["count"]++;
                seenDuplicates[index]["name"] = option?.ingredient?.label ? option?.ingredient?.label : option?.ingredient?.name;
                seenDuplicates[index]["parent"] = customization?.name;
              }
            }
          }
        }
      }
    }

    let stateData = [];
    for (const duplicate in seenDuplicates) {
      if (seenDuplicates?.[duplicate]?.count > 1) {
        stateData = [...stateData, { ingredient: seenDuplicates?.[duplicate]?.name, parent: seenDuplicates?.[duplicate]?.parent }];
      }
    }

    setDuplicateIngredientsError(stateData);
    return stateData.length > 0;
  };

  const submit = async (data) => {
    let customizationData = [...customizations];

    const validatedIngredients = validateIngredients(customizationData);

    // If there are ingredients errors, return
    if (Object?.keys(validatedIngredients)?.length > 0) {
      return;
    }

    // If there are duplicates errors, return
    if (hasDuplicates(customizationData)) {
      return;
    }

    for (const customization of customizationData) {
      if (hasDuplicates(customization?.options)) {
        return;
      }
    }

    if (hasDuplicateIngredients(customizationData)) {
      return;
    }

    customizationData.forEach((customization) => {
      // Transform ingredient - from object to id
      customization?.options?.forEach((option) => {
        if (option?.ingredient?.value) {
          option.ingredient = option?.ingredient?.value;
        }
      });
    });

    metadata?.restaurant && metadata?.fromRestaurant && setSaveChangesLoading(true);

    if (metadata?.restaurant && metadata?.fromRestaurant) {
      const response = await Api.call(Endpoint?.setCustomizations, { data: { customizations: customizationData } });
      if (response?.confirmation === "Success" && response?.message && Array.isArray(response?.message)) {
        Restaurant.find({ forceRender: true });
        setCustomizations(response?.message);

        toast("Changes have been saved successfully");
        setSaveChangesLoading(false);

        return;
      }
      toast(response?.message);
      setSaveChangesLoading(false);
    } else {
      const tempCustomizationsObj = { ...customizationsObject };
      tempCustomizationsObj.custom = customizationData;
      setValue("customizations", JSON.stringify(tempCustomizationsObj));
      typeof closeModal === "function" && closeModal();
    }
  };

  const addCustomization = () => {
    const tempCustomizations = [...customizations];
    tempCustomizations.push(DefaultData?.initialCustomization());
    setCustomizations(tempCustomizations);
    setCollapseIndex(customizations?.length);
  };

  const deleteCustomization = (index) => {
    const tempCustomizations = [...customizations];
    if (tempCustomizations?.length > 1) {
      tempCustomizations.splice(index, 1);
      setCustomizations(tempCustomizations);
    }
  };

  const handleCollapseOnChange = (index) => {
    setCollapseIndex(collapseIndex === index ? -1 : index);
  };

  const hasErrors = () => {
    return Object?.keys(errors)?.length > 0 || (validationIngredientErrors && Object?.keys(validationIngredientErrors)?.length > 0);
  };

  return (
    <div style={{ maxWidth: 800 }}>
      <Modal {...modal} />
      {duplicateValidationsError && duplicateValidationsError?.length > 0 && (
        <Alert variant={"danger"}>
          {duplicateValidationsError?.map((duplicate, index) => (
            <div key={index}>
              <small>
                <strong style={{ fontWeight: "bold" }}>{duplicate}</strong> already exists.
              </small>
            </div>
          ))}
        </Alert>
      )}
      {duplicateIngredientsError && duplicateIngredientsError?.length > 0 && (
        <Alert variant={"danger"}>
          {duplicateIngredientsError?.map((duplicate, index) => (
            <div key={index}>
              <small>
                <strong style={{ fontWeight: "bold" }}>{duplicate?.ingredient}</strong> already exists at{" "}
                <strong>{duplicate?.parent}</strong>.
              </small>
            </div>
          ))}
        </Alert>
      )}
      <Form onSubmit={handleSubmit(submit)}>
        {!metadata?.restaurant && Restaurant?.restaurant?.customizations?.length > 0 && (
          <div className={styles?.loadFromGlobalTemplates}>
            <p onClick={loadFromGlobalTemplates}>Load from global</p>
          </div>
        )}
        {customizations &&
          customizations?.length > 0 &&
          customizations?.map((customization, parentIndex) => {
            return (
              <div
                key={parentIndex}
                style={{
                  margin: collapseIndex !== parentIndex ? "0" : "10px 0",
                  transition: "all .4s ease",
                }}
              >
                <div className={styles?.customizationsWrapper}>
                  <div
                    className={styles?.openCustomization}
                    onClick={() => handleCollapseOnChange(parentIndex)}
                    style={{
                      transition: "all .4s ease",
                      backgroundColor: collapseIndex !== parentIndex ? "#f9f9f9" : "white",
                    }}
                  >
                    <h5>{customization?.name?.length > 0 ? customization?.name : "Customization name"}</h5>
                    <i
                      className="far fa-chevron-down"
                      style={{ transform: `${collapseIndex === parentIndex ? "rotate(180deg)" : "rotate(0deg)"}` }}
                    ></i>
                  </div>
                  <div
                    className={`${styles.deleteWrapper} ${customizations?.length === 1 ? styles.disabled : ""}`}
                    onClick={() => {
                      customizations.length !== 1 && deleteCustomization(parentIndex);
                    }}
                  >
                    <Tooltip
                      title={customizations.length === 1 ? "You can not delete the last customization." : "Delete customization"}
                      enterTouchDelay={0}
                    >
                      <i className="far fa-trash-alt"></i>
                    </Tooltip>
                  </div>
                </div>

                <Collapse in={collapseIndex === parentIndex} className={styles?.collapseExpanded}>
                  <div id={`customizations-${parentIndex}`}>
                    <Form.Group>
                      <div className="d-flex justify-content-between">
                        <Form.Label>Customization name</Form.Label>
                      </div>
                      <Form.Control
                        name={`name.${parentIndex}`}
                        value={customizations?.[parentIndex]?.name ? customizations?.[parentIndex]?.name : ""}
                        onChange={(e) => handleCustomizationInput(parentIndex, "name", e?.target?.value)}
                        ref={register({ required: `Please fill this field.` })}
                        placeholder="Customization name"
                      />
                      {errors?.name?.[parentIndex]?.message && (
                        <small style={{ color: "red" }}>{errors?.name?.[parentIndex]?.message}</small>
                      )}
                    </Form.Group>

                    <div className="d-flex justify-content-between mb-4 align-items-center customization-options">
                      <div>
                        <FormControlLabel
                          value="start"
                          control={
                            <Radio
                              style={{ color: "var(--brand)" }}
                              size="small"
                              onChange={(e) => {
                                handleCustomizationInput(parentIndex, "isCustom", !customizations?.[parentIndex]?.isCustom, {
                                  isCustom: false,
                                });
                              }}
                              checked={customizations?.[parentIndex]?.isCustom ? false : true}
                            />
                          }
                          label="Select from ingredients"
                          labelPlacement="start"
                          style={{ textTransform: "none", marginBottom: 0 }}
                        />
                        <FormControlLabel
                          value="start"
                          control={
                            <Radio
                              style={{ color: "var(--brand)" }}
                              size="small"
                              onChange={(e) => {
                                handleCustomizationInput(parentIndex, "isCustom", !customizations?.[parentIndex]?.isCustom, {
                                  isCustom: true,
                                });
                              }}
                              checked={customizations?.[parentIndex]?.isCustom ? customizations?.[parentIndex]?.isCustom : false}
                            />
                          }
                          label="Add custom properties"
                          labelPlacement="start"
                          style={{ textTransform: "none", marginBottom: 0 }}
                        />
                      </div>
                      <div className={"d-flex justify-content-end"}>
                        <div className={styles?.input} style={{ display: "flex", alignItems: "center" }}>
                          <p
                            style={{
                              margin: "0 10px 0",
                              fontSize: 13,
                              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                              fontWeight: 400,
                              lineHeight: 1.5,
                              letterSpacing: "0.00938em",
                              color: "var(--text-color)",
                            }}
                          >
                            Pricing option:
                          </p>
                          <div className={styles?.fixedPriceBtn}>
                            <Button
                              className={customization?.isFixedPrice ? styles?.isFixedPriceButton : ""}
                              onClick={() => handleCustomizationInput(parentIndex, "isFixedPrice", true)}
                            >
                              $
                            </Button>
                            <Tooltip title="Adds an additional x% to the base dish price" enterTouchDelay={0}>
                              <Button
                                className={!customization?.isFixedPrice ? styles?.isFixedPriceButton : ""}
                                onClick={() => handleCustomizationInput(parentIndex, "isFixedPrice", false)}
                              >
                                %
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Form.Label style={{ verticalAlign: "middle", marginBottom: 0, fontSize: 12 }}>
                          <span style={{ fontSize: 12 }}>Is Available</span>
                          <Checkbox
                            checked={customizations?.[parentIndex]?.isAvailable ? true : false}
                            size="small"
                            onChange={(e) => {
                              handleCustomizationInput(parentIndex, "isAvailable", e.target.checked);
                            }}
                          />
                        </Form.Label>
                      </div>
                    </div>

                    <Form.Group>
                      <Form.Label>Customization options</Form.Label>
                      {customization?.options &&
                        customization?.options?.length > 0 &&
                        customization?.options?.map((option, index) => {
                          return (
                            <div key={index}>
                              <div className={styles?.repeaterOptionsWrapper}>
                                <div className={styles?.inputOptionsWrapper}>
                                  {customizations?.[parentIndex]?.isCustom && (
                                    <div className={styles?.isAvailableOptionWrapper}>
                                      <Tooltip title="Is Available" enterTouchDelay={0}>
                                        <div>
                                          <Checkbox
                                            checked={
                                              typeof customizations?.[parentIndex]?.options?.[index]?.isAvailable === "boolean"
                                                ? customizations?.[parentIndex]?.options?.[index]?.isAvailable
                                                : true
                                            }
                                            size="small"
                                            onChange={() => {
                                              handleOnChangeCustomizationOptions(
                                                parentIndex,
                                                "isAvailable",
                                                index,
                                                customizations?.[parentIndex]?.options?.[index]?.isAvailable ? false : true
                                              );
                                            }}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  )}

                                  <div className={styles?.input}>
                                    <Form.Group>
                                      {!customizations?.[parentIndex]?.isCustom ? (
                                        <>
                                          <SelectList
                                            placeholder="Select ingredient..."
                                            isMulti={false}
                                            value={
                                              customizations?.[parentIndex]?.options?.[index]?.ingredient
                                                ? transformIngredients([customizations?.[parentIndex]?.options?.[index]?.ingredient])
                                                : []
                                            }
                                            options={transformIngredients(ingredients)}
                                            onChange={(data) =>
                                              handleOnChangeCustomizationOptions(parentIndex, "ingredient", index, data, {
                                                ingredient: true,
                                              })
                                            }
                                          />
                                          {validationIngredientErrors?.[parentIndex]?.[index] && (
                                            <small style={{ color: "red" }}>This field is required</small>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <Form.Control
                                            name={`options.${parentIndex}.names.${index}`}
                                            value={
                                              customizations?.[parentIndex]?.options?.[index]?.name
                                                ? customizations?.[parentIndex]?.options?.[index]?.name
                                                : ""
                                            }
                                            onChange={(e) =>
                                              handleOnChangeCustomizationOptions(parentIndex, "name", index, e?.target?.value)
                                            }
                                            ref={register({
                                              required: `Please fill this field.`,
                                            })}
                                            placeholder="Name"
                                          />
                                          {displayError(parentIndex, index, "names") && (
                                            <small style={{ color: "red" }}>{displayError(parentIndex, index, "names")}</small>
                                          )}
                                        </>
                                      )}
                                    </Form.Group>
                                  </div>

                                  <div className={styles?.input} style={{ maxWidth: 250 }}>
                                    <Form.Group>
                                      <Form.Control
                                        type="number"
                                        value={
                                          customizations?.[parentIndex]?.options?.[index]?.price
                                            ? customizations?.[parentIndex]?.options?.[index]?.price
                                            : ""
                                        }
                                        name={`options.${parentIndex}.prices.${index}`}
                                        onChange={(e) => handleOnChangeCustomizationOptions(parentIndex, "price", index, e?.target?.value)}
                                        placeholder="Price"
                                      />
                                    </Form.Group>
                                  </div>
                                </div>

                                <div className={styles?.deleteOptionBtn}>
                                  <Tooltip title="Delete option - disabled for the first option" enterTouchDelay={0}>
                                    <i onClick={() => removeOption(parentIndex, index)} className="far fa-trash-alt fa-1.5x" />
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      <div className={styles?.addOptionRepeaterWrapper}>
                        <Tooltip title="Add new option" enterTouchDelay={0}>
                          <p onClick={() => addOption(parentIndex)}>Add option</p>
                        </Tooltip>
                      </div>
                    </Form.Group>
                  </div>
                </Collapse>
              </div>
            );
          })}
        <div className="d-flex justify-content-end mt-5">
          <Tooltip title="Add another customization" style={{ marginRight: "10px" }} enterTouchDelay={0}>
            <Button variant="secondary" onClick={addCustomization}>
              Add more
            </Button>
          </Tooltip>

          <Tooltip title={hasErrors() ? "Some errors were found." : "Save current changes."} enterTouchDelay={0}>
            <div>
              <Button type="submit" disabled={hasErrors() ? true : saveChangesLoading ? true : false}>
                {saveChangesLoading ? <LoadingSpinner /> : "Submit"}
              </Button>
            </div>
          </Tooltip>
        </div>
      </Form>
    </div>
  );
}
