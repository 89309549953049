import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import LoadingSpinner from "../../LoadingSpinner";
import registerStyle from "../../../Views/Register/Register.module.scss";
import { useHistory } from "react-router-dom";
import Colors from "../../Colors/Colors";
import ImageInput from "../../ImageInput";
import SelectList from "../../SelectList";
import SelectFontList from "../../SelectFontList";
import MenuNames from "./MenuNames";
import SelectItem from "../../SelectItem";
import styles from "./MenuForm.module.scss";
import { toast } from "react-toastify";
import Section from "../../Section";
import MenuCTA from "./MenuCTA";
import general from "../../../helpers/general";
import WarningAlert from "../../../components/Alerts/Warning";
import Modal from "../../../components/Modal";
import SubscribeRequireModal from "../../Modal/SubscribeRequiredModal";
import constants from "../../../constants";
import TextEditor from "../../TextEditor";
import File from "../../../utils/file";
import DeleteConfirmation from "../DeleteConfirmation";

const templates = constants.templates;

function MenuForm({ Auth, Menu, Category, Restaurant, update, fromDashboard, closeModal, fixedActions = true }) {
  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const [description, setDescription] = useState("");

  const showSubscribeRequired = async () => {
    setModal({
      ...modal,
      visible: true,
      title: "Subscribe required",
      Component: <SubscribeRequireModal Auth={Auth} closeModal={() => setModal({ ...modal, visible: false })} />,
    });
  };

  const defaultMenuCTA = {
    title: process.env.REACT_APP_MENU_CTA_DEFAULT_TITLE,
    content: process.env.REACT_APP_MENU_CTA_DEFAULT_CONTENT,
    emails: [Auth?.user?.email],
  };

  const history = useHistory();

  const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE);
  const maxFileSizeErrorText = process.env.REACT_APP_MAX_FILE_SIZE_ERROR;

  const [error, setError] = useState(null);
  const [selectCategories, setSelectedCategories] = useState([]);
  const [singleMenu, setSingleMenu] = useState({});
  const [fileError, setFileError] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [colors, setColors] = useState({
    primary: "#ff1616",
    secondary: "#83cdf1",
    tertiary: "#f46d26",
  });

  const [displayDietary, setDisplayDietary] = useState(true);
  const [displayIngredients, setDisplayIngredients] = useState(true);
  const [displaySideDishes, setDisplaySideDishes] = useState(true);
  const [templateSelected, setTemplateSelected] = useState("");
  const [selectedFonts, setSelectedFonts] = useState({
    primary: "",
    secondary: "",
    tertiary: "",
  });
  const [googleFonts, setGoogleFonts] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  //eslint-disable-next-line
  const [saveLoading, setSaveLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [menuCTA, setMenuCTA] = useState({});

  /* This we will use to display name on menuNames */
  const [menuSelected, setMenuSelected] = useState({});
  const [selectedMeta, setSelectedMeta] = useState({});

  const { register, handleSubmit, errors, setValue, clearErrors } = useForm();
  const { menus } = Menu;
  const { categories } = Category;

  const [menuNotSaved, setMenuNotSaved] = useState(false);
  const [imageInputKey, setImageInputKey] = useState(new Date().getTime().toString());

  const loadGoogleFonts = async () => {
    const response = await fetch(
      "https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyA8F0vStR1zTh9MwYnHkop4rd8NacHtV8I"
    );
    const resp = await response.json();
    let fonts = resp?.items;
    fonts.length = 50;
    setGoogleFonts(fonts);
  };

  const transformFonts = (googleFonts) => {
    if (googleFonts?.length === 0) return [];
    return googleFonts.map((font) => ({
      value: font?.family,
      label: font?.family,
    }));
  };

  useEffect(() => {
    const localStorageMenu = Menu.getMenuFromSessionStorage();
    if (localStorageMenu?._id) {
      loadSingleMenu(localStorageMenu?._id);
    }

    register({ name: "description", value: description });
    register({ name: "categories", value: JSON.stringify([]) });
    register({ name: "fonts" });
    register({ name: "selectedTemplate" });
    register({ name: "showCTA", value: JSON.stringify(menuCTA) });
    register({ name: "displayIngredients", value: displayIngredients });
    register({ name: "displayDietary", value: displayDietary });
    register({ name: "displaySideDishes", value: displaySideDishes });

    Menu.load();
    Category.load();

    loadGoogleFonts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Menu?.selected?._id) {
      if (Menu?.selected?._id?.toString() !== menuSelected?._id?.toString()) {
        setMenuSelected(Menu?.selected);
        return;
      }
    }

    //eslint-disable-next-line
  }, [Menu?.selected]);

  useEffect(() => {
    setTemplateSelected(Menu?.selected?.selectedTemplate);
  }, [Menu]);

  useEffect(() => {
    if (singleMenu?._id?.toString() !== menuSelected?._id?.toString()) {
      setSingleMenu(menuSelected);
    }
    //eslint-disable-next-line
  }, [menuSelected]);

  // useEffect(() => )

  useEffect(() => {
    setValue("description", description);
    //eslint-disable-next-line
  }, [description]);

  useEffect(() => {
    setValue("showCTA", JSON.stringify(menuCTA));
    //eslint-disable-next-line
  }, [menuCTA]);

  useEffect(() => {
    let tempFonts = {
      primary: selectedFonts.primary.value,
      secondary: selectedFonts.secondary.value,
      tertiary: selectedFonts.tertiary.value,
    };
    setValue("fonts", JSON.stringify(tempFonts));
    // eslint-disable-next-line
  }, [selectedFonts]);

  useEffect(() => {
    setValue("categories", selectCategories);
    //eslint-disable-next-line
  }, [selectCategories]);

  useEffect(() => {
    setValue("colors", JSON.stringify(colors));
    //eslint-disable-next-line
  }, [colors]);

  useEffect(() => {
    setValue("selectedTemplate", templateSelected);
    //eslint-disable-next-line
  }, [templateSelected]);

  useEffect(() => {
    setValue("displayIngredients", displayIngredients);
    //eslint-disable-next-line
  }, [displayIngredients]);

  useEffect(() => {
    setValue("displayDietary", displayDietary);
    //eslint-disable-next-line
  }, [displayDietary]);

  useEffect(() => {
    setValue("displaySideDishes", displaySideDishes);
    //eslint-disable-next-line
  }, [displaySideDishes]);

  useEffect(() => {
    singleMenu?.categories
      ? setSelectedCategories(
          singleMenu?.categories?.map((schema) => ({
            value: schema?.category?._id,
            label: schema?.category?.name,
            valid: schema?.valid,
          }))
        )
      : setSelectedCategories([]);

    singleMenu?.cover ? setCoverImage(singleMenu?.cover) : setCoverImage(null);
    singleMenu?.logo ? setLogoImage(singleMenu?.logo) : setLogoImage(null);
    singleMenu?.colors ? setColors(singleMenu?.colors) : setColors({});

    setDisplayDietary(singleMenu?.displayDietary === undefined ? true : singleMenu?.displayDietary);
    setDisplayIngredients(singleMenu?.displayIngredients === undefined ? true : singleMenu?.displayIngredients);
    setDisplaySideDishes(singleMenu?.displaySideDishes === undefined ? true : singleMenu?.displaySideDishes);

    setValue("name", singleMenu?.name);
    setDescription(singleMenu?.description || "");
    // setValue("description", singleMenu?.description);
    setValue("content", singleMenu?.showCTA ? singleMenu?.showCTA?.content : defaultMenuCTA?.content);

    singleMenu?.showCTA ? setMenuCTA(singleMenu?.showCTA) : setMenuCTA(defaultMenuCTA);

    if (singleMenu) {
      const primary = singleMenu?.fonts?.primary;
      const secondary = singleMenu?.fonts?.secondary;
      const tertiary = singleMenu?.fonts?.tertiary;
      setSelectedFonts({
        primary: { label: primary, value: primary },
        secondary: { label: secondary, value: secondary },
        tertiary: { label: tertiary, value: tertiary },
      });
    } else setSelectedFonts({ primary: "", secondary: "", tertiary: "" });
    //eslint-disable-next-line
  }, [singleMenu]);

  // useEffect(() => {
  //     if (templates && templates[0] && templates[0]?.template) {
  //         setTemplateSelected(templates[0].template);
  //     }
  // }, [templates]);

  useEffect(() => {
    if (templates && templateSelected) {
      let index = templates.findIndex((tmp) => tmp.template === templateSelected);
      setSelectedMeta(templates[index]?.template_meta);
    }
    //eslint-disable-next-line
  }, [templates, templateSelected]);

  const transformCategories = (categories) => {
    if (categories?.length === 0) return [];
    let selectOptions = categories.map((category) => ({
      value: category?._id,
      label: category?.name,
    }));
    return selectOptions;
  };

  const startLoading = (params) => {
    if (params.publish || params.unPublish) setPublishLoading(true);
    else setSaveLoading(true);
  };

  const stopLoading = (params) => {
    if (params.publish || params.unPublish) setPublishLoading(false);
    else setSaveLoading(false);
  };

  const validateFile = (file, field) => {
    if (!file || typeof file === "string") {
      setFileError(null);
      return true;
    }

    if (file?.size > maxFileSize) {
      setFileError({ field, message: `This file ${file?.name} is big. Maximum size for file is ${maxFileSizeErrorText}.` });
      return false;
    }

    try {
      const allowed_extensions = File.allowed_extensions;
      for (let i = 0; i < allowed_extensions.length; i++) {
        if (file?.name?.endsWith(allowed_extensions[i])) {
          setFileError(null);
          return true;
        }
      }
      setFileError({ field, message: "File not allowed" });
      return false;
    } catch (err) {
      return false;
    }
  };

  const loadSingleMenu = async (id) => {
    const response = await Menu.findSingle(id);
    if (response?.confirmation === "Success") {
      setMenuSelected(response?.message);
      setSingleMenu(response?.message);
    }
  };

  const showDeletePrompt = async () => {
    setModal({
      ...modal,
      visible: true,
      title: "Delete Menu",
      Component: (
        <DeleteConfirmation
          type="Dishes"
          customMessage={"Are you sure you want to delete this menu"}
          customButtonMessage={"Delete"}
          funk={async (id) => {
            await Menu.delete(singleMenu?._id);
            setDeleteLoading(false);
            toast("Menu deleted successfully");
            if (typeof closeModal === "function") {
              closeModal();
              return;
            }
            history.push("/");
          }}
          closeModal={() => {
            setModal({ ...modal, visible: false });
            setDeleteLoading(false);
          }}
        />
      ),
    });
  };

  const submit = async (data, params = {}) => {
    if (params.publish) {
      data.draft = false;
    }
    if (params.unPublish) data.draft = true;

    const tempMenuCTA = { ...menuCTA, content: data?.content };

    setMenuNotSaved(false);

    if (params.delete) {
      setDeleteLoading(true);
      showDeletePrompt();
      return;
    }

    startLoading(params);

    let menuCategories =
      selectCategories?.length > 0 ? selectCategories.map((category) => ({ category: category?.value, valid: category?.valid })) : [];
    data = {
      ...data,
      categories: JSON.stringify(menuCategories),
      colors: JSON.stringify(colors),
      ...{ showCTA: JSON.stringify(tempMenuCTA) },
    };

    if (!validateFile(coverImage, "cover")) {
      stopLoading(params);
      return false;
    }

    if (!validateFile(logoImage, "logo")) {
      stopLoading(params);
      return false;
    }

    // fallback on first template if not selected
    if (data.selectedTemplate === "") {
      if (templates && templates[0] && templates[0]?.template) {
        data.selectedTemplate = templates[0].template;
      }
    }

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    formData.append("cover", coverImage);
    formData.append("logo", logoImage);

    const response = singleMenu?._id ? await Menu.update(singleMenu?._id, formData) : await Menu.create(formData);

    if (response?.confirmation === "Fail") {
      stopLoading(params);
      if (response?.message === "Payment not setup!") {
        showSubscribeRequired();
        return;
      }
      if (!general.checkPermissionDeniedResponse(response)) setError(response?.message);
    } else {
      stopLoading(params);
      setError(null);
      toast(singleMenu?._id ? "Menu updated successfully" : params?.publish ? "Menu published successfully" : "Menu saved successfully");
      typeof closeModal === "function" && closeModal();
      setSingleMenu(response?.message);
    }
  };

  const notifyInputChanged = () => {
    if (!menuNotSaved) {
      setMenuNotSaved(true);
    }
  };

  const onCoverRemove = () => {
    setCoverImage(null);
    setImageInputKey(new Date().getTime().toString());
  };
  const onLogoRemove = () => {
    setLogoImage(null);
    setImageInputKey(new Date().getTime().toString());
  };

  return (
    <div>
      <Modal {...modal} />
      {singleMenu?.draft && <WarningAlert title="Your menu has not been published yet!" />}
      {menuNotSaved && <WarningAlert title={`You haven't saved your changes!`} />}
      {singleMenu.fromParent && (
        <WarningAlert
          title={`This menu is inherited from ${Restaurant?.restaurant?.parentRestaurant?.name}. It can only be edited by the owner of the menu!`}
        />
      )}
      {!fromDashboard && (
        <MenuNames
          onClick={(menu, obj) => {
            setImageInputKey(new Date().getTime().toString());
            if (obj?.addNew) {
              setError(null);
              clearErrors();
              setMenuSelected({});
              setSingleMenu({});
              localStorage.removeItem("menuSelected");
              Menu?.setMenuSelectedLocally({});
            } else {
              setError(null);
              clearErrors();
              setSingleMenu(menu);
              setMenuSelected(menu);

              localStorage.setItem("menuSelected", JSON.stringify(menu));
              Menu?.setMenuSelectedLocally(menu);
            }
          }}
          menuSelected={Menu?.selected}
          menus={menus}
        />
      )}
      <Section title="Menu" margin="0">
        <Form onSubmit={handleSubmit(submit)}>
          <Form.Group>
            <Form.Label>Menu name</Form.Label>
            <Form.Control
              onChange={notifyInputChanged}
              defaultValue={singleMenu?.name ? singleMenu?.name : ""}
              disabled={singleMenu?.fromParent}
              ref={register({
                required: "Menu name cannot be empty",
              })}
              name="name"
              placeholder="Menu Name"
            />
            {(errors?.name?.message || error) && (
              <small className={registerStyle.registerErrorText}>{errors?.name?.message || error}</small>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Menu description</Form.Label>
            <TextEditor
              placeholder="Menu description"
              value={description || ""}
              onChange={setDescription}
              disabled={singleMenu?.fromParent}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Menu Categories</Form.Label>
            <SelectList
              isMulti={true}
              value={selectCategories}
              options={transformCategories(categories)}
              disabled={singleMenu?.fromParent}
              onChange={(data) => {
                notifyInputChanged();
                setSelectedCategories(data);
              }}
            />
          </Form.Group>

          <Form.Group style={{ marginBottom: 25 }}>
            <MenuCTA
              user={Auth?.user}
              menuCTA={menuCTA}
              setMenuCTA={setMenuCTA}
              register={register}
              setValue={setValue}
              styles={styles}
              disabled={singleMenu?.fromParent}
              onChange={() => notifyInputChanged()}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Menu Logo</Form.Label>
            <ImageInput
              key={imageInputKey}
              uploadFromMedia={true}
              placeHolderImage={logoImage}
              placeHolderTitle="Choose a logo to override the default one"
              handleImage={(img) => {
                setLogoImage(img);
                notifyInputChanged();
              }}
              onImageRemove={onLogoRemove}
            />
            {fileError?.field === "logo" && fileError?.message !== "" && (
              <small className={registerStyle.registerErrorText}>{fileError?.message}</small>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Menu Cover</Form.Label>
            <ImageInput
              key={imageInputKey}
              uploadFromMedia={true}
              placeHolderImage={coverImage}
              placeHolderTitle="Choose a menu cover"
              handleImage={(img) => {
                setCoverImage(img);
                notifyInputChanged();
              }}
              onImageRemove={onCoverRemove}
            />
            {fileError?.field === "cover" && fileError?.message !== "" && (
              <small className={registerStyle.registerErrorText}>{fileError?.message}</small>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label style={{ marginTop: 30 }}>Templates</Form.Label>
            <SelectItem
              selected={templateSelected}
              handleClick={(template) => {
                setTemplateSelected(template === templateSelected ? "" : template);
                notifyInputChanged();
              }}
              data={templates}
            />
          </Form.Group>

          <Section title="Branding">
            <Form.Group className="d-flex">
              <Colors onChange={() => notifyInputChanged()} meta={selectedMeta} colors={colors} setColors={setColors} />
              <div style={{ flexGrow: 1 }}>
                {selectedMeta?.fonts?.primary?.enabled && (
                  <Form.Group>
                    <Form.Label>Primary Font</Form.Label>
                    <SelectFontList
                      isMulti={false}
                      value={selectedFonts.primary}
                      options={transformFonts(googleFonts)}
                      onChange={(font) => {
                        setSelectedFonts({
                          ...selectedFonts,
                          primary: font,
                        });
                        notifyInputChanged();
                      }}
                    />
                    <Form.Text className="text-muted text-right">{selectedMeta?.fonts?.primary?.description}</Form.Text>
                  </Form.Group>
                )}
                {selectedMeta?.fonts?.secondary?.enabled && (
                  <Form.Group>
                    <Form.Label>Secondary Font</Form.Label>
                    <SelectFontList
                      isMulti={false}
                      value={selectedFonts.secondary}
                      options={transformFonts(googleFonts)}
                      onChange={(font) => {
                        setSelectedFonts({
                          ...selectedFonts,
                          secondary: font,
                        });
                        notifyInputChanged();
                      }}
                    />
                    <Form.Text className="text-muted text-right">{selectedMeta?.fonts?.secondary?.description}</Form.Text>
                  </Form.Group>
                )}
                {selectedMeta?.fonts?.tertiary?.enabled && (
                  <Form.Group>
                    <Form.Label>Tertiary Font</Form.Label>
                    <SelectFontList
                      isMulti={false}
                      value={selectedFonts.tertiary}
                      options={transformFonts(googleFonts)}
                      onChange={(font) => {
                        setSelectedFonts({
                          ...selectedFonts,
                          tertiary: font,
                        });
                        notifyInputChanged();
                      }}
                    />
                    <Form.Text className="text-muted text-right">{selectedMeta?.fonts?.tertiary?.description}</Form.Text>
                  </Form.Group>
                )}
              </div>
            </Form.Group>
          </Section>
          {!singleMenu?.fromParent && (
            <div className={`${fixedActions ? "sticky-footer-wrapper" : "footer-wrapper"}`}>
              {singleMenu?._id ? (
                <div className="d-flex justify-content-end has-gap">
                  {singleMenu?.draft ? (
                    <>
                      <Button
                        variant="secondary"
                        disabled={publishLoading || Object.keys(errors).length > 0 ? true : false}
                        onClick={handleSubmit((data) => submit(data, { publish: true }))}
                      >
                        {publishLoading ? <LoadingSpinner /> : "Publish"}
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="secondary"
                      disabled={publishLoading || Object.keys(errors).length > 0 ? true : false}
                      onClick={handleSubmit((data) => submit(data, { unPublish: true }))}
                    >
                      {publishLoading ? <LoadingSpinner /> : "UnPublish"}
                    </Button>
                  )}

                  <Button disabled={saveLoading || Object.keys(errors).length > 0 ? true : false} type="submit">
                    {saveLoading ? <LoadingSpinner /> : "Update"}
                  </Button>

                  <Button
                    variant="danger"
                    disabled={deleteLoading || Object.keys(errors).length > 0 ? true : false}
                    onClick={handleSubmit((data) => submit(data, { delete: true }))}
                  >
                    {deleteLoading ? <LoadingSpinner /> : "Delete"}
                  </Button>
                </div>
              ) : (
                <div className="d-flex justify-content-end">
                  <Button
                    disabled={publishLoading || Object.keys(errors).length > 0 ? true : false}
                    style={{ marginRight: "10px" }}
                    onClick={handleSubmit((data) => submit(data, { publish: true }))}
                  >
                    {publishLoading ? <LoadingSpinner /> : "Publish"}
                  </Button>
                  <Button variant="secondary" disabled={saveLoading || Object.keys(errors).length > 0 ? true : false} type="submit">
                    {saveLoading ? <LoadingSpinner /> : "Save"}
                  </Button>
                </div>
              )}
            </div>
          )}
        </Form>
      </Section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Category: state.Category,
  Menu: state.Menu,
  Auth: state?.Auth,
  Restaurant: state?.Restaurant,
});

export default connect(mapStateToProps)(MenuForm);
