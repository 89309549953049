import React, { useState } from "react";
import LoadingSpinner from "../../LoadingSpinner";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Form, Button, Alert } from "react-bootstrap";
import { Api, Endpoint } from "../../../api";

export default function TransferOwnershipForm({ closeModal, checkTransferOwnershipStatus, Auth }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const submit = async (data) => {
    setLoading(true);
    const response = await Api.call(Endpoint.request_transfer_ownership, { data });

    if (!response || response?.confirmation === "Fail") {
      setLoading(false);
      setError(response?.message);
      return;
    }

    await checkTransferOwnershipStatus();
    setLoading(false);
    setError(null);
    typeof closeModal === "function" && closeModal();
    toast("Restaurant ownership request has been made successfully.");
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(submit)}>
        <Form.Group>
          <Alert variant={"danger"}>
            <div>Are you sure you want to proceed?</div>
            <div>This action cannot be undone!</div>
          </Alert>
        </Form.Group>

        <Form.Group>
          <div className="d-flex justify-content-between">
            <Form.Label>Email</Form.Label>
            <small style={{ fontWeight: "bold", color: "var(--text-color)" }}>
              User email that you want to transfer ownership
            </small>
          </div>
          <Form.Control
            ref={register({
              required: "Email cannot be empty",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
            type="email"
            name="email"
            placeholder="E-mail *"
          />
          {(errors?.email?.message || error) && <small style={{ color: "red" }}>{errors?.email?.message || error}</small>}
        </Form.Group>

        <Form.Group>
          <div className="d-flex justify-content-between">
            <Form.Label>Password</Form.Label>
            <small style={{ fontWeight: "bold", color: "var(--text-color)" }}>Your account password</small>
          </div>
          <Form.Control
            name="password"
            type="password"
            autocomplete="off"
            ref={register({ required: "For your security, you have to enter your account password in order to continue." })}
            placeholder="Enter your password"
          />
          {errors?.password?.message && <small style={{ color: "red" }}>{errors?.password?.message}</small>}
        </Form.Group>

        <Form.Group className="d-flex justify-content-end">
          {typeof closeModal === "function" && (
            <Button style={{ marginRight: "10px" }} variant="secondary" disabled={loading} onClick={closeModal}>
              Close
            </Button>
          )}
          <Button variant="danger" type="submit" disabled={loading}>
            {loading ? <LoadingSpinner size="sm" /> : "Transfer"}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}
