import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

const withSelectedMenu = (BaseComponent) => (props) => {
  const { data } = props;
  const { menus } = data ? data : {};
  const { Menu } = useSelector((state) => ({ Menu: state?.Menu }));

  const match = useRouteMatch("/menu/:id");
  const matchMap = useRouteMatch("/menu/:restaurantId/:mapId");

  const [menuIndex, setMenuIndex] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [restaurantID, setRestaurantID] = useState(null);

  useEffect(() => {
    if (selectedMenu && typeof menus === "object") {
      const index = menus.findIndex((menu) => menu?._id === selectedMenu?._id);
      setMenuIndex(index);
    }
    // eslint-disable-next-line
  }, [selectedMenu, menus]);

  useEffect(() => {
    if (selectedMenu) {
      document.title = selectedMenu?.name;
    }
  }, [selectedMenu]);

  useEffect(() => {
    if (match?.params?.id && match?.params?.id !== restaurantID) {
      setRestaurantID(match?.params?.id);
    }

    if (matchMap?.params?.restaurantId) {
      setRestaurantID(matchMap?.params?.restaurantId);
    }

    // eslint-disable-next-line
  }, [match]);

  useEffect(() => {
    if (restaurantID && data) {
      if (data?.menus?.length) {
        if (data?.menus?.length > 1) {
          /* We have multiple menus */
          if (!selectedMenu) setSelectedMenu(null);
        } else {
          /* We have just one menu */
          setSelectedMenu(data?.menus[0]);
        }
      } else {
        /* Restaurant does not have any menu */
      }
    }
    // eslint-disable-next-line
  }, [restaurantID, data]);

  useEffect(() => {
    if (match) return;
    if (Menu?.selected && typeof Menu?.selected === "object" && Object.keys(Menu?.selected)?.length) {
      setSelectedMenu(Menu?.selected);
    } else {
      setSelectedMenu(null);
    }
    // eslint-disable-next-line
  }, [Menu?.selected]);

  return <BaseComponent {...props} selectedMenu={selectedMenu} index={menuIndex} setSelectedMenu={setSelectedMenu} />;
};

export default withSelectedMenu;
