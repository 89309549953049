import React from "react";
import TabsView from "../../components/TabsView/TabsView";
import styles from "./Login.module.scss";
import dashboardStyles from "../../App.module.scss";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useState } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useForm } from "react-hook-form";
import MultiAccounts from "./MultiAccounts";

import ReCaptchaComponent from "../../components/ReCaptcha";
import useReCaptcha from "../../hoc/useReCaptcha";
import { Api, Endpoint } from "../../api";
import { useHistory } from "react-router-dom";

function Login({ Auth }) {
  const { reCaptchaRef, executeReCaptchaToken, onChangeReCaptcha } = useReCaptcha();
  const [loading, setLoading] = useState(false);
  const [customError, setCustomError] = useState(null);
  const [accountSelected, setAccountSelected] = useState({});
  const [multipleAccounts, setMultipleAccounts] = useState([]);
  const [accountUnVerifiedData, setAccountUnVerifiedData] = useState({
    account_unverified: false,
    token: null,
  });

  const history = useHistory();

  const submit = async (data) => {
    setLoading(true);
    const reCaptchaToken = await executeReCaptchaToken();

    const field = data?.username?.includes("@") ? { email: data?.username } : { username: data?.username };
    data = { ...field, password: data.password, reCaptchaToken };

    const response = await Auth.LOGIN(data);
    setLoading(false);

    // TODO: For a future use, the api should return error messages types in order to check the error type, instead of checking the response manually.
    if (response?.message?.multipleAccounts) {
      setCustomError(null);
      setMultipleAccounts(response?.message?.accounts);
      setAccountUnVerifiedData({});
    } else if (response?.message?.account_unverified) {
      setAccountUnVerifiedData({ account_unverified: true, token: response?.message?.token });
      setCustomError(null);
      setMultipleAccounts([]);
    } else {
      typeof response?.message === "string" && setCustomError(response?.message);
      setAccountUnVerifiedData({});
    }
  };

  const { register, handleSubmit, errors } = useForm();

  const accountClicked = (account) => {
    setMultipleAccounts([]);
    setAccountSelected(account);
  };

  const resendEmailConfirmation = async () => {
    await Api.call(Endpoint.resendEmailAccountVerify, { data: { token: accountUnVerifiedData?.token } });
    history.push("/register?email_confirmation_sent=true");
  };

  return (
    <form className={styles.loginPageWrapper} onSubmit={handleSubmit(submit)}>
      <ReCaptchaComponent innerRef={reCaptchaRef} onChange={onChangeReCaptcha} />
      <div className={styles.pageContainer}>
        {multipleAccounts?.length > 0 ? (
          <MultiAccounts onClick={accountClicked} data={multipleAccounts} />
        ) : (
          <TabsView active="Login">
            <Form.Group>
              <h3 className={dashboardStyles.formTitle}>Sign in</h3>
              <p className="form-desc">Do you have an account with Foodi?</p>
            </Form.Group>
            <Form.Group>
              <i className="fal fa-envelope"></i>
              <Form.Control
                tabIndex={1}
                defaultValue={accountSelected?.username ? accountSelected?.username : ""}
                ref={register({
                  required: "Username or email cannot be empty",
                })}
                type="text"
                name="username"
                placeholder="Username or Email"
              />
              {(errors?.username?.message || customError) && (
                <small className={styles.registerErrorText}>{errors?.username?.message || customError}</small>
              )}

              {accountUnVerifiedData?.account_unverified && (
                <div className={styles?.accountUnVerified}>
                  <small>
                    Your account has not been verified yet. Please click this <strong onClick={resendEmailConfirmation}>link</strong> to
                    resend an email confirmation.
                  </small>
                </div>
              )}
            </Form.Group>
            <Form.Group>
              <i className="fal fa-unlock"></i>
              <Form.Control
                tabIndex={2}
                ref={register({ required: "Password cannot be empty" })}
                type="password"
                name="password"
                placeholder="Password"
              />
              {errors?.password?.message && <small className={styles.registerErrorText}>{errors.password.message}</small>}
            </Form.Group>
            <Form.Group className="d-flex justify-content-between">
              <Link to="/forgot-password" className="btn btn-secondary">
                Forgot Password?
              </Link>
              <Button tabIndex={3} disabled={loading || Object.keys(errors).length > 0 ? true : false} type="submit">
                {loading ? <LoadingSpinner size="sm" /> : "Sign in"}
              </Button>
            </Form.Group>
          </TabsView>
        )}
      </div>
    </form>
  );
}

const mapStateToProps = (state) => ({ Auth: state.Auth });
export default connect(mapStateToProps)(Login);
