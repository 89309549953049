import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Alert } from "react-bootstrap";
import LoadingSpinner from "../../../LoadingSpinner";
import { Api, Endpoint } from "../../../../api";
import Stepper from "../AddClientStepper";

import { toast } from "react-toastify";

import AddClient from "./AddClient";
import AddOrSelectRestaurant from "./AddOrSelectRestaurant";

import Complete from "./Complete";

const STEPS = {
  ADD_USER_INFO: 0,
  ADD_OR_SELECT_RESTAURANT: 1,
  COMPLETE: 2,
};

export default function Main({ onSuccessCallback, closeModal, loadTemporaryClients }) {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  const [activeStep, setActiveStep] = useState(0);
  const [restaurants, setRestaurants] = useState([]);

  const steps = ["Add email info", "Add or select an restaurant", "Complete"];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const { register, unregister, handleSubmit, errors, setValue, setError, clearErrors, getValues } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldFocusError: true,
    shouldUnregister: true,
    criteriaMode: "all",
  });

  useEffect(() => {
    register(
      { name: "email" },
      {
        required: "Email cannot be empty",
        pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address" },
      }
    );

    register({ name: "restaurant_id", value: null }); // If user selects an existing restaurant
    register({ name: "restaurant_name", value: null }); // If user adds a new restaurant
    //eslint-disable-next-line
  }, []);

  const handleSubmitFirstStep = async (data) => {
    data.step = Object.keys(STEPS)[activeStep];
    const response = await Api.call(Endpoint.DMA.clients.create, { data });
    if (response?.statusCode !== 200) {
      setLoading(false);
      setApiError(response?.message || "Something went wrong");
      return;
    }
    setApiError(null);
    setLoading(false);
    setRestaurants(response?.message?.restaurants || []);
    handleNext();
  };

  const clearErrorsOnSecondStep = function () {
    clearErrors("restaurant_id");
    clearErrors("restaurant_name");
  };

  const submit = async (data) => {
    data.step = Object.keys(STEPS)[activeStep];

    setLoading(true);

    if (STEPS.ADD_USER_INFO === activeStep) {
      await handleSubmitFirstStep(data);
      return;
    }

    setLoading(false);

    if (STEPS.ADD_OR_SELECT_RESTAURANT === activeStep) {
      // Check if the user has selected an existing restaurant or added a new restaurant
      if (data?.restaurant_id || data?.restaurant_name) {
        clearErrorsOnSecondStep();
        handleNext();
        return;
      }

      setError("restaurant_id", {
        type: "manual",
        message: "You must select or add a new restaurant",
      });
      return;
    }
    if (data?.restaurant_id || data?.restaurant_name) {
      clearErrorsOnSecondStep();
    } else {
      setError("restaurant_id", {
        type: "manual",
        message: "You must select or add a new restaurant",
      });
      return;
    }

    setLoading(true);

    data.restaurant_id = getValues?.("restaurant_id")?.value || null;
    data.restaurant_name = getValues("restaurant_name") || null;

    const response = await Api.call(Endpoint.DMA.clients.create, { data });

    if (response?.statusCode === 200) {
      setLoading(false);
      toast("An invitation to this client has been sent successfully");
      typeof loadTemporaryClients === "function" && loadTemporaryClients();
      typeof onSuccessCallback === "function" && onSuccessCallback();
      typeof closeModal === "function" && closeModal();
      return;
    }

    setLoading(false);
    setApiError(response?.message || "Something went wrong");
  };

  return (
    <div>
      <Form onSubmit={handleSubmit((data) => submit(data))}>
        <Stepper
          activeStep={activeStep}
          steps={steps}
          handleBack={handleBack}
          handleNext={handleNext}
          handleReset={handleReset}
        />
        {apiError && <Alert variant="danger">{apiError}</Alert>}

        {activeStep === STEPS.ADD_USER_INFO && (
          <AddClient unregister={unregister} setValue={setValue} getValues={getValues} errors={errors} />
        )}

        {activeStep === STEPS.ADD_OR_SELECT_RESTAURANT && (
          <AddOrSelectRestaurant
            register={register}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            restaurants={restaurants}
          />
        )}

        {activeStep === STEPS.COMPLETE && (
          <Complete register={register} setValue={setValue} getValues={getValues} errors={errors} restaurants={restaurants} />
        )}

        <Form.Group style={{ display: "flex", justifyContent: "flex-end" }}>
          {activeStep > 0 && (
            <Button type="button" onClick={handleBack} variant="secondary" style={{ marginRight: "10px" }}>
              Back
            </Button>
          )}
          <Button type="submit">
            {loading ? <LoadingSpinner size="sm" /> : STEPS.COMPLETE === activeStep ? "Submit" : "Next"}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}
