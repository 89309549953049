import templateMeta1 from "../Templates/Classic Template/template_meta.json";
import templateMeta2 from "../Templates/Pizza Restaurant/template_meta.json";
import templateMeta3 from "../Templates/Sushi Restaurant/template_meta.json";

const templates = [
  {
    template: "Classic Template",
    image: "screenshot.jpg",
    template_meta: templateMeta1,
  },
  {
    template: "Pizza Restaurant",
    image: "screenshot.jpg",
    template_meta: templateMeta2,
  },
  {
    template: "Sushi Restaurant",
    image: "screenshot.jpg",
    template_meta: templateMeta3,
  },
];

export default templates;
