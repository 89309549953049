import React, { useState, useEffect } from "react";
import IngredientForm from "../../components/Forms/IngredientForm";
import Modal from "../../components/Modal";
import Section from "../../components/Section/Section";
import { connect } from "react-redux";
import styles from "../CreateCategory/CreateCategory.module.scss";
import CSVForm from "../../components/Forms/CSVForm";
import { Button } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteConfirmation from "../../components/Forms/DeleteConfirmation";

import Ingredients from "../../components/Table/Ingredients";

import withDashboardLayout from "../../Layouts/AdminPanel";
import { toast } from "react-toastify";

function CreateIngredient({ Ingredient, Auth }) {
  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  useEffect(() => {
    Ingredient.load();
    // eslint-disable-next-line
  }, []);

  const showCSVModal = () => {
    setModal({
      ...modal,
      visible: true,
      title: "Upload ingredients",
      Component: (
        <CSVForm
          Controller={Ingredient}
          downloadExampleLink={process.env.REACT_APP_INGREDIENT_CSV_EXAMPLE}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const addIngredient = (ingredient) => {
    setModal({
      ...modal,
      title: ingredient?._id && ingredient?.name ? "Update ingredient" : "Add ingredients",
      visible: true,
      Component: (
        <IngredientForm ingredient={ingredient} IngredientRedux={Ingredient} closeModal={() => setModal({ ...modal, visible: false })} />
      ),
    });
  };

  const deleteIngredients = (ids, name) => {
    let customButtonMessage = Array?.isArray(ids)
      ? `Delete ${ids?.length} ${ids?.length === 1 ? "ingredient" : "ingredients"}`
      : `Delete ingredient`;
    let customMessage = `Are you sure you want to delete ${
      ids?.length <= 1 ? `${name ? name : "this ingredient"}` : `${ids?.length} ingredients`
    }?`;

    setModal({
      ...modal,
      visible: true,
      title: `Delete ${ids?.length === 1 ? "ingredient" : "ingredients"}`,
      confirmation: true,
      Component: (
        <DeleteConfirmation
          type="Ingredients"
          customMessage={customMessage}
          customButtonMessage={customButtonMessage}
          funk={async (id) => {
            return await Ingredient.delete(ids);
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const clone = async (ingredient) => {
    const newIngredientName = ingredient.name + " (copy)";

    const response = await Ingredient.create({
      ingredients: [newIngredientName],
    });

    if (response && response?.confirmation === "Success") {
      if (response?.message?.errors?.includes(newIngredientName)) {
        // TODO: improve this
        // it makes multiple requests if there are multiple ingredients with the same name
        clone({ name: newIngredientName });
      } else {
        toast("Ingredient cloned successfully");
        Ingredient.load({ forceRender: true });
      }
    }
  };

  return (
    <>
      <Modal {...modal} />
      <IngredientForm IngredientRedux={Ingredient} />
      <br />
      <Section
        title={`Ingredients ${Ingredient?.ingredients?.length > 0 ? `(${Ingredient?.ingredients?.length})` : ""}`}
        action={{ name: "Add Ingredients", funk: addIngredient }}
      >
        <Ingredients
          Auth={Auth}
          data={Ingredient?.ingredients || []}
          addIngredient={addIngredient}
          deleteIngredients={deleteIngredients}
          clone={clone}
        />
      </Section>
      <div className={styles.uploadCSVButtonParent}>
        <Tooltip title="You can add ingredients one at a time, or upload all of them at once." enterTouchDelay={0}>
          <Button type="button" onClick={() => showCSVModal()} style={{ alginSelf: "center" }}>
            Upload CSV
          </Button>
        </Tooltip>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({ Ingredient: state?.Ingredient, Auth: state?.Auth });
export default connect(mapStateToProps)(withDashboardLayout(CreateIngredient));
