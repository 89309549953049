import * as TYPES from "../actions/dish"
import { methods } from "../actions/dish"

const initialState = {
    ...methods,
    dishes: [],
}

const dishReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.DISH_CREATED_SUCCESSFULLY:
            let tempDishes = [...state.dishes]
            tempDishes.unshift(action.dish)
            return { ...state, dishes: tempDishes }
        case TYPES.DISH_LOADED_SUCCESSFULLY: 
            return { ...state, dishes: action.dishes }
        case TYPES.DISH_DELETED_SUCCESSFULLY:
            return { ...state }
        case TYPES.DISH_RESET: 
            return { ...state, dishes: [] }
        default: 
            return { ...state }
    }
} 

export { dishReducer }
