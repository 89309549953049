import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import LoadingSpinner from "../../../components/LoadingSpinner";
import registerStyle from "../../../Views/Register/Register.module.scss";
import Checkbox from "../../../components/Checkbox";
import { useRouteMatch } from "react-router-dom";
import ImageInput from "../../../components/ImageInput";
import ModalFooter from "../../Modal/ModalFooter";
import SelectDishes from "../../SelectDishes";
import general from "../../../helpers/general";
import Modal from "../../Modal";
import IngredientForm from "../IngredientForm";
import { toast } from "react-toastify";
import DietaryConsiderations from "./DietaryConsiderations";
import SelectList from "../../SelectList";
import GeneralModal from "../../../components/Modal/General";
import CustomizationForm from "../CustomizationForm";
import styles from "./DietaryConsiderations.module.scss";
import DefaultCustomizationsData from "../../Forms/CustomizationForm/DefaultData";
import IngredientAttribution from "../../IngredientAttrubution/IngredientAttribution";
import File from "../../../utils/file";
import TextEditor from "../../TextEditor";

function DishForm({ Category, Dish, Ingredient, Auth, Restaurant, update, dishID, closeModal, categoryID, Admin, dish }) {
  const { register, handleSubmit, errors, setValue, reset, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [imageSelected, setImageSelected] = useState(null);
  const [isAvailable, setIsAvailable] = useState(true);
  const [fileError, setFileError] = useState(null);
  const [error, setError] = useState(null);
  const [sideDishes, setSideDishes] = useState([]);
  const [selectedSideDishes, setSelectedSideDishes] = useState([]);
  const [priceError, setPriceError] = useState(null);
  const [categorySideDishes, setCategorySideDishes] = useState([]);
  const [copyCategorySideDishes, setCopyCategorySideDishes] = useState([]);
  const [categoryUpdate, setCategoryUpdate] = useState(false);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [selectedCustomizations, setSelectedCustomizations] = useState([]);

  const [dietaryResetKey, setDietaryResetKey] = useState(new Date().getTime().toString());
  const [editorValue, setEditorValue] = useState("");

  const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE);
  const maxFileSizeErrorText = process.env.REACT_APP_MAX_FILE_SIZE_ERROR;

  const [modal, setModal] = useState({
    dialogClassName: null,
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const resetFormAfterCreating = () => {
    reset();
    setImageSelected(null);
    setIsAvailable(true);
    setSideDishes([]);
    setSelectedSideDishes([]);
    setSelectedIngredients([]);
    setDietaryResetKey(new Date().getTime().toString());
  };

  const { dishes } = Dish;
  const { ingredients } = Ingredient;
  const { restaurant } = Restaurant;
  const { categories } = Category;
  const match = useRouteMatch("/update-dish/:id");

  useEffect(() => {
    if (update) {
      /* Check dish ingredients */
      if (dish?.ingredients && Array.isArray(dish?.ingredients)) {
        let selected = [];
        dish?.ingredients?.forEach((ing) => ing && ing?.ingredient && selected.push(ing?.ingredient));
        setSelectedIngredients(selected);
      }

      if (dish?.image) {
        setImageSelected(dish?.image);
      }

      setEditorValue(dish?.description || "");

      /* Check customizations */
      if (dish?.customizations) {
        register({ name: "customizations", value: JSON.stringify(dish?.customizations) });
        if (dish?.customizations?.selected && Array.isArray(dish?.customizations?.selected)) {
          setSelectedCustomizations(dish?.customizations?.selected);
        } else {
          setSelectedCustomizations([]);
        }
      } else {
        register({ name: "customizations", value: JSON.stringify(DefaultCustomizationsData?.dish?.customizations()) });
      }

      /* Check dish isAvailable */
      setIsAvailable(dish?.isAvailable ? true : false);

      /* Check side dishes */
      setSelectedSideDishes(dish?.sideDishes ? dish?.sideDishes?.map((dish) => dish?._id) : []);
    } else {
      /* When creating dish set default customizations */
      register({ name: "customizations", value: JSON.stringify(DefaultCustomizationsData?.dish?.customizations()) });
    }
    //eslint-disable-next-line
  }, [dish]);

  useEffect(() => {
    Ingredient?.load();
    Dish.load();
    Category.load();
    Restaurant?.find();
    register({ name: "sideDishes", value: JSON.stringify(selectedSideDishes) });
    register({ name: "customizations", value: JSON.stringify(DefaultCustomizationsData?.dish?.customizations()) });
    register({ name: "description", value: "" });
    //eslint-disable-next-line
  }, []);

  const transformCategories = () => {
    if (categories?.length === 0) return [];
    return categories.map((cat) => ({ value: cat._id, label: cat.name }));
  };

  const transformCustomizations = (customizations) => {
    if (!customizations || !Array.isArray(customizations) || customizations?.length === 0) return [];
    return customizations?.map((customization) => {
      if ((customization?._id || customization?.name) && !customization?.value) {
        return { value: customization?._id, label: customization?.name };
      } else {
        return { value: customization?.value, label: customization?.label };
      }
    });
  };

  const addIngredient = () => {
    setModal({
      ...modal,
      title: "Add missing ingredients",
      visible: true,
      dialogClassName: null,
      Component: (
        <IngredientForm
          handleDataOnDishForm={(data) => handleMissingIngredients(data)}
          closeModal={() => setModal({ ...modal, visible: false })}
          IngredientRedux={Ingredient}
        />
      ),
    });
  };

  const addToSelect = (id) => {
    let temp = [...selectedSideDishes];
    temp?.includes(id) ? temp.splice(temp.indexOf(id), 1) : temp.push(id);
    setSelectedSideDishes(temp);
  };

  const getDishId = () => {
    if (update && match?.params?.id) return match?.params?.id;
    else if (dishID) return dishID;
    return null;
  };

  useEffect(() => {
    setValue("description", editorValue);
    // eslint-disable-next-line
  }, [editorValue]);

  useEffect(() => {
    const values = getValues();
    const customizations = general.parseJSON(values?.customizations, DefaultCustomizationsData.dish.customizations());
    customizations.selected = selectedCustomizations?.map((selected) => (selected?._id ? selected?._id : selected?.value));
    setValue("customizations", JSON.stringify(customizations));
    // eslint-disable-next-line
  }, [selectedCustomizations]);

  useEffect(() => {
    if (typeof dishes === "object") setSideDishes(dishes.filter((dish) => dish?._id?.toString() !== getDishId()));
    // eslint-disable-next-line
  }, [dishes]);

  useEffect(() => {
    setValue("sideDishes", JSON.stringify(selectedSideDishes));
    // eslint-disable-next-line
  }, [selectedSideDishes]);

  const validateFile = (file) => {
    if (!imageSelected || typeof imageSelected === "string") {
      setFileError(null);
      return true;
    }

    if (file?.size > maxFileSize) {
      setFileError(`This file ${file?.name} is big. Maximum size for file is ${maxFileSizeErrorText}.`);
      return false;
    }

    try {
      const validExtensionsFile = File.allowed_extensions;
      for (let i = 0; i < validExtensionsFile.length; i++) {
        if (file?.name?.endsWith(validExtensionsFile[i])) {
          setFileError(null);
          return true;
        }
      }
      setFileError("File not allowed");
      return false;
    } catch (err) {
      return false;
    }
  };

  const generateFormData = (data) => {
    let ingredients = [];

    if (selectedIngredients && selectedIngredients?.length > 0) {
      ingredients = selectedIngredients?.map((ingredient) => ({
        ingredient: ingredient?._id ? ingredient?._id : ingredient?.value,
      }));
    }

    data.isAvailable = isAvailable;
    if (categoryID) data["category"] = categoryID;

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    formData.append("image", imageSelected);
    formData.append("ingredients", JSON.stringify(ingredients));
    return formData;
  };

  const showGeneralModal = (data) => {
    setModal({
      ...modal,
      title: "Confirm modal",
      visible: true,
      confirmation: true,
      dialogClassName: null,
      Component: (
        <GeneralModal
          {...modal}
          customMessage={
            <>
              <p>You didn't select any ingredient(s).</p>
              <p style={{ fontSize: 16 }}>Do you want to save/create this dish anyway?</p>
            </>
          }
          // "You didn't select any ingredient(s)."
          customButtonMessage="Continue anyway"
          toastMessage={update ? "Dish updated successfully" : "Dish created successfully"}
          funk={async (id) => {
            const response = update ? await Dish.update(data, dish?._id) : await Dish.create(data);
            if (response?.confirmation === "Success") {
              setModal({ ...modal, visible: false });
              typeof closeModal === "function" && closeModal();
              resetFormAfterCreating();
            } else {
              setModal({ ...modal, visible: false });
            }
            return response;
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const showSameNameModal = (data, noIngredients) => {
    setModal({
      ...modal,
      title: "Confirm modal",
      visible: true,
      confirmation: true,
      dialogClassName: null,
      Component: (
        <GeneralModal
          {...modal}
          customMessage={
            <>
              <p>{`You already have an item named "${data?.name}"`}</p>
              <p style={{ fontSize: 16 }}>{`${noIngredients ? "You also did not select any ingredient(s)." : ""}`}</p>
              <p style={{ fontSize: 16 }}>Do you want to save/create this dish anyway?</p>
            </>
          }
          customButtonMessage="Continue anyway"
          toastMessage={update ? "Dish updated successfully" : "Dish created successfully"}
          funk={async (id) => {
            const response = update ? await Dish.update(data, dish?._id) : await Dish.create(data);
            if (response?.confirmation === "Success") {
              setModal({ ...modal, visible: false });
              typeof closeModal === "function" && closeModal();
              resetFormAfterCreating();
            } else {
              setModal({ ...modal, visible: false });
            }
            return response;
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const submit = async (data) => {
    if (!validateFile(imageSelected)) return false;

    if (data?.price) {
      const validatedPrice = general.validatePrice(data?.price);
      if (validatedPrice?.err) {
        setPriceError(validatedPrice?.message);
        document?.querySelector(".modal")?.scrollTo({ top: 0 });
        document?.querySelector(".panel-wrapper")?.scrollTo({ top: 0 });
        return false;
      }
      data.price = validatedPrice.price;
      setPriceError(null);
    }

    // TEMP
    if (dishes.find((dish) => dish.name === data.name) && !update) {
      const noIngredients = !selectedIngredients || selectedIngredients?.length === 0;
      return showSameNameModal(data, noIngredients);
    }

    data = generateFormData(data);

    if (!selectedIngredients || selectedIngredients?.length === 0) {
      return showGeneralModal(data);
    }

    setLoading(true);

    const response = update ? await Dish.update(data, dish?._id) : await Dish.create(data, categoryID);
    if (response?.confirmation === "Fail") {
      setError(response?.message);
      setLoading(false);
    } else {
      setError(null);
      toast(update ? "Dish updated successfully" : "Dish created successfully");
      setLoading(false);
      typeof closeModal === "function" && closeModal();
      resetFormAfterCreating();
    }
  };

  const handleMissingIngredients = (ingredients) => {
    const missedIngredients = [];
    if (ingredients && Array.isArray(ingredients) && ingredients?.length > 0) {
      ingredients?.forEach((ingredient) => {
        if (ingredient?._id && ingredient?.name) {
          missedIngredients.push(ingredient);
        }
      });
    }
    if (missedIngredients?.length > 0) {
      setSelectedIngredients([...selectedIngredients, ...missedIngredients]);
    }
  };

  const handleCategorySideDishes = (data) => {
    if (!data) data = [];

    if (data.length < categorySideDishes.length) {
      const dataValues = data.map((d) => d.value);
      const categorySideDishesValues = categorySideDishes.map((cat) => cat.value);
      const diff = general.arr_diff(dataValues, categorySideDishesValues);
      let dishesToRemove = extractDishIDsFromCategory(diff);
      let copy = [...selectedSideDishes];
      copy = copy.filter((dish) => !dishesToRemove.includes(dish));
      setSelectedSideDishes(copy);
    } else {
      setCategorySideDishes(data || []);
      let categoryDishes = [];
      if (categories && categories.length !== 0 && data && data.length !== 0) {
        data.forEach((d) => {
          categories.forEach((cat) => {
            if (d.value === cat._id) {
              let dishes = [...cat.dishes];
              let ids = dishes.map((dish) => dish._id);
              categoryDishes.push(...ids);
            }
          });
        });
      }

      categoryDishes = [...categoryDishes, ...selectedSideDishes];
      categoryDishes = categoryDishes.filter((value, index, self) => self.indexOf(value) === index);
      setSelectedSideDishes(categoryDishes);
      checkIfDishesMatchAnyCategory();
    }
    setCategoryUpdate(true);
  };

  useEffect(() => {
    // Syncronize selectedSideDishes and categorySideDishes
    if (copyCategorySideDishes.length > categorySideDishes.length) {
      const ccsd = copyCategorySideDishes.map((c) => c.value);
      const csd = categorySideDishes.map((c) => c.value);
      const diff = general.arr_diff(ccsd, csd);
      let catToRemove = categories.filter((cat) => {
        return cat._id === diff[0];
      });
      catToRemove = catToRemove.map((cat) => cat._id);
      if (catToRemove && categoryUpdate) {
        let dishesToRemove = extractDishIDsFromCategory(catToRemove);
        let copy = [...selectedSideDishes];
        copy = copy.filter((dish) => !dishesToRemove.includes(dish));
        setSelectedSideDishes(copy);
      }
    }
    setCopyCategorySideDishes(categorySideDishes);
    //eslint-disable-next-line
  }, [selectedSideDishes, categorySideDishes]);

  useEffect(() => {
    checkIfDishesMatchAnyCategory();
    setCategoryUpdate(false);
    //eslint-disable-next-line
  }, [selectedSideDishes]);

  const checkIfDishesMatchAnyCategory = () => {
    const newCategories = [];
    categories.forEach((category) => {
      let all = category.dishes.every((dish) => selectedSideDishes.includes(dish._id));
      if (all && selectedSideDishes.length !== 0 && category?.dishes?.length !== 0) {
        newCategories.push({ value: category._id, label: category.name });
      }
    });

    setCategorySideDishes(newCategories);
  };

  const extractDishIDsFromCategory = (catIDs) => {
    if (!catIDs) return;

    const cats = categories.filter((cat) => catIDs.includes(cat._id));
    let dishes = cats.map((cat) => cat?.dishes);
    dishes = [].concat.apply([], dishes);
    const dishIDs = dishes.map((dish) => dish._id);
    return dishIDs.filter((value, index, self) => self.indexOf(value) === index);
  };

  const parseCustomizations = (customizations) => {
    try {
      return JSON.parse(customizations);
    } catch (err) {
      return [];
    }
  };

  const getCustomCustomizations = () => {
    try {
      const values = getValues();
      const customizations = parseCustomizations(values?.customizations);
      return customizations?.custom;
    } catch (err) {
      return [];
    }
  };

  const showCustomizationModal = () => {
    const values = getValues();
    const customizations = parseCustomizations(values?.customizations);
    setModal({
      ...modal,
      dialogClassName: styles?.customModalStyle,
      title: "Customization Options",
      visible: true,
      size: "lg",
      Component: (
        <CustomizationForm
          ingredients={ingredients}
          register={register}
          customizationsObject={customizations}
          stateCustomizations={customizations?.custom && Array.isArray(customizations?.custom) ? customizations?.custom : []}
          setValue={setValue}
          selectedGlobalCustomizations={selectedCustomizations}
          Restaurant={Restaurant}
          dish={dish}
          {...modal}
        />
      ),
    });
  };

  const onImageRemove = () => {
    setImageSelected(null);
  };

  return (
    <div>
      <Modal style={{ backgroundColor: "rgba(0,0,0,0.45)" }} {...modal} />
      <Form onSubmit={handleSubmit(submit)}>
        <Form.Group>
          <Form.Label>Dish name</Form.Label>
          <Form.Control
            defaultValue={update ? dish?.name : ""}
            ref={register({ required: "Dish name cannot be empty" })}
            name="name"
            placeholder="Dish Name"
          />
          {(errors?.name?.message || error) && <small className={registerStyle.registerErrorText}>{errors?.name?.message || error}</small>}
        </Form.Group>
        <Form.Group>
          <Form.Label>Dish description</Form.Label>
          <TextEditor placeholder="Dish Description" value={editorValue} onChange={setEditorValue} />
          {errors?.description?.message && <small className={registerStyle.registerErrorText}>{errors?.description?.message}</small>}
        </Form.Group>

        <Form.Group className="d-flex mobile-direction-column has-gap">
          <Form.Group>
            <Form.Label>Dish Calories</Form.Label>
            <Form.Control
              defaultValue={update ? dish?.calories : ""}
              ref={register}
              {...register("calories", {
                min: { value: 0, message: "Calories can not be a negative number!" },
              })}
              min={0}
              type="number"
              name="calories"
              placeholder="Calories"
            />
            {errors?.calories?.message && <small className={registerStyle.registerErrorText}>{errors?.calories?.message}</small>}
          </Form.Group>
          <Form.Group>
            <Form.Label>Dish Price</Form.Label>
            <Form.Control
              defaultValue={update ? (dish?.price ? dish?.price : "") : ""}
              ref={register}
              type="text"
              name="price"
              placeholder="Price"
            />
            {priceError && <small className={registerStyle.registerErrorText}>{priceError}</small>}
          </Form.Group>
          <Form.Group>
            <Form.Label>Alcohol Percentage</Form.Label>
            <Form.Control
              defaultValue={update ? dish?.alcoholPercentage : ""}
              min={0}
              max={100}
              ref={register}
              {...register("alcoholPercentage", {
                min: { value: 0, message: "Alcohol Percentage can not be a negative number!" },
                max: { value: 100, message: "Alcohol Percentage can not be greater than 100!" },
              })}
              type="number"
              name="alcoholPercentage"
              placeholder="Alcohol Percentage"
            />
            {errors?.alcoholPercentage?.message && (
              <small className={registerStyle.registerErrorText}>{errors?.alcoholPercentage?.message}</small>
            )}
          </Form.Group>
        </Form.Group>

        <DietaryConsiderations
          key={dietaryResetKey}
          register={register}
          setValue={setValue}
          update={update}
          Dish={dish}
          Restaurant={restaurant}
          Admin={Admin}
        />

        <Form.Group>
          <Form.Label>Select menu-wide customizations</Form.Label>
          <SelectList
            placeholder="Select menu-wide customizations"
            isMulti={true}
            value={transformCustomizations(selectedCustomizations, true)}
            options={transformCustomizations(restaurant?.customizations)}
            onChange={(data) => setSelectedCustomizations(data)}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Add dish specific customizations</Form.Label>
          <SelectList
            placeholder="Add dish specific customizations"
            isMulti={true}
            value={transformCustomizations(getCustomCustomizations())}
            options={[]}
            onChange={showCustomizationModal}
            onMenuOpen={showCustomizationModal}
          />
        </Form.Group>

        {sideDishes && sideDishes?.length > 0 && (
          <Form.Group>
            <div className="d-flex" style={{ width: "100%" }}>
              <Form.Label>Add Upsells by Category ({restaurant?.sideDishesLabel || "Side Dishes"})</Form.Label>
              <Tooltip
                title="Selecting a Category will add all its menu items as upsells. If you add all the individual items in a category, this field will update to list that category as having been added."
                enterTouchDelay={0}
              >
                <span
                  style={{
                    fontSize: 9,
                    color: "inherit",
                    width: 20,
                    height: 20,
                    border: "solid 1px",
                    borderRadius: "50%",
                    marginLeft: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="fas fa-info"
                ></span>
              </Tooltip>
            </div>
            <SelectList
              placeholder={`Select ${(restaurant?.sideDishesLabel || "side dishes").toLowerCase()} categories...`}
              isMulti={true}
              value={categorySideDishes}
              options={transformCategories(categories)}
              onChange={(data) => handleCategorySideDishes(data)}
            />
            <Form.Label style={{ marginTop: "10px" }}>Add Individual Upsells ({restaurant?.sideDishesLabel || "Side Dishes"})</Form.Label>
            <SelectDishes addToSelect={addToSelect} selectedDishes={selectedSideDishes} dishes={sideDishes} />
          </Form.Group>
        )}
        <Form.Group style={{ marginTop: 40 }}>
          <IngredientAttribution
            addIngredient={addIngredient}
            Auth={Auth}
            ingredients={ingredients}
            selectedIngredients={selectedIngredients}
            setSelectedIngredients={setSelectedIngredients}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Dish Image</Form.Label>
          <ImageInput
            displayFile={update ? dish?.image : imageSelected}
            modalStyle={{ backgroundColor: "rgba(0,0,0,0.45)" }}
            uploadFromMedia={true}
            defaultValue={update ? dish?.image : ""}
            placeHolderImage={update && dish?.image ? dish?.image : null}
            handleImage={(img) => setImageSelected(img)}
            onImageRemove={onImageRemove}
          />
          {fileError && <small className={registerStyle.registerErrorText}>{fileError}</small>}
        </Form.Group>
        <Form.Group>
          <Form.Label>Dish Available</Form.Label>
          <Checkbox
            checked={isAvailable}
            onChange={(e) => {
              setIsAvailable(e.target.checked);
            }}
          />
        </Form.Group>
        {Object.keys(errors).length > 0 || loading ? true : false}
        <ModalFooter>
          <Button disabled={Object.keys(errors).length > 0 || loading ? true : false} onClick={handleSubmit(submit)}>
            {loading ? <LoadingSpinner size="sm" /> : update ? "Update Dish" : "Create Dish"}
          </Button>
          {typeof closeModal === "function" && (
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          )}
        </ModalFooter>
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Dish: state?.Dish,
  Ingredient: state?.Ingredient,
  Auth: state?.Auth,
  Restaurant: state?.Restaurant,
  Category: state?.Category,
  Admin: state?.Admin,
});
export default connect(mapStateToProps)(DishForm);
