import React from "react";
import styles from "./Categories.module.scss";
import CategoriesHeader from "./CategoriesHeader";
import placeholderFood from "../../assets/img/placeholder_food.png";
import general from "../../helpers/general";
import { connect } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Tooltip from "@material-ui/core/Tooltip";
import { IKImage } from "imagekitio-react";

function DndCategories({
  categoriesToShow,
  Auth,
  category,
  addDishToCategory,
  removeDishFromCategory,
  editCategory,
  deleteCategory,
  onDragEnd,
  clone,
  menuSelected,
}) {
  function generateSlides(category) {
    return (
      <div className={styles.draggableWrapper}>
        {category?.dishes.map((dish, index) => {
          let imagePath = placeholderFood;

          if (dish?.image) {
            imagePath = `${process.env.REACT_APP_API_URL}${
              process?.env?.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ""
            }/api/settings/show-image/${dish?.image}`;
          }
          return (
            <div key={dish._id}>
              <Draggable draggableId={`${dish._id}`} index={index}>
                {(provided) => (
                  <div>
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <div key={dish?._id} className={styles.swiperSlide} {...provided.dragHandleProps}>
                        {general?.checkPermissions({
                          exceptPermissions: ["category"],
                          originalPermissions: Auth?.user?.permissions,
                        }) &&
                          !menuSelected?.fromParent && (
                            <Tooltip title="Remove dish from this category" enterTouchDelay={0}>
                              <button onClick={() => removeDishFromCategory(category?._id, dish?._id)} className={styles.closeBtn}>
                                <i className="fas fa-minus"></i>
                              </button>
                            </Tooltip>
                          )}
                        <div className={styles.categoryItem}>
                          {dish?.image ? (
                            <IKImage
                              path={dish?.image}
                              transformation={[{ width: 150, height: 225 }]}
                              loading="lazy"
                              lqip={{ active: true }}
                              alt={dish?.name}
                              className={styles.categoryImage}
                            />
                          ) : (
                            <img className={styles.categoryImage} src={imagePath} alt={dish?.name} />
                          )}
                          <div className={styles.categoryItemContent}>
                            <p className={styles.dishPrice}>{dish?.price && general.formatPrice(dish?.price)}</p>
                            <p className={styles.dishName}>{dish?.name}</p>
                          </div>
                        </div>
                      </div>
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Draggable>
            </div>
          );
        })}
        {general?.checkPermissions({ exceptPermissions: ["category"], originalPermissions: Auth?.user?.permissions }) &&
          !menuSelected?.fromParent && (
            <Tooltip title="Add new dish to this category" enterTouchDelay={0}>
              <div
                className={styles.swiperSlide}
                style={{ cursor: "pointer" }}
                onClick={() => addDishToCategory(category, category?._id?.toString())}
              >
                <div className={styles.categoryAddItem}>
                  <i className="fas fa-plus"></i>
                </div>
              </div>
            </Tooltip>
          )}
      </div>
    );
  }
  return (
    <>
      {categoriesToShow ? (
        <>
          {categoriesToShow?.length > 0 &&
            categoriesToShow.map((category, index) => (
              <div key={category?._id}>
                <Draggable draggableId={`${category._id}`} index={index}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} className={styles.categoryHolder}>
                      <CategoriesHeader
                        Auth={Auth}
                        deleteCategory={deleteCategory}
                        editCategory={editCategory}
                        addDishToCategory={addDishToCategory}
                        category={category?.category}
                        clone={clone}
                        menuSelected={menuSelected}
                      />
                      <div className={styles.categorySlider}>
                        <Tooltip title="Drag category" enterTouchDelay={0}>
                          <i className="fal fa-grip-lines-vertical" {...provided.dragHandleProps} />
                        </Tooltip>
                        <div className={styles.droppableWrapper}>
                          {category?.category && category?.category?.dishes && (
                            <DragDropContext onDragEnd={onDragEnd}>
                              <Droppable droppableId={category._id} direction="horizontal" type="column">
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {generateSlides(category?.category)}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          )}
                        </div>
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              </div>
            ))}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({ Auth: state?.Auth });
export default connect(mapStateToProps)(DndCategories);
