import React from "react";
import AddClient from "./AddClient";
import AddOrSelectRestaurant from "./AddOrSelectRestaurant";

export default function Complete({ setValue, getValues, errors, restaurants, register }) {
  return (
    <div>
      <AddClient setValue={setValue} getValues={getValues} errors={errors} />
      <div className="mb-4">
        <AddOrSelectRestaurant
          register={register}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          restaurants={restaurants}
        />
      </div>
    </div>
  );
}
