import React from "react";
import Select from "react-draggable-multi-select";

function getSelectStyles(selectedFont) {
  return {
    container: (styles) => ({ ...styles }),
    control: (styles) => ({
      ...styles,
      height: 40,
      textAlign: "left",
      "& > div:first-of-type": { minHeight: 40 },
    }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles) => ({
      ...styles,
      fontFamily: selectedFont?.value,
    }),
    input: (styles) => ({
      ...styles,
      boxShadow: "none",
      position: "absolute",
      "& input": { height: 40, boxShadow: "none" },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      cursor: "pointer",
      fontWeight: state.isSelected ? "600" : "400",
      color: "inherit",
      "&:not(:last-child)": { borderBottom: "solid 1px #efefef" },
      "&:focus, &:active": {
        backgroundColor: "transparent",
      },
    }),
  };
}

const SingleListItem = (props) => {
  const { children, innerProps, options } = props;
  return (
    <div
      className="single-font-item"
      style={{
        borderBottom: children === options[options.length - 1].value ? "none" : "solid 1px #efefef",
        fontFamily: `'${children}'`,
        padding: "8px 16px",
        cursor: "pointer",
      }}
      {...innerProps}
    >
      {children}
    </div>
  );
};

export default function SelectFontList({ isMulti, options, onChange, value }) {
  return (
    <Select
      components={{ Option: SingleListItem }}
      styles={getSelectStyles(value)}
      value={value}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
    />
  );
}
