export default function CancelChanges({ mode }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M15.375 0.25H15.0625C14.8438 0.25 14.6875 0.4375 14.6875 0.625V4.09375C13.3125 1.8125 10.8125 0.25 7.96875 0.28125C3.6875 0.28125 0.21875 3.78125 0.25 8.03125C0.25 12.3125 3.71875 15.75 8 15.75C9.96875 15.75 11.8125 15 13.1875 13.7812C13.3438 13.625 13.3438 13.375 13.1875 13.2188L12.9688 13C12.8438 12.875 12.5938 12.8438 12.4688 13C11.2812 14.0625 9.71875 14.6875 8 14.6875C4.3125 14.6875 1.3125 11.7188 1.3125 8C1.3125 4.34375 4.28125 1.3125 8 1.3125C10.5625 1.3125 12.8125 2.8125 13.9375 4.9375H10.375C10.1562 4.9375 10 5.125 10 5.3125V5.625C10 5.84375 10.1562 6 10.375 6H15.375C15.5625 6 15.75 5.84375 15.75 5.625V0.625C15.75 0.4375 15.5625 0.25 15.375 0.25Z"
          fill="#888888"
        />
      </svg>
    </>
  );
}
