import React from "react";
import MenuForm from "../../components/Forms/MenuForm";

import withDashboardLayout from "../../Layouts/AdminPanel";

function CreateMenu(props) {
  return <MenuForm {...props} />;
}

export default withDashboardLayout(CreateMenu);
