import React, { useState, useEffect, useRef } from "react";
import styles from "../../components/QrCode/QrCode.module.scss";
import qrStyles from "./QR.module.scss";
import EasyQR from "easyqrcodejs";

export default function QR({ fields, values, restaurantId, qrSettings, advancedQrCode, qrUrl }) {
  const { text_top, text_bottom } = values;
  const [qrOptions, setQrOptions] = useState({
    text: qrUrl ? qrUrl : `${window.location.origin}/menu/${restaurantId}`,
    width: 220,
    height: 220,
  });

  const qrRef = useRef(null);
  const [qrElement, setQrElement] = useState();

  useEffect(() => {
    if (!qrElement) return;
    qrElement.clear();
    setQrElement(new EasyQR(advancedQrCode.current, qrOptions));
    //eslint-disable-next-line
  }, [qrOptions]);

  useEffect(() => {
    setQrElement(new EasyQR(advancedQrCode.current, qrOptions));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (restaurantId) {
      setQrOptions({ ...qrOptions, text: qrUrl ? qrUrl : `${window.location.origin}/menu/${restaurantId}` });
    }
    //eslint-disable-next-line
  }, [restaurantId]);

  useEffect(() => {
    let temp = { ...qrOptions };
    let optionFields = ["colorDark", "colorLight"];
    optionFields?.forEach((option) => {
      if (!qrSettings[option] && qrSettings[option] !== 0) {
        delete temp[option];
      } else {
        temp[option] = qrSettings[option];
      }
    });
    setQrOptions({
      ...temp,
    });
    //eslint-disable-next-line
  }, [qrSettings]);

  return (
    <div className={qrStyles.qrWrapper}>
      <h3 className={qrStyles.title}>{text_top || fields?.text_top?.placeholder}</h3>
      <div className={qrStyles.qrRef} ref={qrRef}>
        <div className={styles.QrCode} ref={advancedQrCode}></div>
      </div>
      <h1>{text_bottom || fields?.text_bottom?.placeholder}</h1>
    </div>
  );
}
