import React, { useState } from "react";
import LoadingSpinner from "../../LoadingSpinner";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Form, Button, Alert } from "react-bootstrap";
import { Api, Endpoint } from "../../../api";
import { useHistory } from "react-router-dom";

import CONSTANTS from "../../../utils/constants";

const { USER } = CONSTANTS;

export default function DeleteAccountForm({ Auth, closeModal }) {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  const submit = async (data) => {
    setLoading(true);

    const response = await Api.call(Endpoint.deleteAccount, { data });
    if (!response || response?.confirmation === "Fail") {
      setLoading(false);
      setError(response?.message);
      return;
    }

    Auth.resetAuthState();
    Auth.resetStateData();

    setError(null);
    setLoading(false);

    toast("Account deleted successfully.");

    history.push("/login");
  };
  return (
    <div>
      <Form onSubmit={handleSubmit(submit)}>
        {Auth?.user?.role !== USER.ROLES.SUPER_ADMIN && (
          <Alert variant={"danger"}>
            <div>Are you sure you want to proceed?</div>
            <div>This action cannot be undone!</div>
          </Alert>
        )}
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            name="password"
            type="password"
            autoComplete="new-password"
            ref={register({ required: "For your security you have to enter the password." })}
            placeholder="Enter your password"
          />
          {(errors?.password?.message || error) && <small style={{ color: "red" }}>{errors?.password?.message || error}</small>}
        </Form.Group>

        <Form.Group className="d-flex justify-content-end">
          {typeof closeModal === "function" && (
            <Button style={{ marginRight: "10px" }} variant="secondary" onClick={closeModal}>
              Close
            </Button>
          )}
          <Button variant="danger" type="submit" disabled={loading}>
            {loading ? <LoadingSpinner size="sm" /> : "Delete"}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}
