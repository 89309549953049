import { connect } from "react-redux";
import { Alert, Button, Col, Collapse, Form, Row } from "react-bootstrap";
import Checkbox from "../Checkbox";
import styles from "./FiltersForm.module.scss";
import React, { useEffect, useState } from "react";
import SelectList from "../SelectList";
import { Api, Endpoint } from "../../api";
import { toast } from "react-toastify";
import constants from "../../utils/constants";
import Section from "../Section";
import { FormControlLabel, Radio, RadioGroup, Tooltip } from "@material-ui/core";

function FiltersForm({ Restaurant, Ingredient }) {
  const [filterData, setFilterData] = useState({});
  const [collapseIndex, setCollapseIndex] = useState(0);

  const { ingredients } = Ingredient;
  const { restaurant } = Restaurant;
  const { RESTAURANT } = constants;
  const { DIETARY_CONSIDERATIONS } = RESTAURANT;

  useEffect(() => {
    Ingredient.load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (restaurant?.filterOptions) {
      setFilterData(restaurant?.filterOptions);
    } else {
      Restaurant.find();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurant]);

  const submit = async (e) => {
    e.preventDefault();

    const requestData = {
      ...filterData,
      conjunction: "AND",
    };
    if (requestData?.filter && requestData?.filter?.length > 0) {
      requestData.filter = requestData.filter.map((filter) => ({
        ...filter,
        ingredients: filter.ingredients.map((ing) => ing?._id || ing),
      }));
    }

    const response = await Api.call(Endpoint?.setFilter, { data: requestData });
    if (response?.confirmation === "Success") {
      Restaurant.find({ forceRender: true });
      toast("Changes have been saved successfully");
    }
    toast(response?.message);
  };

  const transformIngredients = (ingredientsArr) => {
    if (!ingredientsArr || ingredientsArr?.length === 0) return [];
    return ingredientsArr?.map((ingredient) => {
      if (typeof ingredient === "string" && ingredients && ingredients?.length > 0) {
        // TODO: Update this if we are using pagination. It might be wrong.
        const foundIngredient = ingredients.find((ing) => ing?._id?.toString() === ingredient?.toString());
        if (foundIngredient) {
          return { value: foundIngredient?._id, label: foundIngredient?.name };
        }
      }
      if (ingredient?._id && ingredient?.name && !ingredient?.value) {
        return { value: ingredient?._id, label: ingredient?.name };
      } else {
        return { value: ingredient?.value, label: ingredient?.label };
      }
    });
  };

  const transformDietaryConsiderations = (dietaryConsiderations) => {
    if (Array.isArray(dietaryConsiderations)) {
      return dietaryConsiderations?.map((key) => ({ value: key, label: DIETARY_CONSIDERATIONS[key] }));
    }
    return Object?.keys(dietaryConsiderations)?.map((key) => ({ value: key, label: dietaryConsiderations[key] }));
  };

  const handleDietaryConsiderations = (data) => {
    const newFilterData = { ...filterData };
    const newArr = [];
    data?.forEach((item) => {
      newArr.push(item?.value);
    });

    newFilterData.dietaryConsiderations = newArr;

    setFilterData(newFilterData);
  };

  const handleSortChange = (e) => {
    const newData = { ...filterData };
    const newSort = newData?.sort || {};
    newSort[e.target.name] = e.target.checked;
    newData.sort = newSort;
    setFilterData(newData);
  };

  const handleAddIngredient = (data, index) => {
    const newFilterData = { ...filterData };
    const { filter } = newFilterData;

    const newIngredients = [];
    data?.forEach((option) => {
      let found = ingredients?.find((ingredient) => ingredient?._id?.toString() === option?.value?.toString());
      if (found) {
        newIngredients.push(found);
      }
    });

    filter[index].ingredients = newIngredients;
    setFilterData(newFilterData);
  };

  const deleteFilter = (e, index) => {
    e.preventDefault();
    const newFilterData = { ...filterData };
    const { filter } = newFilterData;
    filter.splice(index, 1);
    setFilterData(newFilterData);
  };

  const addFilter = (e) => {
    e.preventDefault();
    const blankFilter = {
      name: "",
      showOptions: true,
      ingredients: [],
    };
    let newFilterData = { ...filterData };
    const { filter } = newFilterData;
    if (filter) {
      filter.push(blankFilter);
    } else {
      newFilterData.filter = [blankFilter];
    }

    setFilterData(newFilterData);
    setCollapseIndex(filter ? filter?.length - 1 : 0);
  };

  const handleFieldChange = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.name === "enabled" ? e.target.checked : e.target.value });
  };

  const handleRadioChange = (e) => {
    setFilterData({ ...filterData, exclusive: e.target.value === "exclusive" });
  };

  const handleFilterFieldChange = (e, index) => {
    const newFilterData = { ...filterData };
    const { filter } = newFilterData;
    filter[index][e.target.name] = e.target.value;
    setFilterData(newFilterData);
  };

  return (
    <div>
      <Form onSubmit={submit}>
        <Form.Group>
          <Form.Label>Filter title</Form.Label>
          <Form.Control type="text" value={filterData?.title} onChange={handleFieldChange} name="title" placeholder="Filter title" />
        </Form.Group>

        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            value={filterData?.description}
            onChange={handleFieldChange}
            name="description"
            rows={4}
            placeholder="Your description goes here, max 200 chars..."
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>
            <Checkbox checked={!!filterData?.enabled} name="enabled" onChange={handleFieldChange} />
            <span>Enable Filter</span>
          </Form.Label>
        </Form.Group>

        <Form.Group className="mb-5">
          <Form.Label>Default Filters</Form.Label>

          <SelectList
            placeholder="Select dietary considerations..."
            isMulti={true}
            value={
              filterData?.dietaryConsiderations &&
              Array.isArray(filterData?.dietaryConsiderations) &&
              filterData?.dietaryConsiderations?.length !== 0
                ? transformDietaryConsiderations(filterData?.dietaryConsiderations)
                : []
            }
            options={transformDietaryConsiderations(DIETARY_CONSIDERATIONS)}
            onChange={(data) => handleDietaryConsiderations(data)}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Custom Filters</Form.Label>
          <br />
          <RadioGroup row name="custom-filter-type" value={filterData?.exclusive ? "exclusive" : "inclusive"} onChange={handleRadioChange}>
            <div style={{ marginRight: 30 }}>
              <FormControlLabel
                style={{ fontWeight: "bold" }}
                value="inclusive"
                control={<Radio style={{ color: "var(--brand)" }} />}
                label="Inclusive"
              />
              <Tooltip
                title="When activated, items that do not contain the selected ingredients will be grayed out on the menu."
                enterTouchDelay={0}
              >
                <i
                  style={{
                    fontSize: 9,
                    color: "inherit",
                    width: 20,
                    height: 20,
                    border: "solid 1px",
                    borderRadius: "50%",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "static",
                  }}
                  className="fas fa-info"
                />
              </Tooltip>
            </div>
            <div>
              <FormControlLabel value="exclusive" control={<Radio style={{ color: "var(--brand)" }} />} label="Exclusive" />
              <Tooltip
                title="When activated, items containing the selected ingredients will be grayed out on the menu."
                enterTouchDelay={0}
              >
                <i
                  style={{
                    fontSize: 9,
                    color: "inherit",
                    width: 20,
                    height: 20,
                    border: "solid 1px",
                    borderRadius: "50%",
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "static",
                  }}
                  className="fas fa-info"
                />
              </Tooltip>
            </div>
          </RadioGroup>
          {filterData?.filter?.length === 0 && <Alert variant="info">Start by adding a Category filter</Alert>}
          {filterData?.filter?.map((filter, index) => (
            <div className={styles.categoryFilter} key={index}>
              <header className={styles.categoryHeader}>
                <div
                  className={`${styles.headerTitle} ${filter?.name && styles.bold}`}
                  onClick={() => setCollapseIndex(collapseIndex === index ? -1 : index)}
                >
                  {filter?.name || "Filter Name"}
                </div>
                <button
                  className={styles.deleteWrapper}
                  onClick={(e) => {
                    deleteFilter(e, index);
                  }}
                >
                  <i className={`far fa-trash-alt ${styles.deleteIcon}`}></i>
                </button>
              </header>
              <Collapse in={collapseIndex === index} className={styles?.collapseExpanded}>
                <Form.Group style={{ padding: 10, border: "solid 1px #ddd" }}>
                  <div id={`filter-${index}`}>
                    <Row style={{ alignItems: "center" }}>
                      <Col md={12}>
                        <Form.Label>Name</Form.Label>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            value={filter.name}
                            name="name"
                            onChange={(e) => handleFilterFieldChange(e, index)}
                            placeholder="Filter title"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group>
                      <Form.Label>Ingredients</Form.Label>
                      <Tooltip title="Items containing the selected ingredients will be grayed out on the menu" enterTouchDelay={0}>
                        <i
                          style={{
                            fontSize: 9,
                            color: "inherit",
                            width: 20,
                            height: 20,
                            border: "solid 1px",
                            borderRadius: "50%",
                            marginLeft: 15,
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "static",
                          }}
                          className="fas fa-info"
                        />
                      </Tooltip>
                    </Form.Group>
                    <SelectList
                      placeholder="Select ingredient..."
                      isMulti={true}
                      value={filter?.ingredients && filter?.ingredients?.length !== 0 ? transformIngredients(filter?.ingredients) : []}
                      options={transformIngredients(ingredients)}
                      onChange={(data) => handleAddIngredient(data, index)}
                    />
                  </div>
                </Form.Group>
              </Collapse>
            </div>
          ))}
        </Form.Group>
        <Form.Group>
          <div className={styles.addWrapper}>
            <button className={styles.addFilter} onClick={addFilter}>
              Add filter
            </button>
          </div>
        </Form.Group>

        <Section title="Sort Options">
          <Form.Group>
            <div>
              <Form.Label>Sort by:</Form.Label>
            </div>
            <Form.Label>
              <Checkbox checked={!!filterData?.sort?.price} name="price" onChange={handleSortChange} />
              <span>Price</span>
            </Form.Label>
            <Form.Label>
              <Checkbox checked={!!filterData?.sort?.calories} name="calories" onChange={handleSortChange} />
              <span>Calories</span>
            </Form.Label>
            <Form.Label>
              <Checkbox checked={!!filterData?.sort?.alcoholPercentage} name="alcoholPercentage" onChange={handleSortChange} />
              <span>Alcohol percentage</span>
            </Form.Label>
          </Form.Group>
        </Section>

        <div className="d-flex justify-content-end mt-5">
          <Button type="submit">Save</Button>
        </div>
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Restaurant: state.Restaurant,
  Ingredient: state.Ingredient,
});

export default connect(mapStateToProps)(FiltersForm);
