import React, { useEffect, useState, useRef } from "react";
import { Api, Endpoint } from "../../api";

import LoadingSpinner from "../../components/LoadingSpinner";
import { Alert, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Helpers from "../../helpers/general";

import PhoneInput from "react-phone-input-2";
import registerFormStyle from "../Register/Register.module.scss";

const { passwordRegex, passwordMessage } = Helpers.getPasswordRegex();

export default function DMARegister() {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState(false);

  const [apiSubmitDataError, setApiSubmitDataError] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("+1");
  const [dmaData, setDMAData] = useState({});

  const { register, handleSubmit, errors, setValue, watch } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    register({ name: "phoneNumber", value: phoneNumber });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue("phoneNumber", phoneNumber);
    //eslint-disable-next-line
  }, [phoneNumber]);

  const getToken = function () {
    return new URLSearchParams(window.location.search).get("token");
  };
  const verifyToken = async function () {
    const token = getToken();
    const response = await Api.call(Endpoint.DMA.verifyToken(token));
    if (response?.statusCode !== 200) {
      setLoading(false);
      setError("Something went wrong. Please try again later.");
      return;
    }
    setDMAData({ email: response?.message?.email });
    setLoading(false);
    setError(null);
  };

  const submit = async function (data) {
    setSubmitLoading(true);
    const response = await Api.call(Endpoint.DMA.register(getToken()), { data });
    if (response?.statusCode !== 200) {
      setApiSubmitDataError(response?.message);
      setSubmitLoading(false);
      return;
    }
    setApiSubmitDataError(null);
    setSubmitLoading(false);
    toast("Successfully registered. Please login to continue.");
    history.push("/login");
  };

  useEffect(() => {
    verifyToken();
    //eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className="bodyWrapper">
        {loading ? (
          <>
            <LoadingSpinner />
            <p>Please wait...</p>
          </>
        ) : error ? (
          <>
            <i className="fas fa-exclamation-circle fa-7x"></i>
            <h3>{error}</h3>
          </>
        ) : (
          <>
            <div className={registerFormStyle.loginPageWrapper}>
              <div className={registerFormStyle.pageContainer}>
                {apiSubmitDataError && <Alert variant={"danger"}>{apiSubmitDataError}</Alert>}
                <Form.Group>
                  <h3>
                    {" "}
                    Hi <strong>{dmaData?.email}!</strong>{" "}
                  </h3>
                  <p className="form-desc">Please complete your account.</p>
                </Form.Group>
                <Form onSubmit={handleSubmit(submit)}>
                  <Row>
                    <Col>
                      <Form.Group>
                        <i className="fal fa-user"></i>
                        <Form.Control ref={register} type="text" name="firstName" placeholder="First Name" />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <i className="fal fa-user"></i>
                        <Form.Control ref={register} type="text" name="lastName" placeholder="Last Name" />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group>
                        <i className="fal fa-user"></i>
                        <PhoneInput
                          inputStyle={{ width: "100%" }}
                          country={"us"}
                          value={phoneNumber}
                          onChange={(phone) => setPhoneNumber(phone)}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group>
                        <i className="fal fa-user"></i>
                        <Form.Control
                          ref={register({
                            required: "Username cannot be empty",
                            minLength: {
                              value: 5,
                              message: "Username must be at least 5 characters",
                            },
                          })}
                          type="text"
                          name="username"
                          placeholder="Username"
                        />
                        {errors?.username?.message && (
                          <small className={registerFormStyle.registerErrorText}>{errors?.username?.message}</small>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group>
                    <i className="fal fa-envelope"></i>
                    <Form.Control type="text" name="email" disabled={true} defaultValue={dmaData?.email} placeholder="Email" />
                  </Form.Group>

                  <Form.Group>
                    <i className="fal fa-user"></i>
                    <Form.Control
                      ref={register({ required: "Company name cannot be empty" })}
                      type="text"
                      name="company_name"
                      placeholder="Company Name"
                    />
                    {errors?.company_name?.message && (
                      <small className={registerFormStyle.registerErrorText}>{errors?.company_name?.message}</small>
                    )}
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <i className="fal fa-lock"></i>
                        <Form.Control
                          ref={register({
                            required: "Password cannot be empty",
                            pattern: { value: passwordRegex, message: passwordMessage },
                          })}
                          type="password"
                          name="password"
                          placeholder="Password"
                        />
                        {errors?.password?.message && (
                          <small className={registerFormStyle.registerErrorText}>{errors?.password?.message}</small>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group>
                    <i className="fal fa-lock"></i>
                    <Form.Control
                      ref={register({
                        required: "Confirm password cannot be empty",
                        validate: (value) => value === password.current || "The password confirmation does not match.",
                      })}
                      type="password"
                      name="confirm_password"
                      placeholder="Confirm Password"
                    />
                    {errors?.confirm_password?.message && (
                      <small className={registerFormStyle.registerErrorText}>{errors?.confirm_password?.message}</small>
                    )}
                  </Form.Group>
                  <Button
                    type="submit"
                    disabled={submitLoading || Object.keys(errors).length > 0 ? true : false}
                    style={{ width: "100%" }}
                    onClick={handleSubmit(submit)}
                  >
                    {submitLoading ? <LoadingSpinner size="sm" /> : "Submit"}
                  </Button>
                </Form>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
