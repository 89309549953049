import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Container, Button, Form } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Api, Endpoint } from "../../api";
import Helpers from "../../helpers/general";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const { passwordRegex, passwordMessage } = Helpers.getPasswordRegex();

export default function DMAClientVerifyInvitation() {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const [responseData, setResponseData] = useState({});
  const [password, setPassword] = useState("");

  const extractToken = () => {
    return new URLSearchParams(window.location.search).get("token");
  };

  async function verifyToken() {
    const token = extractToken();
    if (!token) {
      setLoading(false);
      setError("Page url not valid.");
      return;
    }

    const query = { token };
    const response = await Api.call(Endpoint.DMA.clients.verify_temporary_token, { query });
    if (response?.statusCode !== 200) {
      setLoading(false);
      setError("The link is not valid. Please try again or contact the support.");
      return;
    }
    setLoading(false);
    setError(null);
    setResponseData(response?.message);
  }

  const accept = async function () {
    if (responseData?.shouldEnterPassword && !passwordRegex.test(password)) {
      toast(passwordMessage);
      return;
    }

    const data = { password };
    const query = { token: extractToken() };

    setAcceptLoading(true);
    const response = await Api.call(Endpoint.DMA.clients.accepted_invitation, { data, query });
    if (response?.statusCode === 200) {
      toast("Invitation accepted successfully.");
      setAcceptLoading(false);
      history.push("/login");
      return;
    }

    setAcceptLoading(false);
    toast(response?.message || "Something went wrong. Please try again later or contact the support.");
  };

  const reject = async function () {
    const query = { token: extractToken() };

    setRejectLoading(true);
    const response = await Api.call(Endpoint.DMA.clients.rejected_invitation, { query });
    if (response?.statusCode === 200) {
      toast("Invitation rejected successfully.");
      setRejectLoading(false);
      history.push("/login");
      return;
    }

    setRejectLoading(false);
    toast(response?.message || "Something went wrong. Please try again later or contact the support.");
  };

  useEffect(() => {
    verifyToken();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="bodyWrapper">
        {loading ? (
          <>
            <LoadingSpinner />
            <p>Please wait...</p>
          </>
        ) : error || !responseData?.dma || !responseData?.decoded ? (
          <>
            <i className="fas fa-exclamation-circle fa-7x"></i>
            <h3>{error}</h3>
          </>
        ) : (
          <>
            <Container>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Card style={{ width: "40rem" }}>
                  <Card.Body>
                    <Card.Title>You've got an invitation from {responseData?.dma?.company_name}</Card.Title>
                    <hr />
                    <Card.Text>
                      {responseData?.decoded?.client?.restaurant_id ? (
                        <p>
                          <strong>{responseData?.dma?.company_name || ""}</strong> wants to add you as a client for the following menu:{" "}
                          <strong>{responseData?.restaurant?.name} </strong>.
                        </p>
                      ) : (
                        <p>
                          <strong>{responseData?.dma?.company_name || ""}</strong> wants to add you as a client for the following menu:{" "}
                          <strong>{responseData?.decoded?.client?.restaurant_name} </strong>.
                        </p>
                      )}
                      <p>
                        You can accept or reject the invitation. <strong>{responseData?.dma?.company_name || ""}</strong> will be notified
                        of your decision.
                      </p>
                      <p>
                        If you accept, <strong>{responseData?.dma?.company_name || ""}</strong> will be able to manage your menu and have
                        access to your Foodi Menus account.
                      </p>
                      <p>If you have more than one account, you won't lose access to your other accounts.</p>

                      {responseData?.shouldEnterPassword && (
                        <Form.Group>
                          <Form.Label>If accepted, you'll need to create a strong password below:</Form.Label>
                          <Form.Control
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e?.target?.value)}
                            name="Password"
                            placeholder="Password"
                          />
                        </Form.Group>
                      )}
                    </Card.Text>
                    <hr />

                    <div className="d-flex justify-content-end">
                      <Button disabled={rejectLoading || acceptLoading} variant="danger" style={{ marginRight: "20px" }} onClick={reject}>
                        {rejectLoading ? <LoadingSpinner size="sm" /> : "Reject"}
                      </Button>
                      <Button variant="primary" onClick={accept} disabled={rejectLoading || acceptLoading}>
                        {acceptLoading ? <LoadingSpinner size="sm" /> : "Accept"}
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Container>
          </>
        )}
      </div>
    </>
  );
}
