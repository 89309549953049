import React from "react";
import { Form } from "react-bootstrap";

export default function PromotionCode({ promotionCode, setPromotionCode }) {
  return (
    <div className="mb-4">
      <Form.Group>
        <Form.Label>Promotion Code</Form.Label>
        <Form.Control
          value={promotionCode}
          onChange={(e) => setPromotionCode(e?.target?.value)}
          type="text"
          name="promotion_code"
          placeholder="Promotion Code"
        />
      </Form.Group>
    </div>
  );
}
