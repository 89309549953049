import React from "react";
import withDashboardLayout from "../../Layouts/AdminPanel";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import TextEditor from "../../components/TextEditor";
import { Api, Endpoint } from "../../api";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/LoadingSpinner";

function EditEmailTemplate() {
  const { register, handleSubmit } = useForm();
  const query = new URLSearchParams(window?.location?.search);

  const [templateResponse, setTemplateResponse] = useState({});
  const [emailContent, setEmailContent] = useState("");
  const [loading, setLoading] = useState(false);

  const checkTemplate = async () => {
    const template = query.get("template");
    const response = await Api.call(Endpoint.admin.getEmailTemplate, { query: { template } });
    if (response?.statusCode === 200) {
      setTemplateResponse(response?.message);
    }
  };

  const submit = async (data) => {
    setLoading(true);
    data = { ...data, emailContent, template: query.get("template") };
    const response = await Api.call(Endpoint.admin.editEmailTemplate, { data });
    setLoading(false);
    if (response?.statusCode === 200) {
      toast("Template updated successfully.");
      return;
    }
    toast(response?.message);
  };

  useEffect(() => {
    checkTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (templateResponse?.content) {
      setEmailContent(templateResponse?.content);
    }
  }, [templateResponse]);

  return (
    <div>
      <div className="mb-3">
        <h4>Edit template</h4>
      </div>
      <Row>
        <Col md={6}>
          <Form onSubmit={handleSubmit(submit)}>
            <Form.Group>
              <Form.Control ref={register} defaultValue={templateResponse?.subject} type="text" name="subject" placeholder="Subject" />
            </Form.Group>
            <Form.Group>
              <TextEditor placeholder="Email Template Content" value={emailContent} onChange={setEmailContent} />
            </Form.Group>
            <div className="d-flex justify-content-end align-items-center">
              <Button variant="primary" type="submit">
                {loading ? <LoadingSpinner size="s" /> : "Submit"}
              </Button>
            </div>
          </Form>
        </Col>
        <Col md={6}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>User Template Formats</Card.Title>
              <hr />
              <Card.Text>
                <p>
                  <strong>{"{{user.firstName}}"}</strong> - First name of the user
                </p>
                <p>
                  <strong>{"{{user.lastName}}"}</strong> - Last name of the user
                </p>
                <p>
                  <strong>{"{{user.username}}"}</strong> - Username of the user
                </p>
                <p>
                  <strong>{"{{user.email}}"}</strong> - Email of the user
                </p>
                <p>
                  <strong>{"{{user.phoneNumber}}"}</strong> - Phone number of the user
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Restaurant Template Formats</Card.Title>
              <hr />
              <Card.Text>
                <p>
                  <strong>{"{{restaurant.name}}"}</strong> - Username of the user
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Transfer Ownership Formats</Card.Title>
              <hr />
              <Card.Text>
                <p>
                  <strong>{"{{transferOwnershipNewOwner.firstName}}"}</strong> - First name of the new owner{" "}
                </p>
                <p>
                  <strong>{"{{transferOwnershipNewOwner.lastName}}"}</strong> - Last name of the new owner
                </p>
                <p>
                  <strong>{"{{transferOwnershipNewOwner.username}}"}</strong> - Username of the new owner
                </p>
                <p>
                  <strong>{"{{transferOwnershipNewOwner.email}}"}</strong> - Email of the new owner{" "}
                </p>
                <p>
                  <strong>{"{{transferOwnershipNewOwner.phoneNumber}}"}</strong> - Phone number of the new owner
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Restaurant Members Formats</Card.Title>
              <hr />
              <Card.Text>
                <p>
                  <strong>{"{{member.firstName}}"}</strong> - First name of the member{" "}
                </p>
                <p>
                  <strong>{"{{member.lastName}}"}</strong> - Last name of the member
                </p>
                <p>
                  <strong>{"{{member.username}}"}</strong> - Username of the member
                </p>
                <p>
                  <strong>{"{{member.email}}"}</strong> - Email of the member
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Stripe Formats</Card.Title>
              <hr />
              <Card.Text>
                <p>
                  <strong>{"{{stripeHost.hosted_invoice_url}}"}</strong> - Stripe hosted invoice url
                </p>
                <p>
                  <strong>{"{{stripeHost.invoice_pdf}}"}</strong> - Stripe hosted invoice pdf url
                </p>
                <p>
                  <strong>{"{{stripeCustomer.name}}"}</strong> - Stripe customer name
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>DMA Formats</Card.Title>
              <hr />
              <Card.Text>
                <p>
                  <strong>{"{{dmaClient.email}}"}</strong> - DMA client email
                </p>
                <p>
                  <strong>{"{{dmaClient.username}}"}</strong> - DMA client username
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Other Template Formats</Card.Title>
              <hr />
              <Card.Text>
                <p>
                  <strong>{"{{token.value}}"}</strong> - The current token in the process
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default withDashboardLayout(EditEmailTemplate);
