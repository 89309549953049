import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import LoadingSpinner from "../../LoadingSpinner";

export default function CancelSubscription({ closeModal, onComplete, submit: submitCallback, warning_message }) {
  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    await submitCallback();

    typeof onComplete === "function" && onComplete();

    toast("Clients subscription has been cancelled successfully.");

    closeModal();
    setLoading(false);
  };
  return (
    <div>
      <Form onSubmit={submit}>
        <Form.Group>
          <Alert variant={"danger"}>{warning_message}</Alert>
        </Form.Group>

        <Form.Group className="d-flex justify-content-end">
          {typeof closeModal === "function" && (
            <Button style={{ marginRight: "10px" }} disabled={loading} variant="secondary" onClick={closeModal}>
              Close
            </Button>
          )}
          <Button variant="danger" type="submit" disabled={loading}>
            {loading ? <LoadingSpinner size="sm" /> : "Delete"}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}
