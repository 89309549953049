import React from "react";
import adminStyle from "../../../Views/Admin/Admin.module.scss";

export default function Statistics({ statistics }) {
  const icons = {
    "Published menus": "fas fa-book-open",
    "Unpublished menus": "fad fa-book-open",
    Dishes: "fas fa-utensils",
    Categories: "fas fa-hat-chef",
    Ingredients: "fas fa-carrot",
  };

  if (statistics && typeof statistics === "object" && Object.keys(statistics)?.length) {
    return (
      <div className={adminStyle?.minorGraph}>
        {Object.keys(statistics)?.map((statistic, index) => (
          <div key={index}>
            <div className={adminStyle?.radiusGraph}>
              <span>{statistics[statistic]}</span>
              {icons[statistic] && <i className={icons[statistic]}></i>}
            </div>
            <div className={adminStyle?.secondRow}>
              <span>{statistic}</span>
            </div>
          </div>
        ))}
      </div>
    );
  }
  return null;
}
