import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ModalFooter from "../../Modal/ModalFooter";
import { toast } from "react-toastify";
import LoadingSpinner from "../../LoadingSpinner";
import { Tooltip } from "@material-ui/core";

function DeleteConfirmation({
  id,
  type,
  funk,
  closeModal,
  closeLabel,
  customMessage,
  customButtonMessage,
  toastMessage,
  deleteAccount,
  removeCategoryFromMenu,
  ids,
  deleteButtonTooltip,
  removeButtonTooltip,
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [removeCategoryFromMenuLoading, setRemoveCategoryFromMenuLoading] = useState(false);

  const deleteItem = async () => {
    setLoading(true);
    const response = await funk(id);
    if (response?.confirmation === "Success") {
      if (toastMessage) toast(toastMessage);
      else toast(`${type} deleted successfully`);
    }
    typeof closeModal === "function" ? closeModal() : history.push("/");
    setLoading(false);
  };

  const removeItem = async () => {
    setRemoveCategoryFromMenuLoading(true);
    await removeCategoryFromMenu(ids);
    setRemoveCategoryFromMenuLoading(false);
  };

  return (
    <div>
      <div style={{ marginBottom: 20, textAlign: "center" }}>
        {customMessage ? customMessage : `Are you sure you want to delete this ${type}?`}
        {deleteAccount && <p>All of this user data will be permanently deleted.</p>}
      </div>
      <ModalFooter>
        <Tooltip title={typeof deleteButtonTooltip === "string" ? deleteButtonTooltip : ""} enterTouchDelay={0}>
          <Button size="sm" variant="danger" disabled={loading} onClick={deleteItem}>
            {loading ? <LoadingSpinner size="sm" /> : customButtonMessage ? customButtonMessage : `Delete ${type}`}
          </Button>
        </Tooltip>
        {typeof removeCategoryFromMenu === "function" && (
          <Tooltip title={removeButtonTooltip} enterTouchDelay={0}>
            <Button size="sm" onClick={removeItem}>
              {removeCategoryFromMenuLoading ? <LoadingSpinner size="sm" /> : "Remove"}
            </Button>
          </Tooltip>
        )}
        {typeof closeModal === "function" && (
          <Button size="sm" variant="secondary" onClick={closeModal}>
            {closeLabel ? closeLabel : "Close"}
          </Button>
        )}
      </ModalFooter>
    </div>
  );
}

export default DeleteConfirmation;
