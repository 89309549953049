import { Api, Endpoint } from "../../api"
import { methods as CategoryMethods } from './category'
import { store } from '../connect/store'

export const DISH_CREATED_SUCCESSFULLY = "DISH_CREATED_SUCCESSFULLY"
export const DISH_LOADED_SUCCESSFULLY = "DISH_LOADED_SUCCESSFULLY"
export const DISH_DELETED_SUCCESSFULLY = "DISH_DELETED_SUCCESSFULLY"
export const DISH_RESET = "DISH_RESET"

export const methods = {
    create: async (data, fromDashboard) => {
        const response = await Api.call(Endpoint.createDish, { data })
        if (response?.confirmation === "Success") {
            store.dispatch({ type: DISH_CREATED_SUCCESSFULLY, dish: response?.message })
            if (fromDashboard) CategoryMethods.load({ forceRender: true })
        }
        return response;
    },
    findById: async (id) => {
        return await Api.call(Endpoint.findDishById, { params: [id] })
    },
    update: async (data, id, params = {}) => {
        const response = await Api.call(Endpoint.updateDish, { data, params: [id] })
        if (response?.confirmation === "Success") {
            methods.load({ forceRender: true })
            if (!params?.dismissRender?.category) {
                CategoryMethods.load({ forceRender: true })
            } 
            store.getState().Restaurant.find({ forceRender: true });
        }
        return response;
    },
    delete: async (data) => {
        const response = await Api.call(Endpoint.deleteDish, { data })
        if (response?.confirmation === "Success") {
            methods.load({ forceRender: true })
            CategoryMethods.load({ forceRender: true })
            store.getState().Restaurant.find({ forceRender: true });
        }
        return response;
    },
    load: async (obj = false) => {
        const { Auth, Dish } = store.getState();
        if (Auth?.IS_ADMIN()) return

        const forceRender = (typeof obj === "object" && obj.forceRender) ? true : false;
        
        if (forceRender) {
            const response = await Api.call(Endpoint.loadDishes);
            if (response?.confirmation === "Success") store.dispatch({ type: DISH_LOADED_SUCCESSFULLY, dishes: response?.message })
            return;
        }
    
        if (!Dish?.dishes?.length) return methods.load({ forceRender: true })
    },
    uploadCSV: async (data) => {
        const response = await Api.call(Endpoint.uploadDishCSV, { data, query: { csvFile: true } })
        methods.load({ forceRender: true })
        return response
    }
}