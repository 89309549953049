import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import LoadingSpinner from "../../LoadingSpinner";

export default function DeleteUser({
  user,
  closeModal,
  onComplete,
  submit: submitCallback,
  warning_message,
  requires_password,
  toast_complete_message,
}) {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const submit = async (e) => {
    e.preventDefault();

    if (requires_password) {
      if (!password) {
        return setError("Password is required");
      }
    }
    setLoading(true);

    const response = await submitCallback(user, password);

    if (response?.statusCode !== 200) {
      toast(response?.message || "Something went wrong");
      setLoading(false);
      typeof onComplete === "function" && onComplete(response);
      return;
    }

    toast(toast_complete_message ? toast_complete_message : "Account has been deleted successfully");
    typeof onComplete === "function" && onComplete(response);
    closeModal();
    setLoading(false);
  };

  return (
    <div>
      <Form onSubmit={submit}>
        <Form.Group>
          <Alert variant={"danger"}>{warning_message}</Alert>
        </Form.Group>

        {requires_password && (
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e?.target?.value)}
              type="password"
              name="password"
            />
            {error && <Form.Text className="text-danger">{error}</Form.Text>}
          </Form.Group>
        )}
        <Form.Group className="d-flex justify-content-end">
          {typeof closeModal === "function" && (
            <Button style={{ marginRight: "10px" }} variant="secondary" onClick={closeModal}>
              Close
            </Button>
          )}
          <Button variant="danger" type="submit" disabled={loading}>
            {loading ? <LoadingSpinner size="sm" /> : "Delete"}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}
