import { IKImage } from "imagekitio-react";
import { Draggable } from "react-beautiful-dnd";
import placeholderFood from "../../assets/img/placeholder_food.png";
import styles from "./MapMenu.module.scss";

export default function MapMenu({ menu, menu_index }) {
  return (
    <Draggable draggableId={`${menu?._id}`} key={menu?._id} index={menu_index}>
      {(provided, snapshot) => (
        <>
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div className={styles.typeMenu}>
              {menu?.cover ? (
                <IKImage
                  path={menu?.cover}
                  style={{ borderRadius: "4px", objectFit: "cover" }}
                  transformation={[{ width: 125, height: 225 }]}
                  loading="lazy"
                  lqip={{ active: true }}
                />
              ) : (
                <img src={placeholderFood} alt={menu.name} loading="lazy" />
              )}
              <div className={styles.menuName}>
                <p>{menu.name}</p>
              </div>
            </div>
          </div>
          {snapshot.isDragging && (
            <div>
              <div className={styles.typeMenu}>
                {menu?.cover ? (
                  <IKImage
                    path={menu?.cover}
                    style={{ borderRadius: "4px", objectFit: "cover" }}
                    transformation={[{ width: 125, height: 225 }]}
                    loading="lazy"
                    lqip={{ active: true }}
                  />
                ) : (
                  <img src={placeholderFood} alt={menu.name} loading="lazy" />
                )}
                <div className={styles.menuName}>
                  <p>{menu.name}</p>
                </div>
              </div>
            </div>
          )}
          {provided.placeholder}
        </>
      )}
    </Draggable>
  );
}
