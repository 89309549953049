import React from "react";
import Section from "../../components/Section";

import withLayout from "../../Layouts/AdminPanel";
import FiltersForm from "../../components/FiltersForm";

function Settings() {
  return (
    <Section title="Menu Filters">
      <FiltersForm />
    </Section>
  );
}

export default withLayout(Settings);
