import React, { useState } from "react";
import { Button } from "react-bootstrap";

import Section from "../../Section/Section";
import Table from "../../Table";

import { Api, Endpoint } from "../../../api/index";

import { Cookies } from "react-cookie";
import { toast } from "react-toastify";

import LoadingSpinner from "../../LoadingSpinner";
import AdminStyle from "../../../Views/Admin/Admin.module.scss";
import Modal from "../../Modal";

import DeleteUser from "../../Forms/DeleteUser";
import ClientsSubscribe from "./ClientsSubscribe/Main";

import { Button as MaterialUIButton } from "@material-ui/core";

import { useHistory } from "react-router-dom";

import EmbedMenuOptions from "../../RestaurantForm/EmbedMenuOptions";

const cookies = new Cookies();

export default function LicensesSubscriptionStatus({
  Auth,
  subscribedClients = [],
  unSubscribedClients = [],
  paymentMethods,
  loadClientsSubscriptionsStatus,
  productPrices,
  updatePlans,
}) {
  const history = useHistory();

  const [loadingUserShapeShift, setLoadingUserShapeShift] = useState(-1);

  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const subscribeForAllClients = async () => {
    setModal({
      ...modal,
      visible: true,
      title: `Subscribe for all clients (${unSubscribedClients?.length})`,
      Component: (
        <ClientsSubscribe
          paymentMethods={paymentMethods}
          productPrices={productPrices}
          unSubscribedClients={unSubscribedClients?.map((client) => client?._id)}
          loadClientsSubscriptionsStatus={loadClientsSubscriptionsStatus}
          updatePlans={updatePlans}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const subscribeForMultipleUsers = async (users = {}) => {
    users = Object?.keys(users);
    setModal({
      ...modal,
      visible: true,
      title: `Subscribe for ${users?.length} ${users?.length > 1 ? "licenses" : "license"}`,
      Component: (
        <ClientsSubscribe
          paymentMethods={paymentMethods}
          productPrices={productPrices}
          unSubscribedClients={users}
          loadClientsSubscriptionsStatus={loadClientsSubscriptionsStatus}
          updatePlans={updatePlans}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const shapeshift = async (user) => {
    if (!user?.state) {
      toast("This account has not been verified.");
      return;
    }
    setLoadingUserShapeShift(user?._id);

    const data = {
      _id: user._id,
      refreshToken: cookies.get("refreshToken"),
      email: user.email,
    };

    const response = await Auth.SHAPE_SHIFT(data, Endpoint.DMA.clients.shapeshift);
    if (response?.statusCode !== 200) {
      toast(response?.message);
      setLoadingUserShapeShift(-1);
      return;
    }

    window.location.href = "/";
    setLoadingUserShapeShift(-1);
  };

  const showDeleteClientModal = (user) => {
    setModal({
      ...modal,
      title: "Remove license",
      visible: true,
      Component: (
        <DeleteUser
          {...modal}
          requires_password
          warning_message="Are you sure you want to remove this license?"
          user={user}
          submit={async (user, password) => {
            const response = await Api.call(Endpoint.DMA.clients.delete(user?._id), { data: { password } });
            typeof loadClientsSubscriptionsStatus === "function" && loadClientsSubscriptionsStatus();
            return response;
          }}
        />
      ),
    });
  };

  const viewMore = function (user) {
    history.push(`/dma/client?id=${user?._id}`);
  };

  const showEmbedModal = (restaurant_id) => {
    setModal({
      ...modal,
      visible: true,
      title: "Embed Menu",
      Component: <EmbedMenuOptions {...modal} restaurantId={restaurant_id} />,
    });
  };

  const columns = {
    subscribedClients: [
      {
        field: "Email",
        access: "email",
      },
      {
        field: "Full Name",
        render: ({ row, column, index }) => (
          <span>
            {row?.firstName} {row?.lastName}
          </span>
        ),
      },
      {
        field: "Username",
        access: "username",
      },
      {
        field: "Actions",
        render: ({ row, column, index }) => (
          <i onClick={() => showDeleteClientModal(row)} className={`${AdminStyle.deleteIcon} far fa-trash-alt`}></i>
        ),
      },
      {
        field: "View More",
        render: ({ row, column, index }) => (
          <div>
            <Button onClick={async () => viewMore(row)} style={{ padding: "4px", fontSize: "14px" }}>
              View More
            </Button>
          </div>
        ),
      },
      {
        field: "Copy Embed Code",
        render: ({ row, column, index }) => (
          <div>
            <Button onClick={() => showEmbedModal(row?.restaurants?.[0])} style={{ padding: "4px", fontSize: "14px" }}>
              Copy Embed Menu
            </Button>
          </div>
        ),
      },
      {
        field: "Login as",
        render: ({ row, column, index }) => (
          <Button
            disabled={loadingUserShapeShift !== -1}
            onClick={async () => await shapeshift(row, index)}
            style={{ padding: "4px", fontSize: "14px" }}
          >
            {loadingUserShapeShift?.toString() === row?._id?.toString() ? <LoadingSpinner size="sm" /> : "Login"}
          </Button>
        ),
      },
    ],
    unSubscribedClients: [
      {
        field: "Email",
        access: "email",
      },
      {
        field: "Full Name",
        render: ({ row, column, index }) => (
          <span>
            {row?.firstName} {row?.lastName}
          </span>
        ),
      },
      {
        field: "Username",
        access: "username",
      },
      {
        field: "Actions",
        render: ({ row, column, index }) => (
          <i onClick={() => showDeleteClientModal(row)} className={`${AdminStyle.deleteIcon} far fa-trash-alt`}></i>
        ),
      },
      {
        field: "Subscribe",
        render: ({ row, column, index }) => (
          <div>
            <Button
              onClick={async () => subscribeForMultipleUsers({ [row?._id]: true })}
              style={{ padding: "4px", fontSize: "14px" }}
            >
              Subscribe
            </Button>
          </div>
        ),
      },
      {
        field: "View More",
        render: ({ row, column, index }) => (
          <div>
            <Button onClick={async () => viewMore(row)} style={{ padding: "4px", fontSize: "14px" }}>
              View More
            </Button>
          </div>
        ),
      },
      {
        field: "Copy Embed Code",
        render: ({ row, column, index }) => (
          <div>
            <Button onClick={() => showEmbedModal(row?.restaurants?.[0])} style={{ padding: "4px", fontSize: "14px" }}>
              Copy Embed Menu
            </Button>
          </div>
        ),
      },
      {
        field: "Login as",
        render: ({ row, column, index }) => (
          <Button
            disabled={loadingUserShapeShift !== -1}
            onClick={async () => await shapeshift(row, index)}
            style={{ padding: "4px", fontSize: "14px" }}
          >
            {loadingUserShapeShift?.toString() === row?._id?.toString() ? <LoadingSpinner size="sm" /> : "Login"}
          </Button>
        ),
      },
    ],
  };

  return (
    <div>
      <Modal {...modal} />
      <Section title={`Subscribed licenses (${subscribedClients?.length})`}>
        <Table columns={columns?.subscribedClients} rows={subscribedClients} enableLocalPagination />
      </Section>
      <Section
        title={`Unsubscribed licenses (${unSubscribedClients?.length})`}
        action={
          productPrices?.monthly_product && productPrices?.yearly_product && unSubscribedClients?.length > 0
            ? { name: "Subscribe for all", funk: subscribeForAllClients }
            : null
        }
      >
        <Table
          columns={columns?.unSubscribedClients}
          onSelectRowsRenderView={(checked, setChecked) => (
            <MaterialUIButton
              variant="outlined"
              style={{ marginRight: "5px", color: "white", borderColor: "var(--brand)", backgroundColor: "var(--brand)" }}
              onClick={() => {
                subscribeForMultipleUsers(checked);
                setChecked({});
              }}
            >
              Subscribe
            </MaterialUIButton>
          )}
          rows={unSubscribedClients}
          enableLocalPagination
          enableSelectMultiple
          onSelectRowsDisableDelete
        />
      </Section>
    </div>
  );
}
