import React, { useEffect, useState } from "react";
import SelectList from "../../SelectList";
import { Button } from "react-bootstrap";
import LoadingSpinner from "../../LoadingSpinner";
import { toast } from "react-toastify";

export default function SelectCategoriesForm({ Menu, Category, closeModal }) {
  const { categories } = Category;

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    Menu.load();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const categoriesSelected = [];
    if (Menu?.selected?.categories?.length > 0) {
      Menu.selected.categories.forEach((schema) => {
        if (schema?.category?._id && schema?.category?.name) {
          categoriesSelected.push({
            _id: schema?.category?._id,
            name: schema?.category?.name,
          });
        }
      });
    }
    setSelected(categoriesSelected);
    //eslint-disable-next-line
  }, [Menu?.menus]);

  const transformCategories = (data) => {
    if (data && Array.isArray(data) && data?.length > 0) {
      return data.map((elem) => {
        if (elem?._id && elem?.name) {
          return { label: elem?.name, value: elem?._id };
        }
        return { value: elem?.value, label: elem?.label };
      });
    }
    return [];
  };

  const saveChanges = async () => {
    setLoading(true);

    const selectedCategories = [];
    if (selected?.length > 0) {
      selected.forEach((category) => {
        selectedCategories.push({ category: category?._id ? category?._id : category?.value });
      });
    }

    const data = {
      selected: selectedCategories,
      menu: Menu?.selected?._id,
    };

    const response = await Menu.selectCategories(data);
    setLoading(false);
    closeModal();
    toast(response?.confirmation === "success" ? "Changes have been made successfully" : response?.message);
  };

  return (
    <div>
      <div style={{ paddingBottom: "20px" }}>
        <SelectList
          placeholder="Select categories..."
          isMulti={true}
          value={transformCategories(selected)}
          options={transformCategories(categories)}
          onChange={(data) => setSelected(data)}
        />
      </div>
      <div className="d-flex justify-content-end">
        <Button variant="secondary" style={{ marginRight: "5px" }} onClick={closeModal}>
          Close
        </Button>
        <Button size="sm" onClick={saveChanges}>
          {loading ? <LoadingSpinner size="sm" /> : "Save changes"}
        </Button>
      </div>
    </div>
  );
}
