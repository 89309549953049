import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import ImageInput from "../../../components/ImageInput";
import registerStyle from "../../../Views/Register/Register.module.scss";
import LoadingSpinner from "../../LoadingSpinner";
import styles from "./CSVForm.module.scss";
// import csv from "../../../assets/csv/Category CSV example"

function CSVForm({ Controller, downloadExampleLink }) {
  const [csvFile, setCSVFile] = useState(null);

  /* state "csvFileError" is used for client side validation. */
  const [csvFileError, setCSVFileError] = useState(null);
  const [loading, setLoading] = useState(null);

  /* If api fails */
  const [error, setError] = useState(null);

  /* CSV Validation state */
  const [successMessage, setSuccessMessage] = useState(null);
  const [existValidationError, setExistValidationError] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [duplicateErrors, setDuplicateErrors] = useState(null);

  const resetAllValidationCSV = () => {
    setSuccessMessage(null);
    setExistValidationError(null);
    setValidationError(null);
    setSuccessMessage(null);
  };

  const submit = async () => {
    try {
      if (!csvFile) {
        setCSVFileError("Please select a csv file");
        return;
      }
      if (!csvFile.name.endsWith(".csv")) {
        setCSVFileError("Please select a valid csv file");
        return;
      }

      setLoading(true);
      setCSVFileError(null);

      const formData = new FormData();
      formData.append("csv", csvFile);
      const data = await Controller.uploadCSV(formData);

      if (!data || data?.confirmation === "Fail") {
        setLoading(false);
        resetAllValidationCSV();
        setError(data?.message);
        return;
      }

      if (data?.confirmation === "Success" && data?.message) {
        const { successMessage, existValidationError, validationError, duplicateErrors } = data?.message;
        successMessage ? setSuccessMessage(successMessage) : setSuccessMessage(null);
        existValidationError ? setExistValidationError(existValidationError) : setExistValidationError(null);
        validationError ? setValidationError(validationError) : setValidationError(null);
        duplicateErrors ? setDuplicateErrors(duplicateErrors) : setDuplicateErrors(null);
        setLoading(false);
        return;
      }

      setLoading(false);
      resetAllValidationCSV();
    } catch (err) {
      resetAllValidationCSV();
      setLoading(false);
      setCSVFileError("Something went wrong. Please try again or report this issue");
    }
  };

  const onImageRemove = () => {
    setCSVFile(null);
    resetAllValidationCSV();
    setError(null);
    setDuplicateErrors(null);
  };

  return (
    <div>
      <Form.Group>
        <Form.Label>CSV File</Form.Label>
        <ImageInput
          accept=".csv"
          modalStyle={{ backgroundColor: "rgba(0,0,0,0.45)" }}
          placeHolderTitle={"Upload CSV file"}
          handleImage={(img) => setCSVFile(img)}
          onImageRemove={loading ? null : onImageRemove}
        />
        <div className={styles.csvErrorParent}>
          {successMessage && <small className={registerStyle.successMessageCSV}>{successMessage}</small>}
          {csvFileError && <small className={registerStyle.registerErrorText}>{csvFileError}</small>}
          {error && <small className={registerStyle.registerErrorText}>{error}</small>}
          {existValidationError && <small className={registerStyle.registerErrorText}>{existValidationError}</small>}
          {validationError && <small className={registerStyle.registerErrorText}>{validationError}</small>}

          {duplicateErrors &&
            duplicateErrors?.length > 0 &&
            duplicateErrors?.map((err, index) => (
              <div key={index}>
                <small className={registerStyle.registerErrorText}>
                  <strong style={{ fontWeight: "bold" }}>{err}</strong> already exists, so will be ignored.
                </small>
              </div>
            ))}
        </div>
      </Form.Group>

      <div className={styles.footerContainer}>
        <Button disabled={loading} type="button" onClick={submit}>
          {loading ? <LoadingSpinner /> : "Submit"}
        </Button>
        <a href={downloadExampleLink} download>
          Download CSV template
        </a>
      </div>
    </div>
  );
}

export default CSVForm;
