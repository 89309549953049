import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";
import registerStyle from "../../Views/Register/Register.module.scss";
import ModalFooter from "../../components/Modal/ModalFooter";
import styles from "./Members.module.scss";
import Checkbox from "../../components/Checkbox";
import { Api, Endpoint } from "../../api";

import CONSTANTS from "../../utils/constants";

const { USER } = CONSTANTS;

//eslint-disable-next-line
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const memberPermissionsNames = {
  restaurant: {
    label: "Restaurant settings",
    description: "Manage restaurant settings",
  },
  menu: {
    label: "Menus",
    description: "Manage menus, such as creating, updating and deleting.",
  },
  category: {
    label: "Categories",
    description: "Manage categories, such as creating, updating and deleting.",
  },
  dish: {
    label: "Dishes",
    description: "Manage dishes, such as creating, updating and deleting.",
  },
  ingredient: {
    label: "Ingredients",
    description: "Manage ingredients, such as creating, updating and deleting.",
  },
  member: {
    label: "Members",
    description: "Create and see all members.",
  },
};

function AddMember({ closeModal, Restaurant, updateMember, Auth }) {
  const [memberPermissions, setMemberPermissions] = useState({
    member: false,
    restaurant: false,
    menu: false,
    category: false,
    dish: false,
    ingredient: false,
  });
  const [loading, setLoading] = useState(false);
  const [customError, setCustomError] = useState(null);
  const { register, handleSubmit, errors, setValue } = useForm();

  const allPermissionsSelected = Object.keys(memberPermissions).every((permissions) => memberPermissions[permissions] === true);

  const disablePermission = (permission) => {
    if (Auth?.user?.role === USER.ROLES.MEMBER && !Auth?.user?.permissions[permission]) return true;
    return false;
  };

  const onChangePermission = ({ permission }) => {
    setMemberPermissions({
      ...memberPermissions,
      [permission]: !memberPermissions[permission],
    });
  };

  const toggleSelectAll = (boolean) => {
    let tempPermissions = { ...memberPermissions };
    Object.keys(tempPermissions).forEach((key) => {
      if (Auth?.user?.permissions[key]) {
        tempPermissions = { ...tempPermissions, [key]: boolean };
      }
    });
    setMemberPermissions(tempPermissions);
  };

  useEffect(() => {
    register({ name: "permissions", value: memberPermissions });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue("permissions", memberPermissions);
    //eslint-disable-next-line
  }, [memberPermissions]);

  useEffect(() => {
    if (typeof updateMember?.permissions === "object") {
      let tempPermissions = { ...updateMember?.permissions };
      delete tempPermissions["superAdmin"];
      delete tempPermissions["admin"];
      setMemberPermissions(tempPermissions);
    }
    //eslint-disable-next-line
  }, [updateMember]);

  const submit = async (data) => {
    setLoading(true);
    const response =
      updateMember && updateMember?._id
        ? await Api.call(Endpoint.updateMember, {
            data,
            params: [updateMember?._id],
          })
        : await Api.call(Endpoint.createMember, { data });
    if (response?.confirmation === "Success") {
      setCustomError(null);
      toast(
        updateMember && updateMember?._id
          ? "Member updated successfully"
          : "Member created successfully. We have sent an email with a confirmation link to this user."
      );
      typeof closeModal === "function" && closeModal();
      Restaurant.loadMembers({ forceRender: true });
    }
    toast(response?.message);
    setLoading(false);
  };

  const getUpdateMemberField = (field) => {
    try {
      if (updateMember && typeof updateMember === "object" && Object?.keys(updateMember)?.length) {
        return updateMember[field];
      }
      return "";
    } catch (err) {
      return "";
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(submit)}>
        {!updateMember?._id && (
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <Form.Control
              defaultValue={getUpdateMemberField("username")}
              ref={register({
                required: "Username cannot be empty",
              })}
              name="username"
              placeholder="Username"
            />
            {(errors?.username?.message || customError) && (
              <small className={registerStyle.registerErrorText}>{errors?.username?.message || customError}</small>
            )}
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            disabled={updateMember && updateMember?._id ? true : false}
            defaultValue={getUpdateMemberField("email")}
            ref={register({
              required: "Email cannot be empty",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
            type="email"
            name="email"
            placeholder="Email"
          />
          {errors?.email?.message && <small className={registerStyle.registerErrorText}>{errors?.email?.message}</small>}
        </Form.Group>

        {/* Permissions */}
        <Form.Group>
          <Form.Label>Permissions</Form.Label>
          <div className={styles?.memberPermissionParent}>
            <ul className="list-unstyled mb-0">
              <li className={styles?.selectAll}>
                <Form.Label className={styles.permissionBox}>
                  <Checkbox
                    size="small"
                    checked={allPermissionsSelected}
                    onChange={() => toggleSelectAll(!allPermissionsSelected)}
                  />
                  <span>All permissions</span>
                </Form.Label>
              </li>
              {Object.keys(memberPermissions).map((keyPermission, index) => (
                <li key={index}>
                  <Form.Label className={styles.permissionBox}>
                    <Checkbox
                      disabled={disablePermission(keyPermission)}
                      checked={memberPermissions[keyPermission]}
                      size="small"
                      onChange={() =>
                        onChangePermission({
                          permission: keyPermission,
                        })
                      }
                    />
                    <span>
                      {memberPermissionsNames[keyPermission]?.label}
                      <br />
                      <small className={styles.permissionDescription}>{memberPermissionsNames[keyPermission]?.description}</small>
                    </span>
                  </Form.Label>
                </li>
              ))}
            </ul>
          </div>
        </Form.Group>
      </Form>
      <ModalFooter>
        <Button disabled={Object.keys(errors).length > 0 || loading ? true : false} onClick={handleSubmit(submit)}>
          {loading ? <LoadingSpinner size="sm" /> : updateMember && updateMember?._id ? "Update member" : "Add member"}
        </Button>
        {typeof closeModal === "function" && (
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        )}
      </ModalFooter>
    </>
  );
}

export default AddMember;
