import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner";
import styles from "./VerifyAccount.module.scss";
import { useHistory } from "react-router-dom";
import EnterPasswordAndVerify from "./EnterPasswordAndVerify";

function VerifyAccount({ Auth }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [requirePasswordStep, setRequirePasswordStep] = useState(false);
  const history = useHistory();

  const extractToken = () => {
    return new URLSearchParams(window.location.search).get("token");
  };

  const verifyAccount = async () => {
    const token = extractToken();
    if (!token) history.push("/login");

    const response = await Auth.VERIFY_ACCOUNT(token);
    if (response?.message?.decoded_token?.require_enter_password) {
      setLoading(false);
      setError(null);
      setRequirePasswordStep(true);
    } else if (response?.confirmation === "Success") {
      setLoading(false);
      setError(null);
      setTimeout(() => history.push("/login"), 2000);
    } else {
      setLoading(false);
      setError("Your account has been already verified or this link is not valid.");
    }
  };

  const onSuccessPasswordVerifyCompleted = () => {
    setRequirePasswordStep(false);
    setTimeout(() => history.push("/login"), 2000);
  };

  useEffect(() => {
    verifyAccount();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className={styles.bodyWrapper}>
        {loading ? (
          <>
            <LoadingSpinner />
            <p className={styles.loadingText}>Verifying account...</p>
          </>
        ) : error ? (
          <>
            <i className="fas fa-exclamation-circle fa-7x"></i>
            <h3 className={styles.errorText}>{error}</h3>
          </>
        ) : !requirePasswordStep ? (
          <>
            <i className="fas fa-check fa-7x"></i>
            <h2>Account has been verified successfully.</h2>
          </>
        ) : (
          <EnterPasswordAndVerify token={extractToken()} onSuccess={onSuccessPasswordVerifyCompleted} />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({ Auth: state.Auth });
export default connect(mapStateToProps)(VerifyAccount);
