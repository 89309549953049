import { Pagination } from "react-bootstrap";
import general from "../../helpers/general";
import styles from "./Pagination.module.scss";

function CustomPagination({ changePage, currentPage, totalPages }) {
  if (!totalPages || totalPages < 2) {
    return null;
  }

  const pages = () => {
    let pages = [];

    pages.push(
      <div key={"prev"} className={styles?.pagination}>
        <Pagination.Prev
          disabled={currentPage === 0}
          onClick={(e) => {
            changePage(e, currentPage - 1);
          }}
        />
      </div>
    );

    general.ellipsePagination(currentPage, totalPages).forEach((elem, i) => {
      if (elem === "...") {
        pages.push(
          <div key={i} className={styles?.pagination}>
            <Pagination.Ellipsis />
          </div>
        );
      } else {
        pages.push(
          <div key={i} className={`${styles?.pagination} ${elem - 1 === currentPage ? styles?.paginationActive : ""}`}>
            <Pagination.Item
              onClick={(e) => {
                changePage(e, elem - 1);
              }}
              active={elem - 1 === currentPage}
            >
              {elem}
            </Pagination.Item>
          </div>
        );
      }
    });

    pages.push(
      <div key={"next"} className={styles?.pagination}>
        <Pagination.Next
          disabled={currentPage === totalPages - 1}
          onClick={(e) => {
            changePage(e, currentPage + 1);
          }}
        />
      </div>
    );

    return pages;
  };

  return (
    <Pagination className={`text-center d-flex justify-content-center container-fluid ${styles?.pagination}`}>
      {pages()}
    </Pagination>
  );
}

export default CustomPagination;
