import React, { useState } from "react";
import SelectList from "../../SelectList";
import { Button } from "react-bootstrap";

export default function LoadGlobalTemplates({ Restaurant, closeModal, customizations, setCustomizations, setCollapseIndex }) {
  const { restaurant } = Restaurant;
  const [selectedCustomizations, setSelectedCustomizations] = useState([]);

  const transformCustomizations = (customizations) => {
    if (!customizations || !Array.isArray(customizations) || customizations?.length === 0) return [];
    return customizations?.map((customization) => {
      if (customization?._id && customization?.name && !customization?.value) {
        return { value: customization?._id, label: customization?.name };
      } else {
        return { value: customization?.value, label: customization?.label };
      }
    });
  };

  const saveChanges = () => {
    const tempRestaurant = { ...restaurant };
    const data = [];
    if (selectedCustomizations && selectedCustomizations?.length > 0) {
      selectedCustomizations.forEach((selected) => {
        if (restaurant?.customizations?.length > 0) {
          const copied = tempRestaurant?.customizations?.find((el) => el?._id?.toString() === selected?.value?.toString());

          if (copied) {
            if (
              customizations?.[customizations?.length - 1]?.name === "" &&
              customizations?.[customizations?.length - 1]?.options?.[0].name === ""
            ) {
              let tempData = [...customizations];
              tempData[tempData.length - 1] = { ...copied, name: copied.name + " (copy)" };
              setCustomizations(tempData);
              setCollapseIndex(customizations.length - 1);
              closeModal();
              return;
            }

            data.push({ ...copied, name: copied.name + " (copy)" });
          }
        }
      });
    }
    if (data?.length > 0) {
      setCustomizations([...customizations, ...data]);
      setCollapseIndex(customizations.length);
    }

    closeModal();
  };

  return (
    <div>
      <div style={{ paddingBottom: "20px" }}>
        <SelectList
          placeholder="Select global customizations..."
          isMulti={true}
          value={transformCustomizations(selectedCustomizations, true)}
          options={transformCustomizations(restaurant?.customizations)}
          onChange={(data) => setSelectedCustomizations(data)}
        />
      </div>
      <div className="d-flex justify-content-end">
        <Button variant="secondary" style={{ marginRight: "5px" }} onClick={closeModal}>
          Close
        </Button>
        <Button onClick={saveChanges}>Save changes</Button>
      </div>
    </div>
  );
}
