import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import PublicNotAuthRoute from "./PublicNotAuthRoute";

import NotFound from "../components/NotFound";

import general from "../helpers/general";

import { menuRoutes, adminRoutes, publicRoutes, noAuthenticatedRoutes, privateRoutes, profileRoutes, DMARoutes } from "../constants/routes";

function Routes({ Auth, Restaurant }) {
  const { hash } = useLocation();

  useEffect(() => {
    if (!hash) return;
    setTimeout(() => {
      const target = document.querySelector(hash);
      document.querySelector(".panel-wrapper").scrollTo(0, target.getBoundingClientRect().y - 60);
    }, 1000);
  }, [hash]);

  const checkRoutePermission = (route) => {
    return general?.checkPermissions({
      exceptPermissions: route?.permissions?.except,
      $connector: route?.permissions?.$connector,
      originalPermissions: Auth?.user?.permissions,
    });
  };

  const restaurantType = Auth?.user?.restaurants?.[0]?.restaurantType?.toUpperCase();

  return (
    <Switch>
      {DMARoutes?.map((route, index) => {
        if (!route?.permissions || checkRoutePermission(route)) {
          return <PrivateRoute key={index} {...route} />;
        }
        return null;
      })}
      {adminRoutes?.map((route, index) => {
        if (!route?.permissions || checkRoutePermission(route)) {
          return <PrivateRoute key={index} {...route} />;
        }
        return null;
      })}
      {menuRoutes.map((props, i) => (
        <Route key={i} {...props} />
      ))}

      {noAuthenticatedRoutes?.map((route, index) => {
        return <PublicNotAuthRoute key={index} {...route} />;
      })}

      {publicRoutes?.map((route, index) => {
        return <Route key={index} {...route} />;
      })}

      {privateRoutes?.map((route, index) => {
        if (!route?.permissions || checkRoutePermission(route)) {
          if (!route?.roles || route?.roles?.includes(Auth?.user?.role)) {
            if (route.path === "/add-restaurant") {
              if (restaurantType?.toLowerCase() === "restaurant") {
                route.name = "Add Location";
              } else {
                route.name = "Add Food Truck";
              }
            }
            return <PrivateRoute key={index} {...route} />;
          }
        }
        return null;
      })}

      {/* Private routes on header profile section */}
      {profileRoutes?.map((route, index) => {
        if (!route?.permissions || checkRoutePermission(route)) {
          if (!route?.roles || route?.roles?.includes(Auth?.user?.role)) {
            return <PrivateRoute key={index} {...route} />;
          }
        }
        return null;
      })}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

const mapStateToProps = (state) => ({ Auth: state.Auth, Restaurant: state.Restaurant });
export default connect(mapStateToProps)(Routes);
