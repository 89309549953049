import { ResponsivePie } from "@nivo/pie";

const CustomPieChart = ({ data }) => (
  <div style={{ height: 300 }}>
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 80, bottom: 0, left: -80 }}
      sortByValue={true}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      enableRadialLabels={false}
      colors={[
        "rgba(244, 109, 37, 1)",
        "rgba(244, 109, 37, 0.8)",
        "rgba(244, 109, 37, 0.6)",
        "rgba(244, 109, 37, 0.4)",
        "rgba(244, 109, 37, 0.2)",
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color", modifiers: [] }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      legends={[
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 20,
          translateY: 0,
          itemsSpacing: 8,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: "circle",
        },
      ]}
    />
  </div>
);

export default CustomPieChart;
