import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/fonts/fontawesome-pro-5.11.2-web/css/all.min.css";
import "./assets/scss/main.scss";

import { store } from "./store-redux/connect/store";

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
