import React, { useEffect } from "react";
import { Form } from "react-bootstrap";

export default function AddClient({ setValue, unregister, errors, getValues }) {
  useEffect(() => {
    if (typeof unregister === "function") {
      unregister("username");
    }
    //eslint-disable-next-line
  }, []);
  return (
    <div>
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control
          defaultValue={getValues("email") || ""}
          onChange={(e) => setValue("email", e.target.value, { shouldValidate: true })}
          type="email"
          name="email"
          placeholder="Email"
        />
        {errors?.email?.message && <small className="form-error-text">{errors?.email?.message}</small>}
      </Form.Group>
    </div>
  );
}
