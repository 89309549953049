import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useState } from "react";
import dashboardStyles from "../../App.module.scss";
import Modal from "../Modal";
import ChooseFromMedia from "../Forms/MediaForm/ChooseFromMedia";
import { connect } from "react-redux";
import general from "../../helpers/general";

function ImageInput({ Auth, handleImage, placeHolder, modalStyle, displayFile }) {
  const [modal, setModal] = useState({
    visible: false,
    update: false,
    updateIndex: null,
    Component: null,
    title: "Select an image",
    closeModal: () => setModal({ ...modal, visible: false }),
  });
  const [fileSelectedLocally, setFileSelectedLocally] = useState(null);

  useEffect(() => {
    setFileSelectedLocally(displayFile);
  }, [displayFile]);

  const chooseFromMedia = async () => {
    setModal({
      ...modal,
      visible: true,
      Component: (
        <ChooseFromMedia
          handleImage={handleImage}
          setFileSelectedLocally={setFileSelectedLocally}
          closeModal={modal.closeModal}
        />
      ),
    });
  };

  return (
    <div className={dashboardStyles.imageInputWrapper}>
      <Modal {...modal} style={modalStyle} />
      <div className={dashboardStyles.filePathWrapper}>
        {general?.checkPermissions({ exceptPermissions: ["dish"], originalPermissions: Auth?.user?.permissions }) && (
          <Button type="button" onClick={() => chooseFromMedia()}>
            Upload from media
          </Button>
        )}
        <label>{fileSelectedLocally ? fileSelectedLocally : placeHolder ? placeHolder : "Select an image"}</label>
        <input type="text" />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({ Auth: state.Auth });
export default connect(mapStateToProps)(ImageInput);
