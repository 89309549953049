import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../LoadingSpinner";
import registerStyle from "../../../Views/Register/Register.module.scss";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import styles from "./UpdateAccountForm.module.scss";
import PhoneInput from "react-phone-input-2";
import Modal from "../../Modal";
import DeleteAccountForm from "../DeleteAccountForm";

import CONSTANTS from "../../../utils/constants";

import ReCaptchaComponent from "../../../components/ReCaptcha";
import useReCaptcha from "../../../hoc/useReCaptcha";

const { USER } = CONSTANTS;

function UpdateAccountForm({ Auth, User }) {
  const { register, handleSubmit, errors, setValue } = useForm();
  const { reCaptchaRef, executeReCaptchaToken, onChangeReCaptcha } = useReCaptcha();

  const [phoneNumber, setPhoneNumber] = useState(User?.phoneNumber ? User?.phoneNumber : "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  useEffect(() => {
    register({ name: "phoneNumber", value: phoneNumber });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue("phoneNumber", phoneNumber);
    // eslint-disable-next-line
  }, [phoneNumber]);

  useEffect(() => {
    if (User?.phoneNumber && User?.phoneNumber !== phoneNumber) {
      setPhoneNumber(User?.phoneNumber);
    }

    if (User?.role === USER.ROLES.SUPER_ADMIN || User?.role === USER.ROLES.ADMIN) {
      register({ name: "username" });
      setValue("username", User?.username);
    }
    // eslint-disable-next-line
  }, [User]);

  const submit = async (data) => {
    setLoading(true);

    const reCaptchaToken = await executeReCaptchaToken();
    data.reCaptchaToken = reCaptchaToken;

    const response = await Auth.UPDATE_ACCOUNT(data);
    if (response?.confirmation === "Success") {
      setError(null);
      setLoading(false);
      toast("Account updated successfully");
      return;
    }
    setError(response?.message);
    setLoading(false);
  };

  const getUserField = (field) => {
    return User?.[field] || "";
  };

  const showDeleteAccountModal = () => {
    setModal({
      ...modal,
      visible: true,
      title: "Delete account",
      Component: <DeleteAccountForm {...modal} Auth={Auth} />,
    });
  };

  return (
    <div>
      <Modal {...modal} />
      <Form onSubmit={handleSubmit(submit)}>
        <ReCaptchaComponent innerRef={reCaptchaRef} onChange={onChangeReCaptcha} />

        <Form.Group className={styles.formGroupParent}>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control name="firstName" ref={register} defaultValue={getUserField("firstName")} placeholder="First Name" />
          </Form.Group>
          <Form.Group style={{ width: "100%" }}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control name="lastName" ref={register} defaultValue={getUserField("lastName")} placeholder="Last Name" />
          </Form.Group>
        </Form.Group>

        <Form.Group className={styles.formGroupParent}>
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <Form.Control
              name="username"
              disabled={Auth?.user?.role === USER.ROLES.ADMIN || Auth?.user?.role === USER.ROLES.SUPER_ADMIN}
              ref={
                Auth?.user?.role === USER.ROLES.ADMIN || Auth?.user?.role === USER.ROLES.SUPER_ADMIN
                  ? null
                  : register({ required: "Username cannot be empty" })
              }
              defaultValue={getUserField("username")}
              placeholder="Username"
            />
            {(errors?.username?.message || error) && (
              <small className={registerStyle.registerErrorText}>{errors?.username?.message || error}</small>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control disabled={true} name="email" defaultValue={getUserField("email")} placeholder="Email" />
          </Form.Group>
        </Form.Group>

        {Auth?.user?.role === USER.ROLES.DMA && (
          <Form.Group className="mb-3">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              name="company_name"
              type="text"
              ref={register({ required: "Company name cannot be empty" })}
              defaultValue={getUserField("company_name")}
              placeholder="Company Name"
            />
            {(errors?.company_name?.message || error) && (
              <small className={registerStyle.registerErrorText}>{errors?.company_name?.message || error}</small>
            )}{" "}
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>Phone Number</Form.Label>
          <PhoneInput inputStyle={{ width: "100%" }} value={phoneNumber} onChange={(phone) => setPhoneNumber(phone)} />
        </Form.Group>

        <Form.Group className="d-flex justify-content-between">
          {Auth?.user?.role !== USER.ROLES.SUPER_ADMIN && Auth?.user?.role !== USER.ROLES.DMA_CLIENT && (
            <Button variant="danger" disabled={loading} onClick={showDeleteAccountModal}>
              Delete account
            </Button>
          )}
          <Button
            style={{ marginRight: "10px" }}
            disabled={loading || Object.keys(errors).length > 0 ? true : false}
            type="submit"
          >
            {loading ? <LoadingSpinner size="sm" /> : "Update account"}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

export default UpdateAccountForm;
