import React from "react";
import { Button } from "react-bootstrap";
import { useState } from "react";
import dashboardStyles from "../../App.module.scss";
import Modal from "../Modal";
import ChooseFromMedia from "../Forms/MediaForm/ChooseFromMedia";
import { connect } from "react-redux";
import general from "../../helpers/general";
import styles from "./ImageInput.module.scss";

function ImageInput({
  Auth,
  handleImage,
  placeHolderImage,
  placeHolderTitle,
  uploadFromMedia,
  modalStyle,
  multiple,
  accept,
  onImageRemove,
}) {
  const [modal, setModal] = useState({
    visible: false,
    update: false,
    updateIndex: null,
    Component: null,
    title: "Select an image",
    closeModal: () => setModal({ ...modal, visible: false }),
  });
  const [imageRemoved, setImageRemoved] = useState(false);
  const [fileSelected, setFileSelected] = useState(null);

  const onChange = (event) => {
    if (multiple && event?.target?.files) {
      setFileSelected(`${event?.target?.files?.length} files selected`);
      handleImage(event.target.files);
    } else {
      setFileSelected(event?.target?.files?.[0]?.name);
      handleImage(event.target.files[0]);
    }
    setImageRemoved(false);
  };

  const chooseFromMedia = async () => {
    setModal({
      ...modal,
      visible: true,
      Component: <ChooseFromMedia handleImage={handleImage} setFileSelectedLocally={setFileSelected} closeModal={modal.closeModal} />,
    });
  };

  const removeImage = () => {
    setFileSelected(null);
    onImageRemove();
    setImageRemoved(true);
  };

  const getPlaceHolderLabel = () => {
    if (fileSelected && typeof fileSelected === "string") {
      // File selected manually
      return fileSelected;
    }
    if (placeHolderImage && !imageRemoved) {
      return placeHolderImage;
    }
    if (placeHolderTitle) {
      return placeHolderTitle;
    }
    return "Select an image";
  };

  return (
    <div className={dashboardStyles.imageInputWrapper}>
      <Modal {...modal} style={modalStyle} />

      {uploadFromMedia && general?.checkPermissions({ exceptPermissions: ["dish"], originalPermissions: Auth?.user?.permissions }) && (
        <Button type="button" onClick={() => chooseFromMedia()}>
          Upload from media
        </Button>
      )}
      <div className={styles?.fileWrapperContainer}>
        <div className={dashboardStyles.fileInputWrapper}>
          <label>{typeof getPlaceHolderLabel() === "string" && getPlaceHolderLabel()}</label>
          <input
            type="file"
            multiple={multiple ? true : false}
            onChange={onChange}
            accept={accept ? accept : "image/x-png,image/gif,image/jpeg,image/webp"}
          />
        </div>
        {onImageRemove && typeof onImageRemove === "function" && (fileSelected || (placeHolderImage && !imageRemoved)) && (
          <i onClick={removeImage} className="far fa-trash-alt"></i>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({ Auth: state.Auth });
export default connect(mapStateToProps)(ImageInput);
