import { Api, Endpoint } from "../../api";
import { store } from "../connect/store";
import { Cookies } from "react-cookie";
import CONSTANTS from "../../utils/constants";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCEED = "LOGIN_SUCCEED";
export const REGISTER_SUCCEED = "REGISTER_SUCCEED";
export const REGISTER_FAILED = "REGISTER_SUCCEED";
export const LOGOUT = "LOGOUT";
export const USER_UPDATED_SUCCESSFULLY = "USER_UPDATED_SUCCESSFULLY";

const cookies = new Cookies();

const setAuthToken = (key, token) => cookies.set(key, token, { path: "/" });
const getAuthToken = (key) => cookies.get(key);

const getUserFromSessionStorage = () => {
  try {
    return JSON.parse(localStorage?.getItem("user"));
  } catch (err) {
    return {};
  }
};

const resetAuthState = () => {
  cookies.remove("accessToken", { path: "/" });
  cookies.remove("refreshToken", { path: "/" });
  localStorage.removeItem("user");
  localStorage.removeItem("menuSelected");
};

const setUserOnCookiesWithoutTokens = (user) => {
  let tempUser = { ...user };
  delete tempUser["accessToken"];
  delete tempUser["newRefreshToken"];
  delete tempUser["refreshToken"]; // Just for security
  localStorage.setItem("user", JSON.stringify(tempUser));
};

const resetStateData = () => {
  const types = ["LOGOUT", "CATEGORIES_RESET", "DISH_RESET", "INGREDIENTS_RESET", "RESTAURANT_RESET", "MENU_RESET", "ADMIN_RESET_DATA"];
  types.forEach((type) => store.dispatch({ type: type }));
};

export const methods = {
  resetAuthState: resetAuthState,
  resetStateData: resetStateData,
  LOGIN: async (data) => {
    localStorage?.removeItem("menuSelected"); // Just for security
    const response = await Api.call(Endpoint.login, { data });
    if (response?.confirmation === "Success" && !response?.message?.multipleAccounts && !response?.message?.account_unverified) {
      setAuthToken("accessToken", response?.message?.accessToken);
      setAuthToken("refreshToken", response?.message?.refreshToken);
      setUserOnCookiesWithoutTokens(response?.message);
      store.dispatch({ type: LOGIN_SUCCEED, user: response?.message });
    }
    return response;
  },
  REGISTER: async (data) => {
    const response = await Api.call(Endpoint.register, { data });
    if (response?.confirmation === "Fail") {
      store.dispatch({
        type: REGISTER_FAILED,
        registerError: response.message,
      });
      return response;
    }
    return response;
  },
  LOGOUT: async () => {
    const response = await Api.call(Endpoint.logout, {
      data: { refreshToken: getAuthToken("refreshToken") },
    });
    resetAuthState();
    resetStateData();
    return response;
  },
  REFRESH_TOKEN: async () => {
    const data = { refreshToken: getAuthToken("refreshToken") };
    const response = await Api.call(Endpoint.newAccessToken, { data });

    if (response?.message?.newRefreshToken && response?.message?.accessToken) {
      setAuthToken("accessToken", response?.message?.accessToken);
      setAuthToken("refreshToken", response?.message?.newRefreshToken);
      return true;
    }

    resetAuthState();
    resetStateData();
    return false;
  },
  IS_ADMIN: () => {
    const { Auth } = store.getState();
    //eslint-disable-next-line
    return (Auth?.user && Auth?.user?.role === CONSTANTS.USER.ROLES.ADMIN) || Auth?.user?.role === CONSTANTS.USER.ROLES.SUPER_ADMIN;
  },
  UPDATE_ACCOUNT: async (data) => {
    const response = await Api.call(Endpoint.updateAccount, { data });
    if (response?.confirmation === "Success" && typeof response?.message === "object") {
      store.dispatch({
        type: USER_UPDATED_SUCCESSFULLY,
        user: {
          ...getUserFromSessionStorage("user"),
          ...response?.message,
        },
      });
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...getUserFromSessionStorage("user"),
          ...response.message,
        })
      );
    }
    return response;
  },
  ADD_RESTAURANT: async (data) => {
    const response = await Api.call(Endpoint.addRestaurant, { data });
    if (response?.confirmation === "Success" && typeof response?.message === "object") {
      localStorage?.removeItem("menuSelected");
      setAuthToken("accessToken", response?.message?.accessToken);
      setAuthToken("refreshToken", response?.message?.newRefreshToken);
      setUserOnCookiesWithoutTokens(response?.message);
      return response;
    }
    return response;
  },
  SWITCH_ACCOUNT: async (data) => {
    data = { ...data, refreshToken: getAuthToken("refreshToken") };
    const response = await Api.call(Endpoint?.switchAccount, { data });
    if (response?.confirmation === "Success" && typeof response?.message === "object") {
      setAuthToken("accessToken", response?.message?.accessToken);
      setAuthToken("refreshToken", response?.message?.newRefreshToken);
      localStorage?.removeItem("menuSelected");
      setUserOnCookiesWithoutTokens(response?.message);
      return response;
    }
    return response;
  },
  SHAPE_SHIFT: async (data, endpoint) => {
    const response = await Api.call(endpoint, { data });
    if (response?.confirmation === "Success" && response?.message?.accessToken && response?.message?.newRefreshToken) {
      setAuthToken("accessToken", response?.message?.accessToken);
      setAuthToken("refreshToken", response?.message?.newRefreshToken);
      setUserOnCookiesWithoutTokens(response?.message);
      return response;
    }
    return response;
  },
  ME: async () => {
    const response = await Api.call(Endpoint.me);
    if (response?.confirmation === "Success" && typeof response?.message === "object") {
      store.dispatch({
        type: USER_UPDATED_SUCCESSFULLY,
        user: response?.message,
      });
      localStorage.setItem("user", JSON.stringify(response.message));
    }
    return response;
  },
  VERIFY_ACCOUNT: async (token) => {
    return await Api.call(Endpoint.verifyAccount, { params: [token] });
  },
  LOGGED_IN: () => {
    try {
      const accessToken = getAuthToken("accessToken");
      const refreshToken = getAuthToken("refreshToken");
      const user = JSON.parse(localStorage.getItem("user"));
      if (
        !accessToken ||
        !refreshToken ||
        !user ||
        user === "undefined" ||
        typeof user !== "object" ||
        accessToken === "undefined" ||
        refreshToken === "undefined"
      )
        return false;
      return true;
    } catch (err) {
      return false;
    }
  },
};
