import React, { useState, useEffect } from "react";
import Tour from "reactour";
import walkthroughSteps from "../../walkthrough-steps.json";

export default function TourComponent({ Menu, Auth }) {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [introduceSteps, setIntroduceSteps] = useState([]);

  const filterSteps = () => {
    const originalSteps = walkthroughSteps?.dashboard?.steps;
    const filteredSteps = [];
    for (const step of originalSteps) {
      if (!step?.permission || !step.permission?.connector) {
        filteredSteps.push(step);
      } else {
        let hasPermission;
        if (step?.permission?.connector === "$or") {
          hasPermission = step?.permission?.except?.some((permission) => Auth?.user?.permissions[permission]);
        } else {
          hasPermission = step?.permission?.except?.every((permission) => Auth?.user?.permissions[permission]);
        }
        if (hasPermission) {
          filteredSteps.push(step);
        }
      }
    }
    setIntroduceSteps(filteredSteps);
    return filteredSteps;
  };

  useEffect(() => {
    if (isTourOpen) {
      const baseNavbar = document.getElementById("foodi-navbar");
      if (baseNavbar) {
        baseNavbar.click();
      }
      const navbarToggler = document.querySelector(".navbar-toggler");
      if (navbarToggler) {
        navbarToggler?.click();
      }
    }

    const sub_menu_classes = document.querySelectorAll(".tour-sub-menu");

    if (sub_menu_classes && sub_menu_classes?.length > 0) {
      for (const sub_menu of sub_menu_classes) {
        isTourOpen ? sub_menu?.classList.add("active") : sub_menu?.classList.remove("active");
      }
    }

    //eslint-disable-next-line
  }, [isTourOpen]);

  const parseFromLocalStorage = (value) => {
    try {
      const parsed = JSON.parse(value);
      if (typeof parsed === "object" && Array?.isArray(parsed)) {
        return parsed;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  const showInstructions = () => {
    localStorage.removeItem("menuSelected");
    Menu?.setMenuSelectedLocally({});
    setTimeout(() => setIsTourOpen(true), 1000);
  };

  const getFirstTimeLoggedIn = function () {
    return localStorage.getItem("first_time_logged_in");
  };

  const setFirstTimeLoggedIn = function (value) {
    localStorage?.setItem("first_time_logged_in", value);
  };

  useEffect(() => {
    filterSteps();
    let firstTimeLoggedIn = getFirstTimeLoggedIn();
    const parsedData = parseFromLocalStorage(firstTimeLoggedIn);
    if (!firstTimeLoggedIn || !parsedData) {
      const data = [];
      data.push(Auth?.user?._id);
      setFirstTimeLoggedIn(JSON.stringify(data));
      showInstructions();
    } else {
      if (!parsedData?.includes(Auth?.user?._id?.toString())) {
        parsedData?.push(Auth?.user?._id?.toString());
        setFirstTimeLoggedIn(JSON.stringify(parsedData));
        showInstructions();
      }
    }
    //eslint-disable-next-line
  }, []);

  const onRequestClose = () => {
    setIsTourOpen(false);
  };

  if (!introduceSteps || introduceSteps?.length === 0) {
    return null;
  }

  return (
    <div>
      <Tour
        steps={introduceSteps}
        isOpen={isTourOpen}
        onRequestClose={onRequestClose}
        disableInteraction={true}
        accentColor="var(--brand)"
      />
      {isTourOpen && <div id="tour-opened"> </div>}
    </div>
  );
}
