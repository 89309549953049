import React from "react";
import styles from "./Categories.module.scss";
import general from "../../helpers/general";
import { Tooltip } from "@material-ui/core";

export default function CategoriesHeader({ Auth, category, addDishToCategory, editCategory, deleteCategory, clone, menuSelected }) {
  return (
    <div>
      <div className={styles.categoriesHeader}>
        <h5>
          {category?.name}
          <span style={{ marginLeft: 8 }}>{category?.dishes?.length === 0 ? "0" : category?.dishes?.length}</span>
        </h5>
        <hr />
        {general?.checkPermissions({ exceptPermissions: ["category"], originalPermissions: Auth?.user?.permissions }) && (
          <>
            <div className={styles.deleteCategoryDashboard}>
              <Tooltip title="Clone category" enterTouchDelay={0}>
                <button onClick={() => clone(category)}>
                  <i className="fal fa-copy"></i>
                </button>
              </Tooltip>
            </div>
            {!menuSelected?.fromParent && (
              <>
                <div className={styles.deleteCategoryDashboard}>
                  <Tooltip title="Edit category" enterTouchDelay={0}>
                    <button onClick={() => editCategory(category?._id?.toString())}>
                      <i className="fas fa-pencil-alt"></i>
                    </button>
                  </Tooltip>
                </div>
                <Tooltip title="Delete category" enterTouchDelay={0}>
                  <button className={styles.deleteCategoryDashboard} onClick={() => deleteCategory(category?._id?.toString())}>
                    <i className="far fa-trash-alt"></i>
                  </button>
                </Tooltip>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
