import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import React from "react";
import styles from "./Section.module.scss";
import Tooltip from "@material-ui/core/Tooltip";
import LoadingSpinner from "../LoadingSpinner";

export default function SectionHeader({ title, action = null, tooltip, tour }) {
  const generateStyle = (act) => {
    return {
      marginLeft: 10,
      backgroundColor: !act?.state ? "var(--light-bg-color)" : "var(--brand)",
      color: !act?.state ? "var(--text-color)" : "var(--white)",
      borderColor: !act?.state ? "var(--light-bg-color)" : "var(--brand)",
    };
  };

  return (
    <div className={styles.sectionHeader}>
      <h3>{title}</h3>
      <hr />
      <div className={styles.actionsWrapper}>
        {action && Array.isArray(action)
          ? action.map((act, i) => (
              <React.Fragment key={i}>
                {act?.dropdownItems && act?.dropdownItems?.length > 0 ? (
                  <DropdownButton
                    data-tour={tour}
                    style={generateStyle(act)}
                    disabled={act?.disabled}
                    title={
                      act?.loadingState ? (
                        <span className={styles.downloadBtn}>
                          <LoadingSpinner size="sm" />
                        </span>
                      ) : (
                        <span className={styles.downloadBtn}>{act?.name}</span>
                      )
                    }
                  >
                    {act?.dropdownItems?.map((item, i) => (
                      <Dropdown.Item
                        key={i}
                        onClick={() => {
                          item.funk();
                        }}
                      >
                        {item?.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                ) : (
                  <Button
                    data-tour={tour}
                    art={act?.state?.toString()}
                    variant="secondary"
                    key={i}
                    style={generateStyle(act)}
                    onClick={act.funk}
                    disabled={act?.disabled}
                  >
                    {act.name}
                  </Button>
                )}
              </React.Fragment>
            ))
          : typeof action?.funk === "function" &&
            (tooltip && tooltip?.title ? (
              <Tooltip title={tooltip?.title} enterTouchDelay={0}>
                <Button data-tour={tour} variant="secondary" onClick={action.funk} disabled={action?.disabled}>
                  {action?.name}
                </Button>
              </Tooltip>
            ) : (
              <Button data-tour={tour} variant="secondary" onClick={action.funk} disabled={action?.disabled}>
                {action?.name}
              </Button>
            ))}
      </div>
    </div>
  );
}
