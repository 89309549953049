import React, { useState } from "react";
import UpdateAccountForm from "../../components/Forms/UpdateAccountForm";
import UpdatePasswordForm from "../../components/Forms/UpdateAccountForm/UpdatePassword";
import Section from "../../components/Section";
import { connect } from "react-redux";

import styles from "./UpdateAccount.module.scss";
import { Row, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import withDashboardLayout from "../../Layouts/AdminPanel";

const tab_options = {
  PERSONAL_INFORMATION: "PERSONAL_INFORMATION",
  PASSWORD: "PASSWORD",
};

function UpdateAccount({ Auth }) {
  const [tab, setTab] = useState(tab_options.PERSONAL_INFORMATION);

  return (
    <Section title="Update account">
      <div className="mb-4">
        <Row className={styles?.tabs_row}>
          <Col md={6}>
            <Button
              className={tab === tab_options?.PERSONAL_INFORMATION ? styles?.active_tab : ""}
              onClick={() => setTab(tab_options?.PERSONAL_INFORMATION)}
              variant={tab === tab_options?.PERSONAL_INFORMATION ? "contained" : "outlined"}
            >
              Personal Information
            </Button>
          </Col>
          <Col md={6}>
            <Button
              className={tab === tab_options?.PASSWORD ? styles?.active_tab : ""}
              onClick={() => setTab(tab_options?.PASSWORD)}
              variant={tab === tab_options?.PASSWORD ? "contained" : "outlined"}
            >
              Password
            </Button>
          </Col>
        </Row>
      </div>
      <hr />
      {tab === tab_options?.PERSONAL_INFORMATION ? (
        <UpdateAccountForm Auth={Auth} User={Auth?.user} />
      ) : (
        <UpdatePasswordForm Auth={Auth} User={Auth?.user} />
      )}
    </Section>
  );
}

const mapStateToProps = (state) => ({ Auth: state.Auth });
export default connect(mapStateToProps)(withDashboardLayout(UpdateAccount));
