import React, { useEffect, useState } from "react";
import DishForm from "../../components/Forms/DishForm";
import Modal from "../../components/Modal";
import { Button } from "react-bootstrap";
import Section from "../../components/Section/Section";
import { connect } from "react-redux";
import DeleteConfirmation from "../../components/Forms/DeleteConfirmation";
import styles from "../CreateCategory/CreateCategory.module.scss";
import CSVForm from "../../components/Forms/CSVForm";
import Tooltip from "@material-ui/core/Tooltip";
import Dishes from "../../components/Table/Dishes";

import withDashboardLayout from "../../Layouts/AdminPanel";
import { toast } from "react-toastify";

function CreateDish({ Auth, Dish, Admin }) {
  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const { dishes } = Dish;

  useEffect(() => {
    Dish.load();
    if (Auth?.user?.permissions?.dish) {
      Admin?.loadDietaryConsiderations();
    }
    // eslint-disable-next-line
  }, []);

  const updateDish = (dish) => {
    setModal({
      ...modal,
      size: "lg",
      visible: true,
      Component: <DishForm update={true} dish={dish} dishID={dish?._id} closeModal={() => setModal({ ...modal, visible: false })} />,
    });
  };

  const deleteDishes = (ids, name) => {
    let customButtonMessage = Array?.isArray(ids) ? `Delete ${ids?.length} ${ids?.length === 1 ? "dish" : "dishes"}` : `Delete dish`;
    let customMessage = `Are you sure you want to delete ${ids?.length <= 1 ? `${name ? name : "this dish"}` : `${ids?.length} dishes`}?`;

    setModal({
      ...modal,
      visible: true,
      title: "Delete dish",
      confirmation: true,
      Component: (
        <DeleteConfirmation
          type="Dishes"
          customMessage={customMessage}
          customButtonMessage={customButtonMessage}
          funk={async (id) => {
            return await Dish.delete({ ids: ids });
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const showCSVModal = () => {
    setModal({
      ...modal,
      visible: true,
      title: "Upload dishes",
      Component: (
        <CSVForm
          Controller={Dish}
          downloadExampleLink={process.env.REACT_APP_DISH_CSV_EXAMPLE}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const clone = async (dish) => {
    if (dish?.createdAt) {
      delete dish?.createdAt;
      dish.restaurantDietaryConsiderations = dish?.restaurant?.dietaryConsiderations;
      dish.name = dish?.name + " (copy)";
      dish.isAvailable = false;

      const selectedCustomizations = dish?.customizations?.selected?.map((customization) => customization?._id);

      dish.customizations.selected = selectedCustomizations;
      dish.customizations = JSON.stringify(dish?.customizations);

      dish.sideDishes = JSON.stringify(dish?.sideDishes?.map((dish) => dish?._id));

      const ingredients = dish?.ingredients?.map((ingredient) => ({
        ingredient: ingredient?.ingredient?._id,
      }));

      dish.ingredients = JSON.stringify(ingredients);
    } else {
      dish.name = dish?.name + " (copy)";
    }

    const response = await Dish.create(dish, true);
    if (response?.confirmation === "Fail") {
      if (response?.statusCode === 412) {
        clone(dish);
      } else {
        toast("Failed to clone dish");
      }
    } else {
      toast("Dish cloned successfully");
      Dish.load({ forceRender: true });
    }
  };

  return (
    <React.Fragment>
      <Modal {...modal} />
      <DishForm />
      <br />
      <Section title={`Dishes ${dishes?.length > 0 ? `(${dishes?.length})` : ""}`}>
        <Dishes Auth={Auth} data={dishes} updateDish={updateDish} deleteDishes={deleteDishes} clone={clone} />
      </Section>
      <div className={styles.uploadCSVButtonParent}>
        <Tooltip title="You can add dishes one at a time, or upload all of them at once." enterTouchDelay={0}>
          <Button type="button" onClick={() => showCSVModal()} style={{ alginSelf: "center" }}>
            Upload CSV
          </Button>
        </Tooltip>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({ Dish: state?.Dish, Auth: state?.Auth, Admin: state?.Admin });
export default connect(mapStateToProps)(withDashboardLayout(CreateDish));
