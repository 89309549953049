import React, { useState, useEffect, useRef } from "react";
import styles from "../../components/QrCode/QrCode.module.scss";
import qrStyles from "./QR.module.scss";
import EasyQR from "easyqrcodejs";
import { IKImage } from "imagekitio-react";

export default function QR({ fields, values, restaurantId, qrSettings, advancedQrCode, qrUrl }) {
  const { title, text_top, logo, wifi_name, wifi_password } = values;
  const [qrOptions, setQrOptions] = useState({
    text: qrUrl ? qrUrl : `${window.location.origin}/menu/${restaurantId}`,
    width: 220,
    height: 220,
  });

  const qrRef = useRef(null);
  const [qrElement, setQrElement] = useState();

  useEffect(() => {
    if (!qrElement) return;
    qrElement.clear();
    setQrElement(new EasyQR(advancedQrCode.current, qrOptions));
    //eslint-disable-next-line
  }, [qrOptions]);

  useEffect(() => {
    setQrElement(new EasyQR(advancedQrCode.current, qrOptions));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (restaurantId) {
      setQrOptions({ ...qrOptions, text: qrUrl ? qrUrl : `${window.location.origin}/menu/${restaurantId}` });
    }
    //eslint-disable-next-line
  }, [restaurantId]);

  useEffect(() => {
    let temp = { ...qrOptions };
    let optionFields = ["colorDark", "colorLight"];
    optionFields?.forEach((option) => {
      if (!qrSettings[option] && qrSettings[option] !== 0) {
        delete temp[option];
      } else {
        temp[option] = qrSettings[option];
      }
    });
    setQrOptions({
      ...temp,
    });
    //eslint-disable-next-line
  }, [qrSettings]);

  return (
    <div className={qrStyles.qrWrapper}>
      <h3 className={qrStyles.title}>{title || fields?.title?.placeholder}</h3>
      <p>{text_top || fields?.text_top?.placeholder}</p>
      <div className={qrStyles.qrRef} ref={qrRef}>
        <div className={styles.QrCode} ref={advancedQrCode}></div>
      </div>
      <footer>
        {logo ? (
          <IKImage
            path={logo}
            transformation={[{ width: 80, height: 80 }]}
            loading="lazy"
            lqip={{ active: true }}
            alt=""
            className={qrStyles.logo}
          />
        ) : (
          <img className={qrStyles.logo} src={fields?.logo?.placeholder} alt="" />
        )}

        <p>
          <span>{fields?.wifi_name?.label}</span>
          {wifi_name || fields?.wifi_name?.placeholder}
        </p>
        <p>
          <span>{fields?.wifi_password?.label}</span>
          {wifi_password || fields?.wifi_password?.placeholder}
        </p>
      </footer>
    </div>
  );
}
