import React, { useEffect, useState } from "react";
import styles from "../Templates/Templates.module.scss";
import placeholderFood from "../../assets/img/placeholder_food.png";
import { Alert } from "react-bootstrap";
import general from "../../helpers/general";
import Pagination from "../Pagination";
import { IKImage } from "imagekitio-react";

export default function SelectDishes({ addToSelect, selectedDishes, dishes }) {
  const [localPagination, setLocalPaginationData] = useState({
    pagination: {},
    data: [],
  });

  useEffect(() => {
    const temp = { ...localPagination };

    let page = temp?.pagination?.page || 1;
    let per_page = temp?.pagination?.per_page || 24;
    let data = temp?.data;

    // If user deletes the last record, and page is greater than 1, then he should go back automatically one page back
    if ((!data || data?.length === 1) && page > 0) {
      page = page - 1;
    }

    setLocalPaginationData(general.paginate(dishes, page, per_page));
    //eslint-disable-next-line
  }, [dishes]);

  const changePage = async (e, page) => {
    setLocalPaginationData(general.paginate(dishes, page + 1, 24));
  };

  const data = localPagination?.data || [];
  return (
    <div>
      <div className={styles.categorySlider}>
        {data && data?.length > 0 ? (
          data?.map((dish) => (
            <div
              key={dish?._id}
              onClick={() => addToSelect(dish?._id)}
              className={`${styles.swiperSlide} ${selectedDishes?.includes(dish?._id) ? styles.selected : ""}`}
              style={{ opacity: selectedDishes?.includes(dish?._id) ? 1 : 0.6 }}
            >
              <div className={styles.categoryItem}>
                {dish?.image ? (
                  <IKImage
                    path={dish?.image}
                    transformation={[{ width: 92, height: 92 }]}
                    loading="lazy"
                    lqip={{ active: true }}
                    alt={dish.fileName}
                    className={styles.dishImage}
                  />
                ) : (
                  <img className={styles.dishImage} src={placeholderFood} alt="" />
                )}
                <div className={styles.categoryItemContent}>
                  <p className={styles.dishName}>{dish?.price && general.formatPrice(dish?.price)}</p>
                  <p className={styles.dishName}>{dish?.name}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Alert variant="danger" style={{ marginTop: 30, width: "100%", display: "flex", flexDirection: "column" }}>
            <span>There are no dishes to select!</span>
            <span>Please add some first!</span>
          </Alert>
        )}
      </div>

      {dishes && dishes?.length > 0 && (
        <div style={{ marginTop: "20px" }}>
          <Pagination
            changePage={changePage}
            currentPage={localPagination?.pagination?.page - 1}
            totalPages={localPagination?.pagination?.total_pages}
          />
        </div>
      )}
    </div>
  );
}
