import { Api, Endpoint } from "../../api"
import { store } from '../connect/store'

export const ADMIN_USERS_LOADED_SUCCESSFULLY = "ADMIN_USERS_LOADED_SUCCESSFULLY"
export const ADMIN_RESTAURANTS_LOADED_SUCCESSFULLY = "ADMIN_RESTAURANTS_LOADED_SUCCESSFULLY"
export const ADMIN_STATISTICS_LOADED_SUCCESSFULLY = "ADMIN_STATISTICS_LOADED_SUCCESSFULLY"
export const ADMIN_LOADED_SUCCESSFULLY = "ADMIN_LOADED_SUCCESSFULLY"
export const ADMIN_DIETARY_CONSIDERATIONS_LOADED = "ADMIN_DIETARY_CONSIDERATIONS_LOADED" 
export const ADMIN_RESET_DATA = "ADMIN_RESET_DATA"

export const methods = {
    load: async ({ Endpoint, dispatchType, field, accessObject, query = {} }) => {
        const response = await Api.call(Endpoint, { query })
        if (response?.confirmation === "Success") {
            store.dispatch({ type: dispatchType, [field]: accessObject ? accessObject(response) : response?.message })
        }
        return response;
    },
    loadDietaryConsiderations: async () => {
        const response = await Api?.call(Endpoint?.admin?.dietary?.read);
        if (response?.confirmation === "Success" && typeof response?.message?.dietaryConsiderations === "object") {
            store.dispatch({ type: ADMIN_DIETARY_CONSIDERATIONS_LOADED, dietaryConsiderations: response?.message?.dietaryConsiderations })
        }
        return response;
    },
}