import React, { useEffect, useState } from "react";
import { Table as BootstrapTable } from "react-bootstrap";
import styles from "./Table.module.scss";
import general from "../../helpers/general";
import Pagination from "../Pagination";
import Checkbox from "../Checkbox";

import Button from "@material-ui/core/Button";

/**
 * @param { Object } props
 * @param { Array } props.columns - Columns to render in table
 * @param { Array } props.rows - Rows data
 * @param { React.Component } props.paginationComponent - Pagination component
 * @param { Boolean } props.enableLocalPagination - Enable local pagination
 * @param { Boolean } props.enableSelectMultiple - Enable multiple selection
 * @param { Function } props.onDeleteMultiple - Callback when rows are selected and delete button is clicked
 * @param { React.Component } props.onSelectRowsRenderView - Callback to render view button or any html element when row is selected
 * @param { Boolean } props.onSelectRowsDisableDelete - Disable delete button when row is selected
 */

export default function Table({
  columns,
  rows,
  paginationComponent,
  enableLocalPagination = false,
  enableSelectMultiple = false,
  onDeleteMultiple,
  onSelectRowsRenderView,
  onSelectRowsDisableDelete = false,
}) {
  const [checked, setChecked] = useState({});
  const [localPagination, setLocalPaginationData] = useState({
    pagination: {},
    data: [],
  });

  const checkedLength = Object?.keys(checked).length;

  const onChangeCheckbox = (id) => {
    id = id.toString();
    const temp = { ...checked };
    temp[id] ? delete temp[id] : (temp[id] = true);
    setChecked(temp);
  };

  const selectAllCheckboxes = () => {
    let temp = { ...checked };
    const tempRows = rows.filter((elem) => !elem?.fromParent);
    if (checkedLength === tempRows?.length) {
      temp = {};
    } else {
      for (let i = 0; i < tempRows?.length; i++) {
        temp[tempRows[i]?._id] = true;
      }
    }
    setChecked(temp);
  };

  const callbackDeleteMultiple = () => {
    onDeleteMultiple(Object?.keys(checked));
    setChecked({});
  };

  useEffect(() => {
    if (localPagination?.data?.length === 0 && localPagination?.pagination?.page > 1) {
      const temp = { ...localPagination };
      temp.pagination.page = localPagination?.pagination?.page - 1;
      setLocalPaginationData(general.paginate(rows, temp.pagination.page, 10));
    }
    // eslint-disable-next-line
  }, [localPagination]);

  useEffect(() => {
    if (enableLocalPagination && rows && Array.isArray(rows)) {
      const temp = { ...localPagination };

      const page = temp?.pagination?.page || 1;
      const per_page = temp?.pagination?.per_page || 10;

      setLocalPaginationData(general.paginate(rows, page, per_page));
    }
    // eslint-disable-next-line
  }, [rows]);

  const changePage = async (e, page) => {
    setLocalPaginationData(general.paginate(rows, page + 1, 10));
  };

  const final_rows = enableLocalPagination ? localPagination?.data || [] : rows;

  return (
    <>
      <div className={styles.customTableWrapper}>
        <BootstrapTable className={styles.customTable}>
          <thead>
            <tr>
              {enableSelectMultiple && (
                <th>
                  <Checkbox color="white" size="small" checked={checkedLength === rows.length} onChange={() => selectAllCheckboxes()} />
                </th>
              )}
              {columns?.map((column, index) => (
                <th key={index}>{column?.field}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {final_rows &&
              Array.isArray(final_rows) &&
              final_rows?.length > 0 &&
              final_rows.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  style={checked?.[row?._id?.toString()] || checkedLength === rows?.length ? { backgroundColor: "#f1f1f1" } : {}}
                >
                  {enableSelectMultiple && (
                    <td>
                      {row?.fromParent ? (
                        <i style={{ padding: "0 6px" }} className="fal fa-solid fa-lock"></i>
                      ) : (
                        <Checkbox
                          color="var(--brand)"
                          size="small"
                          checked={checked[row?._id?.toString()] || checkedLength === rows.length}
                          onChange={() => onChangeCheckbox(row?._id)}
                        />
                      )}
                    </td>
                  )}
                  {columns.map((column, columnIndex) =>
                    column?.render && typeof column?.render === "function" ? (
                      <td key={columnIndex}>{column?.render({ row, column, columnIndex })}</td>
                    ) : (
                      <td key={columnIndex}>{row?.[column?.access]}</td>
                    )
                  )}
                </tr>
              ))}
          </tbody>
        </BootstrapTable>
        {(!rows || rows?.length === 0) && (
          <div className="text-center d-flex justify-content-center container-fluid">
            <p style={{ margin: "20px auto", textAlign: "center", color: "#aaa" }}>No data available</p>
          </div>
        )}

        {paginationComponent && rows && rows?.length > 0 && <div style={{ marginTop: "20px" }}>{paginationComponent}</div>}
      </div>

      <div className={`d-flex align-items-center justify-content-${checkedLength > 0 ? "between" : "center"}`}>
        {checkedLength > 0 && (
          <div>
            <p style={{ color: "#605d5d" }}>{checkedLength === 1 ? `${checkedLength} row` : `${checkedLength} rows`} selected</p>
          </div>
        )}
        {enableLocalPagination && rows && rows?.length > 0 && (
          <div style={{ marginTop: "20px" }}>
            <Pagination
              changePage={changePage}
              currentPage={localPagination?.pagination?.page - 1}
              totalPages={localPagination?.pagination?.total_pages}
            />
          </div>
        )}

        {checkedLength > 0 && (
          <div>
            {typeof onSelectRowsRenderView === "function" && onSelectRowsRenderView(checked, setChecked)}
            {!onSelectRowsDisableDelete && (
              <Button variant="outlined" color="secondary" onClick={callbackDeleteMultiple}>
                Delete
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
}
