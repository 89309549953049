import React, { useEffect, useState } from "react";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import styles from "./AdminPanel.module.scss";
import LoadingSpinner from "../components/LoadingSpinner";
import MenuWrapper from "../components/Wrappers/MenuWrapper";
import TopBar from "../components/Header/TopBar";
import PageHeader from "../components/Header/PageHeader";
import { useLocation } from "react-router-dom";

export default function Dashboard(BaseComponent) {
  return function Dashboard(props) {
    const { Menu } = useSelector((state) => state);
    const [loading, setLoading] = useState(true);
    const [disablePreview, setDisablePreview] = useState(false);
    const [navCollapsed, setNavCollapsed] = useState(false);
    const [preview, setPreview] = useState(false);
    const { selected: selectedMenu } = Menu;
    const [isMapPage, setIsMapPage] = useState(false);
    const [windowSize, setWindowSize] = useState(0);
    const location = useLocation();

    useEffect(() => {
      handleResize();
      (async () => {
        await Menu.load();
        setLoading(false);
      })();
      //eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (location?.pathname.includes("menu-display")) {
        setNavCollapsed(true);
        setDisablePreview(true);
        setIsMapPage(true);
      }
    }, [location?.pathname]);

    const handleResize = () => {
      setWindowSize(window?.innerWidth);
    };

    useEffect(() => {
      setNavCollapsed(windowSize <= 1200);
    }, [windowSize]);

    if (loading) return <LoadingSpinner fullScreen={true} />;

    return (
      <section className={styles.panel}>
        <TopBar />
        <Header navCollapsed={navCollapsed} setNavCollapsed={setNavCollapsed} preview={preview} setPreview={setPreview} />
        <div className={`panel-wrapper ${styles.mainWrapper} ${isMapPage ? styles.noPadding : ""}`}>
          <main>
            <div className={`${isMapPage ? "container-fluid2" : "container"}`}>
              <PageHeader />
              <BaseComponent {...props} />
            </div>
          </main>
        </div>
        {selectedMenu && selectedMenu?._id && !disablePreview && (
          <section className={`${styles.preview} ${preview ? styles.show : ""}`}>
            <div className={styles.previewContainer}>
              {MenuWrapper && (
                <Suspense fallback={<LoadingSpinner />}>
                  <MenuWrapper preview={preview} />
                </Suspense>
              )}
            </div>
          </section>
        )}
      </section>
    );
  };
}
