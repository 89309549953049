/* eslint-disable import/no-anonymous-default-export */

import moment from "moment";

const general = {
  validatePrice: (price) => {
    try {
      // eslint-disable-next-line no-extend-native
      String.prototype.isNumber = function () {
        return /^\d+[.\d]*$/.test(this);
      };

      if (!price.isNumber()) {
        throw new Error("Price is not valid");
      }

      return { err: false, message: null, price: price };
    } catch (err) {
      return { err: true, message: "Price is not valid" };
    }
  },
  formatPrice: (price) => {
    try {
      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(price);
      return formatted;
    } catch (err) {
      return price;
    }
  },
  isDarkColor: (color) => {
    if (!color) return false;
    const c = color.substring(1);
    const rgb = parseInt(c, 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma < 40;
  },
  compareColors: (color1, color2) => {
    const c1 = general.isDarkColor(color1);
    const c2 = general.isDarkColor(color2);
    if (c1 === c2) {
      if (c1) {
        return "white";
      } else {
        return "black";
      }
    }
    return c1 !== c2;
  },
  getPasswordRegex: () => {
    // const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?^&]{8,}$/;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    const passwordMessage =
      "Password should not be empty, minimum eight characters, at least one letter, one number and one special character.";
    return { passwordRegex, passwordMessage };
  },
  timeAsDate: (time) => {
    if (typeof time !== "string" || !time.includes(":")) return false;
    try {
      const today = new Date();
      const date = new Date(today.toDateString() + " " + time);
      return new Date(date);
    } catch (err) {
      return false;
    }
  },
  dateAsTime: (date) => {
    try {
      date = new Date(date).toLocaleString();
      return moment(date).format("HH:mm").toString();
    } catch (err) {
      return "";
    }
  },
  checkPermissionDeniedResponse: (response) => {
    try {
      const permissionStatusCode = parseInt(process.env.REACT_APP_PERMISSION_DENIED_ERROR_STATUS);
      return parseInt(response?.statusCode) === permissionStatusCode;
    } catch (err) {
      return false;
    }
  },
  /**
   * @param { Number } price
   * @param { Number } percentage
   * @return { Number }
   */
  incrementPriceByPercentage: (price, percentage) => {
    try {
      const num = price + (price * percentage) / 100;
      return Math.round((num + Number.EPSILON) * 100) / 100;
    } catch (err) {
      return price;
    }
  },
  /**
   * @param { String } target // String that is stringified using JSON.stringify()
   * @return { dynamic }
   */
  parseJSON: (target, onErrorReturn = []) => {
    try {
      return JSON.parse(target);
    } catch (err) {
      return onErrorReturn;
    }
  },
  reorder: (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  },
  /**
   * @param { Date } Date Start date
   * @param { Number } exceptMinutes Except minutes te check with start date
   * @return { boolean }
   */
  getExceptedMinutesFromDate: (startDate, exceptMinutes) => {
    const nowDate = moment(new Date());
    startDate = moment(startDate);
    const duration = moment.duration(nowDate.diff(startDate));

    let minutes = duration.asMinutes();

    if (startDate.isBefore(nowDate)) {
      return false;
    }

    const result = Math.round(Math.abs(minutes));
    return result <= parseInt(exceptMinutes);
  },
  /**
   * @param { Object } menusTimeline menusTimeline object from api
   * @param { Array } menus Restaurant available menus
   * @param { Number } menu_period_transition Restaurant menu_period_transition
   * @return { Array }
   */
  findClosestMenus: (menusTimeline, menus, menu_period_transition) => {
    const notifyMenus = [];
    if (menusTimeline && typeof menusTimeline === "object" && Object?.keys(menusTimeline)?.length > 0 && menus && menus?.length > 0) {
      const filteredMenusTimeline = Object?.values(menusTimeline)?.filter(
        (timeline) => !menus?.find((menu) => menu?._id?.toString() === timeline?._id?.toString())
      );
      if (filteredMenusTimeline && menu_period_transition) {
        filteredMenusTimeline?.forEach((menu) => {
          const startTime = general.timeAsDate(general.dateAsTime(menu?.startTime));
          if (general.getExceptedMinutesFromDate(startTime, menu_period_transition)) {
            notifyMenus?.push(menu);
          }
        });
      }
    }
    return notifyMenus;
  },
  /**
   * @param {Array} exceptPermissions Except permissions
   * @param {object} originalPermissions Auth user permissions
   * @param {string} $connector "$or" or "$and". Default will be "$and"
   * @return {boolean}
   */
  checkPermissions: (params = { $connector: "$and" }) => {
    const { exceptPermissions, $connector, originalPermissions } = params;
    if (!exceptPermissions || !originalPermissions) return false;
    if ($connector === "$or") {
      return exceptPermissions?.some((permission) => originalPermissions[permission]);
    }
    return exceptPermissions?.every((permission) => originalPermissions[permission]);
  },
  /**
   * @param {Array} arr1
   * @param {Array} arr2
   * @return {Array} arrayDifference
   */
  arr_diff: (a1, a2) => {
    const a = [],
      diff = [];
    for (let i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }
    for (let i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }

    for (var k in a) {
      diff.push(k);
    }

    return diff;
  },
  ellipsePagination: (currentPage, totalPages) => {
    let current = currentPage,
      last = totalPages,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;
    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }
    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }
    return rangeWithDots;
  },
  /**
   * @param {String} url
   * @return {String} generatedURL
   */
  generateUrl: (url) => {
    if (url && url !== "" && !url.startsWith("http")) {
      return "https://" + url;
    }
    return url;
  },
  paginate: (items, current_page, per_page_items) => {
    let page = current_page || 1,
      per_page = per_page_items || 10,
      offset = (page - 1) * per_page,
      paginatedItems = items.slice(offset).slice(0, per_page_items),
      total_pages = Math.ceil(items.length / per_page);

    return {
      pagination: {
        page: parseInt(page),
        per_page: parseInt(per_page),
        previous_page: page - 1 ? page - 1 : null,
        next_page: total_pages > page ? page + 1 : null,
        total: items.length,
        total_pages: total_pages,
      },
      data: paginatedItems,
    };
  },
  withPromiseTimeout: (milliseconds, promise) => {
    const timeout = new Promise((resolve, reject) => setTimeout(() => reject(`Timed out after ${milliseconds} ms.`), milliseconds));
    return Promise.race([promise, timeout]);
  },

  generateTimeOptions: {
    thisWeek: () => {
      const today = new Date();
      let dDay = today.getDay() > 0 ? today.getDay() : 7;
      let first = today.getDate() - dDay + 1;
      let firstDayWeek = new Date(today.setDate(first));
      return { value: firstDayWeek.getDate(), label: "This Week" };
    },
    thisMonth: () => {
      const today = new Date();
      return { value: today.getDate(), label: "This Month" };
    },
    thisQuarter: () => {
      const today = new Date();
      const quarter = Math.floor((today.getMonth() + 3) / 3);
      const start = new Date(today.getFullYear(), quarter * 3 - 3, 0);
      const diff = today - start;
      const oneDay = 1000 * 60 * 60 * 24;
      const dayQuarter = Math.floor(diff / oneDay);
      return { value: dayQuarter, label: "This Quarter" };
    },
    thisYear: () => {
      const today = new Date();
      const start = new Date(today.getFullYear(), 0, 0);
      const diff = today - start;
      const oneDay = 1000 * 60 * 60 * 24;
      const day = Math.floor(diff / oneDay);
      return { value: day, label: "This Year" };
    },
  },
  convertToReadableRestaurantType: (type) => {
    const types = {
      FOOD_TRUCK: "Food truck",
      RESTAURANT: "Restaurant",
    };
    return types[type];
  },
};

export default general;
