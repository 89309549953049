import React, { useEffect, useState } from "react";
import { Api, Endpoint } from "../../../api";
import style from "./ChooseFromMedia.module.scss";
import LoadingSpinner from "../../LoadingSpinner";
import Pagination from "../../Pagination";
import { IKImage } from "imagekitio-react";

export default function ChooseFromMedia({ closeModal, setFileSelectedLocally, handleImage }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [pagination, setPagination] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async (query = {}) => {
    const response = await Api.call(Endpoint.listMedia, { query });
    if (response?.confirmation === "Success" && typeof response?.message === "object" && response?.message?.data?.length > 0) {
      setData(response?.message?.data);
      setPagination(response?.message?.pagination);
    }
    setLoading(false);
  };

  const fileSelected = (elem) => {
    setFileSelectedLocally(elem?.key);
    handleImage(elem?.key);
    closeModal();
  };

  const changePage = async (e, page) => {
    setPagination({ ...pagination, page: page + 1 });
    await getData({ currentPage: page + 1 });
  };

  if (loading) {
    return (
      <div className={style.loadingSpinnerParent}>
        <LoadingSpinner />
        <p>Loading data...</p>
      </div>
    );
  } else if (data && data?.length > 0) {
    return (
      <div>
        <div className={style.chooseFromMediaParent}>
          {data.map((el, i) => (
            <IKImage
              path={el?.key}
              transformation={[{ width: 100, height: 100 }]}
              loading="lazy"
              lqip={{ active: true }}
              alt={el.fileName}
              key={i}
              onClick={() => fileSelected(el)}
            />
          ))}
        </div>
        <div style={{ marginTop: "20px" }}>
          <Pagination changePage={changePage} currentPage={pagination?.page - 1} totalPages={pagination?.total_pages} />
        </div>
      </div>
    );
  }
  return <p>No images available to upload</p>;
}
