import * as TYPES from "../actions/ingredient";
import { methods } from "../actions/ingredient";

const initialState = {
  ...methods,
  ingredients: [],
};

const ingredientReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.INGREDIENT_CREATED_SUCCESSFULLY:
      let tempIngredients = [...state.ingredients];
      tempIngredients.push(action.ingredient);
      return { ...state, ingredients: tempIngredients };
    case TYPES.INGREDIENTS_LOADED_SUCCESSFULLY:
      return { ...state, ingredients: action?.ingredients };
    case TYPES.INGREDIENTS_RESET:
      return { ...state, ingredients: [] };
    default:
      return { ...state };
  }
};

export { ingredientReducer };
