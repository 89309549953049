import templateMeta1 from "../QRTemplates/Template 1/template_meta.json";
import templateMeta2 from "../QRTemplates/Template 2/template_meta.json";
import templateMeta3 from "../QRTemplates/Template 3/template_meta.json";
import templateMeta4 from "../QRTemplates/Template 4/template_meta.json";

const templates = [
  {
    template: "Template 1",
    image: "screenshot.png",
    template_meta: templateMeta1,
  },
  {
    template: "Template 2",
    image: "screenshot.png",
    template_meta: templateMeta2,
  },
  {
    template: "Template 3",
    image: "screenshot.png",
    template_meta: templateMeta3,
  },
  {
    template: "Template 4",
    image: "screenshot.png",
    template_meta: templateMeta4,
  },
];

export default templates;
