import React, { useState, useRef } from "react";
import Map from "./Map";
import styles from "./Zone.module.scss";
import { Draggable, Droppable } from "react-beautiful-dnd";
import EditButtonMap from "./EditButtonMap";
import DeleteItem from "./DeleteItem";
import CancelChanges from "./CancelChanges";
import Modal from "../../components/Modal";
import DeleteConfirmation from "../../components/Forms/DeleteConfirmation";

export default function Zone({
  zone,
  zone_index,
  findAndDeleteMap,
  findAndUpdateMap,
  findAndUpdateZone,
  findAndDeleteZone,
  findAndDeleteMenu,
  location_index,
  findMap,
  findMenu,
  updateMaps,
}) {
  const ref = useRef(null);
  const [isUpdating, setIsUpdating] = useState(true);
  const [currentText, setCurrentText] = useState("");

  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const updateItemHandler = (e) => {
    setIsUpdating(!isUpdating);
    setTimeout(() => {
      ref.current.focus();
      updateMaps();
    }, 100);

    setCurrentText(ref.current.value);
  };

  const cancelChanges = (e) => {
    setIsUpdating(!isUpdating);
    ref.current.value = currentText;
    setCurrentText("");
  };

  const showConfirmationModal = (id) => {
    setModal({
      ...modal,
      title: "Delete content",
      visible: true,
      confirmation: true,
      Component: (
        <DeleteConfirmation
          {...modal}
          customMessage="Are you sure you want to delete this zone"
          customButtonMessage="Delete"
          toastMessage="Zone has been deleted"
          funk={() => {
            findAndDeleteZone(id, location_index);
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const deleteItem = (id) => {
    showConfirmationModal(id);
    setIsUpdating(!isUpdating);
  };

  return (
    <>
      <Modal {...modal} />
      <Draggable draggableId={`${zone?._id}`} key={zone?._id} index={zone_index}>
        {(provided) => (
          <div
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            ref={provided.innerRef}
            className={`${!isUpdating ? styles.active : ""} ${styles.zone}`}
          >
            <div className={styles.zoneTitle}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setIsUpdating(true);
                  updateMaps();
                }}
              >
                <div className={styles.wrapper}>
                  <span style={{ fontSize: 12, whiteSpace: "nowrap" }} onClick={() => updateItemHandler(zone?._id)}>
                    Zone
                  </span>
                  <span className="map-preview-title" onClick={() => updateItemHandler(zone?._id)}>
                    {zone?.name?.length > 0 ? zone?.name : "Zone"}
                  </span>
                </div>
                <p>
                  <input
                    style={{ fontSize: 12, display: `${isUpdating ? "none" : "block"}` }}
                    type="text"
                    className="inputChangeTextMap"
                    suppressContentEditableWarning={true}
                    disabled={isUpdating}
                    ref={ref}
                    placeholder="Zone"
                    defaultValue={zone?.name}
                    onChange={() => {
                      findAndUpdateZone(zone?._id, ref.current.value);
                    }}
                  />
                </p>
              </form>
              <div className={styles.zoneActions}>
                <div
                  className={`deleteItem ${!isUpdating ? styles.active : ""} ${styles.deleteItem}`}
                  onClick={(e) => {
                    e.preventDefault();
                    deleteItem(zone?._id, ref.current.value);
                  }}
                >
                  <DeleteItem />
                </div>
                <div
                  className={`editButtonMap ${!isUpdating ? styles.editActive : ""} ${styles.editButtonMap}`}
                  onClick={() => updateItemHandler(zone?._id)}
                >
                  <EditButtonMap color="#888888" mode={isUpdating} />
                </div>
                <div
                  className={`cancelChanges ${!isUpdating ? styles.active : ""} ${styles.cancelChanges}`}
                  onClick={(e) => {
                    e.preventDefault();
                    cancelChanges();
                  }}
                >
                  <CancelChanges />
                </div>
              </div>
            </div>
            <Droppable droppableId={`${zone?._id}`} type="map">
              {(provided) => (
                <div className={styles.mapsWrapper} {...provided.droppableProps} ref={provided.innerRef}>
                  {zone?.maps?.map((map, index) => (
                    <Map
                      location_index={location_index}
                      zone_index={zone_index}
                      findAndDeleteMap={findAndDeleteMap}
                      findAndUpdateMap={findAndUpdateMap}
                      findAndDeleteMenu={findAndDeleteMenu}
                      findMap={findMap}
                      findMenu={findMenu}
                      key={index}
                      map={map}
                      map_index={index}
                      updateMaps={updateMaps}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        )}
      </Draggable>
    </>
  );
}
