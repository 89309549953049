import React from "react";
import Table from "./Table";
import general from "../../helpers/general";

import placeholderFood from "../../assets/img/placeholder_food.png";
import { IKImage } from "imagekitio-react";

export default function Dishes({ Auth, data, updateDish, deleteDishes, clone }) {
  const columns = [
    {
      field: "name",
      access: "name",
    },
    {
      field: "Price",
      render: function ({ row, column, index }) {
        if (row?.price && typeof row?.price === "number") {
          return general.formatPrice(row?.price);
        }
        return null;
      },
    },
    {
      field: "Image",
      render: function ({ row }) {
        return (
          <>
            {row?.image ? (
              <IKImage
                transformation={[{ width: 80, height: 50 }]}
                loading="lazy"
                lqip={{ active: true }}
                path={row?.image}
                alt={row?.image}
              />
            ) : (
              <img src={placeholderFood} alt="" />
            )}
          </>
        );
      },
    },
    general?.checkPermissions({ exceptPermissions: ["dish"], originalPermissions: Auth?.user?.permissions }) && {
      field: "Actions",
      render: ({ row, column, index }) => (
        <div className={`actionsWrapper`}>
          <>
            {!row?.fromParent && <i onClick={() => updateDish(row)} className={`edit-icon fal fa-edit`} />}
            <i onClick={() => clone(row)} className={`edit-icon fal fa-copy`} />
            {!row?.fromParent && (
              <i
                onClick={() => {
                  if (row?._id) {
                    deleteDishes([row?._id], row?.name);
                    return;
                  }
                  deleteDishes(row);
                }}
                className={`delete-icon far fa-trash-alt`}
              />
            )}
          </>
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      rows={data || []}
      enableLocalPagination={true}
      enableSelectMultiple={true}
      onDeleteMultiple={(ids) => deleteDishes(ids)}
    />
  );
}
