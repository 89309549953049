import React, { useState, useEffect } from "react";
import styles from "./MapsSection.module.scss";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import withDashboardLayout from "../../Layouts/AdminPanel";
import { connect } from "react-redux";

import { Api, Endpoint } from "../../api";
import Location from "./Location";
import MapMenu from "./MapMenu";
import MapAction from "./MapAction";
import { v4 as uuid } from "uuid";

import magnifier from "./../../images/magnifier.svg";
import map from "./../../images/map.svg";
// import pin from "./../../images/pin.svg";
import share from "./../../images/share.svg";
import Modal from "../../components/Modal";
import DeleteConfirmation from "../../components/Forms/DeleteConfirmation";
import { toast } from "react-toastify";

const initialState = {
  locations: [{ id: uuid, name: "", zones: [] }],
};

const MapsSection = ({ Menu }) => {
  const [data, setData] = useState(initialState);
  const [, setCurrentTarget] = useState(null);
  const [collapsedTypes, setCollapsedTypes] = useState(false);

  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  // Map Action Types
  const MapTypes = [
    {
      id: 101,
      label: "Zone",
      value: "addZone",
      type: "zone",
      image: magnifier,
    },
    {
      id: 102,
      label: "Menu Display",
      value: "addMap",
      type: "map",
      image: map,
    },
    {
      id: 103,
      label: "Group",
      value: "addGroup",
      type: "group",
      image: map,
      disabled: true,
    },
    {
      id: 104,
      label: "Shared",
      value: "addShared",
      type: "share",
      image: share,
      disabled: true,
    },
  ];

  const getLocation = () => ({
    name: "Location",
    zones: [],
    _id: uuid(),
  });

  const getZone = () => ({
    name: "Zone",
    maps: [],
    _id: uuid(),
  });

  const getMap = () => ({
    name: "Menu Display",
    menus: [],
    _id: uuid(),
  });

  useEffect(() => {
    const wrapper = document.querySelector(".custom-droppable-element");

    wrapper.addEventListener("mouseover", (e) => {
      setCurrentTarget(e.target);
    });

    return wrapper.removeEventListener("mouseover", (e) => {});
  }, []);

  const getMenu = (id) => {
    const currentMenu = Menu?.menus.find((m) => m?._id === id);
    return { menu: currentMenu, _id: uuid() };
  };

  const handleLocations = ({ data, destination, source, draggableId }) => {
    //TODO: use this to auto populate the map
    let currentLocation = findLocation(draggableId);
    const isAdd = source.droppableId.includes("add");
    if (isAdd) {
      currentLocation = getLocation();
    }
    updateLocation(currentLocation, destination, source, isAdd);
  };

  const handleZones = ({ data, destination, source, draggableId }) => {
    //TODO: use this to auto populate the map
    const isAdd = source.droppableId.includes("add");
    data.locations.forEach((location, i) => {
      if (location._id === destination.droppableId) {
        let currentZone = findZone(draggableId);
        if (isAdd) currentZone = getZone();
        updateZone(currentZone, destination, source, isAdd);
      }
    });
  };
  const handleMaps = ({ data, destination, source, draggableId }) => {
    //TODO: use this to auto populate the map
    const isAdd = source.droppableId.includes("add");
    data.locations.forEach((location, i) => {
      location.zones.forEach((zone, j) => {
        if (zone._id === destination.droppableId) {
          let currentMap = findMap(draggableId);
          if (isAdd) currentMap = getMap();
          updateMap(currentMap, destination, source, isAdd);
        }
      });
    });
  };
  const handleMenus = async ({ data, destination, source, draggableId }) => {
    const droppableElement = document.querySelector(`[data-rbd-droppable-id="${destination.droppableId}"]`);
    const zoneDroppableId = droppableElement.parentElement.parentElement.getAttribute("data-rbd-droppable-id");

    //TODO: use this to auto populate the map
    let theMenu;
    let containsCurrent = false;
    const counts = {
      locations: 0,
      zones: 0,
      maps: 0,
    };

    const isAdd = source.droppableId.includes("add");
    data.locations.forEach((location, i) => {
      counts.locations++;
      location.zones.forEach((zone, j) => {
        counts.zones++;
        zone.maps.forEach((map) => {
          counts.maps++;
          if (map._id === destination.droppableId) {
            let currentMenu = findMenu(draggableId);
            if (isAdd) currentMenu = getMenu(draggableId);
            containsCurrent = map?.menus?.find((menu) => menu?.menu?._id === currentMenu?.menu?._id);
            theMenu = currentMenu;
          }
        });
      });
    });

    if (containsCurrent) {
      toast("Menu already exists in this map");
      return;
    }

    let addToAll = false;

    let theCount = 0;
    data.locations?.[0].zones.forEach((zone) => {
      if (zone?._id === zoneDroppableId) {
        theCount = zone?.maps?.length;
      }
    });

    if (theCount > 1) {
      try {
        addToAll = await showConfirmationModal();
      } catch (error) {
        console.error(error);
      }
    }

    updateMenu(theMenu, destination, source, addToAll);
  };

  const updateLocation = (currentLocation, destination, source, isAdd) => {
    let stateCopy = { ...data };
    !isAdd && stateCopy.locations.splice(source.index, 1);
    stateCopy.locations.splice(destination.index, 0, currentLocation);
    setData(stateCopy);
  };

  const updateZone = (currentZone, destination, source) => {
    let stateCopy = { ...data };
    stateCopy.locations.forEach((location, i) => {
      if (location._id === source.droppableId) {
        location.zones.splice(source.index, 1);
      }
      if (location._id === destination.droppableId) {
        location.zones.splice(destination.index, 0, currentZone);
      }
      // if (!destination && !source) {
      // }
    });
    setData(stateCopy);
  };
  const updateMap = (currentMap, destination, source) => {
    let stateCopy = { ...data };
    stateCopy.locations.forEach((location, i) => {
      location.zones.forEach((zone, i) => {
        if (zone._id === source.droppableId) {
          zone.maps.splice(source.index, 1);
        }
        if (zone._id === destination.droppableId) {
          zone.maps.splice(destination.index, 0, currentMap);
        }
      });
    });
    setData(stateCopy);
  };
  const updateMenu = (currentMenu, destination, source, addToAll = false) => {
    const droppableElement = document.querySelector(`[data-rbd-droppable-id="${destination.droppableId}"]`);
    const zoneDroppableId = droppableElement.parentElement.parentElement.getAttribute("data-rbd-droppable-id");

    let stateCopy = { ...data };
    stateCopy.locations.forEach((location, i) => {
      location.zones.forEach((zone, i) => {
        zone.maps.forEach((map) => {
          // TODO: improve this by removing locations
          if (map._id === source.droppableId) {
            map.menus.splice(source.index, 1);
          }
          if (source?.droppableId.includes("add_menu")) {
            if (addToAll) {
              const found = map?.menus?.find((menu) => menu?.menu?._id === currentMenu?.menu?._id);
              if (found) return;
              if (zone._id === zoneDroppableId) {
                map.menus.splice(destination.index, 0, currentMenu);
              }
            } else {
              if (map._id === destination.droppableId) {
                map.menus.splice(destination.index, 0, currentMenu);
              }
            }
          } else {
            if (map._id === destination.droppableId) {
              map.menus.splice(destination.index, 0, currentMenu);
            }
          }
        });
      });
    });

    setData(stateCopy);
    updateMaps();
  };
  const findLocation = (id) => {
    return data.locations.find((location) => location._id === id);
  };

  const findZone = (id) => {
    let currentZone;
    data.locations.forEach((location) => {
      location.zones.forEach((zone) => {
        if (zone._id === id) {
          currentZone = zone;
        }
      });
    });
    return currentZone;
  };

  const findAndUpdateZone = (id, name) => {
    const temp = { ...data };

    temp.locations.forEach((location) => {
      location.zones.forEach((zone) => {
        if (zone._id === id) {
          zone.name = name;
        }
      });
    });
    setData(temp);
  };

  const findAndUpdateMap = (id, name) => {
    const temp = { ...data };

    temp.locations.forEach((location) => {
      location.zones.forEach((zone) => {
        zone.maps.forEach((map) => {
          if (map._id === id) {
            map.name = name;
          }
        });
      });
    });
    setData(temp);
  };

  const findAndUpdateLocation = (id, name) => {
    const temp = { ...data };

    temp.locations.forEach((location) => {
      if (location._id === id) {
        location.name = name;
      }
    });
    setData(temp);
  };

  const findAndDeleteLocation = (id, name) => {
    const temp = { ...data };
    temp.locations.forEach((location, index) => {
      if (location._id === id) {
        temp.locations.splice(index, 1);
      }
    });

    setData(temp);
    updateMaps();
  };

  const findAndDeleteZone = (id, loc_index) => {
    const temp = { ...data };

    temp.locations[loc_index].zones.forEach((zone, index) => {
      if (zone._id === id) {
        temp.locations[loc_index].zones.splice(index, 1);
      }
    });

    setData(temp);
    updateMaps();
  };

  const findAndDeleteMap = (id, zon_index, loc_index) => {
    const temp = { ...data };

    temp.locations[loc_index].zones[zon_index].maps.forEach((map, index) => {
      if (map._id === id) {
        temp.locations[loc_index].zones[zon_index].maps.splice(index, 1);
      }
    });

    setData(temp);
    updateMaps();
  };

  const findAndDeleteMenu = (id, map_index, zon_index, loc_index) => {
    const temp = { ...data };

    temp.locations[loc_index].zones[zon_index].maps[map_index].menus.forEach((menu, index) => {
      if (menu._id === id) {
        temp.locations[loc_index].zones[zon_index].maps[map_index].menus.splice(index, 1);
      }
    });

    setData(temp);
    updateMaps();
  };

  const findMap = (id) => {
    let currentMap;
    data.locations.forEach((location) => {
      location.zones.forEach((zone) => {
        zone.maps.forEach((map) => {
          if (map._id === id) {
            currentMap = map;
          }
        });
      });
    });
    return currentMap;
  };

  const findMenu = (id) => {
    let currentMenu;
    data.locations.forEach((location) => {
      location.zones.forEach((zone) => {
        zone.maps.forEach((map) => {
          map.menus.forEach((menu) => {
            if (menu._id === id) {
              currentMenu = menu;
            }
          });
        });
      });
    });
    return currentMenu;
  };

  const loadMaps = async () => {
    const response = await Api.call(Endpoint.maps.get);
    if (response?.message?.locations?.length > 0) {
      setData({ locations: response?.message?.locations });
    } else {
      setData(initialState);
    }
  };

  const updateMaps = async (rewrite) => {
    let tempData = { ...data };
    if (rewrite) tempData = { ...rewrite };
    tempData?.locations?.forEach((location) => {
      location?._id?.includes("-") && delete location?._id;
      location?.zones.forEach((zone) => {
        zone?._id?.includes("-") && delete zone?._id;
        zone?.maps.forEach((map) => {
          map?._id?.includes("-") && delete map?._id;
          map?.menus.forEach((menu) => {
            menu?._id?.includes("-") && delete menu?._id;
          });
        });
      });
    });

    const response = await Api.call(Endpoint.maps.post, { data: tempData });
    if (response?.message?.locations !== undefined) setData({ locations: response?.message?.locations });
  };

  useEffect(() => {
    Menu?.load();
    loadMaps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    //TODO: use this to auto populate the map
    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    switch (result.type) {
      case "location":
        handleLocations({ data, destination, source, draggableId });
        break;
      case "zone":
        handleZones({ data, destination, source, draggableId });
        break;
      case "map":
        handleMaps({ data, destination, source, draggableId });
        break;
      case "menu":
        handleMenus({ data, destination, source, draggableId });
        break;
      default:
        break;
    }

    updateMaps();
  };

  const showConfirmationModal = () => {
    return new Promise((resolve, reject) => {
      setModal({
        ...modal,
        title: "Add this menu to all maps on this zone?",
        visible: true,
        confirmation: true,
        Component: (
          <DeleteConfirmation
            {...modal}
            customMessage="Do you want to add this menu to all other menu displays in this zone?"
            customButtonMessage="Yes, add it"
            toastMessage="Menu has been added to all menu displays of the same zone"
            closeLabel="No, just this one"
            funk={() => {
              try {
                resolve(true);
              } catch (error) {
                reject(false);
              }
            }}
            closeModal={() => {
              setModal({ ...modal, visible: false });
              reject(false);
            }}
          />
        ),
      });
    });
  };

  return (
    <div>
      <Modal {...modal} />
      <DragDropContext onDragEnd={onDragEnd}>
        <section className={styles.mapActionsWrapper}>
          <div className={styles.mapToggleWrapper}>
            <button
              className={`${styles.toggleBtn} ${collapsedTypes ? styles.closed : ""}`}
              onClick={() => {
                setCollapsedTypes(!collapsedTypes);
              }}
            >
              <svg width="31" height="60" viewBox="0 0 31 60" fill="none">
                <g filter="url(#filter0_d_952_7048)">
                  <path
                    d="M-12 14.1598C-12 8.40932 -6.11416 4.537 -0.833364 6.81321L12.1666 12.4167C15.0999 13.681 17 16.5691 17 19.7632V38.2368C17 41.4309 15.0999 44.319 12.1666 45.5833L-0.833372 51.1868C-6.11417 53.463 -12 49.5907 -12 43.8402V14.1598Z"
                    fill="#dedede"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_952_7048"
                    x="-22"
                    y="-1.84888"
                    width="53"
                    height="69.6978"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2" dy="4" />
                    <feGaussianBlur stdDeviation="6" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_952_7048" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_952_7048" result="shape" />
                  </filter>
                </defs>
              </svg>

              <i className="fal fa-chevron-left" />
            </button>
          </div>
          <div className={`${styles.typesOffMaps} ${collapsedTypes && styles.collapsed}`}>
            <h5>Display Types</h5>
            <div className={styles.typesWrapper}>
              <Droppable droppableId="typesOffMapsWrapper" type="typesOffMapsWrapper" isDropDisabled={true}>
                {(provided) => (
                  <div className={styles.wrapper} {...provided.droppableProps} ref={provided.innerRef}>
                    {MapTypes && MapTypes?.map((mapType, index) => <MapAction maptype={mapType} maptype_index={index} key={index} />)}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className={styles.mapTypes}>
              <h5>Menus</h5>
              <Droppable droppableId="mapTypesWrapper add_menu" type="menu">
                {(provided) => (
                  <div className={styles.wrapper} {...provided.droppableProps} ref={provided.innerRef}>
                    {Menu && Menu?.menus?.map((menu, index) => <MapMenu menu={menu} menu_index={index} key={index} />)}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </section>
        <section className={`${styles.locationsWrapper} ${collapsedTypes && styles.collapsed}`}>
          {/* <button
            onClick={() => {
              updateMaps(initialState);
            }}
          >
            Reset maps
          </button> */}
          <Droppable droppableId="locationsWrapper" type="location">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="custom-droppable-element">
                {data &&
                  data?.locations?.map((location, index) => (
                    <Location
                      findAndUpdateLocation={findAndUpdateLocation}
                      findAndDeleteZone={findAndDeleteZone}
                      findAndDeleteLocation={findAndDeleteLocation}
                      findAndDeleteMap={findAndDeleteMap}
                      findAndUpdateZone={findAndUpdateZone}
                      findAndUpdateMap={findAndUpdateMap}
                      findAndDeleteMenu={findAndDeleteMenu}
                      findMap={findMap}
                      findMenu={findMenu}
                      location={location}
                      location_index={index}
                      updateMaps={updateMaps}
                      key={index}
                    />
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </section>
      </DragDropContext>
    </div>
  );
};

const mapStateToProps = (state) => ({
  Menu: state?.Menu,
});

export default connect(mapStateToProps)(withDashboardLayout(MapsSection));
