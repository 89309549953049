import React from "react";
import styles from "./ModalFooter.module.scss";
export default function ModalFooter({ children }) {
  return (
    <div>
      <div className={styles.decorationLine}></div>
      <div className={styles.parent}>{children}</div>
    </div>
  );
}
