import React, { useState, useEffect } from "react";

import { Form } from "react-bootstrap";
import SelectList from "../../../SelectList";

const STEPS = {
  SELECT_AN_RESTAURANT: 0,
  ADD_NEW_RESTAURANT: 1,
};

export default function AddOrSelectRestaurant({ errors, restaurants, setValue, getValues, register }) {
  const [restaurantSelected, setRestaurantSelected] = useState(getValues("restaurant_id") || null);

  const [step, setStep] = useState(getValues("restaurant_name") ? 1 : 0);

  useEffect(() => {
    if (step === STEPS.ADD_NEW_RESTAURANT) {
      register({ name: "username" }, { required: "Username cannot be empty" });
    }
    //eslint-disable-next-line
  }, [step]);
  useEffect(() => {
    setValue("restaurant_id", restaurantSelected);
    //eslint-disable-next-line
  }, [restaurantSelected]);

  const transformRestaurants = function () {
    const defaultOption = { value: -1, label: "Add new restaurant" };
    if (!restaurants || restaurants?.length === 0) {
      return [defaultOption];
    }
    let options = restaurants?.map((restaurant) => ({ label: restaurant?.name, value: restaurant?._id }));
    options.push(defaultOption);
    return options;
  };

  const handleOnChange = function (data) {
    if (data?.value === -1) {
      setRestaurantSelected(null);
      setStep(STEPS.ADD_NEW_RESTAURANT);

      return;
    }
    setRestaurantSelected(data);
  };
  return (
    <div>
      <div className="mb-4">
        {step === STEPS.SELECT_AN_RESTAURANT && (
          <>
            <SelectList
              placeholder="Select or add a new restaurant..."
              value={restaurantSelected}
              options={transformRestaurants()}
              onChange={(data) => handleOnChange(data)}
            />
            {errors?.restaurant_id?.message && <small className="form-error-text">{errors?.restaurant_id?.message}</small>}
          </>
        )}

        {step === STEPS.ADD_NEW_RESTAURANT && (
          <>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control
                defaultValue={getValues("username") || ""}
                onChange={(e) => setValue("username", e.target.value, { shouldValidate: true })}
                name="username"
                placeholder="Username"
              />
              {errors?.username?.message ? (
                <small className="form-error-text">{errors?.username?.message}</small>
              ) : (
                <small style={{ color: "var(--text-color)" }}>Username should be unique</small>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Restaurant name</Form.Label>

              <Form.Control
                defaultValue={getValues("restaurant_name") || ""}
                onChange={(e) => setValue("restaurant_name", e.target.value, { shouldValidate: true })}
                type="text"
                name="restaurant_name"
                placeholder="Restaurant name"
              />
              {errors?.restaurant_id?.message && <small className="form-error-text">You must provide a valid name</small>}
            </Form.Group>
          </>
        )}
      </div>
    </div>
  );
}
