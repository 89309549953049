import React, { useEffect, useState } from "react";
import Section from "../../components/Section/Section";
import Header from "../../components/Header";
import { Button } from "react-bootstrap";
import styles from "../../App.module.scss";
import { connect } from "react-redux";
import Statistics from "../../components/Forms/DashboardStatistics";
import { Api, Endpoint } from "../../api/index";
import Modal from "../../components/Modal";
import AddAdminForm from "../../components/Forms/AddAdminForm/AddAdminFrom";
import { toast } from "react-toastify";
import DeleteConfirmation from "../../components/Forms/DeleteConfirmation";
import RestaurantInformation from "./RestaurantInformation";
import AdminStyle from "./Admin.module.scss";
import { Cookies } from "react-cookie";
import DeleteUser from "../../components/Forms/DeleteUser";
import AddDMA from "./AddDMA";
import Table from "../../components/Table";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "../../components/Pagination";
import table_styles from "../../components/Table/Table.module.scss";
import CONSTANTS from "../../utils/constants";
import AddUserForm from "../../components/Forms/AddUserForm";
import ViewDMALicenses from "../../components/Forms/ViewDMALicenses";
import TopBar from "../../components/Header/TopBar";
import PageHeader from "../../components/Header/PageHeader";
import { IKImage } from "imagekitio-react";
import { Link } from "react-router-dom";

const cookies = new Cookies();

const defaultPaginationData = {
  page: 0,
  count: 0,
  limit: 2,
};

function Dashboard({ Auth }) {
  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const [users, setUsers] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [dmas, setDMAS] = useState([]);
  const [dmas_pending, setDMASPending] = useState([]);
  const [navCollapsed, setNavCollapsed] = useState(false);

  const [usersPagination, setUsersPagination] = useState({ ...defaultPaginationData });

  const [restaurantsPagination, setRestaurantsPagination] = useState({ ...defaultPaginationData });

  const [dmasPagination, setDmasPagination] = useState({ ...defaultPaginationData });

  const [loadingUserShapeShift, setLoadingUserShapeShift] = useState(-1);

  const toastId = React.useRef(null);

  const resendInvitation = async (admin) => {
    toastId.current = toast("The invitation has been sent successfully.");
    const response = await Api.call(Endpoint?.admin?.resendInvitation, { data: { _id: admin._id } });
    if (response?.confirmation === "Fail") {
      toast.dismiss(toastId.current);
      toast(response?.message);
    }
  };

  const deleteAdmin = async (admin) => {
    setModal({
      ...modal,
      visible: true,
      dialogClassName: null,
      title: "Delete admin",
      confirmation: true,
      Component: (
        <DeleteConfirmation
          id={admin?._id}
          type="Admin"
          funk={async (id) => {
            await Api.call(Endpoint?.admin?.delete, { params: [admin?._id] });
            loadAdmins();
            toast("Admin has been deleted successfully");
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const showRegisterDMAModal = () => {
    setModal({
      ...modal,
      visible: true,
      dialogClassName: null,
      title: "Add DMA",
      Component: <AddDMA closeModal={() => setModal({ ...modal, visible: false })} loadDMASPending={loadDMASPending} />,
    });
  };

  const shapeshift = async (user) => {
    setLoadingUserShapeShift(user?._id);

    const data = {
      _id: user._id,
      refreshToken: cookies.get("refreshToken"),
      email: user.email,
    };

    const response = await Auth.SHAPE_SHIFT(data, Endpoint.admin.shapeshift);
    if (!response || !response?.confirmation === "Success" || typeof response?.message !== "object") {
      toast(response?.message);
      setLoadingUserShapeShift(-1);
      return;
    }

    window.location.href = "/";
    setLoadingUserShapeShift(-1);
  };

  const loadUsers = async (query = {}) => {
    const response = await Api.call(Endpoint?.admin?.users, { query });
    if (response?.statusCode === 200) {
      setUsersPagination(response?.message?.pagination);
      setUsers(response?.message?.users);
    }
  };

  const loadRestaurants = async (query = {}) => {
    const response = await Api.call(Endpoint?.admin?.restaurants, { query });
    if (response?.statusCode === 200) {
      setRestaurantsPagination(response?.message?.pagination);
      setRestaurants(response?.message?.restaurants);
    }
  };

  const loadAdmins = async () => {
    if (Auth?.user?.role !== CONSTANTS.USER.ROLES.SUPER_ADMIN) return;
    const response = await Api.call(Endpoint?.admin?.load);
    if (response?.statusCode === 200) {
      setAdmins(response?.message);
    }
  };

  const loadStatistics = async function () {
    const response = await Api.call(Endpoint?.admin?.statistics);
    if (response?.statusCode === 200) {
      setStatistics(response?.message?.statistics);
    }
  };

  const loadDMAS = async function (query = {}) {
    const response = await Api.call(Endpoint?.admin?.DMA.list, { query });
    if (response?.statusCode === 200) {
      setDmasPagination(response?.message?.pagination);
      setDMAS(response?.message?.dmas);
    }
  };

  const loadDMASPending = async function () {
    const response = await Api.call(Endpoint?.admin?.DMA.list_pending);
    if (response?.statusCode === 200 && Array.isArray(response?.message)) {
      setDMASPending(response?.message || []);
    }
  };

  const init = async () => {
    handleResize();
    await loadStatistics();
    await loadDMAS();
    await loadDMASPending();
    await loadUsers();
    await loadAdmins();
    await loadRestaurants();
  };

  const handleResize = () => {
    setNavCollapsed(window?.innerWidth <= 1200);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const showDeleteUserModal = (user) => {
    setModal({
      ...modal,
      title: "Delete user",
      visible: true,
      Component: (
        <DeleteUser
          {...modal}
          warning_message="User data will be permanently deleted."
          user={user}
          submit={async () => {
            return await Api.call(Endpoint.admin.user.delete, { data: { _id: user._id } });
          }}
          onComplete={(response) => {
            if (response?.confirmation === "Success") {
              if (users?.length === 1 && usersPagination?.page > 0) {
                setUsersPagination({ ...usersPagination, page: usersPagination?.page - 1 });
                loadUsers({ page: usersPagination?.page - 1 });
              } else {
                loadUsers({ page: usersPagination?.page });
              }
            }
          }}
        />
      ),
    });
  };

  const showDeleteDMAModal = (user) => {
    setModal({
      ...modal,
      title: "Delete DMA",
      visible: true,
      Component: (
        <DeleteUser
          {...modal}
          warning_message="DMA data and all the clients will be permanently deleted. The DMA will be notified about the deletion. For security reasons, we will ask to confirm the password."
          user={user}
          requires_password
          submit={async (user, password) => {
            return await Api.call(Endpoint.admin.DMA.delete(user?._id), { data: { password } });
          }}
          onComplete={(response) => {
            if (response?.confirmation === "Success") {
              if (dmas?.length === 1 && dmasPagination?.page > 0) {
                setDmasPagination({ ...dmasPagination, page: dmasPagination?.page - 1 });
                loadDMAS({ page: dmasPagination?.page - 1 });
              } else {
                loadDMAS({ page: dmasPagination?.page });
              }
            }
          }}
        />
      ),
    });
  };

  const showCancelDMAInvitationModal = async (dma) => {
    setModal({
      ...modal,
      title: "Cancel invitation",
      visible: true,
      Component: (
        <DeleteUser
          {...modal}
          warning_message="Are you sure you want to cancel the invitation?"
          toast_complete_message="Invitation canceled successfully"
          submit={async () => {
            const response = await Api.call(Endpoint.admin.DMA.cancel_dma_invitation(dma?.token));
            await loadDMASPending();
            return response;
          }}
        />
      ),
    });
  };

  const viewLicenses = (row) => {
    setModal({
      ...modal,
      title: "View licenses",
      visible: true,
      Component: <ViewDMALicenses {...modal} dma={row} loadDMAS={loadDMAS} shapeshift={shapeshift} />,
    });
  };

  const columns = {
    pending_dmas: [
      {
        field: "Email",
        render: ({ row }) => <span>{row?.data?.email}</span>,
      },
      {
        field: "Added by",
        render: ({ row }) => <span>{row?.data?.added_by}</span>,
      },
      {
        field: "Actions",
        render: ({ row }) => (
          <i onClick={() => showCancelDMAInvitationModal(row)} style={{ color: "red", cursor: "pointer" }} className="far fa-trash-alt"></i>
        ),
      },
    ],
    dmas: [
      {
        field: "Email",
        access: "email",
      },
      {
        field: "First name",
        access: "firstName",
      },
      {
        field: "Last name",
        access: "lastName",
      },
      {
        field: "Username",
        access: "username",
      },
      {
        field: "Licenses",
        render: ({ row, column, index }) => (
          <div>
            <button className="btn btn-primary" onClick={() => viewLicenses(row)} style={{ padding: "4px", fontSize: "14px" }}>
              View licenses
            </button>
          </div>
        ),
      },
      {
        field: "Login as",
        render: ({ row, column, index }) => (
          <Button
            disabled={loadingUserShapeShift !== -1}
            onClick={async () => await shapeshift(row, index)}
            style={{ padding: "4px", fontSize: "14px" }}
          >
            {loadingUserShapeShift?.toString() === row?._id?.toString() ? <LoadingSpinner size="sm" /> : "Login"}
          </Button>
        ),
      },
      {
        field: "Actions",
        render: ({ row, column, index }) => (
          <i onClick={() => showDeleteDMAModal(row)} className={`${AdminStyle.deleteIcon} far fa-trash-alt`}></i>
        ),
      },
    ],
    users: [
      {
        field: "Email",
        access: "email",
      },
      {
        field: "First name",
        access: "firstName",
      },
      {
        field: "Last name",
        access: "lastName",
      },
      {
        field: "Username",
        access: "username",
      },
      {
        field: "Menu",
        render: ({ row, column, index }) => {
          return (
            <Link to={`/menu/${row?.restaurants?.[0]}`} target="_blank" style={{ color: "var(--brand)", padding: "4px", fontSize: "14px" }}>
              <i className="fal fa-book-open"></i>
            </Link>
          );
        },
      },
      {
        field: "Login as",
        render: ({ row, column, index }) => (
          <Button
            disabled={loadingUserShapeShift !== -1}
            onClick={async () => await shapeshift(row, index)}
            style={{ padding: "4px", fontSize: "14px" }}
          >
            {loadingUserShapeShift?.toString() === row?._id?.toString() ? <LoadingSpinner size="sm" /> : "Login"}
          </Button>
        ),
      },
      {
        field: "Actions",
        render: ({ row, column, index }) => (
          <i onClick={() => showDeleteUserModal(row)} className={`${AdminStyle.deleteIcon} far fa-trash-alt`}></i>
        ),
      },
    ],
    restaurants: [
      {
        field: "Name",
        access: "name",
      },
      {
        field: "Owner",
        render: ({ row }) => {
          return row?.user?.username;
        },
      },
      {
        field: "Logo",
        render: ({ row }) => {
          if (!row?.logo) {
            return null;
          }
          return (
            <IKImage
              path={row?.logo}
              style={{ borderRadius: "4px", objectFit: "cover" }}
              transformation={[{ width: 80, height: 50 }]}
              loading="lazy"
              lqip={{ active: true }}
            />
          );
        },
      },
      {
        field: "Actions",
        render: ({ row }) => {
          return (
            <Button
              onClick={() => {
                setModal({
                  ...modal,
                  dialogClassName: AdminStyle?.customModalStyle,
                  visible: true,
                  title: "Restaurant information",
                  Component: <RestaurantInformation restaurant={row} />,
                });
              }}
              style={{ padding: "4px", fontSize: "14px" }}
            >
              View more
            </Button>
          );
        },
      },
    ],
    admins: [
      {
        field: "First name",
        access: "firstName",
      },
      {
        field: "Last name",
        access: "lastName",
      },
      {
        field: "Username",
        access: "username",
      },
      {
        field: "Email",
        access: "email",
      },
      {
        field: "Verified",
        render: ({ row }) => {
          return (
            <div className={table_styles?.memberVerifiedWrapper}>
              <div>{row?.state ? "Yes" : "No"}</div>
              {!row?.state && (
                <div>
                  <i
                    onClick={() => {
                      resendInvitation(row);
                    }}
                    className="fas fa-sync"
                  ></i>
                </div>
              )}
            </div>
          );
        },
      },
      {
        field: "Actions",
        render: ({ row }) => {
          return (
            <div className={`actionsWrapper`}>
              <i
                onClick={() => {
                  deleteAdmin(row);
                }}
                className={`delete-icon far fa-trash-alt`}
              ></i>
            </div>
          );
        },
      },
      {
        field: "Login as",
        render: ({ row, index }) => {
          return (
            <Button
              className={table_styles?.viewMoreInformationButton}
              disabled={loadingUserShapeShift?.toString() === row?._id?.toString()}
              onClick={() => shapeshift(row, index)}
            >
              {loadingUserShapeShift?.toString() === row?._id?.toString() ? <LoadingSpinner size={"sm"} /> : "Log in"}
            </Button>
          );
        },
      },
    ],
  };

  const renderPagination = {
    users: {
      pagination: (
        <Pagination
          changePage={(_, page) => {
            setUsersPagination({ ...usersPagination, page: page });
            loadUsers({ page });
          }}
          currentPage={usersPagination?.page}
          totalPages={Math.ceil(usersPagination?.count / usersPagination?.limit)}
        />
      ),
    },
    restaurants: {
      pagination: (
        <Pagination
          changePage={(_, page) => {
            setRestaurantsPagination({ ...restaurantsPagination, page: page });
            loadRestaurants({ page });
          }}
          currentPage={restaurantsPagination?.page}
          totalPages={Math.ceil(restaurantsPagination?.count / restaurantsPagination?.limit)}
        />
      ),
    },
    dmas: {
      pagination: (
        <Pagination
          changePage={(_, page) => {
            setDmasPagination({ ...dmasPagination, page: page });
            loadDMAS({ page });
          }}
          currentPage={dmasPagination?.page}
          totalPages={Math.ceil(dmasPagination?.count / dmasPagination?.limit)}
        />
      ),
    },
  };

  return (
    <>
      <Modal {...modal} />
      <section className={styles.panel}>
        <TopBar />
        <Header navCollapsed={navCollapsed} setNavCollapsed={setNavCollapsed} />
        <div className={styles.mainWrapper}>
          <main>
            <div className="container">
              <PageHeader />
              <Statistics statistics={statistics} />
              <Section
                title={`DMA-S (${dmasPagination?.count})`}
                action={{
                  name: "Add DMA",
                  funk: showRegisterDMAModal,
                }}
              >
                <Table
                  columns={columns?.dmas}
                  rows={dmas}
                  paginationComponent={dmasPagination?.count > 0 ? renderPagination.dmas.pagination : null}
                />
              </Section>

              {dmas_pending?.length > 0 && (
                <Section title={`Pending DMA-S (${dmas_pending?.length})`}>
                  <Table columns={columns?.pending_dmas} rows={dmas_pending} enableLocalPagination />
                </Section>
              )}

              <Section
                title={`Users (${usersPagination?.count})`}
                action={{
                  name: "Add user",
                  funk: () =>
                    setModal({
                      ...modal,
                      dialogClassName: null,
                      title: "Add user",
                      visible: true,
                      Component: <AddUserForm {...modal} loadUsers={loadUsers} />,
                    }),
                }}
              >
                <Table
                  columns={columns?.users}
                  rows={users}
                  paginationComponent={usersPagination?.count > 0 ? renderPagination.users.pagination : null}
                />
              </Section>
              {Auth?.user?.role === CONSTANTS.USER.ROLES.SUPER_ADMIN && (
                <Section
                  title={`Admins (${admins?.length})`}
                  action={{
                    name: "Add admin",
                    funk: () =>
                      setModal({
                        ...modal,
                        dialogClassName: null,
                        title: "Add admin",
                        visible: true,
                        Component: <AddAdminForm loadAdmins={loadAdmins} {...modal} />,
                      }),
                  }}
                >
                  <Table columns={columns?.admins} rows={admins || []} enableLocalPagination={true} />
                </Section>
              )}
              <Section title={`Restaurants (${restaurantsPagination?.count})`}>
                <Table
                  columns={columns?.restaurants}
                  rows={restaurants}
                  paginationComponent={restaurantsPagination?.count > 0 ? renderPagination.restaurants.pagination : null}
                />
              </Section>
            </div>
          </main>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => ({ Auth: state?.Auth, Admin: state?.Admin });
export default connect(mapStateToProps)(Dashboard);
