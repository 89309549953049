import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import dashboardStyles from "../../App.module.scss";
import TabsView from "../../components/TabsView/TabsView";
import styles from "./Register.module.scss";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/LoadingSpinner";
import Helpers from "../../helpers/general";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import ReCaptchaComponent from "../../components/ReCaptcha";
import useReCaptcha from "../../hoc/useReCaptcha";

const { passwordRegex, passwordMessage } = Helpers.getPasswordRegex();

function Register({ Auth }) {
  const { reCaptchaRef, executeReCaptchaToken, onChangeReCaptcha } = useReCaptcha();

  const [loading, setLoading] = useState(false);
  const [accountVerified, setAccountVerified] = useState(false);
  const [error, setError] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("+1");

  const { register, handleSubmit, errors, watch, setValue } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {
    register({ name: "phoneNumber", value: phoneNumber });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue("phoneNumber", phoneNumber);
    //eslint-disable-next-line
  }, [phoneNumber]);

  const submit = async (data) => {
    setLoading(true);

    const reCaptchaToken = await executeReCaptchaToken();
    data.reCaptchaToken = reCaptchaToken;

    const response = await Auth.REGISTER(data);
    setLoading(false);

    if (response?.statusCode === 200) {
      setAccountVerified(true);
      setError(null);
    } else {
      setError(response?.message);
      setAccountVerified(false);
    }
  };

  const emailConfirmationAlreadySent = new URLSearchParams(window.location.search).get("email_confirmation_sent");

  return (
    <div className={styles.loginPageWrapper}>
      <div className={styles.pageContainer}>
        {accountVerified || emailConfirmationAlreadySent === "true" ? (
          <div className={styles.accountVerifiedSuccessfully}>
            <i className="fas fa-check fa-7x"></i>
            <p>We have sent you an email with an account confirmation link.</p>
            <p>Please open the link within 24 hours to finish creating your profile.</p>
          </div>
        ) : (
          <TabsView active="Register">
            <Form onSubmit={handleSubmit(submit)}>
              <ReCaptchaComponent innerRef={reCaptchaRef} onChange={onChangeReCaptcha} />

              <Form.Group>
                <h3 className={dashboardStyles.formTitle}>Register</h3>
                <p className="form-desc">Create an account with us.</p>
              </Form.Group>

              {error && <Alert variant="danger">{error}</Alert>}
              <Form.Group>
                <i className="fal fa-user"></i>
                <Form.Control ref={register} type="text" name="firstName" placeholder="First Name" />
              </Form.Group>
              <Form.Group>
                <i className="fal fa-user"></i>
                <Form.Control ref={register} type="text" name="lastName" placeholder="Last Name" />
              </Form.Group>
              <Form.Group>
                <PhoneInput inputStyle={{ width: "100%" }} country={"us"} value={phoneNumber} onChange={(phone) => setPhoneNumber(phone)} />
              </Form.Group>
              <Form.Group>
                <i className="fal fa-user"></i>
                <Form.Control
                  ref={register({ required: "Username cannot be empty" })}
                  type="text"
                  name="username"
                  placeholder="Username *"
                />
                {errors?.username?.message && <small className={styles.registerErrorText}>{errors?.username?.message}</small>}
              </Form.Group>
              <Form.Group>
                <i className="fal fa-envelope"></i>
                <Form.Control
                  ref={register({
                    required: "Email cannot be empty",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  type="email"
                  name="email"
                  placeholder="E-mail *"
                />
                {errors?.email?.message && <small className={styles.registerErrorText}>{errors?.email?.message}</small>}
              </Form.Group>
              <Form.Group>
                <i className="fal fa-lock"></i>
                <Form.Control
                  ref={register({ required: "Restaurant name cannot be empty" })}
                  type="text"
                  name="restaurantName"
                  placeholder="Restaurant Name *"
                />
                {errors?.restaurantName?.message && <small className={styles.registerErrorText}>{errors?.restaurantName?.message}</small>}
              </Form.Group>
              <Form.Group>
                <i className="fal fa-lock"></i>
                <Form.Control
                  ref={register({
                    required: "Password cannot be empty",
                    pattern: { value: passwordRegex, message: passwordMessage },
                  })}
                  type="password"
                  name="password"
                  placeholder="Password *"
                />
                {errors?.password?.message && <small className={styles.registerErrorText}>{errors?.password?.message}</small>}
              </Form.Group>
              <Form.Group>
                <i className="fal fa-lock"></i>
                <Form.Control
                  ref={register({
                    required: "Confirm password cannot be empty",
                    validate: (value) => value === password.current || "The password confirmation does not match.",
                  })}
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm Password *"
                />
                {errors?.confirm_password?.message && (
                  <small className={styles.registerErrorText}>{errors?.confirm_password?.message}</small>
                )}
              </Form.Group>

              <Form.Group className="d-flex justify-content-between">
                <Link to="/forgot-password" className="btn btn-secondary">
                  Forgot Password?
                </Link>
                <Button disabled={Object.keys(errors).length > 0 || loading ? true : false} type="submit">
                  {loading ? <LoadingSpinner size="sm" /> : "Register"}
                </Button>
              </Form.Group>
            </Form>
          </TabsView>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({ Auth: state.Auth });
export default connect(mapStateToProps)(Register);
