const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE);
const maxFileSizeErrorText = process.env.REACT_APP_MAX_FILE_SIZE_ERROR;

class File {
  constructor() {
    this.allowed_extensions = [".jpg", ".JPG", ".png", ".PNG", ".jpeg", ".JPEG", ".png", ".webp"];
  }
  validate(file) {
    if (!file || typeof file === "string") {
      return { fileError: null, success: true };
    }

    if (file?.size > maxFileSize) {
      return { fileError: `This file ${file?.name} is big. Maximum size for file is ${maxFileSizeErrorText}.`, success: false };
    }

    try {
      for (let i = 0; i < this.allowed_extensions.length; i++) {
        if (file?.name?.endsWith(this.allowed_extensions[i])) {
          return { fileError: null, success: true };
        }
      }
      return { fileError: "File not allowed", success: false };
    } catch (err) {
      return { fileError: "File not allowed", success: false };
    }
  }
}

export default new File();
