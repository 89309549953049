import { Api, Endpoint } from "../../api";
import { store } from "../connect/store";

export const CATEGORY_CREATED_SUCCESSFULLY = "CATEGORY_CREATED_SUCCESSFULLY";
export const CATEGORIES_LOADED_SUCCESSFULLY = "CATEGORIES_LOADED_SUCCESSFULLY";
export const CATEGORIES_RESET = "CATEGORIES_RESET";

export const methods = {
  create: async (data, selectedMenuID) => {
    const state = store.getState();
    const response = await Api.call(Endpoint.createCategory, { data });
    if (response?.confirmation === "Success") {
      store.dispatch({ type: CATEGORY_CREATED_SUCCESSFULLY, category: response?.message });
      if (selectedMenuID) {
        state?.Restaurant.find({ forceRender: true });
        state?.Menu.load({ forceRender: true });
      }
    }
    return response;
  },
  findById: async (id) => {
    return await Api.call(Endpoint.findCategoryById, { params: [id] });
  },
  update: async (id, data) => {
    const state = store.getState();
    let resp = await Api.call(Endpoint.updateCategory, { data, params: [id] });
    if (resp?.confirmation === "Success") {
      methods.load({ forceRender: true });
      state?.Restaurant.find({ forceRender: true });
      state?.Menu.load({ forceRender: true });
    }
    return resp;
  },
  delete: async (ids) => {
    const state = store.getState();
    const data = { ids };
    const response = await Api.call(Endpoint.deleteCategory, { data });
    if (response?.confirmation === "Success") {
      methods.load({ forceRender: true });
      state?.Restaurant.find({ forceRender: true });
      state?.Menu.load({ forceRender: true });
    }
    return response;
  },
  load: async (obj = false) => {
    const { Auth, Category } = store.getState();
    if (Auth?.IS_ADMIN()) return;

    const forceRender = typeof obj === "object" && obj.forceRender ? true : false;
    const options = {};

    if (obj?.selectedMenu) {
      options.query = { menu: obj?.selectedMenu };
    }

    if (forceRender) {
      const response = await Api.call(Endpoint.loadCategories, options);
      if (response?.confirmation === "Success") {
        if (!obj?.selectedMenu) store.dispatch({ type: CATEGORIES_LOADED_SUCCESSFULLY, categories: response?.message });
      }
      return response;
    }

    if (!Category?.categories?.length) return methods.load({ forceRender: true });
  },
  categoryQuickEdit: async (data) => {
    const state = store.getState();
    const response = await Api.call(Endpoint?.categoryQuickEdit, { data });
    if (response?.confirmation === "Success") {
      methods.load({ forceRender: true });
      state?.Restaurant.find({ forceRender: true });
      state?.Menu.load({ forceRender: true });
    }
    return response;
  },
  addDishes: async (categoryID, dishesID) => {
    const response = await Api.call(Endpoint.addDishesToCategory, { data: { categoryID: categoryID, dishesID: dishesID } });
    if (response?.confirmation === "Success") {
      methods.load({ forceRender: true });
      store.getState().Restaurant.find({ forceRender: true });
    }
    return response;
  },
  removeDish: async (categoryID, dishID) => {
    const response = await Api.call(Endpoint.removeDishFromCategory, { data: { categoryID: categoryID, dishID: dishID } });
    methods.load({ forceRender: true });
    store.getState().Restaurant.find({ forceRender: true });
    return response;
  },
  uploadCSV: async (data) => {
    const response = await Api.call(Endpoint.uploadCategoryCSV, { data, query: { csvFile: true } });
    if (response?.confirmation === "Success") {
      methods.load({ forceRender: true });
    }
    return response;
  },
};
