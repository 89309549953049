import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useReactToPrint } from "react-to-print";
import Tooltip from "@material-ui/core/Tooltip";
import { toast } from "react-toastify";
import { IKImage } from "imagekitio-react";

function MenuPrint({ Restaurant, Menu, Auth }) {
  const printArea = useRef(null);

  const { restaurant } = Restaurant;
  const { selected } = Menu;

  useEffect(() => {
    if (Auth?.LOGGED_IN()) {
      Menu?.load();
    }
    //eslint-disable-next-line
  }, []);

  const printMenu = (e) => {
    e.preventDefault();
    if (selected && Object.keys(selected).length !== 0) {
      handlePrint();
    } else {
      toast("Please select a menu!");
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printArea.current,
  });

  return (
    <div>
      <div className="menu-to-print" ref={printArea}>
        <section className="text-center" style={{ marginBottom: 60 }}>
          <h1>{restaurant?.name}</h1>
          <div dangerouslySetInnerHTML={{ __html: restaurant?.description }} />
          {restaurant?.logo && (
            <IKImage
              width="100"
              path={restaurant?.logo}
              transformation={[{ width: 300, height: 200 }]}
              lqip={{ active: true }}
              alt={restaurant.name}
            />
          )}
        </section>
        {selected?.categories &&
          selected?.categories.map((cat, index) => (
            <article key={`${cat?.category?._id}-${index}`} className="do-not-break" style={{ marginBottom: 60 }}>
              <ul className="list-unstyled" style={{ maxWidth: 500, margin: "0 auto" }}>
                <h3 className="text-center" style={{ marginBottom: 30 }}>
                  {cat?.category?.name}
                </h3>
                {cat?.category?.dishes &&
                  cat?.category?.dishes.map((dish) => (
                    <li
                      className="d-flex justify-content-between"
                      style={{ padding: 10, borderBottom: "solid 1px #ddd" }}
                      key={`${cat?.category?._id}-${index}-${dish?._id}`}
                    >
                      <div>
                        <div style={{ fontSize: 24, fontWeight: "bold" }}>{dish?.name}</div>
                        <div style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: dish?.description }} />
                      </div>
                      {dish?.price && <div style={{ marginTop: 5 }}>${dish?.price}</div>}
                    </li>
                  ))}
              </ul>
            </article>
          ))}
      </div>
      <Tooltip title="Click here to display a physical copy of your Foodi menu" enterTouchDelay={0}>
        {/* eslint-disable-next-line */}
        <a data-tour="nav-menu_print" onClick={printMenu} style={{ cursor: "pointer" }}>
          <i className="fal fa-print"></i>
        </a>
      </Tooltip>
    </div>
  );
}

const mapStateToProps = (state) => ({ Restaurant: state.Restaurant, Menu: state.Menu, Auth: state?.Auth });
export default connect(mapStateToProps)(MenuPrint);
