import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import LoadingSpinner from "../../LoadingSpinner";
import { toast } from "react-toastify";
import registerStyle from "../../../Views/Register/Register.module.scss";
import { Api, Endpoint } from "../../../api";

export default function AddAdminForm({ loadAdmins, closeModal }) {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const submit = async (data) => {
    setLoading(true);
    const response = await Api.call(Endpoint.admin.create, { data });
    if (response?.confirmation === "Fail") {
      setLoading(false);
      toast(response?.message);
      return;
    }
    loadAdmins();
    typeof closeModal === "function" && closeModal();
    setLoading(false);
    toast(response?.message);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(submit)}>
        <Form.Group>
          <Form.Label>Username</Form.Label>
          <Form.Control ref={register({ required: "Username cannot be empty" })} name="username" placeholder="Username" />
          {errors?.username?.message && <small className={registerStyle.registerErrorText}>{errors?.username?.message}</small>}
        </Form.Group>

        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            ref={register({
              required: "Email cannot be empty",
              pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address" },
            })}
            type="email"
            name="email"
            placeholder="Email"
          />
          {errors?.email?.message && <small className={registerStyle.registerErrorText}>{errors?.email?.message}</small>}
        </Form.Group>

        <Form.Group style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="submit" disabled={loading || Object.keys(errors).length > 0 ? true : false}>
            {loading ? <LoadingSpinner /> : "Submit"}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}
