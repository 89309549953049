import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import LoadingSpinner from "../../LoadingSpinner";
import ModalFooter from "../../Modal/ModalFooter";
import { Api, Endpoint } from "../../../api";
import { toast } from "react-toastify";

export default function EditDietary({
  name,
  closeModal,
  type,
  defaultValue,
  restaurantDietary,
  adminDietary,
  dietaryEditedSuccessfully,
  Dish,
}) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(name);
  const [error, setError] = useState(null);

  const dietaryExists = () => {
    const tempRestaurantDietary = { ...restaurantDietary };
    const tempAdminDietary = { ...adminDietary };

    const dataTypeAdmin = tempAdminDietary?.admin[type];
    const dataTypeRestaurant = tempRestaurantDietary[type];

    const existsAdminDietary = dataTypeAdmin.find(
      (el) => el?.name?.toString()?.toLowerCase()?.trim() === value?.toString()?.toLowerCase()?.trim()
    );
    const existsRestaurantDietary = dataTypeRestaurant.find(
      (el) => el?.toString()?.toLowerCase()?.trim() === value?.toString()?.toLowerCase()?.trim()
    );

    return existsAdminDietary || existsRestaurantDietary;
  };

  const submit = async () => {
    if (!value?.toString()?.trim()?.length) {
      setError("Dietary cannot be empty");
      return;
    }
    if (dietaryExists() && value?.toString() !== defaultValue?.toString()) {
      setError("Dietary already exists.");
      return;
    }
    setError(null);
    setLoading(true);
    const data = { name: value, type: type, defaultValue: defaultValue, dish_id: Dish?._id };
    const response = await Api.call(Endpoint?.updateDietary, { data });
    if (response?.confirmation === "Success") {
      setLoading(false);
      toast("Dietary updated successfully");
      dietaryEditedSuccessfully(value);
      typeof closeModal === "function" && closeModal();
      return;
    }
    setLoading(false);
    toast("Dietary failed to update");
    typeof closeModal === "function" && closeModal();
  };
  return (
    <div>
      <Form.Group>
        <Form.Label>Edit dietary</Form.Label>
        <Form.Control
          name="dietary"
          defaultValue={defaultValue}
          onChange={(e) => setValue(e?.target?.value)}
          placeholder="Edit dietary"
        />
        {error && <small style={{ color: "red" }}>{error}</small>}
      </Form.Group>
      <ModalFooter>
        <Button onClick={submit}>{loading ? <LoadingSpinner /> : `Edit`}</Button>
        {typeof closeModal === "function" && (
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        )}
      </ModalFooter>
    </div>
  );
}
