import React, { useState, useEffect } from "react";
import Section from "../../components/Section/Section";
import { connect } from "react-redux";
import Modal from "../../components/Modal";
import { AddMember } from ".";
import styles from "./Members.module.scss";
import DeleteConfirmation from "../../components/Forms/DeleteConfirmation";
import { Api, Endpoint } from "../../api";
import { toast } from "react-toastify";
import TableStyle from "../../components/Table/Table.module.scss";
import { Tooltip } from "@material-ui/core";
import Table from "../../components/Table";

import CONSTANTS from "../../utils/constants";

import withDashboardLayout from "../../Layouts/AdminPanel";

const { USER } = CONSTANTS;

function Members({ Auth, Restaurant }) {
  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    updateMember: null,
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const toastId = React.useRef(null);

  const [displayMembers, setDisplayMembers] = useState(null);
  const { members } = Restaurant;

  useEffect(() => {
    let membersObj = [];
    members.forEach((member) => {
      let permissions = member.permissions;
      delete permissions.admin;
      delete permissions.superAdmin;
      delete permissions.dma;
      delete permissions.dma_client;

      membersObj.push({
        ...member,
        permissionsLabel: Object.keys(permissions)
          .filter((el) => permissions[el])
          .join(", ")
          .toUpperCase(),
      });
    });

    setDisplayMembers(membersObj);
  }, [members]);

  const deleteMembers = (ids, name) => {
    let customButtonMessage = Array?.isArray(ids) ? `Delete ${ids?.length} ${ids?.length === 1 ? "member" : "members"}` : `Delete members`;
    let customMessage = `Are you sure you want to delete ${
      ids?.length <= 1 ? `${name ? name : "this member"}` : `${ids?.length} members`
    }?`;

    setModal({
      ...modal,
      visible: true,
      title: "Delete member",
      confirmation: true,
      Component: (
        <DeleteConfirmation
          type="Members"
          customButtonMessage={customButtonMessage}
          customMessage={customMessage}
          funk={async (id) => {
            const data = { ids };
            const response = await Api.call(Endpoint.deleteMember, { data });
            if (response?.confirmation === "Success") Restaurant.loadMembers({ forceRender: true });
            return response;
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const resendInvitation = async (member) => {
    toastId.current = toast("The invitation has been sent successfully.");
    const response = await Api.call(Endpoint.resendInvitation, { data: { member: member } });
    if (response?.confirmation === "Fail") {
      toast.dismiss(toastId.current);
      toast(response?.message);
    }
  };

  const columns = [
    {
      field: "First name",
      access: "firstName",
    },
    {
      field: "Last name",
      access: "lastName",
    },
    {
      field: "Permissions",
      access: "permissionsLabel",
    },
    Auth?.user?.role === USER.ROLES.RESTAURANT && {
      field: "Verified",
      render: ({ row, column, index }) => {
        return (
          <div className={TableStyle?.memberVerifiedWrapper}>
            <div>{row?.state ? "Yes" : "No"}</div>
            {!row?.state && (
              <div>
                <Tooltip title="Resend invitation" enterTouchDelay={0}>
                  <i className="fas fa-sync" onClick={() => resendInvitation(row)}></i>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "Actions",
      render: ({ row, column, index }) => (
        <div className={`actionsWrapper`}>
          <i
            onClick={() => {
              setModal({
                ...modal,
                visible: true,
                title: "Update member",
                Component: <AddMember Restaurant={Restaurant} {...modal} Auth={Auth} updateMember={row} />,
              });
            }}
            className={`edit-icon fal fa-edit`}
          ></i>
          <i
            onClick={() => {
              if (Array.isArray(row)) {
                deleteMembers(row);
                return;
              }
              deleteMembers([row?._id], row?.username);
            }}
            className={`delete-icon far fa-trash-alt`}
          ></i>
        </div>
      ),
    },
  ];

  useEffect(() => {
    Restaurant?.loadMembers();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Modal {...modal} dialogClassName={modal?.title === "Delete member" ? "" : styles?.customModalAddMember} />
      <Section
        title="Restaurant members"
        action={{
          name: "Add member",
          funk: () =>
            setModal({
              ...modal,
              visible: true,
              Component: <AddMember Auth={Auth} Restaurant={Restaurant} {...modal} updateMember={null} />,
              title: "Add member",
            }),
        }}
      >
        <Table
          columns={columns}
          rows={displayMembers || []}
          enableLocalPagination={true}
          enableSelectMultiple={true}
          onDeleteMultiple={(ids) => deleteMembers(ids)}
        />
      </Section>
    </>
  );
}

const mapStateToProps = (state) => ({ Auth: state?.Auth, Restaurant: state?.Restaurant });
export default connect(mapStateToProps)(withDashboardLayout(Members));
