import React from "react";
import withLayout from "../../Layouts/AdminPanel";
import Section from "../../components/Section";
import InteractionsDashboard from "../../components/InteractionsDashboard";

function MenuInteractions({ Auth }) {
  return (
    <Section title="Menu Interaction Tracker">
      <InteractionsDashboard />
    </Section>
  );
}

export default withLayout(MenuInteractions);
