import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";

import LoadingSpinner from "../../LoadingSpinner";

import Stripe from "../../../utils/stripe";

export default function AddPaymentMethods({ addPaymentMethod }) {
  const [loading, setLoading] = useState(false);
  const handleAddPaymentMethod = async function () {
    setLoading(true);
    const response = await addPaymentMethod({ url: window.location.href });
    setLoading(false);

    if (response.statusCode === 200 && response?.message?.url) {
      await Stripe.redirectToCheckout(response.message.id);
      return;
    }
  };
  return (
    <div className="mb-5">
      <Alert variant="danger">
        <Alert.Heading>Please add a payment method</Alert.Heading>

        <div className="ml-3">
          <li> You won’t be able to subscribe for licenses unless you add a payment method.</li>
          <li> You won’t be able to publish licenses menus unless you add a payment method and subscribe</li>
          <li>After you have added a payment method, you will be able to subscribe for multiple licenses at once.</li>
        </div>
        <hr />
        <button disabled={loading} className="btn btn-secondary" onClick={handleAddPaymentMethod}>
          {loading ? <LoadingSpinner size="sm" /> : "Add Payment Method"}
        </button>
      </Alert>
    </div>
  );
}
