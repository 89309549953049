import React, { useState, useRef, useEffect } from "react";
import { toPng, toJpeg, toSvg } from "html-to-image";
import Menu from "./Menu";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import DeleteItem from "./DeleteItem";
import EditButtonMap from "./EditButtonMap";
import CancelChanges from "./CancelChanges";
import QrCodeBtn from "./QrCodeBtn";
import Modal from "../../components/Modal";
import DeleteConfirmation from "../../components/Forms/DeleteConfirmation";
import EmbedMenuOptions from "../../components/RestaurantForm/EmbedMenuOptions";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import download from "downloadjs";
import { useReactToPrint } from "react-to-print";
import styles from "./Map.module.scss";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";
import QRTemplateComponent from "../../components/QrCode/QRTemplateComponent";

export default function Map({
  map,
  map_index,
  zone_index,
  location_index,
  findAndUpdateMap,
  findAndDeleteMap,
  findMenu,
  updateMaps,
  findAndDeleteMenu,
}) {
  const ref = useRef(null);
  const [isUpdating, setIsUpdating] = useState(true);
  const [clickedMap, setClickedMap] = useState(false);
  const [currentText, setCurrentText] = useState("");
  const [loadingDownload, setLoadingDownload] = useState(false);
  // const [restaurant, setRestaurant] = useState(null);
  const qrRef = useRef(null);
  const canvasRef = useRef(null);
  const advancedQrCode = useRef(null);

  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const handlePrint = useReactToPrint({
    content: () => canvasRef.current,
  });

  const appendCanvas = (selector) => {
    html2canvas(selector, {
      scale: 1,
      profile: true,
      useCORS: false,
      allowTaint: true,
    }).then((canvas) => {
      canvasRef.current.innerHTML = "";
      canvasRef.current.appendChild(canvas);
      handlePrint();
    });
  };

  const Auth = useSelector((state) => state?.Auth);
  const Restaurant = useSelector((state) => state?.Restaurant);

  useEffect(() => {
    (async () => {
      await Restaurant.find();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertToImage = async (selector, type) => {
    setLoadingDownload(true);

    switch (type) {
      case "png":
        toPng(selector, { imagePlaceholder: "" }).then((dataUrl) => {
          download(dataUrl, "qrcode.png");
          setLoadingDownload(false);
        });
        break;

      case "jpg":
        toJpeg(selector, { imagePlaceholder: "" }).then((dataUrl) => {
          download(dataUrl, "qrcode.jpeg");
          setLoadingDownload(false);
        });
        break;

      case "svg":
        toSvg(selector, { imagePlaceholder: "" }).then((dataUrl) => {
          download(dataUrl, "qrcode.svg");
          setLoadingDownload(false);
        });
        break;

      default:
        break;
    }
  };

  const {
    user: { restaurants },
  } = Auth;

  const { restaurant } = Restaurant;

  let mapURL = window.location.origin + "/menu/";

  if (typeof restaurants?.[0] === "string") {
    mapURL += restaurants?.[0];
  } else {
    mapURL += restaurants?.[0]?._id;
  }
  mapURL += `/${map?._id}`;

  const downloadPrint = () => {
    setClickedMap(false);
    setModal({
      ...modal,
      size: "lg",
      visible: true,
      title: "Download / Print",
      Component: (
        <QRTemplateComponent
          {...modal}
          action={downloadPrintActions}
          restaurant={restaurant}
          qrRef={qrRef}
          advancedQrCode={advancedQrCode}
          canvasRef={canvasRef}
          qrUrl={mapURL}
        />
      ),
    });
  };

  const showEmbedModal = () => {
    setClickedMap(false);
    setModal({
      ...modal,
      visible: true,
      title: "Embed Menu",
      Component: <EmbedMenuOptions {...modal} restaurantId={restaurants?.[0]._id} url={mapURL} />,
    });
  };

  const showConfirmationModal = (id) => {
    setModal({
      ...modal,
      title: "Delete content",
      visible: true,
      confirmation: true,
      Component: (
        <DeleteConfirmation
          {...modal}
          customMessage="Are you sure you want to delete this menu display"
          customButtonMessage="Delete"
          toastMessage="Menu Display has been deleted"
          funk={() => {
            findAndDeleteMap(id, zone_index, location_index);
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const updateItemHandler = () => {
    setIsUpdating(!isUpdating);
    setTimeout(() => {
      ref.current.focus();
      updateMaps();
    }, 100);
  };

  const cancelChanges = (e) => {
    setIsUpdating(!isUpdating);
    ref.current.value = currentText;
    setCurrentText("");
  };

  const deleteItem = (id) => {
    showConfirmationModal(id);
    setIsUpdating(!isUpdating);
  };

  const copyQrCodeUrl = () => {
    navigator.clipboard.writeText(mapURL).then(
      function () {
        toast("Menu URL has been copied successfully to clipboard");
      },
      function (err) {
        toast("Error copying Menu url to clipboard. Please try again later");
      }
    );
  };

  const qrShareActions = [
    // {
    //   name: "visit map link",
    //   funk: () => {
    //     setClickedMap(false);
    //     window.open(mapURL, "_blank");
    //   },
    // },
    {
      name: "Copy Menu URL",
      funk: copyQrCodeUrl,
    },
    {
      name: "Copy Embed Code",
      funk: showEmbedModal,
    },
    {
      name: "Download or Print",
      funk: downloadPrint,
    },
    // {
    //   name: "Download",
    //   loadingState: loadingDownload,
    //   dropdownItems: [
    //     {
    //       name: "Download template as .jpg",
    //       funk: () => convertToImage(qrRef.current, "jpg"),
    //     },
    //     {
    //       name: "Download QR Code as .svg",
    //       funk: () => convertToImage(advancedQrCode.current, "svg"),
    //     },
    //     {
    //       name: "Download QR Code as .png",
    //       funk: () => convertToImage(advancedQrCode.current, "png"),
    //     },
    //     {
    //       name: "Download QR Code as .jpg",
    //       funk: () => convertToImage(advancedQrCode.current, "jpg"),
    //     },
    //   ],
    // },
    // {
    //   name: "Print",
    //   dropdownItems: [
    //     {
    //       name: "Print template",
    //       funk: () => appendCanvas(qrRef.current),
    //     },
    //     {
    //       name: "Print Code",
    //       funk: () => appendCanvas(advancedQrCode.current),
    //     },
    //   ],
    // },
  ];

  const downloadPrintActions = [
    {
      name: "Download",
      loadingState: loadingDownload,
      dropdownItems: [
        {
          name: "Download template as .jpg",
          funk: () => convertToImage(qrRef.current, "jpg"),
        },
        {
          name: "Download QR Code as .svg",
          funk: () => convertToImage(advancedQrCode.current, "svg"),
        },
        {
          name: "Download QR Code as .png",
          funk: () => convertToImage(advancedQrCode.current, "png"),
        },
        {
          name: "Download QR Code as .jpg",
          funk: () => convertToImage(advancedQrCode.current, "jpg"),
        },
      ],
    },
    {
      name: "Print",
      dropdownItems: [
        {
          name: "Print template",
          funk: () => appendCanvas(qrRef.current),
        },
        {
          name: "Print Code",
          funk: () => appendCanvas(advancedQrCode.current),
        },
      ],
    },
  ];

  return (
    <>
      <Modal {...modal} />
      <Draggable draggableId={`${map?._id}`} key={map?._id} index={map_index}>
        {(provided) => (
          <div
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            ref={provided.innerRef}
            className={`${!isUpdating ? styles.active : " "} ${clickedMap ? styles.clicked : " "} ${styles.map}`}
          >
            <div className={styles.mapTitle}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setIsUpdating(true);
                  updateMaps();
                }}
              >
                <div className={styles.wrapper}>
                  <span style={{ fontSize: 12, whiteSpace: "nowrap" }} onClick={() => updateItemHandler(map?._id)}>
                    Menu Display
                  </span>
                  <span className="map-preview-title" onClick={() => updateItemHandler(map?._id)}>
                    {map?.name?.length > 0 ? map?.name : "Menu Display"}
                  </span>
                </div>
                <p>
                  <input
                    style={{ fontSize: 12, display: `${isUpdating ? "none" : "block"}` }}
                    type="text"
                    className="inputChangeTextMap"
                    suppressContentEditableWarning={true}
                    disabled={isUpdating}
                    ref={ref}
                    placeholder="Menu Display"
                    defaultValue={map?.name}
                    onChange={() => {
                      findAndUpdateMap(map?._id, ref.current.value);
                    }}
                  />
                </p>
              </form>
              <div className={styles.mapActions}>
                <div
                  className={`deleteItem ${!isUpdating ? styles.active : ""} ${styles.deleteItem}`}
                  onClick={(e) => {
                    e.preventDefault();
                    deleteItem(map?._id, ref.current.value);
                  }}
                >
                  <DeleteItem />
                </div>
                <div
                  className={`editButtonMap ${!isUpdating ? styles.editActive : ""} ${styles.editButtonMap}`}
                  onClick={() => updateItemHandler(map?._id)}
                >
                  <EditButtonMap color="#888888" mode={isUpdating} />
                </div>

                {map?.menus?.length > 0 && (
                  <div
                    className={`qrCodeBtn ${!isUpdating ? styles.qrCodeBtnActive : ""} ${styles.qrCodeBtn}`}
                    onClick={() => {
                      setClickedMap(!clickedMap);
                    }}
                  >
                    <QrCodeBtn color="#888888" />
                  </div>
                )}
                <div
                  className={`cancelChanges ${!isUpdating ? styles.active : ""} ${styles.cancelChanges}`}
                  onClick={(e) => {
                    e.preventDefault();
                    cancelChanges();
                  }}
                >
                  <CancelChanges />
                </div>
              </div>
            </div>
            <Droppable droppableId={`${map?._id}`} type="menu" direction="horizontal">
              {(provided) => (
                <div className={styles.menusWrapper} {...provided.droppableProps} ref={provided.innerRef}>
                  {map?.menus?.map((menu, index) => (
                    <Menu
                      findMenu={findMenu}
                      findAndDeleteMenu={findAndDeleteMenu}
                      key={index}
                      menu={menu}
                      menu_index={index}
                      map_index={map_index}
                      zone_index={zone_index}
                      location_index={location_index}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <div className={styles.mapEdit}>
              <div className={styles.EditButtons}>
                <div className={styles.mapActionButtons}>
                  {/*
                  <button
                    onClick={() => {
                      setClickedMap(false);
                      showEmbedModal();
                    }}
                    className={styles.buttonQR}
                  >
                    Download
                  </button>
                  
                  */}
                  {qrShareActions.map((act, i) => (
                    <React.Fragment key={i}>
                      {act?.dropdownItems && act?.dropdownItems?.length > 0 ? (
                        <DropdownButton
                          className={styles.buttonQR}
                          title={
                            act?.loadingState ? (
                              <span className={styles.downloadBtn}>
                                <LoadingSpinner size="sm" />
                              </span>
                            ) : (
                              <span className={styles.downloadBtn}>{act?.name}</span>
                            )
                          }
                        >
                          {act?.dropdownItems?.map((item, i) => (
                            <Dropdown.Item
                              key={i}
                              onClick={() => {
                                item.funk();
                              }}
                            >
                              {item?.name}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      ) : (
                        <Button art={act?.state?.toString()} variant="secondary" key={i} onClick={act.funk} className={styles.buttonQR}>
                          {act.name}
                        </Button>
                      )}
                    </React.Fragment>
                  ))}

                  <a
                    href="/settings#qr-code"
                    onClick={() => {
                      setClickedMap(false);
                    }}
                    className={styles.buttonQR}
                  >
                    CUSTOMIZE QR
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </Draggable>
      <></>
    </>
  );
}
