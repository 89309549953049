const initialCustomization = () => {
  return {
    name: "",
    isCustom: true,
    isFixedPrice: true,
    isAvailable: true,
    options: [
      {
        name: "",
        price: 0,
        ingredient: null,
        isAvailable: true,
      },
    ],
  };
};

const initialCustomizationOptions = () => {
  return {
    name: "",
    price: 0,
    ingredient: null,
    isAvailable: true,
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  initialCustomization: initialCustomization,
  initialCustomizationOptions: initialCustomizationOptions,
  dish: {
    customizations: function () {
      return {
        selected: [],
        custom: [],
      };
    },
  },
};
