import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import LoadingSpinner from "../../LoadingSpinner";
import { toast } from "react-toastify";
import registerStyle from "../../../Views/Register/Register.module.scss";
import ReCaptchaComponent from "../../../components/ReCaptcha";
import useReCaptcha from "../../../hoc/useReCaptcha";
import SelectList from "../../SelectList";
import { Tooltip } from "@material-ui/core";
import CONSTANTS from "../../../utils/constants";

const {
  RESTAURANT: { TYPES },
} = CONSTANTS;

function AddRestaurantForm({ Auth, Restaurant }) {
  const { reCaptchaRef, executeReCaptchaToken, onChangeReCaptcha } = useReCaptcha();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [isLocation, setIsLocation] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [dynamicLabels, setDynamicLabels] = useState();

  // const { restaurant } = Restaurant;
  const restaurant = Auth?.user?.restaurants?.[0];

  const submit = async (data) => {
    setLoading(true);

    const reCaptchaToken = await executeReCaptchaToken();
    data.reCaptchaToken = reCaptchaToken;

    if (isLocation) {
      data.restaurant = selectedLocation?.value;
    }

    const response = await Auth.ADD_RESTAURANT(data);
    if (!response || response?.confirmation === "Fail") {
      setLoading(false);
      toast(response?.message);
      return;
    }
    setLoading(false);
    window.location.href = "/";
  };

  const handleLocationToggle = (e) => {
    setIsLocation(!isLocation);
  };

  const transformAccounts = (accounts) => {
    if (accounts?.length === 0) return [];
    const selectOptions = [];
    accounts.forEach((account) => {
      if (account?.restaurants?.[0]?.parentRestaurant) return;

      selectOptions.push({
        value: account?.restaurants?.[0],
        label: account?.username,
      });
    });
    return selectOptions;
  };

  useEffect(() => {
    const restaurantType = TYPES[restaurant?.restaurantType?.toUpperCase()]?.toUpperCase();
    const restaurantRadioLabel = restaurantType === "RESTAURANT" ? "New Location No Menus" : "New Food Truck No Menus";
    const submitLabel = restaurantType === "RESTAURANT" ? "Add Restaurant" : "Add Food Truck";
    const locationRadioLabel = restaurantType === "RESTAURANT" ? "New Location copy all menus" : "New Food truck copy all menus";
    const tooltipLabel =
      restaurantType === "RESTAURANT"
        ? "Choose if you want to add an independent restaurant, or a new location for your existing restaurant"
        : "Choose if you want to add an standalone foot truck, or a truck that can inherit their items from your existing one";

    setDynamicLabels({
      restaurantType,
      restaurantRadioLabel,
      locationRadioLabel,
      tooltipLabel,
      submitLabel,
    });
  }, [restaurant]);

  return (
    <div style={{ width: 600, maxWidth: "100%", margin: "auto" }}>
      <ReCaptchaComponent innerRef={reCaptchaRef} onChange={onChangeReCaptcha} />
      <Form onSubmit={handleSubmit(submit)}>
        <Form.Group>
          <Form.Check
            defaultChecked={true}
            name="isLocation"
            inline
            label={dynamicLabels?.restaurantRadioLabel}
            onChange={handleLocationToggle}
            type="radio"
            ref={register}
          />
          <Form.Check
            name="isLocation"
            inline
            label={dynamicLabels?.locationRadioLabel}
            onChange={handleLocationToggle}
            type="radio"
            ref={register}
          />
          <div style={{ position: "absolute", color: "black", width: 50, height: 50, top: 0, right: 0 }}>
            <Tooltip title={dynamicLabels?.tooltipLabel || ""} placement="left" enterTouchDelay={0}>
              <i style={{ color: "black" }} className={`fal fa-info-circle`} />
            </Tooltip>
          </div>
        </Form.Group>

        {isLocation && (
          <Form.Group>
            <Form.Label>{dynamicLabels?.restaurantType ? dynamicLabels?.restaurantType : "Restaurant"}s</Form.Label>
            <SelectList
              isMulti={false}
              value={selectedLocation}
              options={transformAccounts(Auth?.user?.accounts)}
              onChange={(data) => {
                setSelectedLocation(data);
              }}
            />
          </Form.Group>
        )}

        <Form.Group>
          <Form.Label>Username</Form.Label>
          <Form.Control ref={register({ required: "Username cannot be empty" })} name="username" placeholder="Username" />
          {errors?.username?.message && <small className={registerStyle.registerErrorText}>{errors?.username?.message}</small>}
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {dynamicLabels?.restaurantType === "RESTAURANT" ? (isLocation ? "Location" : "Restaurant") : "New Food Truck"} name
          </Form.Label>
          <Form.Control
            ref={register({ required: "Restaurant name cannot be empty" })}
            name="restaurantName"
            placeholder={`${
              dynamicLabels?.restaurantType === "RESTAURANT" ? (isLocation ? "Location" : "Restaurant") : "New Food Truck"
            } name`}
          />
          {errors?.restaurantName?.message && <small className={registerStyle.registerErrorText}>{errors?.restaurantName?.message}</small>}
        </Form.Group>
        <Form.Group>
          <small>
            <b>Note:</b> Newly added {dynamicLabels?.restaurantType ? dynamicLabels?.restaurantType?.toLowerCase() : "restaurant"} will use
            the same password as the current account!
          </small>
        </Form.Group>

        <Form.Group style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="submit" disabled={loading || Object.keys(errors).length > 0 ? true : false}>
            {loading ? <LoadingSpinner size="sm" /> : <>{dynamicLabels?.submitLabel}</>}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => ({ Auth: state?.Auth, Restaurant: state.Restaurant });
export default connect(mapStateToProps)(AddRestaurantForm);
