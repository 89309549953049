import React, { useEffect, useState } from "react";
import SelectPaymentMethod from "./SelectPaymentMethod";
import ChoosePlan from "./ChoosePlan";
import PromotionCode from "./PromotionCode";
import Complete from "./Complete";

import LoadingSpinner from "../../../LoadingSpinner";

import { Form, Button, Alert } from "react-bootstrap";

import Stepper from "../AddClientStepper";
import ProcessCompleted from "./ProcessCompleted";

import { Api, Endpoint } from "../../../../api/index";

import usePrevious from "../../../../hoc/usePrevious";

const STEPS = {
  CHOOSE_PLAN: 0,
  PAYMENT_METHOD: 1,
  PROMOTION_CODE: 2,
  COMPLETE: 3,
};

export default function Main({
  paymentMethods,
  unSubscribedClients,
  productPrices: plans,
  loadClientsSubscriptionsStatus,
  updatePlans,
  closeModal,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const [plansLoading, setPlansLoading] = useState(false);

  const [productPrices, setProductPrices] = useState(plans && typeof plans === "object" ? { ...plans } : {});

  const filterPaymentMethods = function () {
    if (!paymentMethods?.data) return {};
    const temp = { ...paymentMethods };

    const filter = function (payment) {
      if (unSubscribedClients?.length > 1) {
        return !payment?.metadata?.client_id;
      }
      return !payment?.metadata?.client_id || payment?.metadata?.client_id?.toString() === unSubscribedClients?.[0]?.toString();
    };

    temp.data = paymentMethods.data.filter(filter);

    return temp;
  };

  paymentMethods = filterPaymentMethods();

  const [planSelected, setPlanSelected] = useState(null);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(null);

  const previousPaymentMethodSelected = usePrevious(paymentMethodSelected);

  const [promotionCode, setPromotionCode] = useState("");
  const [promotionCodeResponse, setPromotionCodeResponse] = useState(null);

  const [error, setError] = useState(null);

  const [processCompleted, setProcessCompleted] = useState(false);
  const [processCompletedResponse, setProcessCompletedResponse] = useState({
    fail: [],
    success: [],
  });

  const steps = ["Choose plan", "Payment Method", "Promotion Code", "Complete"];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const checkPromotionCode = async (promo_code) => {
    const response = await Api.call(Endpoint.DMA.check_promo_code, { data: { promo_code, price_id: planSelected } });
    setPromotionCodeResponse(response?.message);
    return response?.message;
  };

  const isCardForSpecificClient = (paymentMethodSelected) => {
    const payment = paymentMethods?.data?.find((payment) => payment?.id === paymentMethodSelected);

    if (payment?.metadata?.client_id && payment?.metadata?.dma_id) {
      return true;
    }
    return false;
  };

  const checkForErrors = async function () {
    if (activeStep === STEPS.CHOOSE_PLAN) {
      if (!planSelected) {
        setError("Please select a plan");
        return false;
      }
    }

    if (activeStep === STEPS.PAYMENT_METHOD) {
      if (!paymentMethodSelected) {
        setError("Please select a payment method");
        return false;
      }

      if (isCardForSpecificClient(paymentMethodSelected)) {
        setLoading(true);

        const plans = await updatePlans();
        setProductPrices(plans);
        setPlanSelected(null);

        setLoading(false);
      }
    }

    if (activeStep === STEPS.PROMOTION_CODE) {
      if (!promotionCode) {
        setError("Please enter a promotion code. If you don't have one, you can skip this step by clicking on the skip button.");
        return false;
      }
      setLoading(true);
      const response = await checkPromotionCode(promotionCode);
      setLoading(false);
      if (!response) {
        setError("This code is invalid.");
        return false;
      }
    }

    return true;
  };

  const closeProcessStep = function () {
    closeModal();
  };

  const submit = async (e) => {
    e?.preventDefault();

    if (!(await checkForErrors())) {
      return;
    }

    setError(null);

    const data = {
      price_id: planSelected,
      default_payment_method: paymentMethodSelected,
      promo_code: promotionCode,
      clients: unSubscribedClients,
    };

    if (activeStep !== STEPS.COMPLETE) {
      handleNext();
      return;
    }

    setLoading(true);

    const response = await Api.call(Endpoint.DMA.clients.create_subscription, { data });

    if (response?.statusCode !== 200) {
      setError(response?.message);
      setLoading(false);
      return;
    }

    await loadClientsSubscriptionsStatus();
    setLoading(false);

    setProcessCompletedResponse(response?.message);
    setProcessCompleted(true);
  };

  useEffect(() => {
    if (isCardForSpecificClient(paymentMethodSelected)) {
      if (activeStep === STEPS.COMPLETE) {
        setPlanSelected(null);

        setPlansLoading(true);
        updatePlans()?.then((result) => {
          setProductPrices(result);
          setPlansLoading(false);
        });
      }
    } else {
      setPlansLoading(true);
      setProductPrices(plans);
      activeStep === STEPS.COMPLETE && isCardForSpecificClient(previousPaymentMethodSelected) && setPlanSelected(null);

      setTimeout(() => setPlansLoading(false), 400);
    }
    //eslint-disable-next-line
  }, [paymentMethodSelected]);

  if (processCompleted) {
    return (
      <div>
        <div>
          <ProcessCompleted processCompletedResponse={processCompletedResponse} />
        </div>
        <div>
          <Form.Group style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="secondary" onClick={() => closeProcessStep()}>
              Close
            </Button>
          </Form.Group>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Form onSubmit={submit}>
        <Stepper
          activeStep={activeStep}
          steps={steps}
          handleBack={handleBack}
          handleNext={handleNext}
          handleReset={handleReset}
        />

        {error && <Alert variant="danger">{error}</Alert>}

        {activeStep === STEPS.CHOOSE_PLAN && (
          <ChoosePlan
            plansLoading={plansLoading}
            plans={productPrices}
            planSelected={planSelected}
            setPlanSelected={setPlanSelected}
          />
        )}
        {activeStep === STEPS.PAYMENT_METHOD && (
          <SelectPaymentMethod
            paymentMethodSelected={paymentMethodSelected}
            setPaymentMethodSelected={setPaymentMethodSelected}
            paymentMethods={paymentMethods}
          />
        )}

        {activeStep === STEPS.PROMOTION_CODE && (
          <PromotionCode promotionCode={promotionCode} setPromotionCode={setPromotionCode} />
        )}

        {activeStep === STEPS.COMPLETE && (
          <Complete
            paymentMethodSelected={paymentMethodSelected}
            setPaymentMethodSelected={setPaymentMethodSelected}
            paymentMethods={paymentMethods}
            planSelected={planSelected}
            setPlanSelected={setPlanSelected}
            promotionCode={promotionCode}
            productPrices={productPrices}
            promotionCodeResponse={promotionCodeResponse}
            plansLoading={plansLoading}
          />
        )}

        <Form.Group style={{ display: "flex", justifyContent: "flex-end" }}>
          {activeStep > 0 && (
            <Button disabled={loading} type="button" onClick={handleBack} variant="secondary" style={{ marginRight: "10px" }}>
              Back
            </Button>
          )}
          {activeStep === STEPS.PROMOTION_CODE && (
            <Button
              disabled={loading}
              type="button"
              onClick={() => {
                setPromotionCode("");
                setError(null);
                handleNext();
              }}
              variant="secondary"
              style={{ marginRight: "10px" }}
            >
              Skip
            </Button>
          )}
          <Button disabled={loading} type="submit">
            {loading ? <LoadingSpinner size="sm" /> : STEPS.COMPLETE === activeStep ? "Submit" : "Next"}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}
