class ReCaptcha {
  async executeAsync(recaptchaRef) {
    try {
      const token = await recaptchaRef.current.executeAsync();
      return token;
    } catch (err) {
      return null;
    }
  }
  reset(recaptchaRef) {
    try {
      return recaptchaRef.current.reset();
    } catch (err) {
      return null;
    }
  }
}

export default new ReCaptcha();
