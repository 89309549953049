import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { Api, Endpoint } from "../../../api/index";
import DeleteConfirmation from "../DeleteConfirmation";
import Modal from "../../Modal";

export default function DietaryConsiderationsForm() {
  const [dietaryConsiderations, setDietaryConsiderations] = useState({ diets: [], foodTypes: [], other: [] });
  const [originalDietaryConsiderations, setOriginalDietaryConsiderations] = useState({ diets: [], foodTypes: [], other: [] });

  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    (async () => {
      const response = await Api.call(Endpoint?.admin?.dietary?.read);
      if (response?.confirmation === "Success" && response?.message?.dietaryConsiderations) {
        if (typeof response?.message?.dietaryConsiderations === "object") {
          let data = { diets: [], foodTypes: [], other: [] };
          Object.keys(response?.message?.dietaryConsiderations).forEach((key) => {
            data[key] = response?.message?.dietaryConsiderations[key]?.map((el) => el?.name);
          });
          setOriginalDietaryConsiderations(response?.message?.dietaryConsiderations);
          setDietaryConsiderations(data);
        }
      }
    })();
    register({ name: "dietaryConsiderations", value: dietaryConsiderations });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue("dietaryConsiderations", dietaryConsiderations);
    //eslint-disable-next-line
  }, [dietaryConsiderations]);

  const showConfirmationModal = (data) => {
    setModal({
      ...modal,
      title: "Save changes",
      visible: true,
      confirmation: true,
      Component: (
        <DeleteConfirmation
          {...modal}
          customMessage="Are you sure you want to save changes?"
          customButtonMessage="Save changes"
          toastMessage="Changes have been saved successfully"
          funk={async (id) => {
            return await Api.call(Endpoint?.admin?.dietary?.create, { data });
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const submit = async (data) => {
    data.originalDietaryConsiderations = originalDietaryConsiderations;
    showConfirmationModal(data);
  };

  const onTagDeleteAndCreate = (key, val) => {
    val = val[0];
    let temp = { ...originalDietaryConsiderations };
    let exist = temp[key].findIndex((el) => el?.name?.toString() === val?.toString());
    if (exist === -1) {
      temp[key].push({ name: val });
    } else {
      temp[key] = temp[key].filter((el) => el?.name?.toString() !== val);
    }
    setOriginalDietaryConsiderations(temp);
  };

  return (
    <div>
      <Modal {...modal} />
      <Form onSubmit={handleSubmit(submit)}>
        <Form.Group>
          <Form.Label>Diets</Form.Label>
          <TagsInput
            onlyUnique={true}
            inputProps={{ placeholder: "Add diets" }}
            value={dietaryConsiderations?.diets}
            onChange={(tags, changed) => {
              onTagDeleteAndCreate("diets", changed);
              setDietaryConsiderations({ ...dietaryConsiderations, diets: tags });
            }}
          />
          <div className="d-flex justify-content-between">
            <small style={{ color: "rgb(149, 144, 144)" }}>Add multiple diets by hitting Tab, Enter, or Return after each diet.</small>
            <small style={{ color: "rgb(149, 144, 144)" }}>Duplicates will be ignored.</small>
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label>Food Types</Form.Label>
          <TagsInput
            onlyUnique={true}
            inputProps={{ placeholder: "Add food types" }}
            value={dietaryConsiderations?.foodTypes}
            onChange={(tags, changed) => {
              onTagDeleteAndCreate("foodTypes", changed);
              setDietaryConsiderations({ ...dietaryConsiderations, foodTypes: tags });
            }}
          />
          <div className="d-flex justify-content-between">
            <small style={{ color: "rgb(149, 144, 144)" }}>
              Add multiple food types by hitting Tab, Enter, or Return after each food type.
            </small>
            <small style={{ color: "rgb(149, 144, 144)" }}>Duplicates will be ignored.</small>
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label>Other</Form.Label>
          <TagsInput
            onlyUnique={true}
            inputProps={{ placeholder: "Add other" }}
            value={dietaryConsiderations?.other}
            onChange={(tags, changed) => {
              onTagDeleteAndCreate("other", changed);
              setDietaryConsiderations({ ...dietaryConsiderations, other: tags });
            }}
          />
          <div className="d-flex justify-content-between">
            <small style={{ color: "rgb(149, 144, 144)" }}>
              Add other dietary considerations by hitting Tab, Enter, or Return after each dietary consideration.
            </small>
            <small style={{ color: "rgb(149, 144, 144)" }}>Duplicates will be ignored.</small>
          </div>
        </Form.Group>
        <Form.Group style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="submit">Submit</Button>
        </Form.Group>
      </Form>
    </div>
  );
}
