import React, { useState, useEffect } from "react";
import { Api, Endpoint } from "../api";
import { useRouteMatch } from "react-router-dom";
import styles from "../Templates/Classic Template/SingleMenuPage/SingleMenuPage.module.scss";
import LoadingSpinner from "../components/LoadingSpinner";
import backimg from "../assets/img/placeholder_food.png";

const withMenuSingle = (BaseComponent) => (props) => {
  const [loading, setLoading] = useState(true);
  const [dishNotFound, setDishNotFound] = useState(false);
  const [data, setData] = useState({});
  const [dishId, setDishId] = useState(null);
  const [menusTimeline, setMenusTimeline] = useState(null);
  const match = useRouteMatch("/menu/:restaurantId/dish/:dishId/menuSelected/:menuId");

  useEffect(() => {
    if (getMatchField("dishId") !== dishId) {
      setDishId(getMatchField("dishId"));
    }
    //eslint-disable-next-line
  }, [match]);

  useEffect(() => {
    if (dishId) getData();
    //eslint-disable-next-line
  }, [dishId]);

  const getMatchField = (field) => {
    if (match?.params[field]) return match?.params[field];
  };

  const getData = async () => {
    setLoading(true);
    const params = [getMatchField("restaurantId"), "menu", getMatchField("menuId"), "dish", getMatchField("dishId")];
    const response = await Api.call(Endpoint.customerDish, { params });
    if (
      !response ||
      response?.confirmation === "Fail" ||
      !response?.message ||
      !response?.message?.menu ||
      !response?.message?.restaurant ||
      !response?.message?.dish
    ) {
      setLoading(false);
      setDishNotFound(true);
      return;
    }
    if (response?.confirmation === "Success") {
      let theData = response?.message;
      adminSocialFallback(theData);
    }
    setMenusTimeline(response?.message?.menusTimeline);
    setLoading(false);
  };

  const adminSocialFallback = (data) => {
    const adminSocials = [
      { media: "facebook", icon: "fab fa-facebook-f", url: process.env.REACT_APP_FOODI_FB },
      { media: "instagram", icon: "fab fa-instagram", url: process.env.REACT_APP_FOODI_IN },
      { media: "twitter", icon: "fab fa-twitter", url: process.env.REACT_APP_FOODI_TW },
      { media: "yelp", icon: "fab fa-yelp", url: process.env.REACT_APP_FOODI_YE },
      { media: "website", icon: "fab fa-web", url: process.env.REACT_APP_FOODI_WEB },
    ];

    if (data && data?.restaurant && data?.restaurant?.social) {
      adminSocials.forEach((adminMedia) => {
        data?.restaurant?.social.forEach((media) => {
          if (adminMedia.media === media.media && media.url !== "") {
            adminMedia.url = media.url;
          }
        });
      });
      const tempData = { ...data };
      tempData.restaurant.social = adminSocials;
      setData(tempData);
    }
  };

  const canRenderIngredients = () => {
    const dish = data?.dish;
    if (dish?.ingredients && dish?.ingredients?.length > 0) {
      return true;
    }
    return false;
  };

  const canRenderDietary = () => {
    const dish = data?.dish;

    if (!dish?.dietaryConsiderations || !dish?.dietaryConsiderations?.admin || !dish?.dietaryConsiderations?.selected) {
      return false;
    }

    let renderDietary = false;

    const adminDietaryKeys = Object?.keys(dish?.dietaryConsiderations?.admin);
    const customAddedDietaryKeys = Object?.keys(dish?.dietaryConsiderations?.selected);

    for (const key of adminDietaryKeys) {
      if (dish?.dietaryConsiderations?.admin?.[key]?.length > 0) {
        renderDietary = true;
        break;
      }
    }

    if (!renderDietary) {
      for (const key of customAddedDietaryKeys) {
        if (dish?.dietaryConsiderations?.selected?.[key]?.length > 0) {
          renderDietary = true;
          break;
        }
      }
    }

    return renderDietary;
  };

  if (loading) {
    return (
      <div className={styles?.centeredWrapper}>
        <LoadingSpinner />
        <p>Please wait...</p>
      </div>
    );
  } else if (dishNotFound) {
    return (
      <div className={styles?.centeredWrapper}>
        <img src={backimg} alt="" />
        <h3>Dish not found</h3>
      </div>
    );
  }

  return (
    <BaseComponent
      {...props}
      loading={loading}
      dishNotFound={dishNotFound}
      restaurant={data?.restaurant}
      dish={data?.dish}
      menu={data?.menu}
      canRenderDietary={canRenderDietary}
      canRenderIngredients={canRenderIngredients}
      menusTimeline={menusTimeline}
    />
  );
};

export default withMenuSingle;
