import { BrowserRouter } from "react-router-dom";
import styles from "./App.module.scss";
import Routes from "./Routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WebfontLoader from "@dr-kobros/react-webfont-loader";
import config_fonts from "./constants/config_fonts";
import { connect } from "react-redux";
import { IKContext } from "imagekitio-react";

function App() {
  return (
    <IKContext urlEndpoint={process.env.REACT_APP_IMAGE_CDN_ENDPOINT}>
      <WebfontLoader config={config_fonts}>
        <div className={`${styles.App} foodi-app`}>
          <BrowserRouter>
            <Routes />
            <ToastContainer />
          </BrowserRouter>
        </div>
      </WebfontLoader>
    </IKContext>
  );
}

const mapStateToProps = (state) => ({ Auth: state.Auth });
export default connect(mapStateToProps)(App);
