import React from "react";
import styles from "../Templates/Templates.module.scss";
import placeholderFood from "../../assets/img/placeholder_food.png";

const requestImageFile = require.context("../../Templates/", true, /.jpg$/);

export default function SelectItem({ data, handleClick, selected }) {
  return (
    <div className={styles.templatesSlider} style={{ display: "flex", flexWrap: "wrap" }}>
      {typeof data === "object" &&
        data?.length > 0 &&
        data?.map((elem, index) => {
          let imgPath = placeholderFood;
          if (elem && elem.template && elem.image) {
            imgPath = requestImageFile(`./${elem.template}/${elem.image}`).default;
          }
          let isSelected = elem?.template === selected;
          return (
            <div
              key={index}
              onClick={() => handleClick(elem?.template)}
              className={styles.swiperSlide}
              style={{
                marginRight: "10px",
                opacity: isSelected ? 1 : 0.6,
                filter: isSelected ? "grayscale(0%)" : "grayscale(100%)",
              }}
            >
              <div
                className={styles.categoryItem}
                style={{
                  backgroundColor: isSelected ? "var(--brand)" : "transparent",
                }}
              >
                <img className={styles.templateScreenshot} src={imgPath} alt="" />
                <div className={styles.categoryItemContent}>
                  <p className={styles.dishName}>{elem?.template}</p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
