
import React from "react"
import styles from "./Admin.module.scss"
import { Form } from "react-bootstrap";

const memberTable = {
    username: {
        label: "Username",
        value: "username"
    },
};

const memberTablePermissions = {
    restaurant: {
        label: "Restaurant settings",
    },
    member: {
        label: "Members",
    },
    menu: {
        label: "Menus",
    },
    dish: {
        label: "Dishes",
    },
    category: {
        label: "Categories",
    },
    ingredient: {
        label: "Ingredients",
    },
}

const menuTable = {
    name: {
        label: "Name",
        value: "name"
    },
    dishes: {
        label: "Number of dishes",
        value: "dishes"
    },
    categories: {
        label: "Number of categories",
        value: "categories"
    }
}

export default function RestaurantInformation({ restaurant }) {
    return (
        <>
            {
                <>
                    {
                        <>
                            <Form.Label style={{ fontWeight: 'bold' }}>Menus</Form.Label>
                            <table className={styles?.restaurantInformationTable}>
                                <thead>
                                    <tr>
                                        { Object.keys(menuTable)?.map((table, index) => <th key={index}>{menuTable[table]?.label}</th>) }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        restaurant?.menus?.map((menu, index) => (
                                            <tr key={index}>
                                            {
                                                Object.keys(menuTable)?.map((menuKey, indexKey) => 
                                                    <td key={indexKey}>{menu[menuKey]}</td>)
                                            }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </>
                    }
                </>    
            }

            {
                <>
                    <Form.Label style={{ fontWeight: 'bold' }}>Members</Form.Label>
                    <table className={styles?.restaurantInformationTable}>
                        <thead>
                            <tr>
                                { Object.keys(memberTable)?.map((table, index) => <th key={index}>{memberTable[table]?.label}</th>) }
                                { Object.keys(memberTablePermissions)?.map((table, index) => <th key={index}>{memberTablePermissions[table]?.label}</th>) }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                restaurant?.members?.map((member, memberIndex) => (
                                    <tr key={memberIndex}>
                                        <td>{member?.username}</td>
                                            { Object.keys(member?.permissions)?.map((permission, permissionIndex) => (
                                                <td key={permissionIndex}>{member?.permissions[permission] && (<i className="fas fa-check-circle"></i>)
                                            }
                                        </td>
                                        )) }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </>
            }
        </>
    )
}