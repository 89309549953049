import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import dashboardStyles from "../../App.module.scss";

export default function ColorInput({ initValue, register, name, onChange, label, description }) {
  const [inputValue, setInputValue] = useState("#ffffff");
  return (
    <div className={dashboardStyles.colorInputWrapper} style={{ backgroundColor: initValue }}>
      <Tooltip title={description} placement="right" enterTouchDelay={0}>
        <i className={`${dashboardStyles.colorInfo} fal fa-info-circle`} />
      </Tooltip>
      <label>{label}</label>
      <input
        type="color"
        style={{ cursor: "pointer" }}
        name={name}
        ref={register}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          onChange(name, e.target.value);
        }}
      />
    </div>
  );
}
