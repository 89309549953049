import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ModalFooter from "./ModalFooter";

import CONSTANTS from "../../utils/constants";

const { USER } = CONSTANTS;
const { ROLES } = USER;

export default function SubscribeRequireModal({ Auth, closeModal, customMessage }) {
  const history = useHistory();

  const subscribe = function () {
    if (Auth?.user?.role === ROLES.DMA_CLIENT) {
      window.location.href = `mailto:${Auth?.user?.dma_parent_email}`;
    } else {
      history.push("/subscribe");
    }
  };
  return (
    <div>
      <div style={{ marginBottom: 20, textAlign: "center" }}>
        {customMessage && <p>{customMessage}</p>}
        {Auth?.user?.role === ROLES.DMA_CLIENT ? (
          <p>
            It looks like your account subscription isn’t set up just yet. Please contact your{" "}
            <a href={`mailto:${Auth?.user?.dma_parent_email}`}>account manager</a>.
          </p>
        ) : (
          <p>In order to publish this menu, you have to subscribe to Foodi menus.</p>
        )}
      </div>
      <ModalFooter>
        {typeof closeModal === "function" && (
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        )}
        <Button onClick={subscribe}>Subscribe</Button>
      </ModalFooter>
    </div>
  );
}
