import React from "react";
import styles from "../Login/Login.module.scss";
import dashboardStyles from "../../App.module.scss";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useForm } from "react-hook-form";
import { Api, Endpoint } from "../../api";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import ReCaptchaComponent from "../../components/ReCaptcha";
import useReCaptcha from "../../hoc/useReCaptcha";

function ForgotPassword() {
  const { reCaptchaRef, executeReCaptchaToken, onChangeReCaptcha } = useReCaptcha();
  const [loading, setLoading] = useState(false);
  const [customError, setCustomError] = useState(null);
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();

  const submit = async (data) => {
    setLoading(true);

    const reCaptchaToken = await executeReCaptchaToken();
    data = { email: data.email, reCaptchaToken };

    const response = await Api.call(Endpoint.requestNewPassword, { data });
    setLoading(false);

    if (response?.statusCode === 200) {
      setCustomError(null);
      toast(response?.message);
      return history.push("/login");
    }

    setCustomError(response?.message);
  };

  return (
    <>
      <div className={styles.loginPageWrapper}>
        <div className={styles.pageContainer}>
          <Form onSubmit={handleSubmit(submit)}>
            <ReCaptchaComponent innerRef={reCaptchaRef} onChange={onChangeReCaptcha} />
            <Form.Group>
              <h3 className={dashboardStyles.formTitle}>Reset your password</h3>
              <p className="form-desc">Enter your user account's verified email address and we will send you a password reset link.</p>
            </Form.Group>
            <Form.Group>
              <Form.Control
                ref={register({
                  required: "Email cannot be empty",
                  pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address" },
                })}
                type="email"
                name="email"
                placeholder="Email"
              />
              {(errors?.email?.message || customError) && (
                <small className={styles.registerErrorText}>{errors?.email?.message || customError}</small>
              )}
            </Form.Group>
            <Button disabled={loading || Object.keys(errors).length > 0 ? true : false} style={{ width: "100%" }} type="submit">
              {loading ? <LoadingSpinner size="sm" /> : "Forgot Password"}
            </Button>
          </Form>

          <div style={{ marginTop: "15px", textAlign: "left" }}>
            <Link to="/login" style={{ color: "inherit" }}>
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
