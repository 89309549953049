import React, { useState } from "react";
import { List, ListItem, ListItemIcon, ListItemText, Radio } from "@material-ui/core";
import LoadingSpinner from "../../LoadingSpinner";

import Stripe from "../../../utils/stripe";
import { Alert, Form } from "react-bootstrap";

import Pagination from "../../Pagination";
import general from "../../../helpers/general";

export default function AddCardForClient({ unSubscribedClients = [], addCard }) {
  const [selectedClient, setSelectedClient] = useState("");
  const [loading, setLoading] = useState(false);

  const per_page_items = 5;

  const [clients, setClients] = useState(general.paginate(unSubscribedClients, 1, per_page_items));

  const [error, setError] = useState(null);

  const changePage = async (e, page) => {
    setClients(general.paginate(unSubscribedClients, page + 1, per_page_items));
  };

  const handleAddCard = async function () {
    if (!selectedClient) {
      setError("You must choose at least one client");
      return;
    }

    const metadata = {
      client_id: selectedClient,
    };

    setLoading(true);
    const response = await addCard({ url: window.location.href, metadata });
    setLoading(false);

    if (response.statusCode === 200 && response?.message?.url) {
      await Stripe.redirectToCheckout(response.message.id);
      return;
    }

    typeof response?.message === "string" && setError(response?.message);
  };

  const handleSearch = function (term) {
    if (term?.length === 0) {
      return setClients(general.paginate(unSubscribedClients, 1, per_page_items));
    }

    const filtered = unSubscribedClients?.filter(
      (client) =>
        client?.email?.indexOf(term) !== -1 ||
        client?.username?.indexOf(term) !== -1 ||
        client?.firstName?.indexOf(term) !== -1 ||
        client?.lastName?.indexOf(term) !== -1
    );
    setClients(general.paginate(filtered, 1, per_page_items));
  };

  return (
    <div>
      <List className="mb-3">
        <p>Select the client that you want to add the card.</p>
        <p>After you have completed this step, you can subscribe for the client using that card.</p>

        {error && <Alert variant="danger">{error}</Alert>}

        {unSubscribedClients?.length > 5 && (
          <div className="mb-3">
            <Form.Control type="search" onChange={(e) => handleSearch(e?.target?.value)} placeholder="Search for clients..." />
          </div>
        )}

        {clients?.data?.map((client, index) => (
          <ListItem key={index} dense button onClick={() => setSelectedClient(client?._id)}>
            <ListItemIcon>
              <Radio
                style={{ color: "var(--brand)" }}
                checked={selectedClient === client?._id}
                onChange={() => {}}
                size="small"
              />
            </ListItemIcon>
            <ListItemText
              primary={client?.username?.charAt(0).toUpperCase() + client?.username?.slice(1)}
              secondary={client?.email}
            />
          </ListItem>
        ))}
        {clients?.data?.length === 0 && unSubscribedClients?.length > 0 && (
          <div className="d-flex justify-content-center">
            <p>No clients were found.</p>
          </div>
        )}
      </List>

      <Pagination
        changePage={changePage}
        currentPage={clients?.pagination?.page - 1}
        totalPages={clients?.pagination?.total_pages}
      />

      <div className="d-flex justify-content-end">
        <button disabled={loading} className="btn btn-brand" onClick={handleAddCard}>
          {loading ? <LoadingSpinner size="sm" /> : "Add card"}
        </button>
      </div>
    </div>
  );
}
