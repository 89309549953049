import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import styles from "./PaymentSystem.module.scss";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Api, Endpoint } from "../../api/index";
import logo from "../../assets/img/logo.png";
import moment from "moment";
import Modal from "../../components/Modal";
import WarningAlert from "../../components/Alerts/Warning";
import { toast } from "react-toastify";

import withDashboardLayout from "../../Layouts/AdminPanel";
import Stripe from "../../utils/stripe";

import ReCaptchaComponent from "../../components/ReCaptcha";
import useReCaptcha from "../../hoc/useReCaptcha";

function Purchase() {
  const { reCaptchaRef, executeReCaptchaToken, onChangeReCaptcha } = useReCaptcha();

  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [customerPortalSessionLoading, setCustomerPortalSessionLoading] = useState(false);
  const [baseProduct, setBaseProduct] = useState(null);

  const submit = async (price) => {
    try {
      setSubmitLoading(true);

      const data = {
        price_id: price?.id,
        reCaptchaToken: await executeReCaptchaToken(),
      };

      const response = await Api?.call(Endpoint?.subscription?.checkoutSession, { data });

      setSubmitLoading(false);

      if (response?.statusCode === 200) {
        await Stripe.redirectToCheckout(response?.message);
        return;
      }

      throw new Error(response?.message);
    } catch (err) {
      setSubmitLoading(false);
      toast("We are sorry, but something went wrong. Please try again or contact the support.");
    }
  };

  const checkSubscription = async () => {
    setLoading(true);
    const response = await Api?.call(Endpoint?.subscription?.hasActive);

    if (!response?.message) {
      await getBaseProduct();
    }

    if (response?.statusCode === 200) {
      setSubscription(response?.message);
      setLoading(false);
      return;
    }
    setSubscription(null);
    setLoading(false);
  };

  const getBaseProduct = async () => {
    const response = await Api.call(Endpoint.subscription.baseProduct);
    if (response?.statusCode === 200) {
      setBaseProduct(response?.message);
    }
  };

  useEffect(() => {
    checkSubscription();
    //eslint-disable-next-line
  }, []);

  const manageBillingDetails = async () => {
    setCustomerPortalSessionLoading(true);
    const response = await Api.call(Endpoint.subscription.customerPortalSession, { data: { url: window.location.href } });
    setCustomerPortalSessionLoading(false);
    if (response.statusCode === 200) {
      window.location.href = response?.message?.url;
      return;
    }
    toast("Something went wrong. Please try again later.");
  };

  if (loading) {
    return (
      <div className={styles?.checkoutCompletedWrapper}>
        <LoadingSpinner />
        <p>Please wait...</p>
      </div>
    );
  }

  if (subscription?.id && subscription?.customer) {
    return (
      <>
        {subscription?.cancel_at_period_end && subscription?.current_period_end && (
          <WarningAlert
            title={`Your subscription will be canceled on ${moment.unix(subscription?.current_period_end).format("DD/MM/YYYY")}`}
          />
        )}
        <Modal {...modal} />
        <div className={styles?.centeredWrapper}>
          <div className={styles?.logoWrapper}>
            <img src={logo} alt="" />
          </div>
          <div className={styles?.alreadySubscribedWrapper}>
            <div>
              <h2>Subscribed successfully</h2>
              {subscription?.cancel_at_period_end && (
                <p>
                  Until <strong>{moment.unix(subscription?.current_period_end).format("DD/MM/YYYY")}</strong>
                </p>
              )}
              <p> Manage invoices, subscriptions, payment methods and more!</p>
              <Button
                disabled={customerPortalSessionLoading}
                className={styles?.purchaseButton}
                startIcon={customerPortalSessionLoading ? <LoadingSpinner size={"sm"} /> : null}
                onClick={() => manageBillingDetails()}
              >
                Manage
              </Button>
            </div>
            <div className={styles?.iconWrapper}>
              <i className="far fa-check-circle fa-2x"></i>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (!baseProduct?.monthly_product || !baseProduct?.yearly_product) {
    return (
      <div className={styles?.checkoutCompletedWrapper}>
        <p>Something went wrong. Please try again later.</p>
      </div>
    );
  }

  return (
    <div>
      <ReCaptchaComponent innerRef={reCaptchaRef} onChange={onChangeReCaptcha} />

      <WarningAlert parentClassName={styles?.subscribeMenusWarning} title="You have to subscribe here in order to publish menus!" />
      <div className={styles?.centeredWrapper}>
        <div className={styles?.logoWrapper}>
          <img src={logo} alt="" />
        </div>
        <div className={styles?.purchaseWrapper}>
          <div className={styles?.purchaseHeader}>
            <h2>Get unlimited published menus!</h2>

            <Button
              onClick={() => submit(baseProduct?.monthly_product?.price)}
              disabled={submitLoading}
              className={styles?.purchaseButton}
              startIcon={submitLoading ? <LoadingSpinner size={"sm"} /> : null}
            >
              Subscribe ${(baseProduct?.monthly_product?.price?.unit_amount / 100)?.toFixed(2)?.toString()} monthly
            </Button>

            <Button
              onClick={() => submit(baseProduct?.yearly_product?.price)}
              disabled={submitLoading}
              className={styles?.purchaseButton}
              startIcon={submitLoading ? <LoadingSpinner size={"sm"} /> : null}
            >
              Subscribe ${(baseProduct?.yearly_product?.price?.unit_amount / 100)?.toFixed(2)?.toString()} yearly
            </Button>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default withDashboardLayout(Purchase);
