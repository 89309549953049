import React from "react";
import Spinner from "react-bootstrap/Spinner";
import styles from "./LoadingSpinner.module.scss";

export default function LoadingSpinner({ size, text, fullScreen = false }) {
  if (fullScreen) {
    return (
      <div className={styles.fullScreenLoader}>
        <Spinner animation="border" size={size} role="status">
          {text && <span className="sr-only">Loading...</span>}
        </Spinner>
      </div>
    );
  }
  return (
    <Spinner animation="border" size={size} role="status">
      {text && <span className="sr-only">Loading...</span>}
    </Spinner>
  );
}
