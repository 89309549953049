/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styles from "./Alerts.module.scss";

export default function IncomingMenus({ menus, minutes, onClose, onSwitch, currentMenu }) {
  return (
    <div className={styles.transitionPeriod}>
      {menus && menus?.length === 1 ? (
        <div>
          <strong>{currentMenu?.name}</strong> expires in {minutes} minutes. You can switch to <strong>{menus?.[0]?.name}</strong>{" "}
          by clicking <a onClick={onSwitch}>here</a>
        </div>
      ) : (
        <div>
          <strong>{currentMenu?.name}</strong> expires in {minutes} minutes. You can view the incoming menus by clicking
          <a onClick={onSwitch}>here</a>
        </div>
      )}
      <a className={styles.closeBtn} onClick={onClose}>
        <i className="far fa-times"></i>
      </a>
    </div>
  );
}
