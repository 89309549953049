import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Button, Alert } from "react-bootstrap";
import { Api, Endpoint } from "../../api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Section from "../../components/Section/Section";
import Table from "../../components/Table";
import DeleteUser from "../../components/Forms/DeleteUser";

import global_styles from "../../App.module.scss";

import { Cookies } from "react-cookie";

import moment from "moment";
import { connect } from "react-redux";

import Modal from "../../components/Modal";

import CancelSubscription from "../../components/Forms/DMA/CancelSubscription";

import EmbedMenuOptions from "../../components/RestaurantForm/EmbedMenuOptions";
import { IKImage } from "imagekitio-react";
import TopBar from "../../components/Header/TopBar";
import Header from "../../components/Header";

const cookies = new Cookies();

function SingleClient({ Auth }) {
  const history = useHistory();

  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [navCollapsed, setNavCollapsed] = useState(false);

  const [loadingUserShapeShift, setLoadingUserShapeShift] = useState(false);
  const id = new URLSearchParams(window.location.search).get("id");

  const fetchClient = async () => {
    const result = await Api.call(Endpoint.DMA.clients.single(id));
    setLoading(false);
    if (result?.statusCode === 200) {
      setResponse(result?.message);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    fetchClient();
    //eslint-disable-next-line
  }, []);

  const shapeshift = async () => {
    const user = response?.client;

    if (!user?.state) {
      toast("This account has not been verified.");
      return;
    }
    setLoadingUserShapeShift(true);

    const data = {
      _id: user._id,
      refreshToken: cookies.get("refreshToken"),
      email: user.email,
    };

    const result = await Auth.SHAPE_SHIFT(data, Endpoint.DMA.clients.shapeshift);
    if (result?.statusCode !== 200) {
      toast(result?.message);
      setLoadingUserShapeShift(false);
      return;
    }

    window.location.href = "/";
    setLoadingUserShapeShift(false);
  };

  const copyMenuUrl = (restaurant) => {
    const url = `${window.location.origin}/menu/${restaurant?._id}`;
    navigator.clipboard.writeText(url).then(
      function () {
        toast("Menu URL has been copied successfully to clipboard");
      },
      function (err) {
        toast("Error copying Menu url to clipboard. Please try again later");
      }
    );
  };

  const showDeleteClientModal = () => {
    const user = response?.client;
    setModal({
      ...modal,
      title: "Delete license",
      visible: true,
      Component: (
        <DeleteUser
          {...modal}
          warning_message="User data and the subscription will be permanently deleted."
          user={user}
          submit={async () => {
            const response = await Api.call(Endpoint.DMA.clients.delete(user?._id));
            history.push("/dma/dashboard");
            return response;
          }}
        />
      ),
    });
  };

  const cancelSubscription = async () => {
    const subscription_id = response?.subscription?.id;
    setModal({
      ...modal,
      title: "Cancel subscription",
      visible: true,
      Component: (
        <CancelSubscription
          {...modal}
          warning_message="This subscription will be cancelled and all the client menus will be unpublished automatically."
          submit={async () => {
            const query = { client_id: response?.client?._id };
            return await Api.call(Endpoint.DMA.clients.deleteSubscription(subscription_id), { query });
          }}
          onComplete={() => {
            history.push("/dma/dashboard");
          }}
        />
      ),
    });
  };

  const showEmbedModal = (restaurant_id) => {
    setModal({
      ...modal,
      visible: true,
      title: "Embed Menu",
      Component: <EmbedMenuOptions {...modal} restaurantId={restaurant_id} />,
    });
  };

  const columns = {
    client: [
      {
        field: "Username",
        access: "username",
      },
      {
        field: "First name",
        access: "firstName",
      },
      {
        field: "Last name",
        access: "lastName",
      },
      {
        field: "Email",
        access: "email",
      },
      {
        field: "Phone number",
        access: "phoneNumber",
      },
    ],
    restaurant: [
      {
        field: "Name",
        access: "name",
      },
      {
        field: "Members",
        render: ({ row }) => {
          return row?.members?.length;
        },
      },
      {
        field: "Copy Menu Url",
        render: ({ row }) => (
          <div>
            <Button onClick={() => copyMenuUrl(row)} style={{ padding: "4px", fontSize: "14px" }}>
              Copy QR Code Url
            </Button>
          </div>
        ),
      },
      {
        field: "Copy Embed Code",
        render: ({ row }) => (
          <div>
            <Button onClick={() => showEmbedModal(row?._id)} style={{ padding: "4px", fontSize: "14px" }}>
              Copy Embed Menu
            </Button>
          </div>
        ),
      },
      {
        field: "Logo",
        render: ({ row }) => {
          if (!row?.logo) {
            return null;
          }
          return (
            <IKImage
              path={row?.logo}
              style={{ borderRadius: "4px", objectFit: "cover" }}
              transformation={[{ width: 80, height: 50 }]}
              loading="lazy"
              lqip={{ active: true }}
            />
          );
        },
      },
    ],
    menus: [
      {
        field: "Name",
        access: "name",
      },
      {
        field: "Published",
        render: ({ row }) => {
          return row?.draft ? "No" : "Yes";
        },
      },
      {
        field: "Categories",
        render: ({ row }) => {
          return row?.categories;
        },
      },
      {
        field: "Cover",
        render: ({ row }) => {
          if (!row?.cover) {
            return null;
          }
          return (
            <IKImage
              path={row?.cover}
              style={{ borderRadius: "4px", objectFit: "cover" }}
              transformation={[{ width: 450 }]}
              loading="lazy"
              lqip={{ active: true }}
            />
          );
        },
      },
    ],
    categories: [
      {
        field: "Name",
        access: "name",
      },
      {
        field: "Available",
        render: ({ row }) => {
          return row?.isAvailable ? "Yes" : "No";
        },
      },
      {
        field: "Dishes",
        render: ({ row }) => {
          return row?.dishes;
        },
      },
    ],
    dishes: [
      {
        field: "Name",
        access: "name",
      },
      {
        field: "Available",
        render: ({ row }) => {
          return row?.isAvailable ? "Yes" : "No";
        },
      },
      {
        field: "Calories",
        render: ({ row }) => {
          return row?.calories || "";
        },
      },
      {
        field: "Alcohol Percentage",
        render: ({ row }) => {
          return row?.alcoholPercentage || "";
        },
      },
      {
        field: "Side dishes",
        render: ({ row }) => {
          return row?.sideDishes?.length;
        },
      },
      {
        field: "Ingredients",
        render: ({ row }) => {
          return row?.ingredients;
        },
      },
      {
        field: "Image",
        render: ({ row }) => {
          if (!row?.image) {
            return null;
          }
          return (
            <IKImage
              path={row?.image}
              style={{ borderRadius: "4px", objectFit: "cover" }}
              transformation={[{ width: 125, height: 225 }]}
              loading="lazy"
              lqip={{ active: true }}
            />
          );
        },
      },
    ],
    ingredients: [
      {
        field: "Name",
        access: "name",
      },
      {
        field: "Available",
        render: ({ row }) => {
          return row?.isAvailable ? "Yes" : "No";
        },
      },
    ],
    subscription: [
      {
        field: "Period start",
        render: ({ row }) => {
          return moment.unix(row?.current_period_start).format("DD/MM/YYYY");
        },
      },
      {
        field: "Period end",
        render: ({ row }) => {
          return moment.unix(row?.current_period_end).format("DD/MM/YYYY");
        },
      },
      {
        field: "Amount",
        render: ({ row }) => {
          return `$` + (row?.plan?.amount / 100)?.toFixed(2).toString();
        },
      },
      {
        field: "Interval",
        render: ({ row }) => {
          return row?.plan?.interval;
        },
      },
      response?.subscription?.discount && {
        field: "Discount",
        render: () => {
          if (response?.subscription?.discount?.coupon?.percent_off) {
            return `${response?.subscription?.discount?.coupon?.percent_off?.toString()}%`;
          }
          return `$` + (response?.subscription?.discount?.coupon?.amount_off / 100)?.toFixed(2).toString();
        },
      },
      {
        field: "Status",
        render: ({ row }) => {
          return row?.status;
        },
      },
      {
        field: "Payment method",
        render: ({ row }) => {
          return `${row?.default_payment_method?.card?.last4} - ${row?.default_payment_method?.card?.brand}`;
        },
      },
    ],
  };

  columns.subscription = columns.subscription.filter((column) => column);

  if (loading) {
    return (
      <div className="bodyWrapper">
        <div className="d-flex justify-content-center align-items-center">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  if (error || !response) {
    return (
      <div className="bodyWrapper">
        <div className="d-flex justify-content-center align-items-center">
          <p>This page could not be found.</p>
        </div>
      </div>
    );
  }
  return (
    <section className={global_styles.panel}>
      <TopBar />
      <Header navCollapsed={navCollapsed} setNavCollapsed={setNavCollapsed} />
      <Modal {...modal} />
      <div className={global_styles.mainWrapper}>
        <main>
          <div className="mb-5">
            <Alert variant="secondary">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Button variant="secondary" disabled={loadingUserShapeShift} onClick={() => history.push("/dma/dashboard")}>
                    Back
                  </Button>
                </div>
                <div>
                  <Button variant="danger" disabled={loadingUserShapeShift} onClick={showDeleteClientModal} className="mr-2">
                    Delete client
                  </Button>
                  <Button variant="primary" disabled={loadingUserShapeShift} onClick={shapeshift}>
                    {loadingUserShapeShift ? <LoadingSpinner size="sm" /> : "Login as this client"}
                  </Button>
                </div>
              </div>
            </Alert>
          </div>
          <div>
            <Section title={`Client info`}>
              <Table columns={columns?.client} rows={[response?.client]} />
            </Section>
            {response?.subscription && (
              <Section title={`Subscription`} action={{ name: "Cancel subscription", funk: cancelSubscription }}>
                <Table columns={columns?.subscription} rows={[response?.subscription]} />
              </Section>
            )}
            <Section title={`Restaurant`}>
              <Table columns={columns?.restaurant} rows={[response?.restaurant]} />
            </Section>
            <Section title={`Menus (${response?.menus?.length || 0})`}>
              <Table columns={columns?.menus} rows={response?.menus} enableLocalPagination />
            </Section>
            <Section title={`Categories (${response?.categories?.length || 0})`}>
              <Table columns={columns?.categories} rows={response?.categories} enableLocalPagination />
            </Section>
            <Section title={`Dishes (${response?.dishes?.length || 0})`}>
              <Table columns={columns?.dishes} rows={response?.dishes} enableLocalPagination />
            </Section>
            <Section title={`Ingredients (${response?.ingredients?.length || 0})`}>
              <Table columns={columns?.ingredients} rows={response?.ingredients} enableLocalPagination />
            </Section>
          </div>
        </main>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({ Auth: state?.Auth });
export default connect(mapStateToProps)(SingleClient);
