import React from "react";
import styles from "./Alerts.module.scss";
import LoadingSpinner from "../LoadingSpinner";

export default function Warning({ title, actionButton, actionButtonTitle, tour, loadingActionButton }) {
  return (
    <div className={styles?.alertWarning}>
      <div className={styles?.firstRow}>
        <div>
          <i className="fas fa-exclamation-triangle"></i>
        </div>
        <div className={styles?.warningText}>
          <p>{title}</p>
        </div>
      </div>

      <div className={styles?.buttonWrapper}>
        {actionButton && (
          <button data-tour={tour} id="create-menu" onClick={actionButton}>
            {loadingActionButton ? <LoadingSpinner size="sm" /> : actionButtonTitle}
          </button>
        )}
      </div>
    </div>
  );
}
