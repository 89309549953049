import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Days from "../../components/Forms/MenuForm/Days";
import MenuNames from "../../components/Forms/MenuForm/MenuNames";
import menuStyles from "../../components/Forms/MenuForm/MenuForm.module.scss";
import styles from "./Timeline.module.scss";
import Checkbox from "../../components/Checkbox";
import { Api, Endpoint } from "../../api";
import moment from "moment";

const days = {
  sunday: true,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
};

export default function MenuComponent({ Menu, register, setValue }) {
  const { menus, selected } = Menu;

  const [valid, setValid] = useState(days);
  const [twentyFourHoursMenuAvailable, setTwentyFourHoursMenuAvailable] = useState(true);
  const [tempMenuSelected, setTempMenuSelected] = useState(selected);

  const [menuVersionLoading, setMenuVersionLoading] = useState(false);

  useEffect(() => {
    selected?.valid ? setValid(selected?.valid) : setValid(days);

    setValue("startTime", dateAsTime(selected?.valid?.startTime));
    setValue("endTime", dateAsTime(selected?.valid?.endTime));

    if (!selected?.valid?.startTime || !selected?.valid?.endTime) {
      setTwentyFourHoursMenuAvailable(true);
    } else {
      setTwentyFourHoursMenuAvailable(selected?.twentyFourHoursMenuAvailable || false);
    }

    setTempMenuSelected(selected);
    //eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    setValue("menu_timeline", tempMenuSelected);
    //eslint-disable-next-line
  }, [tempMenuSelected]);

  useEffect(() => {
    setTempMenuSelected({ ...tempMenuSelected, valid: valid, twentyFourHoursMenuAvailable: twentyFourHoursMenuAvailable });
    //eslint-disable-next-line
  }, [valid, twentyFourHoursMenuAvailable]);

  useEffect(() => {
    register({ name: "menu_timeline", value: tempMenuSelected });
    //eslint-disable-next-line
  }, []);

  const onClickMenuVersion = async (menu) => {
    if (menu?._id?.toString() !== selected?._id?.toString()) {
      setMenuVersionLoading(true);
      const response = await Api.call(Endpoint?.findSingleMenu, { params: [menu._id] });
      if (response?.confirmation === "Success" && response?.message && typeof response?.message === "object") {
        localStorage.setItem("menuSelected", JSON.stringify(response?.message));
        Menu?.setMenuSelectedLocally(response?.message);
      }
      setMenuVersionLoading(false);
    }
  };

  const dateAsTime = (date) => {
    try {
      date = new Date(date).toLocaleString();
      return moment(date).format("HH:mm").toString();
    } catch (err) {
      return "";
    }
  };

  if (!menus?.length) {
    return (
      <>
        <div className={styles?.noMenuSelectedWrapper}>
          <h5>No menu selected.</h5>
        </div>
      </>
    );
  }

  return (
    <>
      <MenuNames
        title="Select a menu"
        tooltip={{ title: "Select a menu to adjustment it's timeline!" }}
        onClick={(menu) => onClickMenuVersion(menu)}
        excludeAddNewMenu={true}
      />
      {selected && selected?._id && (
        <>
          <div className={styles?.menuTimeLineWrapper} style={{ opacity: menuVersionLoading ? 0.2 : 1 }}>
            <div className={styles?.days}>
              <Form.Group>
                <div className={menuStyles.daysOfTheWeek}>
                  <Days valid={valid} setValid={setValid} days={days} disabled={selected?.fromParent} />
                  <div className={styles?.timelineWrapper}>
                    <div className={styles?.timelineDates}>
                      {!twentyFourHoursMenuAvailable && (
                        <>
                          <div>
                            <Form.Group>
                              <Form.Label>Start Time</Form.Label>
                              <Form.Control
                                type="time"
                                defaultValue={selected?.valid?.startTime ? dateAsTime(selected?.valid?.startTime) : ""}
                                ref={register}
                                name="startTime"
                                placeholder="Start Time"
                                disabled={selected?.fromParent}
                              />
                            </Form.Group>
                          </div>
                          <div>
                            <Form.Group>
                              <Form.Label>End Time</Form.Label>
                              <Form.Control
                                type="time"
                                defaultValue={selected?.valid?.endTime ? dateAsTime(selected?.valid?.endTime) : ""}
                                ref={register}
                                name="endTime"
                                placeholder="End Time"
                                disabled={selected?.fromParent}
                              />
                            </Form.Group>
                          </div>
                        </>
                      )}
                    </div>
                    <div>
                      <Form.Label>
                        <Checkbox
                          size="small"
                          checked={twentyFourHoursMenuAvailable}
                          onChange={() => setTwentyFourHoursMenuAvailable(!twentyFourHoursMenuAvailable)}
                          disabled={selected?.fromParent}
                        />
                        <span>24HR Menu available</span>
                      </Form.Label>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </div>
          </div>
        </>
      )}
    </>
  );
}
