import * as TYPES from "../actions/restaurant"
import { methods } from "../actions/restaurant"

const initialState = {
    ...methods,
    restaurant: {},
    members: []
}

const restaurantReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.RESTAURANT_FETCHED_SUCCESSFULLY:
            return { ...state, restaurant: action.restaurant }
        case TYPES.RESTAURANT_FETCHED_FAILED: 
            return { ...state, restaurant: {} }
        case TYPES.RESTAURANT_RESET: 
            return { ...state, restaurant: {}, members: [] }
        case TYPES.RESTAURANT_MEMBERS_LOADED_SUCCESSFULLY:
            return { ...state, members: action?.members }
        default:
            return { ...state }
    }
} 

export { restaurantReducer }
