import React from "react";
import withDashboardLayout from "../../Layouts/AdminPanel";
import { Card, Row, Col } from "react-bootstrap";
import emailTemplates from "../../constants/email-templates";
import { Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";

function EmailTemplates() {
    const history = useHistory();
    return (
        <div>
            {
                Object.keys(emailTemplates).map((key, index) => (
                    <div key={index} className="mb-4">
                        <div className="mb-4">
                            <h4>{emailTemplates?.[key]?.title}</h4>
                        </div>
                        <Row>
                            {
                                emailTemplates?.[key]?.data?.map((tmp, tmpIndex) => (
                                    <Col key={tmpIndex} md={4}>
                                        <Card className="mb-3">
                                            <Card.Body className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex justify-content-between align-items-center w-100">
                                                    <div>
                                                        <div>
                                                            <h6>{tmp?.title}</h6>
                                                            <small className="mb-0">{tmp?.description}</small>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Tooltip title="Edit">
                                                            <i style={{ cursor: 'pointer' }} onClick={() => history.push(`/admin/edit-email-template?template=${tmp?.name}`)} className="edit-icon fal fa-edit"></i>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                ))
            }
        </div>
    )
}

export default withDashboardLayout(EmailTemplates)