import { useEffect, useRef } from "react";

export default function useInterval(callback, delay) {
    const savedCallback = useRef();
    const interval = useRef(null);

    const reset = () => {
        clearInterval(interval?.current);
    };

    const start = () => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            interval.current = setInterval(tick, delay);
        }
    };

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            interval.current = setInterval(tick, delay);
            return () => clearInterval(interval?.current);
        }
    }, [delay]);

    return { reset, start };
}
