import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { ReactComponent as LogoMin } from "../../assets/img/logo_min.svg";
import { privateRoutes, adminRoutes, noAuthenticatedRoutes, DMARoutes } from "../../constants/routes";
import styles from "./Header.module.scss";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "../../components/Modal";
import general from "../../helpers/general";
import MenuPrint from "../MenuPrint";

import CONSTANTS from "../../utils/constants";
import MenuPreviewBtn from "../MenuPreviewBtn/MenuPreviewBtn";
import { Tooltip } from "@material-ui/core";

const { USER } = CONSTANTS;

function Header({ Auth, navCollapsed, setNavCollapsed, preview, setPreview }) {
  const history = useHistory();
  const toggleBtn = useRef(null);
  const collapseRef = useRef(null);
  const navbarRef = useRef(null);
  const [openSubMenu] = useState([]);

  const checkRouteRole = (route) => {
    if (!route?.roles || !Array.isArray(route?.roles)) return true;
    return route?.roles?.includes(Auth?.user?.role);
  };

  const checkSubMenuPermissions = (route, $connector = "$and") => {
    if (!route || !route?.subs || route?.subs?.length === 0) {
      return false;
    }
    if ($connector === "$and") {
      return route?.subs?.every((subRoute) =>
        general?.checkPermissions({
          exceptPermissions: subRoute?.permissions?.except,
          $connector: subRoute?.permissions?.$connector,
          originalPermissions: Auth?.user?.permissions,
        })
      );
    }
    return route?.subs?.some((subRoute) =>
      general?.checkPermissions({
        exceptPermissions: subRoute?.permissions?.except,
        $connector: subRoute?.permissions?.$connector,
        originalPermissions: Auth?.user?.permissions,
      })
    );
  };

  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const renderRoutes = (routes) => {
    //eslint-disable-next-line
    return (
      <ul>
        {/* eslint-disable-next-line array-callback-return */}
        {routes?.map((route, i) => {
          if (route?.showInMenu) {
            if (Auth.LOGGED_IN()) {
              if (!route?.permissions || typeof route?.permissions !== "object") {
                if (route?.private) {
                  return (
                    <li key={i}>
                      <Link
                        to={route.path}
                        data-tour={route?.tour}
                        className={route?.path === history?.location?.pathname ? styles?.activeNav : ""}
                      >
                        <i className={route.icon} />
                        <span>{route.name}</span>
                      </Link>
                      {route?.subs?.length > 0 && (
                        <ul className={`tour-sub-menu sub-menu ${openSubMenu?.includes(route.path) ? "active" : "test"}`}>
                          {route?.subs.map(
                            (subRoute, j) =>
                              general?.checkPermissions({
                                exceptPermissions: subRoute?.permissions?.except,
                                $connector: subRoute?.permissions?.$connector,
                                originalPermissions: Auth?.user?.permissions,
                              }) && (
                                <li key={`sub-${j}`}>
                                  <Link
                                    data-tour={subRoute?.tour}
                                    to={subRoute.path}
                                    className={subRoute?.path === history?.location?.pathname ? styles?.activeNav : "test"}
                                  >
                                    <i className={route.icon} />
                                    <span>{subRoute.name}</span>
                                  </Link>
                                </li>
                              )
                          )}
                        </ul>
                      )}
                    </li>
                  );
                }
              } else {
                if (route?.private) {
                  if (
                    general?.checkPermissions({
                      exceptPermissions: route?.permissions?.except,
                      $connector: route?.permissions?.$connector,
                      originalPermissions: Auth?.user?.permissions,
                    }) ||
                    checkSubMenuPermissions(route, "$or")
                  ) {
                    return (
                      <li key={i}>
                        <Link
                          className={route?.path === history?.location?.pathname ? styles?.activeNav : "test"}
                          to={
                            general?.checkPermissions({
                              exceptPermissions: route?.permissions?.except,
                              $connector: route?.permissions?.$connector,
                              originalPermissions: Auth?.user?.permissions,
                            })
                              ? route.path
                              : history?.location?.pathname || "/"
                          }
                          data-tour={route?.tour}
                        >
                          <i className={route.icon} />
                          <span>{route.name}</span>
                        </Link>
                        {route?.subs?.length > 0 && (
                          <ul className={`tour-sub-menu sub-menu ${openSubMenu?.includes(route.path) ? "active" : "test"}`}>
                            {route?.subs.map(
                              (subRoute, j) =>
                                general?.checkPermissions({
                                  exceptPermissions: subRoute?.permissions?.except,
                                  $connector: subRoute?.permissions?.$connector,
                                  originalPermissions: Auth?.user?.permissions,
                                }) &&
                                checkRouteRole(subRoute) && (
                                  <li key={`sub-${j}`}>
                                    <Link
                                      to={subRoute.path}
                                      className={subRoute?.path === history?.location?.pathname ? styles?.activeNav : "test"}
                                      data-tour={subRoute?.tour}
                                    >
                                      <i className={subRoute.icon} />
                                      <span>{subRoute.name}</span>
                                    </Link>
                                  </li>
                                )
                            )}
                          </ul>
                        )}
                      </li>
                    );
                  }
                }
              }
            } else {
              if (!route?.private) {
                return (
                  <li key={i}>
                    <Link
                      className={route?.path === history?.location?.pathname ? styles?.activeNav : ""}
                      to={route.path}
                      data-tour={route?.tour}
                    >
                      {route.name}
                    </Link>
                  </li>
                );
              }
            }
          } else {
            return null;
          }
        })}
      </ul>
    );
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e) {
      if (collapseRef && collapseRef.current) {
        const ref = collapseRef.current;
        if (!ref.contains(e.target) && navbarRef.current.classList.contains("show")) {
          const tourOpened = document.getElementById("tour-opened");
          if (!tourOpened) {
            toggleBtn.current.click();
          }
        }
      }
    }
  }, []);

  return (
    <div className={` ${styles.siteHeaderWrapper} ${navCollapsed ? styles.closed : ""}`}>
      <Modal {...modal} />
      <header className={styles.siteHeader}>
        <button
          className={`${styles.toggleBtn} ${navCollapsed ? styles.closed : ""}`}
          onClick={() => {
            setNavCollapsed(!navCollapsed);
          }}
        >
          <svg width="31" height="60" viewBox="0 0 31 60" fill="none">
            <g filter="url(#filter0_d_952_7048)">
              <path
                d="M-12 14.1598C-12 8.40932 -6.11416 4.537 -0.833364 6.81321L12.1666 12.4167C15.0999 13.681 17 16.5691 17 19.7632V38.2368C17 41.4309 15.0999 44.319 12.1666 45.5833L-0.833372 51.1868C-6.11417 53.463 -12 49.5907 -12 43.8402V14.1598Z"
                fill="white"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_952_7048"
                x="-22"
                y="-1.84888"
                width="53"
                height="69.6978"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dx="2" dy="4" />
                <feGaussianBlur stdDeviation="6" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_952_7048" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_952_7048" result="shape" />
              </filter>
            </defs>
          </svg>

          <i className="fal fa-chevron-left" />
        </button>

        <div className={styles.headerContainer}>
          <Link to="/" className={styles.brand}>
            <Logo className={styles.logo} />
            <LogoMin className={styles.logoMin} />
          </Link>
          <nav className={styles?.navBarLinks}>
            {renderRoutes(
              !Auth?.user
                ? noAuthenticatedRoutes
                : Auth?.user?.role === USER.ROLES.ADMIN || Auth?.user?.role === USER.ROLES.SUPER_ADMIN
                ? adminRoutes
                : Auth?.user?.role === USER.ROLES.DMA
                ? DMARoutes
                : privateRoutes
            )}
          </nav>
          <MenuPreviewBtn closed={navCollapsed} preview={preview} setPreview={setPreview} />
          <nav className={styles.footerMenu}>
            <ul>
              <li style={{ cursor: "pointer" }}>
                <Tooltip title="Help" enterTouchDelay={0}>
                  <span>
                    <i className="fal fa-question"></i>
                  </span>
                </Tooltip>
                <ul className={`${styles.subMenu}`}>
                  <li>
                    <a target="_blank" href={process?.env?.REACT_APP_HELP} rel="noreferrer">
                      Help
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={process?.env?.REACT_APP_SIDE_DISHES} rel="noreferrer">
                      Side Dishes
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={process?.env?.REACT_APP_CSV_DOCUMENTS} rel="noreferrer">
                      CSV Documents
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={process?.env?.REACT_APP_UPLOAD_IMAGES} rel="noreferrer">
                      Uploading Images
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={process?.env?.REACT_APP_QR_CUSTOMIZATIONS} rel="noreferrer">
                      Using Customizations
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href={process?.env?.REACT_APP_QR_CODES} rel="noreferrer">
                      QR Codes
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <MenuPrint />
              </li>
              <li>
                <Tooltip title={process.env.REACT_APP_MENU_CTA_DEFAULT_TITLE} enterTouchDelay={0}>
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={`mailto:${process?.env?.REACT_APP_ADMIN_EMAIL}?subject=${process?.env?.REACT_APP_MENU_CTA_DEFAULT_TITLE}`}
                    data-tour="nav-report_issue"
                  >
                    <i className="fal fa-flag"></i>
                  </a>
                </Tooltip>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
}

const mapStateToProps = (state) => ({ Auth: state.Auth });
export default connect(mapStateToProps)(Header);
