import React, { useEffect, useState } from "react";
import { Api, Endpoint } from "../../api/index";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Pagination from "../../components/Pagination";
import { toast } from "react-toastify";

import Checkbox from "../../components/Checkbox";

import withDashboardLayout from "../../Layouts/AdminPanel";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  cell_short: {
    maxWidth: "300px",
    wordWrap: "break-word",
  },
});

function Logs() {
  const [pagination, setPagination] = useState({
    page: 0,
    count: 0,
    limit: 2,
  });
  const [logs, setLogs] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);

  const classes = useStyles();

  const loadLogs = async (query = {}) => {
    const response = await Api.call(Endpoint.admin.logs.read, { query });
    if (response.statusCode === 200) {
      setLogs(response?.message?.logs);
      setPagination(response?.message?.pagination);
      return;
    }
    setLogs([]);
    setPagination({ page: 0, count: 0, limit: 2 });
  };

  const checkAllRows = function () {
    if (logs?.length === Object?.keys(checkedRows)?.length) {
      setCheckedRows({});
    } else {
      const newCheckedRows = {};
      logs?.forEach((log) => (newCheckedRows[log?._id] = true));
      setCheckedRows(newCheckedRows);
    }
  };

  const deleteLogs = async () => {
    const ids = Object?.keys(checkedRows)?.filter((key) => checkedRows[key]);

    const data = { ids };
    const response = await Api.call(Endpoint.admin.logs.delete, { data });

    setCheckedRows({});

    if (response.statusCode === 200) {
      loadLogs({ page: pagination?.page });
      toast("Logs deleted successfully");
    }
  };

  const renderMessage = (log) => {
    try {
      if (log?.message && typeof log?.message !== "string") {
        return <pre>{JSON.stringify(log?.message, null, 3)}</pre>;
      }
      return log?.message || "Client side: No log message found";
    } catch (err) {
      return "Client side error: Couldn't render message";
    }
  };

  const copyLogId = (log) => {
    navigator.clipboard.writeText(log?._id?.toString()).then(
      function () {
        toast("Log id has been copied successfully to clipboard");
      },
      function (err) {
        toast("Error copying log id to clipboard");
      }
    );
  };

  useEffect(() => {
    loadLogs();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="mb-4">
          <h3>Developer logs ({pagination?.count})</h3>
        </div>
        {Object?.keys(checkedRows)?.filter((key) => checkedRows[key])?.length > 0 && (
          <div>
            <button className="btn btn-danger" onClick={deleteLogs}>
              Delete
            </button>
          </div>
        )}
      </div>
      <TableContainer component={Paper} className="mb-4">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell_short}>
                <Checkbox size="small" onChange={checkAllRows} checked={logs?.length === Object?.keys(checkedRows)?.length} />
              </TableCell>
              <TableCell align="left">Time</TableCell>
              <TableCell>Message</TableCell>
              <TableCell className={classes.cell_short} align="left">
                Url
              </TableCell>
              <TableCell className={classes.cell_short} align="left">
                Status Code
              </TableCell>
              <TableCell className={classes.cell_short} align="left">
                Method
              </TableCell>
              <TableCell className={classes.cell_short} align="left">
                User
              </TableCell>
              <TableCell className={classes.cell_short} align="left">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs &&
              logs?.length > 0 &&
              logs?.map((log) => (
                <TableRow key={log._id} style={checkedRows?.[log?._id] ? { backgroundColor: "#f1f1f1" } : {}}>
                  <TableCell className={classes.cell_short} align="left">
                    <Checkbox
                      size="small"
                      onChange={() => setCheckedRows({ ...checkedRows, [log?._id]: checkedRows?.[log?._id] ? false : true })}
                      checked={checkedRows?.[log?._id] ? true : false}
                    />
                  </TableCell>
                  <TableCell align="left">{log?.createdAt}</TableCell>
                  <TableCell className={classes.cell_short} style={{ width: "20%" }} width="20px" component="th" scope="row">
                    {renderMessage(log)}
                  </TableCell>
                  <TableCell className={classes.cell_short} align="left">
                    {log?.url || ""}
                  </TableCell>
                  <TableCell className={classes.cell_short} align="left">
                    {log?.status_code || ""}
                  </TableCell>
                  <TableCell className={classes.cell_short} align="left">
                    {log?.method || ""}
                  </TableCell>
                  <TableCell className={classes.cell_short} align="left">
                    {log?.user?.username || "Not logged in"}
                  </TableCell>
                  <TableCell className={classes.cell_short} align="left">
                    <Tooltip title="Copy log id" enterTouchDelay={0}>
                      <i style={{ cursor: "pointer" }} onClick={() => copyLogId(log)} className="fal fa-bars"></i>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {logs && logs?.length > 0 && (
        <Pagination
          changePage={(e, page) => {
            setPagination({ ...pagination, page: page });
            loadLogs({ page });
          }}
          currentPage={pagination?.page}
          totalPages={Math.ceil(pagination?.count / pagination?.limit)}
        />
      )}
    </div>
  );
}

export default withDashboardLayout(Logs);
