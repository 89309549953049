import React, { useState, useEffect, useRef } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useHistory } from "react-router-dom";
import styles from "./ResetPassword.module.scss";
import { Api, Endpoint } from "../../api";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import registerFormStyle from "../Register/Register.module.scss";
import { toast } from "react-toastify";
import Helpers from "../../helpers/general";

import ReCaptchaComponent from "../../components/ReCaptcha";
import useReCaptcha from "../../hoc/useReCaptcha";

const { passwordRegex, passwordMessage } = Helpers.getPasswordRegex();

export default function ResetPassword() {
  const { reCaptchaRef, executeReCaptchaToken, onChangeReCaptcha } = useReCaptcha();

  const [loading, setLoading] = useState(true);
  const [loadingButton, setButtonLoading] = useState(false);
  const [error, setError] = useState(false);
  const [customError, setCustomError] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const extractToken = () => {
    return new URLSearchParams(window.location.search).get("token");
  };

  const checkToken = async () => {
    setLoading(true);
    if (!extractToken()) history.push("/login");
    const response = await Api.call(Endpoint.checkResetPasswordToken, { params: [extractToken()] });
    setLoading(false);
    response?.confirmation === "Fail" && setError("Link is not valid.");
  };

  const submit = async (data) => {
    setButtonLoading(true);

    const reCaptchaToken = await executeReCaptchaToken();
    data.reCaptchaToken = reCaptchaToken;

    const response = await Api.call(Endpoint.resetPassword, { data, params: [extractToken()] });
    if (response?.confirmation === "Success") {
      setButtonLoading(false);
      setCustomError(false);
      toast(response?.message);
      history.push("/login");
    }
    setButtonLoading(false);
    setCustomError(response?.message);
  };

  useEffect(() => {
    checkToken();
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className={styles.bodyWrapper}>
        {loading ? (
          <>
            <LoadingSpinner />
            <p style={{ marginTop: "5px" }}>Please wait...</p>
          </>
        ) : error ? (
          <>
            <i className="fas fa-exclamation-circle fa-7x"></i>
            <h5 style={{ marginTop: "10px" }}>Link is not valid.</h5>
          </>
        ) : (
          <>
            <div className={registerFormStyle.loginPageWrapper}>
              <div className={registerFormStyle.pageContainer}>
                <Form onSubmit={handleSubmit(submit)}>
                  <ReCaptchaComponent innerRef={reCaptchaRef} onChange={onChangeReCaptcha} />

                  <Form.Group>
                    <h3>Reset your password</h3>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      ref={register({
                        required: "Password cannot be empty",
                        pattern: { value: passwordRegex, message: passwordMessage },
                      })}
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                    {(errors?.password?.message || customError) && (
                      <small className={styles.errorMessage}>{errors?.password?.message || customError}</small>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      ref={register({
                        required: "Confirm password cannot be empty",
                        validate: (value) => value === password.current || "The password confirmation does not match.",
                      })}
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                    />
                    {errors?.confirmPassword?.message && <small className={styles.errorMessage}>{errors?.confirmPassword?.message}</small>}
                  </Form.Group>
                  <Button type="submit" disabled={loadingButton || Object.keys(errors).length > 0 ? true : false} style={{ width: "100%" }}>
                    {loadingButton ? <LoadingSpinner size="sm" /> : "Forgot Password"}
                  </Button>
                </Form>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
