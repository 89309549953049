import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useHistory } from "react-router-dom";
import { Api, Endpoint } from "../../api";
import Helpers from "../../helpers/general";
import registerFormStyle from "../Register/Register.module.scss";
import PhoneInput from "react-phone-input-2";
import styles from "./Admin.module.scss";

const { passwordRegex, passwordMessage } = Helpers.getPasswordRegex();

export function CompleteAndVerifyAccount() {
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("+1");

  const history = useHistory();
  const { register, handleSubmit, errors, setValue, watch } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  const getToken = function () {
    return new URLSearchParams(window.location.search).get("token");
  };

  useEffect(() => {
    register({ name: "phoneNumber", value: phoneNumber });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue("phoneNumber", phoneNumber);
    //eslint-disable-next-line
  }, [phoneNumber]);

  const verifyAdminAccountToken = async () => {
    const response = await Api.call(Endpoint?.admin?.verifyAccountToken, {
      params: [getToken()],
    });
    if (response?.confirmation === "Fail" || typeof response?.message !== "object") {
      setLoading(false);
      setError("User has been already verified, or this link is not valid.");
      setAdminData(null);
      return;
    }
    setLoading(false);
    setError(null);
    setAdminData(response?.message);
  };

  const submit = async (data) => {
    setSubmitLoading(true);
    const response = await Api.call(Endpoint.admin.completeAccount, {
      data,
      params: [getToken()],
    });
    if (response?.confirmation === "Success") {
      setSubmitLoading(false);
      toast(response?.message);
      history.push("/login");
      return;
    }
    toast(response?.message);
    setSubmitLoading(false);
  };

  useEffect(() => {
    verifyAdminAccountToken();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles.bodyWrapper}>
        {loading ? (
          <>
            <LoadingSpinner />
            <p className={styles.loadingText}>Please wait...</p>
          </>
        ) : error || !adminData ? (
          <>
            <i className="fas fa-exclamation-circle fa-7x"></i>
            <h3 className={styles.errorText}>{error}</h3>
          </>
        ) : (
          <>
            <div className={registerFormStyle.loginPageWrapper}>
              <div className={registerFormStyle.pageContainer}>
                <Form.Group>
                  {adminData?.username && (
                    <h3>
                      {" "}
                      Hi <strong>{adminData?.username}!</strong>{" "}
                    </h3>
                  )}
                  <p className="form-desc">Please complete your account.</p>
                </Form.Group>
                <Form onSubmit={handleSubmit(submit)}>
                  <Form.Group>
                    <i className="fal fa-user"></i>
                    <Form.Control ref={register} type="text" name="firstName" placeholder="First Name" />
                  </Form.Group>
                  <Form.Group>
                    <i className="fal fa-user"></i>
                    <Form.Control ref={register} type="text" name="lastName" placeholder="Last Name" />
                  </Form.Group>
                  <Form.Group>
                    <i className="fal fa-envelope"></i>
                    <Form.Control type="text" name="email" disabled={true} defaultValue={adminData?.email} placeholder="Email" />
                  </Form.Group>
                  <Form.Group>
                    <i className="fal fa-user"></i>
                    <Form.Control
                      defaultValue={adminData?.username ? adminData?.username : ""}
                      ref={register({ required: "Username cannot be empty" })}
                      type="text"
                      name="username"
                      placeholder="Username"
                    />
                    {errors?.username?.message && (
                      <small className={registerFormStyle.registerErrorText}>{errors?.username?.message}</small>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <i className="fal fa-user"></i>
                    <PhoneInput
                      inputStyle={{ width: "100%" }}
                      country={"us"}
                      value={phoneNumber}
                      onChange={(phone) => setPhoneNumber(phone)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <i className="fal fa-lock"></i>
                    <Form.Control
                      ref={register({
                        required: "Password cannot be empty",
                        pattern: {
                          value: passwordRegex,
                          message: passwordMessage,
                        },
                      })}
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                    {errors?.password?.message && (
                      <small className={registerFormStyle.registerErrorText}>{errors?.password?.message}</small>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <i className="fal fa-lock"></i>
                    <Form.Control
                      ref={register({
                        required: "Confirm password cannot be empty",
                        validate: (value) => value === password.current || "The password confirmation does not match.",
                      })}
                      type="password"
                      name="confirm_password"
                      placeholder="Confirm Password"
                    />
                    {errors?.confirm_password?.message && (
                      <small className={registerFormStyle.registerErrorText}>{errors?.confirm_password?.message}</small>
                    )}
                  </Form.Group>
                  <Button
                    type="submit"
                    disabled={submitLoading || Object.keys(errors).length > 0 ? true : false}
                    style={{ width: "100%" }}
                    onClick={handleSubmit(submit)}
                  >
                    {submitLoading ? <LoadingSpinner size="sm" /> : "Submit"}
                  </Button>
                </Form>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
