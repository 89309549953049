import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";
import styles from "./Timeline.module.scss";
import { Api, Endpoint } from "../../api";
import Modal from "../../components/Modal";
import { connect } from "react-redux";

import Menu from "./Menu";
import Categories from "./Categories";
import { toast } from "react-toastify";
import general from "../../helpers/general";

import withDashboardLayout from "../../Layouts/AdminPanel";

function Timeline({ MenuRedux, Category, Auth }) {
  const [loading, setLoading] = useState(false);
  const [menuCategories, setMenuCategories] = useState([]);
  const { register, handleSubmit, setValue } = useForm();

  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    dialogClassName: styles?.categoryAdjustmentsModal,
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const loadMenuCategories = async () => {
    if (MenuRedux?.selected?._id) {
      const response = await Category?.load({ forceRender: true, selectedMenu: MenuRedux?.selected?._id });
      if (response?.confirmation === "Success" && Array.isArray(response?.message?.categories)) {
        setMenuCategories(response?.message?.categories);
        return;
      }
      setMenuCategories([]);
      return;
    }
    setMenuCategories([]);
  };

  useEffect(() => {
    loadMenuCategories();
    //eslint-disable-next-line
  }, [MenuRedux?.selected]);

  const submit = async (data) => {
    if (!data || !data.menu_timeline || !data.menu_timeline.valid) return;
    setLoading(true);

    if (data.startTime) {
      data.menu_timeline.valid.startTime = timeAsDate(data.startTime);
    }

    if (data.endTime) {
      data.menu_timeline.valid.endTime = timeAsDate(data.endTime);
    }

    data = {
      _id: data?.menu_timeline?._id,
      valid: data?.menu_timeline?.valid,
      twentyFourHoursMenuAvailable: data?.menu_timeline?.twentyFourHoursMenuAvailable,
    };

    const menuResponse = await Api.call(Endpoint?.updateMenuTimeline, { data });
    if (menuResponse?.confirmation === "Success" && typeof menuResponse?.message === "object") {
      localStorage.setItem("menuSelected", JSON.stringify(menuResponse?.message));
      MenuRedux.setMenuSelectedLocally(menuResponse?.message);
      setLoading(false);
      toast("Changes have been made successfully");
      return;
    }
    toast(menuResponse?.message);
    setLoading(false);
  };

  const timeAsDate = (time) => {
    if (typeof time !== "string" || !time.includes(":")) return false;
    try {
      const today = new Date();
      const date = new Date(today.toDateString() + " " + time);
      return new Date(date);
    } catch (err) {
      return false;
    }
  };

  const openCategoriesAdjustments = () => {
    setModal({
      ...modal,
      visible: true,
      title: "Categories Adjustments",
      Component: <Categories {...modal} selectedMenu={MenuRedux?.selected} Category={Category} menuCategories={menuCategories} />,
    });
  };

  return (
    <div>
      <Modal {...modal} />
      <Form onSubmit={handleSubmit(submit)}>
        <div className={styles?.menuSectionWrapper}>
          <Menu register={register} setValue={setValue} Menu={MenuRedux} />
        </div>
        <div className={styles?.footer}>
          {menuCategories &&
            menuCategories?.length > 0 &&
            !MenuRedux?.selected?.fromParent &&
            general?.checkPermissions({ exceptPermissions: ["category"], originalPermissions: Auth?.user?.permissions }) && (
              <Button type="button" onClick={openCategoriesAdjustments} variant="secondary">
                Categories Adjustments
              </Button>
            )}
          {MenuRedux?.selected?._id && MenuRedux?.menus?.length > 0 && !MenuRedux?.selected?.fromParent && (
            <Button disabled={loading} type="submit">
              {loading ? <LoadingSpinner /> : "Save changes"}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => ({ MenuRedux: state?.Menu, Category: state?.Category, Auth: state?.Auth });
export default connect(mapStateToProps)(withDashboardLayout(Timeline));
