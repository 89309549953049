import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import Modal from "../../Modal";
import DeleteUser from "../../Forms/DeleteUser";

import { Avatar, IconButton, ListItemText, ListItemSecondaryAction, ListItemIcon, ListItem, List, Tooltip } from "@material-ui/core";

import { Api, Endpoint } from "../../../api";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  })
);

export default function ViewDMALicenses({ dma, loadDMAS, shapeshift }) {
  const [clients, setClients] = useState(dma?.clients || []);
  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const classes = useStyles();

  const showDeleteClientModal = (client) => {
    setModal({
      ...modal,
      title: "Remove license",
      visible: true,
      Component: (
        <DeleteUser
          {...modal}
          warning_message="Are you sure you want to remove this license?"
          user={client}
          submit={async (user, password) => {
            const data = {
              dma_id: dma?._id,
              client_id: client?._id,
            };
            const response = await Api.call(Endpoint.admin.DMA.deleteClient, { data });

            if (response?.statusCode === 200 && response?.message) {
              setClients(clients.filter((c) => c?._id !== client?._id));
              loadDMAS();
            }

            return response;
          }}
        />
      ),
    });
  };

  const shapeShift = async (client) => {
    await shapeshift(client);
  };

  if (!clients || clients?.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <p>No licenses were found</p>
      </div>
    );
  }
  return (
    <div>
      <Modal {...modal} />
      {clients?.map((client, index) => (
        <List key={index} className={classes.root}>
          <ListItem role={undefined} dense button>
            <ListItemIcon>
              <Avatar style={{ backgroundColor: "var(--brand)" }}>{client?.username?.substr(0, 1)?.toUpperCase()}</Avatar>{" "}
            </ListItemIcon>
            <ListItemText primary={client?.username} secondary={client?.email} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="comments" onClick={() => shapeShift(client)}>
                <Tooltip title="Login as this license" enterTouchDelay={0}>
                  <Avatar style={{ backgroundColor: "var(--error)" }}>
                    <i className="far fa-arrow-alt-circle-up"></i>
                  </Avatar>
                </Tooltip>
              </IconButton>
              <IconButton edge="end" aria-label="comments" onClick={() => showDeleteClientModal(client)}>
                <Tooltip title="Remove this license" enterTouchDelay={0}>
                  <Avatar style={{ backgroundColor: "var(--error)" }}>
                    <i className="far fa-trash-alt"></i>
                  </Avatar>
                </Tooltip>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      ))}
    </div>
  );
}
