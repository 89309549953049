import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Button } from "react-bootstrap";
import Checkbox from "../../components/Checkbox";
import general from "../../helpers/general";
import styles from "./Toggle.module.scss";
import { Api, Endpoint } from "../../api/index";
import LoadingSpinner from "../../components/LoadingSpinner";
import { toast } from "react-toastify";
import WarningAlert from "../../components/Alerts/Warning";
import SubscribeRequireModal from "../../components/Modal/SubscribeRequiredModal";
import Modal from "../../components/Modal";
import MenuNames from "../../components/Forms/MenuForm/MenuNames";
import { Link } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import withDashboardLayout from "../../Layouts/AdminPanel";

function Toggle({ Auth, Restaurant, Menu, Category, Dish, Ingredient }) {
  /* State for searching */
  const [tempDishes, setTempDishes] = useState([]);
  const [tempIngredients, setTempIngredients] = useState([]);
  const [customizations, setCustomizations] = useState([]);

  /* State for holding data */
  const [categoriesData, setCategoriesData] = useState([]);
  const [dishesData, setDishesData] = useState([]);
  const [ingredientsData, setIngredientsData] = useState([]);

  /* State for checkboxes */
  const [menusCheckbox, setMenusCheckbox] = useState({});
  const [categoriesCheckbox, setCategoriesCheckbox] = useState({});
  const [dishesCheckbox, setDishesCheckbox] = useState({});
  const [ingredientsCheckbox, setIngredientsCheckbox] = useState({});
  const [customizationsCheckbox, setCustomizationsCheckbox] = useState({});
  const [saveChangesLoading, setSaveChangesLoading] = useState(false);

  const [listenChange, setListenChange] = useState(false);

  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const showSubscribeRequired = async () => {
    const message = "In order to publish your menus, you have to subscribe to Foodi menus.";
    setModal({
      ...modal,
      visible: true,
      title: "Subscribe required",
      Component: <SubscribeRequireModal Auth={Auth} customMessage={message} closeModal={() => setModal({ ...modal, visible: false })} />,
    });
  };

  const transformList = (arr, key, val) => {
    const transformed = {};
    arr.forEach((elem) => (transformed[elem[key]] = { name: elem?.name, checked: elem[val] }));
    return transformed;
  };

  const onChangeCheckbox = (hook, state, _id, checked) => {
    if (!listenChange) {
      setListenChange(true);
    }
    hook({ ...state, [_id]: { ...state[_id], checked: checked, changed: true } });
  };

  const prepareDataObject = (data) => {
    const stored = [];
    if (data && Object?.keys(data)?.length > 0) {
      const objKeys = Object.keys(data);
      objKeys.forEach((key) => {
        if (data?.[key]?.changed) {
          stored.push({ id: key, checked: data[key]?.checked });
        }
      });
    }
    return stored;
  };

  const saveChanges = async () => {
    setSaveChangesLoading(true);

    const menus = prepareDataObject(menusCheckbox);
    const categories = prepareDataObject(categoriesCheckbox);
    const dishes = prepareDataObject(dishesCheckbox);
    const ingredients = prepareDataObject(ingredientsCheckbox);
    const customizations = prepareDataObject(customizationsCheckbox);

    const data = { menus, categories, dishes, ingredients, customizations };
    const response = await Api.call(Endpoint?.settings?.quickEdit?.update, { data });

    let successToastMessage = "Changes have been made successfully.";

    setListenChange(false);

    if (response?.confirmation === "Success") {
      if (menus?.length > 0) {
        if (!response?.message?.menusUpdatedSuccessfully) {
          successToastMessage = "Changes have been made successfully (except menus).";
          showSubscribeRequired();
        }
        Menu.load({ forceRender: true });
      }

      if (categories?.length > 0) {
        Category.load({ forceRender: true });
        if (customizations?.length === 0) {
          Restaurant.find({ forceRender: true });
        }
        if (menus?.length === 0) {
          Menu.load({ forceRender: true });
        }
      }

      if (dishes?.length > 0) {
        Dish.load({ forceRender: true });
        if (customizations?.length === 0) {
          Restaurant.find({ forceRender: true });
        }
      }

      if (ingredients?.length > 0) {
        Ingredient.load({ forceRender: true });
      }

      if (customizations?.length > 0) {
        Restaurant.find({ forceRender: true });
      }
      toast(successToastMessage);
    } else {
      toast(response?.message);
    }

    setSaveChangesLoading(false);
  };

  const loadQuickEdit = async (menu) => {
    if (Menu?.menus?.length === 0 || !menu || !menu?._id) {
      return;
    }
    const params = [menu?._id];
    const response = await Api.call(Endpoint?.settings?.quickEdit?.get, { params });
    if (response?.confirmation === "Success") {
      setCategoriesData(response?.message?.categories);
      setCategoriesCheckbox(transformList(response?.message?.categories, "_id", "isAvailable"));

      setDishesData(response?.message?.dishes);
      setTempDishes(response?.message?.dishes);
      setDishesCheckbox(transformList(response?.message?.dishes, "_id", "isAvailable"));

      setIngredientsData(response?.message?.ingredients);
      setTempIngredients(response?.message?.ingredients);
      setIngredientsCheckbox(transformList(response?.message?.ingredients, "_id", "isAvailable"));

      if (Menu?.menus) {
        setMenusCheckbox(transformList(Menu?.menus, "_id", "draft"));
      }
    } else {
      toast("Failed to load data.");
    }
  };

  useEffect(() => {
    loadQuickEdit(Menu?.selected);
    Restaurant?.find();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Menu?.menus) {
      setMenusCheckbox(transformList(Menu?.menus, "_id", "draft"));
    }
    //eslint-disable-next-line
  }, [Menu?.menus]);

  useEffect(() => {
    if (Restaurant?.restaurant?.customizations) {
      setCustomizationsCheckbox(transformList(Restaurant?.restaurant?.customizations, "_id", "isAvailable"));
      setCustomizations(Restaurant?.restaurant?.customizations);
    }
    //eslint-disable-next-line
  }, [Restaurant?.restaurant]);

  const handleDishesChange = (e) => {
    let copy = [...tempDishes];
    copy = copy.filter((dish) => dish?.name?.toLowerCase()?.includes(e.target.value.toLowerCase()));
    setDishesData(copy);
  };

  const handleIngredientsChange = (e) => {
    let copy = [...tempIngredients];
    copy = copy.filter((ing) => ing?.name?.toLowerCase()?.includes(e.target.value.toLowerCase()));
    setIngredientsData(copy);
  };

  const handleCustomizationsChange = (e) => {
    if (Restaurant?.restaurant?.customizations && Array.isArray(Restaurant?.restaurant?.customizations)) {
      let copy = [...Restaurant?.restaurant?.customizations];
      copy = copy.filter((customization) => customization.name.toLowerCase().includes(e.target.value.toLowerCase()));
      setCustomizations(copy);
    }
  };

  if (Menu?.menus?.length === 0) {
    return (
      <>
        <div className={styles?.noMenuFoundWrapper}>
          <h2>No menu found</h2>
          <p>
            Please create a <Link to="/create-menu">menu</Link>
          </p>
        </div>
      </>
    );
  }

  return (
    <div>
      <Modal {...modal} />
      <MenuNames
        excludeAddNewMenu={true}
        tooltip={{ title: "Click to display on the left side" }}
        onClick={(menu, params = {}) => {
          if (menu?._id?.toString() !== Menu?.selected?._id?.toString()) {
            localStorage.setItem("menuSelected", JSON.stringify(menu));
            Menu?.setMenuSelectedLocally(menu);
            loadQuickEdit(menu);
          }
        }}
        tour="create-menu"
        menus={Menu?.menus}
      />
      {listenChange && (
        <WarningAlert
          loadingActionButton={saveChangesLoading}
          actionButton={saveChanges}
          actionButtonTitle="Save changes"
          title={`You haven't saved your changes!`}
        />
      )}
      <Form>
        {Menu?.selected?._id && general?.checkPermissions({ exceptPermissions: ["menu"], originalPermissions: Auth?.user?.permissions }) && (
          <div className={styles.listWrapper}>
            <FormControlLabel
              className={styles.customSwitch}
              control={
                <Switch
                  checked={menusCheckbox[Menu?.selected?._id]?.checked ? false : true}
                  onChange={() => {
                    onChangeCheckbox(
                      setMenusCheckbox,
                      menusCheckbox,
                      Menu?.selected?._id,
                      !menusCheckbox[Menu?.selected?._id]?.checked,
                      "menu",
                      Menu?.selected?.name
                    );
                  }}
                  color="primary"
                />
              }
              label={<span>Menu Availability</span>}
            />
          </div>
        )}
        {/* {general?.checkPermissions({ exceptPermissions: ["menu"], originalPermissions: Auth?.user?.permissions }) && (
                    <div className={styles.listWrapper}>
                        <Form.Label>Menus</Form.Label>
                        <div className={styles.list}>
                            {Menu?.menus &&
                                Menu?.menus?.length > 0 &&
                                Menu?.menus?.map((menu, index) => (
                                    <Form.Label className="d-flex" key={index}>
                                        <Checkbox
                                            size="small"
                                            label="small"
                                            checked={menusCheckbox[menu?._id]?.checked ? false : true}
                                            onChange={() => {
                                                onChangeCheckbox(
                                                    setMenusCheckbox,
                                                    menusCheckbox,
                                                    menu?._id,
                                                    !menusCheckbox[menu?._id]?.checked,
                                                    "menu",
                                                    menu?.name
                                                );
                                            }}
                                        />
                                        <span style={{ fontWeight: "normal", textTransform: "none" }}>{menu?.name}</span>
                                    </Form.Label>
                                ))}
                        </div>
                    </div>
                )} */}
        {general?.checkPermissions({ exceptPermissions: ["category"], originalPermissions: Auth?.user?.permissions }) &&
          categoriesData?.length > 0 && (
            <div className={styles.listWrapper}>
              <Form.Label>Categories</Form.Label>
              <div className={styles.list}>
                {categoriesData &&
                  categoriesData?.length > 0 &&
                  categoriesData?.map((category, index) => (
                    <Form.Label className="d-flex" key={index}>
                      <Checkbox
                        size="small"
                        label="small"
                        checked={categoriesCheckbox[category?._id]?.checked ? true : false}
                        onChange={() => {
                          onChangeCheckbox(
                            setCategoriesCheckbox,
                            categoriesCheckbox,
                            category?._id,
                            !categoriesCheckbox[category?._id]?.checked,
                            "category",
                            category?.name
                          );
                        }}
                      />
                      <span style={{ fontWeight: "normal", textTransform: "none" }}>{category?.name}</span>
                    </Form.Label>
                  ))}
              </div>
            </div>
          )}
        {general?.checkPermissions({ exceptPermissions: ["dish"], originalPermissions: Auth?.user?.permissions }) &&
          dishesData?.length > 0 && (
            <div className={styles.listWrapper}>
              <Form.Label>Dishes</Form.Label>
              <Form.Control type="search" placeholder="Search for Dishes" onChange={handleDishesChange} style={{ marginBottom: 20 }} />

              <div className={styles.list}>
                {dishesData && dishesData?.length > 0 ? (
                  dishesData?.map((dish, index) => (
                    <Form.Label className="d-flex" key={index}>
                      <Checkbox
                        size="small"
                        label="small"
                        checked={dishesCheckbox[dish?._id]?.checked || false}
                        onChange={() => {
                          onChangeCheckbox(
                            setDishesCheckbox,
                            dishesCheckbox,
                            dish?._id,
                            !dishesCheckbox[dish?._id]?.checked,
                            "dish",
                            dish?.name
                          );
                        }}
                      />
                      <span style={{ fontWeight: "normal", textTransform: "none" }}>{dish?.name}</span>
                    </Form.Label>
                  ))
                ) : (
                  <>{dishesData?.length > 0 && <p className={styles.listMessage}>There are no dishes that match this word</p>}</>
                )}
              </div>
            </div>
          )}
        {general?.checkPermissions({ exceptPermissions: ["ingredient"], originalPermissions: Auth?.user?.permissions }) &&
          ingredientsData?.length > 0 && (
            <div className={styles.listWrapper}>
              <Form.Label>Ingredients</Form.Label>
              <Form.Control
                type="search"
                placeholder="Search for Ingredients"
                onChange={handleIngredientsChange}
                style={{ marginBottom: 20 }}
              />
              <div className={styles.list}>
                {ingredientsData && ingredientsData?.length > 0 ? (
                  ingredientsData?.map((ingredient, index) => (
                    <Form.Label className="d-flex" key={index}>
                      <Checkbox
                        size="small"
                        label="small"
                        checked={ingredientsCheckbox[ingredient?._id]?.checked ? true : false}
                        onChange={() => {
                          onChangeCheckbox(
                            setIngredientsCheckbox,
                            ingredientsCheckbox,
                            ingredient?._id,
                            !ingredientsCheckbox[ingredient?._id]?.checked,
                            "ingredient",
                            ingredient?.name
                          );
                        }}
                      />
                      <span style={{ fontWeight: "normal", textTransform: "none" }}>{ingredient?.name}</span>
                    </Form.Label>
                  ))
                ) : (
                  <>{tempIngredients.length > 0 && <p className={styles.listMessage}>There are no ingredients that match this word</p>}</>
                )}
              </div>
            </div>
          )}

        {general?.checkPermissions({ exceptPermissions: ["restaurant"], originalPermissions: Auth?.user?.permissions }) &&
          customizations?.length > 0 && (
            <div className={styles.listWrapper}>
              <Form.Label>Customizations</Form.Label>
              <Form.Control
                type="search"
                placeholder="Search for customizations"
                onChange={handleCustomizationsChange}
                style={{ marginBottom: 20 }}
              />
              <div className={styles.list}>
                {customizations?.length > 0 ? (
                  customizations?.map((customization, index) => (
                    <Form.Label className="d-flex" key={index}>
                      <Checkbox
                        size="small"
                        label="small"
                        checked={customizationsCheckbox[customization?._id]?.checked ? true : false}
                        onChange={() => {
                          onChangeCheckbox(
                            setCustomizationsCheckbox,
                            customizationsCheckbox,
                            customization?._id,
                            !customizationsCheckbox[customization?._id]?.checked,
                            "customization",
                            customization?.name
                          );
                        }}
                      />
                      <span style={{ fontWeight: "normal", textTransform: "none" }}>{customization?.name}</span>
                    </Form.Label>
                  ))
                ) : (
                  <>
                    {Restaurant?.restaurant?.customizations?.length > 0 && (
                      <p className={styles.listMessage}>There are no customizations that match this word</p>
                    )}
                  </>
                )}
              </div>
              {(Menu?.menus?.length > 0 ||
                Category?.categories?.length > 0 ||
                Dish?.dishes?.length > 0 ||
                Ingredient?.ingredients?.length > 0 ||
                Restaurant?.restaurant?.customizations?.length > 0) && (
                <div className={styles?.saveChangesWrapper}>
                  <Button onClick={saveChanges} disabled={saveChangesLoading} type="button">
                    {saveChangesLoading ? <LoadingSpinner size="sm" /> : "Save changes"}
                  </Button>
                </div>
              )}
            </div>
          )}
      </Form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Auth: state?.Auth,
  Restaurant: state?.Restaurant,
  Menu: state?.Menu,
  Category: state?.Category,
  Dish: state?.Dish,
  Ingredient: state?.Ingredient,
});
export default connect(mapStateToProps)(withDashboardLayout(Toggle));
