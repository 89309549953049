import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button } from "react-bootstrap";
import "swiper/swiper.scss";
import { useSelector } from "react-redux";
import { Tooltip as MaterialUiTooltip } from "@material-ui/core";
import { toast } from "react-toastify";

export default function MenuNames({ onClick, excludeAddNewMenu, tour, tooltip, title = "Menus" }) {
  const addMenuBtn = useRef(null);
  const Menu = useSelector((state) => state?.Menu);
  const { menus } = Menu;

  const clone = async (menu) => {
    const categories = menu?.categories?.map((category) => ({
      category: category?.category?._id,
      valid: category?.valid,
    }));

    const tempMenu = {
      ...menu,
      name: menu?.name + " (copy)",
      categories: JSON.stringify(categories),
      showCTA: JSON.stringify(menu?.showCTA),
      fonts: JSON.stringify(menu?.fonts),
      colors: JSON.stringify(menu?.colors),
      draft: true,
    };

    const response = await Menu.create(tempMenu);

    if (response?.confirmation === "Fail") {
      if (response?.statusCode === 412) {
        // if it fails because of duplicate name, add one more "(copy)" and try again
        menu.name = menu?.name + " (copy)";
        clone(menu);
      } else {
        toast("Menu can not be created");
      }
      return;
    } else {
      toast("Menu created successfully");
      await Menu.load({ forceRender: true });
    }
  };

  return (
    <>
      <div>
        {menus && menus.length !== 0 && menus.filter((menu) => !menu?.fromParent).some((menu) => !menu?.draft) && (
          <>
            <p style={{ fontWeight: "bold", fontSize: "14px", color: "#808080" }}>Published {title}</p>
            <Swiper style={{ marginBottom: "20px" }} spaceBetween={20} slidesPerView={"auto"}>
              {menus.map((menu) => {
                return !menu?.draft && !menu?.fromParent ? (
                  <SwiperSlide
                    key={menu?._id}
                    style={{ display: "inline-block", width: "auto", opacity: menu?.fromParent ? 0.7 : 1 }}
                    className={`${menu?.fromParent ? "parent-menu" : ""}`}
                  >
                    <div className="menu-name-wrapper">
                      <MaterialUiTooltip title={tooltip?.title ? tooltip?.title : ""}>
                        <Button
                          variant={Menu?.selected?._id?.toString() === menu?._id.toString() ? "primary" : "secondary"}
                          onClick={() => {
                            if (menu?._id === Menu?.selected?._id) {
                              onClick({});
                            } else {
                              onClick(menu);
                            }
                          }}
                        >
                          {menu?.name}
                        </Button>
                      </MaterialUiTooltip>
                      <MaterialUiTooltip title={`Make a copy of "${menu?.name?.toUpperCase()}" menu`}>
                        <i className="menu-clone-btn fal fa-copy" onClick={() => clone(menu)} />
                      </MaterialUiTooltip>
                    </div>
                  </SwiperSlide>
                ) : null;
              })}
            </Swiper>
          </>
        )}
      </div>
      <div>
        <p style={{ fontWeight: "bold", fontSize: "14px", color: "#808080" }}>Unpublished {title}</p>
        <Swiper style={{ marginBottom: "20px" }} spaceBetween={20} slidesPerView={"auto"}>
          {menus && menus.length !== 0 && (
            <>
              {menus.map((menu) => {
                return menu?.draft || menu?.fromParent ? (
                  <SwiperSlide
                    key={menu?._id}
                    style={{ display: "inline-block", width: "auto", opacity: menu?.fromParent ? 0.7 : 1 }}
                    className={`${menu?.fromParent ? "parent-menu" : ""}`}
                  >
                    <div className="menu-name-wrapper">
                      <MaterialUiTooltip title={tooltip?.title ? tooltip?.title : ""}>
                        <Button
                          variant={Menu?.selected?._id?.toString() === menu?._id.toString() ? "primary" : "secondary"}
                          onClick={() => {
                            if (menu?._id === Menu?.selected?._id) {
                              onClick({});
                            } else {
                              onClick(menu);
                            }
                          }}
                        >
                          {menu?.name}
                        </Button>
                      </MaterialUiTooltip>
                      <MaterialUiTooltip title={`Make a copy of "${menu?.name?.toUpperCase()}" menu`}>
                        <i className="menu-clone-btn fal fa-copy" onClick={() => clone(menu)} />
                      </MaterialUiTooltip>
                    </div>
                  </SwiperSlide>
                ) : null;
              })}
            </>
          )}

          {!excludeAddNewMenu && (
            <SwiperSlide style={{ display: "inline-block", width: "auto" }}>
              <MaterialUiTooltip title="Add new menu">
                <Button
                  data-tour={tour}
                  ref={addMenuBtn}
                  variant="secondary"
                  onClick={() => {
                    onClick({}, { addNew: true });
                    //TODO: use ref to focus on the input
                    let input = document.querySelector("input");
                    input && input.focus();
                  }}
                >
                  <i className="fas fa-plus"></i>
                </Button>
              </MaterialUiTooltip>
            </SwiperSlide>
          )}
        </Swiper>
      </div>
    </>
  );
}
