// eslint-disable-next-line import/no-anonymous-default-export
export default {
  user: {
    title: "User Email Templates",
    data: [
      {
        title: "New account creation notify",
        description: "Notify admin when an account has been created",
        name: "new-account-creation-notify",
      },
      {
        title: "Verify Account",
        description: "User verify account",
        name: "user-verify-account",
      },
      {
        title: "Forgot Password",
        description: "User forgot password ",
        name: "user-forgot-password",
      },
      {
        title: "Transfer Ownership",
        description: "Restaurant transfer ownership",
        name: "transfer-ownership",
      },
    ],
  },
  admin: {
    title: "Admin Email Templates",
    data: [
      {
        title: "Admin account created",
        description: "Admin account creation template",
        name: "admin-created",
      },
      {
        title: "DMA deleted",
        description: "An admin deleted an DMA account",
        name: "dma-deleted",
      },
    ],
  },
  member: {
    title: "Member Email Templates",
    data: [
      {
        title: "Member account created",
        description: "Member account creation",
        name: "member-created",
      },
      {
        title: "Member account created already verified",
        description: "Member account creation but already verified",
        name: "member-created-already-verified",
      },
    ],
  },
  subscription: {
    title: "Subscription Email Templates",
    data: [
      {
        title: "Invoice payment succeeded",
        description: "Invoice payment succeeded in Stripe",
        name: "invoice-payment-succeeded",
      },
      {
        title: "Invoice payment failed",
        description: "Invoice payment failed in Stripe",
        name: "invoice-payment-failed",
      },
    ],
  },
  dma: {
    title: "DMA Email Templates",
    data: [
      {
        title: "DMA Register",
        description: "DMA register process",
        name: "dma-register",
      },
      {
        title: "Notify Admin Account Created",
        description: "Notify admin account created when an DMA has been registered",
        name: "notify-admin-dma-created",
      },
      {
        title: "Client Added",
        description: "DMA added a client template",
        name: "dma-client-added",
      },
      {
        title: "Client Accepted Invitation",
        description: "DMA Client accepted invitation",
        name: "dma-client-accepted-invitation",
      },
      {
        title: "Client Rejected Invitation",
        description: "DMA Client rejected invitation",
        name: "dma-client-rejected-invitation",
      },
      {
        title: "Client Deleted",
        description: "DMA Client account deleted",
        name: "dma-client-deleted",
      },
    ],
  },
};
