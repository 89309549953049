import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import styles from "./MapAction.module.scss";

export default function MapAction({ maptype, maptype_index }) {
  return (
    <Droppable droppableId={`${maptype?.value}`} type={`${maptype?.type}`} isDropDisabled={true}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <Draggable
            draggableId={`${maptype?.id} ${maptype?.value}`}
            key={maptype?.id}
            index={maptype_index}
            isDragDisabled={maptype?.disabled}
          >
            {(provided, snapshot) => (
              <div className={maptype?.disabled ? "disabled" : ""}>
                <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className={styles.typeMap}>
                  <img src={maptype?.image} alt={maptype?.label} />
                  <h4>{maptype?.label}</h4>
                </div>
                {snapshot.isDragging && (
                  <div className={styles.typeMapClone}>
                    <div className={styles.typeMap}>
                      <img src={maptype?.image} alt={maptype?.label} />
                      <h4>{maptype?.label}</h4>
                    </div>
                  </div>
                )}
                {provided.placeholder}
              </div>
            )}
          </Draggable>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
