import React, { useState, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import ModalFooter from "../../Modal/ModalFooter";

export default function IngredientForm({ ingredient, closeModal, handleDataOnDishForm, IngredientRedux }) {
  const [loading, setLoading] = useState(false);
  const tagInputRef = useRef();

  const [duplicateErrors, setDuplicateErrors] = useState([]);
  const [ingredientsUploadedSuccessfully, setIngredientsUploadedSuccessfully] = useState(-1);
  const [ingredientNames, setIngredientNames] = useState([]);

  const [updateIngredientError, setUpdateIngredientError] = useState(null);

  const ingredientRef = useRef(null);
  //eslint-disable-next-line

  const resetFormAfterCreating = () => {
    setDuplicateErrors([]);
    setIngredientsUploadedSuccessfully(-1);
    setIngredientNames([]);
    setUpdateIngredientError(null);
    if (tagInputRef?.current?.state?.tag) {
      tagInputRef.current.state.tag = "";
    }
    if (ingredientRef && ingredientRef?.current && ingredientRef?.current?.value) {
      ingredientRef.current.value = "";
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (ingredient?._id && ingredient?.name) {
      const inputValue = ingredientRef?.current?.value;
      if (!inputValue || inputValue?.trim()?.length === 0) {
        setUpdateIngredientError("This field is required");
        setLoading(false);
        return;
      }

      setUpdateIngredientError(null);

      const data = { name: inputValue };
      const response = await IngredientRedux.update(data, ingredient?._id);

      if (response?.confirmation === "Success") {
        setLoading(false);
        toast("Ingredient updated successfully!");
        closeModal && closeModal();
        resetFormAfterCreating();
        return;
      }
      setLoading(false);
      setUpdateIngredientError(response?.message);
      return;
    }

    if ((!ingredientNames || ingredientNames?.length === 0) && !tagInputRef.current?.state?.tag) {
      toast("You must add at least one ingredient to submit.");
      setDuplicateErrors([]);
      setLoading(false);
      return;
    }

    const response = await IngredientRedux.create({
      ingredients: ingredientNames?.length !== 0 ? ingredientNames : [tagInputRef.current?.state?.tag],
    });

    if (!response || response?.confirmation === "Fail") {
      toast(response?.message);
      setDuplicateErrors([]);
      setLoading(false);
      return;
    }

    IngredientRedux.load({ forceRender: true });

    typeof handleDataOnDishForm === "function" && handleDataOnDishForm(response?.message?.insertedIngredients);

    if (response?.message?.errors?.length === 0 && response?.message?.insertedIngredients) {
      const message = `${response?.message?.insertedIngredients?.length} ${
        response?.message?.insertedIngredients?.length === 1 ? "ingredient" : "ingredients"
      } uploaded successfully`;
      toast(message);
      setLoading(false);
      closeModal && closeModal();
      resetFormAfterCreating();
      return;
    }

    if (response?.message?.errors) {
      setDuplicateErrors(response?.message?.errors);
    } else {
      setDuplicateErrors([]);
    }

    if (response?.message?.insertedIngredients && Array.isArray(response?.message?.insertedIngredients)) {
      setIngredientsUploadedSuccessfully(response?.message?.insertedIngredients?.length);
    }

    setLoading(false);
  };

  return (
    <div>
      <Form onSubmit={submit}>
        <Form.Group>
          <div className="d-flex justify-content-between">
            <Form.Label>{ingredient?._id ? "Ingredient name" : "Create ingredients"}</Form.Label>
            {!ingredient?._id && (
              <small style={{ fontWeight: "bold", color: "var(--text-color)" }}>
                Add multiple ingredients by hitting Tab, Enter, or Return after each ingredient.
              </small>
            )}
          </div>
          {ingredient?._id ? (
            <>
              <Form.Control defaultValue={ingredient?.name} ref={ingredientRef} name="name" placeholder="Ingredient Name" />
              {updateIngredientError && <small style={{ color: "red" }}>{updateIngredientError}</small>}
            </>
          ) : (
            <div>
              <TagsInput
                onlyUnique={true}
                inputProps={{ placeholder: "Add ingredients" }}
                value={ingredientNames}
                onChange={(tags) => setIngredientNames(tags)}
                ref={tagInputRef}
              />
              {ingredientsUploadedSuccessfully !== -1 && (
                <div>
                  <small>
                    <strong>
                      {ingredientsUploadedSuccessfully} {ingredientsUploadedSuccessfully === 1 ? "ingredient" : "ingredients"}
                    </strong>{" "}
                    uploaded successfully
                  </small>
                </div>
              )}
              {duplicateErrors &&
                duplicateErrors?.length > 0 &&
                duplicateErrors?.map((err, index) => (
                  <div key={index}>
                    <small style={{ color: "red" }}>
                      <strong style={{ fontWeight: "bold" }}>{err}</strong> already exists.
                    </small>
                  </div>
                ))}
            </div>
          )}
        </Form.Group>
        <ModalFooter>
          <Button type="submit" disabled={loading}>
            {loading ? <LoadingSpinner size="sm" /> : ingredient?._id ? "Update Ingredient" : "Create Ingredients"}
          </Button>
          {typeof closeModal === "function" && (
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          )}
        </ModalFooter>
      </Form>
    </div>
  );
}
