const USER = {
  ROLES: {
    RESTAURANT: "RESTAURANT",
    SUPER_ADMIN: "SUPER_ADMIN",
    ADMIN: "ADMIN",
    MEMBER: "MEMBER",
    DMA: "DMA",
    DMA_CLIENT: "DMA_CLIENT",
  },
};

const RESTAURANT = {
  TYPES: {
    RESTAURANT: "restaurant",
    FOOD_TRUCK: "food truck",
  },
  CTA_TYPES: {
    link: "Link",
    take_me_there: "Take Me There",
    website: "View Website",
    order_online: "Order Online",
    order_ahead: "Order Ahead",
    call_to_order: "Call to Order",
  },
  CTA_COLORS: {
    primary: "Primary",
    secondary: "Secondary",
    tertiary: "Tertiary",
  },
  DIETARY_CONSIDERATIONS: {
    diets: "Include Only these Diets",
    foodTypes: "Exclude These Potential Allergens",
    other: "Other Dietary Considerations",
  },
  CONJUNCTIONS: {
    OR: "or",
    AND: "and",
  },
};

const LAYOUT = {
  nav: 200,
  collapsed_nav: 60,
  preview: 450,
};

const data = {
  USER,
  RESTAURANT,
  LAYOUT,
};

export default data;
