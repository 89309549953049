import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Form, Button, Col, Row, DropdownButton, Dropdown } from "react-bootstrap";
import ImageInput from "../ImageInput";
import registerStyle from "../../Views/Register/Register.module.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import Section from "../../components/Section";
import dashboardStyles from "../../App.module.scss";
import { toast } from "react-toastify";
import ColorInput from "../Colors/ColorInput";
import styles from "./RestaurantForm.module.scss";
import { Suspense } from "react";
import MediaOnlyInput from "../ImageInput/MediaOnlyInput";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import Modal from "../../components/Modal";
import TransferOwnershipForm from "../Forms/TransferOwnershipForm/TransferOwnershipForm";
import GeneralModal from "../Modal/General";
import { Api, Endpoint } from "../../api";
import { toPng, toJpeg, toSvg } from "html-to-image";
import download from "downloadjs";
import constants from "../../constants";
import EmbedMenuOptions from "./EmbedMenuOptions";
import { useHistory } from "react-router-dom";
import utils_constants from "../../utils/constants";

import File from "../../utils/file";
import SelectList from "../SelectList";
import Checkbox from "../Checkbox";

const {
  USER: { ROLES },
  RESTAURANT: { TYPES, CTA_TYPES, CTA_COLORS },
} = utils_constants;

const qrTemplates = constants.QRTemplates;

const initialMedia = [
  { media: "facebook", icon: "fab fa-facebook-f", url: "", placeHolder: "Facebook" },
  { media: "instagram", icon: "fab fa-instagram", url: "", placeHolder: "Instagram" },
  { media: "twitter", icon: "fab fa-twitter", url: "", placeHolder: "Twitter" },
  { media: "yelp", icon: "fab fa-yelp", url: "", placeHolder: "Yelp" },
  { media: "website", icon: "fab fa-web", url: "", placeHolder: "Website" },
];

function RestaurantForm({ Restaurant, Auth }) {
  const qrRef = useRef(null);
  const canvasRef = useRef(null);
  const advancedQrCode = useRef(null);

  const [showCancelOwnership, setShowCancelOwnership] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [customError, setCustomError] = useState(null);
  const [logoSelected, setLogoSelected] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [socialMedia, setSocialMedia] = useState(initialMedia);
  const [modal, setModal] = useState({
    visible: false,
    update: false,
    updateIndex: null,
    title: "Add media",
    closeModal: () => setModal({ ...modal, visible: false }),
  });
  const [qrSettings, setQrSettings] = useState({});

  const [SelectedQRTemplate, setSelectedQRTemplate] = useState(null); // holds selected template meta (fields) and it's Component
  const [qrTemplateMeta, setQrTemplateMeta] = useState(null); // holds the values of template fields for all templates
  const [restaurantType, setRestaurantType] = useState({ value: Object.keys(TYPES)[0], label: Object.values(TYPES)[0].toUpperCase() });

  const initialCTATypes = { type: Object.keys(CTA_TYPES)[0], label: Object.values(CTA_TYPES)[0] };
  const initialCTAColors = { type: Object.keys(CTA_COLORS)[0], label: Object.values(CTA_COLORS)[0] };

  const [cta, setCta] = useState({
    type: initialCTATypes,
    newTab: true,
    color: initialCTAColors,
    lightText: false,
    enabled: false,
  });

  const history = useHistory();

  const { restaurant } = Restaurant;
  const handlePrint = useReactToPrint({
    content: () => canvasRef.current,
  });

  const toSelectListData = (data) => {
    return Object.keys(data).map((key) => {
      return {
        value: key,
        label: data[key].toUpperCase(),
      };
    });
  };

  const convertToImage = async (selector, type) => {
    setLoadingDownload(true);

    selector.classList.add("download-width");

    switch (type) {
      case "png":
        toPng(selector, { imagePlaceholder: "" })
          .then((dataUrl) => {
            download(dataUrl, "qrcode.png");
            setLoadingDownload(false);
          })
          .finally(() => {
            selector.classList.remove("download-width");
          });
        break;

      case "jpg":
        toJpeg(selector, { imagePlaceholder: "" })
          .then((dataUrl) => {
            download(dataUrl, "qrcode.jpeg");
            setLoadingDownload(false);
          })
          .finally(() => {
            selector.classList.remove("download-width");
          });
        break;

      case "svg":
        toSvg(selector, { imagePlaceholder: "" })
          .then((dataUrl) => {
            download(dataUrl, "qrcode.svg");
            setLoadingDownload(false);
          })
          .finally(() => {
            selector.classList.remove("download-width");
          });
        break;

      default:
        break;
    }
  };

  const appendCanvas = (selector) => {
    html2canvas(selector, {
      scale: 1,
      profile: true,
      useCORS: false,
      allowTaint: true,
    }).then((canvas) => {
      canvasRef.current.innerHTML = "";
      canvasRef.current.appendChild(canvas);
      handlePrint();
    });
  };

  useEffect(() => {
    if (restaurant?.logo) {
      setLogoSelected(restaurant?.logo);
    }
    if (restaurant?.restaurantType) {
      setRestaurantType({
        value: restaurant?.restaurantType?.toUpperCase(),
        label: TYPES[restaurant?.restaurantType?.toUpperCase()]?.toUpperCase(),
      });
    }

    if (restaurant?.cta) {
      setCta({
        type: {
          value: restaurant?.cta?.type,
          label: CTA_TYPES[restaurant?.cta?.type],
        },
        newTab: restaurant?.cta?.newTab,
        color: {
          value: restaurant?.cta?.color,
          label: CTA_COLORS[restaurant?.cta?.color],
        },
        lightText: restaurant?.cta?.lightText,
        enabled: restaurant?.cta?.enabled,
      });
    }

    //eslint-disable-next-line
  }, [restaurant]);

  useEffect(() => {
    if (restaurant && restaurant.templateMeta) {
      try {
        let tm = JSON.parse(restaurant?.templateMeta);
        setQrTemplateMeta(tm);
      } catch (error) {
        console.error(error.message);
      }
    } else {
      // populate qrTemplateMeta with default Values
      updateTemplateMeta();
    }
    //eslint-disable-next-line
  }, [restaurant, SelectedQRTemplate]);

  useEffect(() => {
    if (!SelectedQRTemplate && qrTemplates && qrTemplates.length !== 0) {
      handleQRTemplate(qrTemplates[0]);
    }
    let selectedTemplate = restaurant?.selectedQrTemplate;
    qrTemplates &&
      qrTemplates.forEach((template) => {
        if (template.template === selectedTemplate) {
          handleQRTemplate(template);
        }
      });

    //eslint-disable-next-line
  }, [restaurant, qrTemplates]);

  useEffect(() => {
    if (SelectedQRTemplate) {
      if (qrTemplateMeta && !qrTemplateMeta?.[SelectedQRTemplate?.templateMeta?.slug]) {
        updateTemplateMeta();
      }
    }
    //eslint-disable-next-line
  }, [SelectedQRTemplate]);

  const updateTemplateMeta = () => {
    let slug = SelectedQRTemplate?.template_meta?.slug;
    let fields = SelectedQRTemplate?.template_meta?.fields;
    let resFields = restaurant?.templateMeta;
    try {
      if (resFields) {
        resFields = JSON.parse(resFields);
        resFields = resFields?.[SelectedQRTemplate?.template_meta?.slug];
      }
    } catch (error) {
      console.error(error);
    }

    let values = {};
    if (slug && fields) {
      Object.keys(fields).forEach((field) => {
        if (fields?.[field]?.type === "file") {
          values[field] = resFields?.logo || restaurant?.logo || "";
        } else {
          values[field] = resFields?.[field] || "";
        }
      });
      setQrTemplateMeta({ ...qrTemplateMeta, [slug]: values });
    }
  };

  const submit = async (data) => {
    let moveToQrCodeField = ["colorDark", "backgroundImageAlpha"];
    data.qrSettings = { ...qrSettings };

    data.selectedQrTemplate = null;
    if (qrTemplateMeta && SelectedQRTemplate) {
      data.templateMeta = JSON.stringify(qrTemplateMeta);
      data.selectedQrTemplate = SelectedQRTemplate?.template;
    }

    moveToQrCodeField.forEach((field) => {
      //eslint-disable-next-line
      if (data[field] != undefined && data[field] != null) data.qrSettings[field] = data[field];
    });

    const validatedFile = File.validate(logoSelected);
    if (!validatedFile?.success) {
      setFileError(validatedFile?.fileError);
      return false;
    }

    data.restaurantType = restaurantType.value;

    setLoading(true);

    data.cta.type = cta?.type?.value || initialCTATypes.value;
    data.cta.color = cta?.color?.value || initialCTAColors.value;
    data.cta.newTab = cta?.newTab;
    data.cta.lightText = cta?.lightText;
    data.cta.enabled = cta?.enabled;

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      key !== "qrSettings" && key !== "cta" && key !== null && formData.append(key, data[key]);
    });

    formData.append("social", JSON.stringify(socialMedia));
    formData.append("qrSettings", JSON.stringify(data.qrSettings));
    formData.append("logo", logoSelected);
    formData.append("cta", JSON.stringify(data.cta));

    const response = await Restaurant.update(formData);
    if (response?.confirmation === "Fail") {
      alert("failed");
      setLoading(false);
      setCustomError(typeof response?.message === "string" ? response?.message : response?.message?.message);
      return;
    }

    if (response?.message?.qrSettings) {
      setQrSettings({ ...qrSettings, backgroundImageAlpha: response?.message?.qrSettings?.backgroundImageAlpha });
    }

    setLoading(false);
    setCustomError(null);
    toast("Restaurant updated successfully!");
    window.location.href = "/settings";
  };

  const addSocialMedia = (media, icon, url) => {
    let temp = [...socialMedia];
    let exist = temp.filter((elem) => elem?.media === media);
    if (exist?.length === 0) {
      temp.push({ media: media, icon: icon, url: url });
      setSocialMedia(temp);
    } else {
      temp.forEach((elem) => {
        if (elem?.media === media) {
          elem.icon = icon;
          elem.url = url;
        }
      });
    }
    setSocialMedia(temp);
  };

  const getSocialMedia = (media) => {
    let temp = [...socialMedia];
    let filtered = temp.filter((elem) => elem.media === media);
    if (filtered.length > 0) {
      return filtered[0];
    }
    return {};
  };

  async function getData() {
    const response = await Restaurant.find({ forceRender: true });
    response?.confirmation === "Fail" ? setCustomError(response?.message) : setCustomError(null);
    if (response?.message[0]?.social) setSocialMedia(response?.message[0]?.social);
    if (response?.message[0]?.qrSettings) setQrSettings(response?.message[0]?.qrSettings);
  }

  async function checkTransferOwnershipStatus() {
    const response = await Api.call(Endpoint.temporary_transfer_ownership_status);
    if (response?.statusCode === 200) {
      setShowCancelOwnership(response?.message || false);
      return;
    }

    setShowCancelOwnership(false);
  }

  useEffect(() => {
    if (restaurant?.social) setSocialMedia(restaurant?.social);
    register({ name: "QRCODE_logo", value: null });
    register({ name: "QRCODE_backgroundImage", value: null });
    register({ name: "cta.color", value: Object.keys(CTA_COLORS)[0] });
    register({ name: "cta.type", value: Object.keys(CTA_TYPES)[0] });
    register({ name: "cta.newTab", value: false });
    register({ name: "cta.lightText", value: false });
    getData();
    checkTransferOwnershipStatus();
    //eslint-disable-next-line
  }, []);

  const { register, handleSubmit, errors } = useForm();

  const handleQRTemplate = (template) => {
    if (template === null) {
      setSelectedQRTemplate(null);
      return;
    }
    setSelectedQRTemplate({ ...template, Component: React.lazy(() => import(`../../QRTemplates/${template?.template}`)) });
  };

  const handleTemplateValues = (e, field) => {
    if (SelectedQRTemplate) {
      let copy = { ...qrTemplateMeta };
      if (e && e.target) {
        copy[SelectedQRTemplate?.template_meta?.slug][field] = e.target.value;
      } else {
        copy[SelectedQRTemplate?.template_meta?.slug][field] = e;
      }
      setQrTemplateMeta(copy);
    }
  };

  const showTransferOwnershipModal = () => {
    setModal({
      ...modal,
      visible: true,
      title: "Transfer ownership",
      Component: (
        <TransferOwnershipForm {...modal} checkTransferOwnershipStatus={checkTransferOwnershipStatus} Restaurant={Restaurant} Auth={Auth} />
      ),
    });
  };

  const showEmbedModal = () => {
    setModal({
      ...modal,
      visible: true,
      title: "Embed Menu",
      Component: <EmbedMenuOptions {...modal} restaurantId={restaurant._id} />,
    });
  };

  const showCancelOwnershipModal = () => {
    setModal({
      ...modal,
      title: "Confirm modal",
      visible: true,
      confirmation: true,
      dialogClassName: null,
      Component: (
        <GeneralModal
          {...modal}
          customMessage="Are you sure you want to cancel ownership?"
          customButtonMessage="Cancel ownership"
          toastMessage={"Ownership canceled successfully"}
          funk={async (id) => {
            const response = await Api.call(Endpoint.cancel_transfer_ownership);
            await checkTransferOwnershipStatus();
            setModal({ ...modal, visible: false, title: "" });
            return response;
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const onImageRemove = () => {
    setLogoSelected(null);
  };

  const copyQrCodeUrl = () => {
    const url = `${window.location.origin}/menu/${restaurant?._id}`;
    navigator.clipboard.writeText(url).then(
      function () {
        toast("Menu URL has been copied successfully to clipboard");
      },
      function (err) {
        toast("Error copying Menu url to clipboard. Please try again later");
      }
    );
  };

  const letterCase = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
  };

  const qrShareActions = [
    {
      name: "Copy Menu URL",
      funk: copyQrCodeUrl,
    },
    {
      name: "Copy Embed Code",
      funk: showEmbedModal,
    },
    {
      name: "Download",
      loadingState: loadingDownload,
      dropdownItems: [
        {
          name: "Download template as .jpg",
          funk: () => convertToImage(qrRef.current, "jpg"),
        },
        {
          name: "Download QR Code as .svg",
          funk: () => convertToImage(advancedQrCode.current, "svg"),
        },
        {
          name: "Download QR Code as .png",
          funk: () => convertToImage(advancedQrCode.current, "png"),
        },
        {
          name: "Download QR Code as .jpg",
          funk: () => convertToImage(advancedQrCode.current, "jpg"),
        },
      ],
    },
    {
      name: "Print",
      dropdownItems: [
        {
          name: "Print template",
          funk: () => appendCanvas(qrRef.current),
        },
        {
          name: "Print Code",
          funk: () => appendCanvas(advancedQrCode.current),
        },
      ],
    },
  ];

  return (
    <Section
      title={restaurantType?.label}
      action={{ name: `Add another ${restaurantType?.label?.toLocaleLowerCase()}`, funk: () => history.push("/add-restaurant") }}
      margin="0"
    >
      <div>
        <Modal {...modal} />
        <Form onSubmit={handleSubmit(submit)}>
          <Section title="QR Share" action={qrShareActions}></Section>
          <Form.Group>
            <Form.Label>{restaurantType.label} Name</Form.Label>
            <div className="d-flex">
              {Restaurant.restaurant?.parentRestaurant && (
                <div className="mr-2">
                  <Form.Control type="text" defaultValue={Restaurant?.restaurant?.parentRestaurant?.name} disabled />
                </div>
              )}
              <Form.Control
                ref={register({ required: `${restaurantType?.label && letterCase(restaurantType?.label)} name cannot be empty` })}
                type="text"
                defaultValue={restaurant?.name}
                name="name"
                placeholder={`${restaurantType?.label && letterCase(restaurantType?.label)} name`}
              />
            </div>
            {(errors?.name?.message || customError) && (
              <small className={registerStyle.registerErrorText}>{errors?.name?.message || customError}</small>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label>Business Type</Form.Label>
            <SelectList
              isMulti={false}
              value={restaurantType}
              options={toSelectListData(TYPES)}
              onChange={(data) => {
                setRestaurantType(data);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{restaurantType?.label} Logo</Form.Label>
            <ImageInput
              onImageRemove={onImageRemove}
              displayFile={restaurant?.logo ? restaurant?.logo : logoSelected}
              uploadFromMedia={true}
              placeHolderImage={restaurant?.logo || null}
              placeHolderTitle={"Choose a logo"}
              handleImage={(img) => setLogoSelected(img)}
            />
            {fileError && <small className={registerStyle.registerErrorText}>{fileError}</small>}
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              ref={register}
              as="textarea"
              defaultValue={restaurant?.description}
              name="description"
              placeholder="Your description goes here, max 200 chars..."
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Menu transition period</Form.Label>
            <Form.Control
              ref={register}
              type="number"
              defaultValue={restaurant?.menu_period_transition}
              min="0"
              max="60"
              name="menu_period_transition"
              placeholder="Time in minutes (min 1 - max 60)"
            />
            <small style={{ color: "rgb(89, 88, 88)" }}>Type 0 to disable</small>
          </Form.Group>
          <Form.Group>
            <Form.Label>Upsell Label</Form.Label>
            <Form.Control
              ref={register}
              type="text"
              defaultValue={restaurant?.sideDishesLabel}
              name="sideDishesLabel"
              placeholder="Side dishes"
            />
          </Form.Group>
          <Section title="Menu CTA">
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Type</Form.Label>
                  <SelectList
                    isMulti={false}
                    value={cta?.type}
                    options={toSelectListData(CTA_TYPES)}
                    onChange={(data) => {
                      setCta({ ...cta, type: data });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <Form.Label>Label</Form.Label>
                  <Form.Control ref={register} placeholder="Button Label" defaultValue={restaurant?.cta?.label} name="cta.label" />
                </Form.Group>
              </Col>
              <Col md={8}>
                <Form.Group>
                  <Form.Label>Link</Form.Label>
                  <Row>
                    <Col>
                      <Form.Control ref={register} placeholder="Link" defaultValue={restaurant?.cta?.link} name="cta.link" />

                      <Form.Label style={{ marginTop: 6 }}>
                        <Checkbox
                          size="small"
                          register={register}
                          checked={cta?.newTab}
                          onChange={() => {
                            setCta({ ...cta, newTab: !cta?.newTab });
                          }}
                        />
                        <span>New Tab</span>
                      </Form.Label>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Color</Form.Label>
                  <SelectList
                    isMulti={false}
                    value={cta?.color}
                    name="cta.color"
                    options={toSelectListData(CTA_COLORS)}
                    onChange={(data) => {
                      setCta({ ...cta, color: data });
                    }}
                  />

                  <Form.Label style={{ marginTop: 6 }}>
                    <Checkbox
                      size="small"
                      register={register}
                      checked={cta?.lightText}
                      onChange={() => {
                        setCta({ ...cta, lightText: !cta?.lightText });
                      }}
                    />
                    <span>Light text color</span>
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <Form.Label style={{ marginTop: 6 }}>
                    <Checkbox
                      size="small"
                      register={register}
                      checked={cta?.enabled}
                      onChange={() => {
                        setCta({ ...cta, enabled: !cta?.enabled });
                      }}
                    />
                    <span>Enable CTA Button</span>
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
          </Section>
          <Section title="QRCODE" id="qr-code">
            <Row className="mb-5">
              <Col className={styles.qrColumn} md={12}>
                {SelectedQRTemplate && (
                  <div ref={qrRef}>
                    {SelectedQRTemplate && qrTemplateMeta && (
                      <Suspense fallback={<p>Loading...</p>}>
                        <SelectedQRTemplate.Component
                          advancedQrCode={advancedQrCode}
                          fields={SelectedQRTemplate && SelectedQRTemplate?.template_meta?.fields}
                          values={qrTemplateMeta && qrTemplateMeta?.[SelectedQRTemplate?.template_meta?.slug]}
                          restaurantId={restaurant?._id}
                          qrSettings={qrSettings}
                          setQrSettings={setQrSettings}
                        />
                      </Suspense>
                    )}
                  </div>
                )}

                <div style={{ marginTop: 50 }}>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={
                      loadingDownload ? (
                        <div className={styles.downloadBtn}>
                          <LoadingSpinner size="sm" />
                        </div>
                      ) : (
                        <div className={styles.downloadBtn}>Download / Print</div>
                      )
                    }
                  >
                    <Dropdown.Item onClick={() => convertToImage(qrRef.current, "png")}>Download template as .png</Dropdown.Item>
                    <Dropdown.Item onClick={() => convertToImage(advancedQrCode.current, "png")}>Download QR code as .png</Dropdown.Item>
                    <Dropdown.Item onClick={() => convertToImage(qrRef.current, "jpg")}>Download template as .jpg</Dropdown.Item>
                    <Dropdown.Item onClick={() => convertToImage(advancedQrCode.current, "jpg")}>Download QR code as .jpg</Dropdown.Item>
                    <Dropdown.Item onClick={() => convertToImage(qrRef.current, "svg")}>Download template as .svg</Dropdown.Item>
                    <Dropdown.Item onClick={() => convertToImage(advancedQrCode.current, "svg")}>Download QR code as .svg</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => appendCanvas(qrRef.current)}>Print template</Dropdown.Item>
                    <Dropdown.Item onClick={() => appendCanvas(advancedQrCode.current)}>Print Code</Dropdown.Item>
                  </DropdownButton>
                  <small style={{ marginTop: 10, display: "inline-block" }}>
                    The template will look the same as the preview on this page!
                  </small>
                  <small style={{ display: "inline-block" }}>
                    Zooming in and out on the page will adjust the size of the template on the printed version
                  </small>

                  <div>
                    <div id="qr-print" style={{ display: "none" }} ref={canvasRef}></div>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <Form.Group>
                  <div className="text-right">
                    <Form.Label>QR Code Templates</Form.Label>
                  </div>
                  <div className="text-right" style={{ marginBottom: 30 }}>
                    {qrTemplates &&
                      qrTemplates?.length &&
                      qrTemplates.map((template, i) => (
                        <div
                          key={i}
                          style={{ marginLeft: 10, marginBottom: 10, display: "inline-block", cursor: "pointer" }}
                          onClick={() => handleQRTemplate(template)}
                        >
                          <img
                            width="80"
                            src={require(`../../QRTemplates/${template?.template}/${template?.image}`).default}
                            alt={template?.template}
                          />
                        </div>
                      ))}
                  </div>
                </Form.Group>
                <Form.Group className="d-flex justify-content-end">
                  <ColorInput
                    label="QR Code color"
                    initValue={qrSettings?.colorDark || "black"}
                    onChange={(name, val) => setQrSettings({ ...qrSettings, colorDark: val })}
                    name="colorDark"
                    description={"QR Code color"}
                  />
                </Form.Group>

                {SelectedQRTemplate &&
                  SelectedQRTemplate?.template_meta &&
                  qrTemplateMeta?.[SelectedQRTemplate?.template_meta?.slug] &&
                  Object.keys(SelectedQRTemplate?.template_meta?.fields).map((field, i) => (
                    <Form.Group key={i}>
                      <Form.Label>{SelectedQRTemplate?.template_meta?.fields[field].label}</Form.Label>
                      {SelectedQRTemplate?.template_meta?.fields[field].type === "file" ? (
                        <>
                          <MediaOnlyInput
                            displayFile={(qrTemplateMeta && qrTemplateMeta[SelectedQRTemplate?.template_meta?.slug][field]) || ""}
                            uploadFromMedia={true}
                            placeHolder={
                              (qrTemplateMeta && qrTemplateMeta[SelectedQRTemplate?.template_meta?.slug][field]) || `Choose a logo`
                            }
                            handleImage={(e) => handleTemplateValues(e, field)}
                          />
                        </>
                      ) : (
                        <Form.Control
                          type={SelectedQRTemplate?.template_meta?.fields[field].type}
                          name={field}
                          value={(qrTemplateMeta && qrTemplateMeta[SelectedQRTemplate?.template_meta?.slug][field]) || ""}
                          placeholder={SelectedQRTemplate?.template_meta?.fields[field].placeholder}
                          onChange={(e) => handleTemplateValues(e, field)}
                        />
                      )}
                    </Form.Group>
                  ))}
              </Col>
            </Row>
            <div>
              <div className="d-flex gap-2">
                <Button onClick={copyQrCodeUrl}>Copy Menu URL</Button>
                <Button style={{ marginLeft: 20 }} onClick={showEmbedModal}>
                  Copy Embed Code
                </Button>
              </div>
              <small style={{ marginTop: 10, display: "inline-block" }}>
                The menu URL will not become active until a{" "}
                <Link to="/subscribe" style={{ color: "var(--brand)" }}>
                  subscription
                </Link>{" "}
                is purchased.
              </small>
            </div>
          </Section>
          <Section title="Social media">
            <Form.Group className={dashboardStyles.specialInput}>
              <Form.Label>Facebook</Form.Label>
              <Form.Control
                type="text"
                name="facebook"
                placeholder="Facebook"
                value={getSocialMedia("facebook") ? getSocialMedia("facebook").url : ""}
                onChange={(e) => addSocialMedia("facebook", "fab fa-facebook-f", e.target.value)}
              />
            </Form.Group>
            <Form.Group className={dashboardStyles.specialInput}>
              <Form.Label>Instagram</Form.Label>
              <Form.Control
                type="text"
                name="instagram"
                placeholder="Instagram"
                value={getSocialMedia("instagram") ? getSocialMedia("instagram").url : ""}
                onChange={(e) => addSocialMedia("instagram", "fab fa-instagram", e.target.value)}
              />
            </Form.Group>
            <Form.Group className={dashboardStyles.specialInput}>
              <Form.Label>Twitter</Form.Label>
              <Form.Control
                type="text"
                name="twitter"
                placeholder="Twitter"
                value={getSocialMedia("twitter") ? getSocialMedia("twitter").url : ""}
                onChange={(e) => addSocialMedia("twitter", "fab fa-twitter", e.target.value)}
              />
            </Form.Group>
            <Form.Group className={dashboardStyles.specialInput}>
              <Form.Label>Yelp</Form.Label>
              <Form.Control
                type="text"
                name="yelp"
                placeholder="Yelp"
                value={getSocialMedia("yelp") ? getSocialMedia("yelp").url : ""}
                onChange={(e) => addSocialMedia("yelp", "fab fa-yelp", e.target.value)}
              />
            </Form.Group>
            <Form.Group className={dashboardStyles.specialInput}>
              <Form.Label>Website</Form.Label>
              <Form.Control
                type="text"
                name="website"
                placeholder="Website"
                value={getSocialMedia("website") ? getSocialMedia("website").url : ""}
                onChange={(e) => addSocialMedia("website", "fab fa-web", e.target.value)}
              />
            </Form.Group>
          </Section>
          <div className="sticky-footer-wrapper">
            <Form.Group className="d-flex justify-content-between" style={{ marginBottom: 0 }}>
              {Auth?.user?.role === ROLES.RESTAURANT && (
                <>
                  {!showCancelOwnership ? (
                    <Button variant="danger" onClick={showTransferOwnershipModal}>
                      Transfer ownership
                    </Button>
                  ) : (
                    <Button variant="danger" onClick={showCancelOwnershipModal}>
                      Cancel ownership
                    </Button>
                  )}
                </>
              )}
              <Button disabled={loading || Object.keys(errors).length > 0 ? true : false} type="submit">
                {loading ? <LoadingSpinner /> : "Update"}
              </Button>
            </Form.Group>
          </div>
        </Form>
      </div>
    </Section>
  );
}

const mapStateToProps = (state) => ({
  Restaurant: state.Restaurant,
  Auth: state.Auth,
});
export default connect(mapStateToProps)(RestaurantForm);
