import React from "react";
import { Button } from "react-bootstrap";
import styles from "./Login.module.scss";
import CONSTANTS from "../../utils/constants";

import { List, ListItem, ListItemSecondaryAction, ListItemAvatar, Avatar } from "@material-ui/core";

const { USER } = CONSTANTS;

export default function MultiAccounts({ onClick, data }) {
  return (
    <List dense>
      <h5 style={{ marginBottom: "15px" }}>Please select an account that you want to continue with</h5>
      {data?.length > 0 &&
        data?.map((user) => (
          <ListItem key={user?._id} button onClick={() => onClick(user)}>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "var(--brand)" }}>{user?.username?.substr(0, 1)?.toUpperCase()}</Avatar>
            </ListItemAvatar>
            <div className={styles?.listItemWrapper}>
              <p>{user?.username}</p>
              <small>{user?.email}</small> <br />
              <small>Restaurant {user?.role === USER.ROLES.RESTAURANT ? "owner" : "member"}</small>
            </div>
            <ListItemSecondaryAction>
              <Button onClick={() => onClick(user)}>Select</Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
    </List>
  );
}
