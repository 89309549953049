import React, { Suspense, useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import constants from "../../constants";
import LoadingSpinner from "../LoadingSpinner";

const qrTemplates = constants.QRTemplates;

export default function QRTemplateComponent({ closeModal, restaurant, qrRef, advancedQrCode, action, canvasRef, qrUrl }) {
  const [SelectedQRTemplate, setSelectedQRTemplate] = useState(null); // holds selected template meta (fields) and it's Component
  const [qrTemplateMeta, setQrTemplateMeta] = useState(null); // holds the values of template fields for all templates

  const handleQRTemplate = (template) => {
    if (template === null) {
      setSelectedQRTemplate(null);
      return;
    }
    setSelectedQRTemplate({ ...template, Component: React.lazy(() => import(`../../QRTemplates/${template?.template}`)) });
  };

  useEffect(() => {
    if (!SelectedQRTemplate && restaurant?.selectedQrTemplate) {
      handleQRTemplate(qrTemplates?.[0]);
    }
    let selectedTemplate = restaurant?.selectedQrTemplate;
    qrTemplates &&
      qrTemplates.forEach((template) => {
        if (template.template === selectedTemplate) {
          handleQRTemplate(template);
        }
      });

    //eslint-disable-next-line
  }, [restaurant]);

  useEffect(() => {
    if (SelectedQRTemplate) {
      if (qrTemplateMeta && !qrTemplateMeta?.[SelectedQRTemplate?.templateMeta?.slug]) {
        updateTemplateMeta();
      }
    }
    //eslint-disable-next-line
  }, [SelectedQRTemplate]);

  const updateTemplateMeta = () => {
    let slug = SelectedQRTemplate?.template_meta?.slug;
    let fields = SelectedQRTemplate?.template_meta?.fields;
    let resFields = restaurant?.templateMeta;
    try {
      if (resFields) {
        resFields = JSON.parse(resFields);
        resFields = resFields?.[SelectedQRTemplate?.template_meta?.slug];
      }
    } catch (error) {
      console.error(error);
    }

    let values = {};
    if (slug && fields) {
      Object.keys(fields).forEach((field) => {
        if (fields?.[field]?.type === "file") {
          values[field] = resFields?.logo || restaurant?.logo || "";
        } else {
          values[field] = resFields?.[field] || "";
        }
      });
      setQrTemplateMeta({ ...qrTemplateMeta, [slug]: values });
    }
  };

  useEffect(() => {
    if (restaurant && restaurant.templateMeta) {
      try {
        let tm = JSON.parse(restaurant?.templateMeta);
        setQrTemplateMeta(tm);
      } catch (error) {
        console.error(error.message);
      }
    } else {
      // populate qrTemplateMeta with default Values
      updateTemplateMeta();
    }
    //eslint-disable-next-line
  }, [restaurant, SelectedQRTemplate]);

  return (
    <div>
      {/* <pre>{JSON.stringify(SelectedQRTemplate, null, 2)}</pre> */}
      {SelectedQRTemplate && (
        <div ref={qrRef}>
          {SelectedQRTemplate && restaurant?.templateMeta && (
            <Suspense fallback={<p>Loading...</p>}>
              <SelectedQRTemplate.Component
                advancedQrCode={advancedQrCode}
                fields={SelectedQRTemplate && SelectedQRTemplate?.template_meta?.fields}
                values={qrTemplateMeta && qrTemplateMeta?.[SelectedQRTemplate?.template_meta?.slug]}
                restaurantId={restaurant?._id}
                qrSettings={restaurant?.qrSettings}
                qrUrl={qrUrl}
              />
            </Suspense>
          )}
        </div>
      )}

      <div>
        <div id="qr-print" style={{ display: "none" }} ref={canvasRef}></div>
      </div>

      <Form.Group className="d-flex justify-content-end mt-4">
        {typeof closeModal === "function" && (
          <Button style={{ marginRight: "10px" }} variant="secondary" onClick={closeModal}>
            Close
          </Button>
        )}

        {action &&
          Array.isArray(action) &&
          action.map((act, i) => (
            <React.Fragment key={i}>
              {act?.dropdownItems && act?.dropdownItems?.length > 0 ? (
                <DropdownButton
                  style={{ marginRight: 10 }}
                  disabled={act?.disabled}
                  title={
                    act?.loadingState ? (
                      <span>
                        <LoadingSpinner size="sm" />
                      </span>
                    ) : (
                      <span>{act?.name}</span>
                    )
                  }
                >
                  {act?.dropdownItems?.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      onClick={() => {
                        item.funk();
                      }}
                    >
                      {item?.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              ) : (
                <Button variant="secondary" key={i} onClick={act.funk} disabled={act?.disabled} style={{ marginRight: 10 }}>
                  {act.name}
                </Button>
              )}
            </React.Fragment>
          ))}
      </Form.Group>
    </div>
  );
}
