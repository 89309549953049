import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ImageInput from "../../ImageInput";
import registerStyle from "../../../Views/Register/Register.module.scss";
import { Api, Endpoint } from "../../../api";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { toast } from "react-toastify";

import File from "../../../utils/file";

export default function MediaForm({ getData, closeModal, pagination }) {
  const { handleSubmit } = useForm();
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [loading, setLoading] = useState(false);

  const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILE_SIZE);
  const maxFileSizeErrorText = process.env.REACT_APP_MAX_FILE_SIZE_ERROR;

  const endsWithAny = (str, arr) => {
    for (let i = 0; i < arr.length; i++) if (str.endsWith(arr[i])) return true;
    return false;
  };

  const validateFile = (files) => {
    if (!file) return true;

    let validExtensionsFile = File.allowed_extensions;
    let errorFileCount = 0;

    if (files instanceof FileList && files.length) {
      for (let i = 0; i < files.length; i++) {
        if (file[i]?.size > maxFileSize) {
          setFileError(`This file ${files[i]?.name} is big. Maximum size for file is ${maxFileSizeErrorText}.`);
          return false;
        } else if (!endsWithAny(files[i]?.name, validExtensionsFile)) {
          errorFileCount += 1;
        }
      }
    }

    if (errorFileCount > 0) {
      if (errorFileCount === 1) setFileError(`${errorFileCount} file that you have uploaded is not valid.`);
      else setFileError(`${errorFileCount} files that you have uploaded are not valid.`);
      return false;
    }

    setFileError(null);
    return true;
  };

  useEffect(() => {
    setFileError(null);
    validateFile(file);
    // eslint-disable-next-line
  }, [file]);

  const submit = async () => {
    if (!file) return setFileError("Please choose an image to upload");
    if (!validateFile(file)) return false;
    setFileError(null);
    setLoading(true);
    const formData = new FormData();
    for (let i = 0; i < file.length; i++) {
      formData.append(`images[${i}]`, file[i]);
    }
    const response = await Api.call(Endpoint.uploadMedia, { data: formData });
    setLoading(false);
    if (response?.confirmation === "Success") {
      closeModal();
      getData({ currentPage: pagination.page });
      setFileError(null);
    } else if (typeof response?.message?.message === "string") {
      setFileError(`File is too large. Maximum size is ${maxFileSizeErrorText}.`);
    } else {
      toast(typeof response?.message === "string" ? response?.message : "Something went wrong");
    }
  };

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Form.Group>
        <ImageInput displayFile={file} multiple={true} placeHolder={"Upload an image"} handleImage={(img) => setFile(img)} />
        {fileError && <small className={registerStyle.registerErrorText}>{fileError}</small>}
      </Form.Group>
      <Button disabled={loading} type="submit">
        {loading ? <LoadingSpinner /> : "Submit"}
      </Button>
    </Form>
  );
}
