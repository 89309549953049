import React, { useEffect, useState } from "react";
import Section from "../../components/Section/Section";
import Categories from "../../components/Categories/Categories";
import { connect } from "react-redux";
import Modal from "../../components/Modal";
import DishForm from "../../components/Forms/DishForm";
import CategoryForm from "../../components/Forms/CategoryForm";
import IngredientForm from "../../components/Forms/IngredientForm";
import DeleteConfirmation from "../../components/Forms/DeleteConfirmation";
import AddDishToCategory from "../../components/Forms/CategoryForm/AddDishToCategory";
import MenuNames from "../../components/Forms/MenuForm/MenuNames";
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import general from "../../helpers/general";
import MenuForm from "../../components/Forms/MenuForm/MenuForm";
import styles from "../Members/Members.module.scss";
import WarningAlert from "../../components/Alerts/Warning";
import TourDashboard from "../../components/TourDashboard";
import SelectCategoriesForm from "../../components/Forms/SelectCategoriesForm";
import Dishes from "../../components/Table/Dishes";
import Ingredients from "../../components/Table/Ingredients";
import CONSTANTS from "../../utils/constants";
import withLayout from "../../Layouts/AdminPanel";
import { toast } from "react-toastify";

function Dashboard({ Restaurant, Category, Dish, Ingredient, Menu, Auth, Admin }) {
  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });
  const [categoriesToShow, setCategoriesToShow] = useState();
  const history = useHistory();
  const [menuSelected, setMenuSelected] = useState(Menu?.getMenuFromSessionStorage() ? Menu?.getMenuFromSessionStorage() : {});

  const { dishes } = Dish;
  const { menus } = Menu;
  const { ingredients } = Ingredient;

  const init = async () => {
    await Dish?.load();
    await Category?.load();
    await Ingredient?.load();
    await Menu?.load();

    if (Auth?.user?.permissions?.dish) {
      await Admin?.loadDietaryConsiderations();
    }
  };

  useEffect(() => {
    if (Auth?.user?.role === CONSTANTS.USER.ROLES.ADMIN || Auth?.user?.role === CONSTANTS.USER.ROLES.SUPER_ADMIN) {
      return history.push("/admin/dashboard");
    }
    if (Auth?.user?.role === CONSTANTS.USER.ROLES.DMA) {
      return history.push("/dma/dashboard");
    }

    init();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (menuSelected?._id !== Menu?.selected?._id) {
      setMenuSelected(Menu?.selected);
    }
    //eslint-disable-next-line
  }, [Menu?.selected]);

  useEffect(() => {
    (async () => {
      if (Menu?.selected?._id) {
        const response = await Category?.load({ forceRender: true, selectedMenu: Menu?.selected?._id });
        if (response?.confirmation === "Success" && Array.isArray(response?.message?.categories)) {
          setCategoriesToShow(response?.message?.categories);
          return;
        }
        setCategoriesToShow([]);
        return;
      }
      setCategoriesToShow([]);
    })();
    //eslint-disable-next-line
  }, [Restaurant?.restaurant, Menu?.selected]);

  const addDish = (categoryID = false) => {
    setModal({
      ...modal,
      visible: true,
      title: "Add dish",
      size: "lg",
      Component: <DishForm categoryID={categoryID} closeModal={() => setModal({ ...modal, visible: false })} />,
    });
  };

  const updateDish = (dish) => {
    setModal({
      ...modal,
      visible: true,
      title: "Update dish",
      size: "lg",
      Component: <DishForm update={true} dish={dish} closeModal={() => setModal({ ...modal, visible: false })} />,
    });
  };

  const deleteDishes = (ids, name) => {
    let customButtonMessage = Array?.isArray(ids) ? `Delete ${ids?.length} ${ids?.length === 1 ? "dish" : "dishes"}` : `Delete dish`;
    let customMessage = `Are you sure you want to delete ${ids?.length <= 1 ? `${name ? name : "this dish"}` : `${ids?.length} dishes`}?`;

    setModal({
      ...modal,
      visible: true,
      title: "Delete dish",
      confirmation: true,
      Component: (
        <DeleteConfirmation
          type="Dishes"
          customMessage={customMessage}
          customButtonMessage={customButtonMessage}
          funk={async (id) => {
            return await Dish.delete({ ids: ids });
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const addCategory = () => {
    setModal({
      ...modal,
      visible: true,
      title: "Create category",
      Component: <CategoryForm menuSelected={menuSelected} closeModal={() => setModal({ ...modal, visible: false })} />,
    });
  };

  const editCategory = (categoryID = false) => {
    setModal({
      ...modal,
      visible: true,
      title: "Update category",
      Component: <CategoryForm update={true} categoryID={categoryID} closeModal={() => setModal({ ...modal, visible: false })} />,
    });
  };

  const addIngredient = (ingredient) => {
    setModal({
      ...modal,
      dialogClassName: styles?.addIngredientsModal,
      title: ingredient?._id && ingredient?.name ? "Update ingredient" : "Add ingredients",
      visible: true,
      Component: (
        <IngredientForm ingredient={ingredient} IngredientRedux={Ingredient} closeModal={() => setModal({ ...modal, visible: false })} />
      ),
    });
  };

  const deleteCategories = (ids, name) => {
    if (typeof ids === "string") {
      ids = [ids];
    }

    let customButtonMessage = Array?.isArray(ids)
      ? `Delete ${ids?.length} ${ids?.length === 1 ? "category" : "categories"}`
      : `Delete categories`;
    let customMessage = `Are you sure you want to delete ${
      ids?.length <= 1 ? `${name ? name : "this category"}` : `${ids?.length} categories`
    }?`;

    setModal({
      ...modal,
      visible: true,
      confirmation: true,
      Component: (
        <DeleteConfirmation
          removeCategoryFromMenu={removeCategoryFromMenu}
          type="Category"
          deleteButtonTooltip="Delete forever. This action cannot be undone."
          removeButtonTooltip="Remove category from current menu."
          customButtonMessage={customButtonMessage}
          customMessage={customMessage}
          ids={ids}
          funk={async (id) => {
            const response = await Category.delete(ids);
            return response;
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const removeCategoryFromMenu = async (id) => {
    const data = {
      menuID: Menu?.selected?._id,
      categoryID: id && id[0] ? id[0] : null,
    };
    await Menu.removeCategory(data);
    setModal({ ...modal, visible: false });
  };

  const deleteIngredients = (ids, name) => {
    let customButtonMessage = Array?.isArray(ids)
      ? `Delete ${ids?.length} ${ids?.length === 1 ? "ingredient" : "ingredients"}`
      : `Delete ingredient`;
    let customMessage = `Are you sure you want to delete ${
      ids?.length <= 1 ? `${name ? name : "this ingredient"}` : `${ids?.length} ingredients`
    }?`;

    setModal({
      ...modal,
      visible: true,
      title: `Delete ${ids?.length === 1 ? "ingredient" : "ingredients"}`,
      confirmation: true,
      Component: (
        <DeleteConfirmation
          type="Ingredients"
          customMessage={customMessage}
          customButtonMessage={customButtonMessage}
          funk={async (id) => {
            return await Ingredient.delete(ids);
          }}
          closeModal={() => setModal({ ...modal, visible: false })}
        />
      ),
    });
  };

  const addDishToCategory = (category, id) => {
    setModal({
      ...modal,
      visible: true,
      title: "Add dish to category",
      size: "xl",
      Component: <AddDishToCategory category={category} categoryID={id} closeModal={() => setModal({ ...modal, visible: false })} />,
    });
  };

  const onDragEnd = (result) => {
    if (!result?.destination) {
      return;
    }

    if (result?.destination?.index === result?.source?.index) {
      return;
    }

    let selectedMenu = { ...menuSelected };
    let currentCategory = {};
    let dishes = [];

    if (result?.type === "app") {
      const categories = general.reorder(categoriesToShow, result?.source?.index, result?.destination?.index);
      selectedMenu.categories = categories;
      setCategoriesToShow(categories);
    } else {
      currentCategory = categoriesToShow?.find((cat) => cat._id === result?.destination?.droppableId);
      dishes = general?.reorder(currentCategory?.category?.dishes, result?.source?.index, result?.destination?.index);

      selectedMenu.categories = categoriesToShow;
      selectedMenu?.categories?.forEach((cat) => {
        if (cat?._id === result?.destination?.droppableId) {
          cat.category.dishes = dishes;
        }
      });
      setCategoriesToShow(selectedMenu.categories);
    }

    const data = {
      categories: selectedMenu?.categories,
      menu_id: selectedMenu?._id,
    };

    if (currentCategory?._id && Array.isArray(dishes)) {
      data.categoryDishes = {
        categoryID: currentCategory?.category?._id,
        dishes: dishes?.map((dish) => dish?._id),
      };
    }

    Menu.reorder(data);
  };

  const selectCategories = () => {
    setModal({
      ...modal,
      visible: true,
      title: "Select categories",
      Component: <SelectCategoriesForm Menu={Menu} Category={Category} closeModal={() => setModal({ ...modal, visible: false })} />,
    });
  };

  const cloneIngredients = async (ingredient) => {
    const newIngredientName = ingredient.name + " (copy)";

    const response = await Ingredient.create({
      ingredients: [newIngredientName],
    });

    if (response && response?.confirmation === "Success") {
      if (response?.message?.errors?.includes(newIngredientName)) {
        // TODO: improve this
        // it makes multiple requests if there are multiple ingredients with the same name
        cloneIngredients({ name: newIngredientName });
      } else {
        toast("Ingredient cloned successfully");
        Ingredient.load({ forceRender: true });
      }
    }
  };

  const cloneDishes = async (dish) => {
    if (dish?.createdAt) {
      delete dish?.createdAt;
      dish.restaurantDietaryConsiderations = dish?.restaurant?.dietaryConsiderations;
      dish.name = dish?.name + " (copy)";
      dish.isAvailable = false;

      const selectedCustomizations = dish?.customizations?.selected?.map((customization) => customization?._id);

      dish.customizations.selected = selectedCustomizations;
      dish.customizations = JSON.stringify(dish?.customizations);

      dish.sideDishes = JSON.stringify(dish?.sideDishes?.map((dish) => dish?._id));

      const ingredients = dish?.ingredients?.map((ingredient) => ({
        ingredient: ingredient?.ingredient?._id,
      }));

      dish.ingredients = JSON.stringify(ingredients);
    } else {
      dish.name = dish?.name + " (copy)";
    }

    const response = await Dish.create(dish, true);
    if (response?.confirmation === "Fail") {
      if (response?.statusCode === 412) {
        cloneDishes(dish);
      } else {
        toast("Failed to clone dish");
      }
    } else {
      toast("Dish cloned successfully");
      Dish.load({ forceRender: true });
    }
  };

  const cloneCategory = async (category) => {
    const newCategory = {
      name: `${category.name} (copy)`,
      isAvailable: true,
      dishes: category?.dishes?.map((dish) => dish?._id || dish),
    };

    if (menuSelected && menuSelected?._id) {
      newCategory.menu = menuSelected?._id;
    }

    const response = await Category.create(newCategory, menuSelected?._id || null); // null for selected menu
    if (response?.confirmation === "Fail") {
      if (response?.statusCode === 412) {
        // if it fails because of duplicate name, add one more "(copy)" and try again
        cloneCategory(newCategory);
      } else {
        toast("Category can not be created");
      }
      return;
    } else {
      toast("Category created successfully");
    }
  };

  return (
    <div>
      <TourDashboard Auth={Auth} Menu={Menu} />
      <Modal {...modal} />
      {menus?.length > 0 ? (
        <MenuNames
          tooltip={{ title: "Click to display on the preview" }}
          onClick={(menu, params = {}) => {
            if (!menu?._id) {
              Menu?.setMenuSelectedLocally({});
              localStorage.removeItem("menuSelected");
              if (params?.addNew) {
                setModal({
                  ...modal,
                  visible: true,
                  title: "Add a menu",
                  dialogClassName: styles?.customModalAddMember,
                  Component: <MenuForm {...modal} fromDashboard={true} fixedActions={false} />,
                });
              }
            }
            if (menu?._id?.toString() !== menuSelected?._id?.toString()) {
              localStorage.setItem("menuSelected", JSON.stringify(menu));
              setMenuSelected(menu);
              Menu?.setMenuSelectedLocally(menu);
            } else {
              Menu?.setMenuSelectedLocally(null);
              localStorage.removeItem("menuSelected");
            }
          }}
          tour="create-menu"
          menuSelected={menuSelected}
          menus={menus}
        />
      ) : (
        general?.checkPermissions({ exceptPermissions: ["menu"], originalPermissions: Auth?.user?.permissions }) && (
          <>
            <WarningAlert
              tour="create-menu"
              actionButton={() => {
                setModal({
                  ...modal,
                  visible: true,
                  title: "Add a menu",
                  dialogClassName: styles?.customModalAddMember,
                  Component: <MenuForm {...modal} fromDashboard={true} fixedActions={false} />,
                });
              }}
              actionButtonTitle={"Create menu"}
              title="Please create a menu!"
            />
          </>
        )
      )}
      <Section
        tour={"create-dish"}
        title={`Dishes ${dishes?.length > 0 ? `(${dishes?.length})` : ""}`}
        action={
          general?.checkPermissions({ exceptPermissions: ["dish"], originalPermissions: Auth?.user?.permissions })
            ? { name: "Add Dish", funk: addDish }
            : null
        }
      >
        <Dishes Auth={Auth} data={dishes} updateDish={updateDish} deleteDishes={deleteDishes} clone={cloneDishes} />
      </Section>
      <Section
        title={`Categories ${categoriesToShow?.length > 0 ? `(${categoriesToShow?.length})` : ""}`}
        tour={"create-category"}
        action={
          general?.checkPermissions({ exceptPermissions: ["category"], originalPermissions: Auth?.user?.permissions }) &&
          Menu?.selected?._id &&
          !menuSelected?.fromParent
            ? [
                {
                  name: "Create category",
                  funk: addCategory,
                },
                {
                  name: "Select categories",
                  funk: selectCategories,
                },
              ]
            : [
                {
                  name: "Create category",
                  disabled: true,
                },
                {
                  name: "Select categories",
                  disabled: true,
                },
              ]
        }
      >
        {categoriesToShow && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="all-categories" type="app">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Categories
                    onDragEnd={onDragEnd}
                    categoriesToShow={categoriesToShow}
                    setCategoriesToShow={setCategoriesToShow}
                    menuSelected={menuSelected}
                    editCategory={editCategory}
                    removeDishFromCategory={Category.removeDish}
                    addDishToCategory={addDishToCategory}
                    deleteCategory={deleteCategories}
                    clone={cloneCategory}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Section>
      <Section
        tour={"create-ingredient"}
        title={`Ingredients ${ingredients?.length > 0 ? `(${ingredients?.length})` : ""}`}
        action={
          general?.checkPermissions({ exceptPermissions: ["ingredient"], originalPermissions: Auth?.user?.permissions })
            ? { name: "Add Ingredients", funk: addIngredient }
            : null
        }
      >
        <Ingredients
          Auth={Auth}
          data={ingredients}
          addIngredient={addIngredient}
          deleteIngredients={deleteIngredients}
          clone={cloneIngredients}
        />
      </Section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Dish: state?.Dish,
  Category: state?.Category,
  Ingredient: state?.Ingredient,
  Menu: state?.Menu,
  Auth: state?.Auth,
  Restaurant: state?.Restaurant,
  Admin: state?.Admin,
});

export default connect(mapStateToProps)(withLayout(Dashboard));
