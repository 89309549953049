import React, { useState } from "react";
import ReCaptchaService from "../utils/ReCaptcha";

import general from "../helpers/general";

const { withPromiseTimeout } = general;

export default function useReCaptcha() {
  const reCaptchaRef = React.useRef();
  const [reCaptchaToken, setReCaptchaToken] = useState(null);

  const executeReCaptchaToken = async () => {
    try {
      if (reCaptchaToken) {
        ReCaptchaService.reset(reCaptchaRef);
      }

      const token = await withPromiseTimeout(50000, ReCaptchaService.executeAsync(reCaptchaRef));
      return token;
    } catch (err) {
      return null;
    }
  };

  const onChangeReCaptcha = (value) => {
    setReCaptchaToken(value);
  };

  return { reCaptchaRef, reCaptchaToken, executeReCaptchaToken, onChangeReCaptcha };
}
