import React from "react";
import SelectPaymentMethod from "./SelectPaymentMethod";
import ChoosePlan from "./ChoosePlan";

export default function Complete({
  planSelected,
  setPlanSelected,
  paymentMethodSelected,
  setPaymentMethodSelected,
  paymentMethods,
  promotionCode,
  productPrices,
  promotionCodeResponse,
  plansLoading,
}) {
  const calculatePriceBasedOnCoupon = function () {
    const { monthly_product, yearly_product } = productPrices || {};
    const product = monthly_product?.price?.id === planSelected ? monthly_product : yearly_product;

    if (!product || product?.price?.id !== planSelected) {
      return "Cannot calculate price. Please try again later.";
    }

    const plan = product?.price;

    const percent_off = promotionCodeResponse?.coupon?.percent_off;
    const amount_off = promotionCodeResponse?.coupon?.amount_off;
    const price = plan?.unit_amount / 100;

    if (percent_off) {
      return "$" + (price * ((100 - percent_off) / 100))?.toFixed(2)?.toString();
    }

    if (amount_off) {
      if (amount_off / 100 > price) {
        return "$0.00";
      }
      return "$" + (price - amount_off / 100)?.toFixed(2)?.toString();
    }
  };

  return (
    <div>
      <ChoosePlan
        plansLoading={plansLoading}
        plans={productPrices}
        planSelected={planSelected}
        setPlanSelected={setPlanSelected}
      />
      <hr />
      <SelectPaymentMethod
        paymentMethodSelected={paymentMethodSelected}
        setPaymentMethodSelected={setPaymentMethodSelected}
        paymentMethods={paymentMethods}
      />
      {promotionCode && promotionCodeResponse && (
        <div>
          <hr />
          <p>
            Promotion Code: <strong>{promotionCode}</strong>
          </p>
          <p>
            {promotionCodeResponse?.coupon?.percent_off
              ? `Discount percentage: ${promotionCodeResponse?.coupon?.percent_off}%`
              : `Discount Price: $${(promotionCodeResponse?.coupon?.amount_off / 100)?.toFixed(2)}`}
            <br />
            Final price per license: <strong>{calculatePriceBasedOnCoupon()}</strong>
          </p>
        </div>
      )}
    </div>
  );
}
