const config = {
  google: {
    families: [
      "Roboto",
      "Open Sans",
      "Noto Sans JP",
      "Lato",
      "Montserrat",
      "Roboto Condensed",
      "Source Sans Pro",
      "Oswald",
      "Poppins",
      "Roboto Mono",
      "Raleway",
      "Noto Sans",
      "PT Sans",
      "Roboto Slab",
      "Merriweather",
      "Ubuntu",
      "Playfair Display",
      "Nunito",
      "Open Sans Condensed",
      "Lora",
      "Noto Sans KR",
      "Rubik",
      "PT Serif",
      "Noto Serif",
      "Nunito Sans",
      "Work Sans",
      "Mukta",
      "Fira Sans",
      "Nanum Gothic",
      "Quicksand",
      "Titillium Web",
      "Noto Sans TC",
      "Hind Siliguri",
      "Inter",
      "Karla",
      "Barlow",
      "Inconsolata",
      "Oxygen",
      "Heebo",
      "Source Code Pro",
      "Josefin Sans",
      "PT Sans Narrow",
      "Arimo",
      "Dosis",
      "Libre Franklin",
      "Cabin",
      "Libre Baskerville",
      "Anton",
      "Noto Sans SC",
      "Bitter",
    ],
  },
};

export default config;
