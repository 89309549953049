import React from "react";
import SectionHeader from "./SectionHeader";
import styles from "./Section.module.scss";

export default function Section(props) {
  return (
    <section className={styles.section} id={props.id ? props.id : null} style={{ marginBottom: props?.margin || 40 }}>
      <SectionHeader {...props} />
      {props.children}
    </section>
  );
}
