import { ResponsiveBar } from "@nivo/bar";

const CustomBarChart = ({ data }) => (
  <div style={{ height: 300 }}>
    <ResponsiveBar
      data={data}
      keys={["value"]}
      indexBy="date"
      colors={["#f46d25"]}
      margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      enableLabel={false}
    />
  </div>
);

export default CustomBarChart;
