import React from "react";
import { Link } from "react-router-dom";
import styles from "./TabsView.module.scss";

export default function TabsView({ active, children }) {
  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabsHeader}>
        <Link to="/register" className={active === "Register" ? styles.active : ""}>
          <i className="fal fa-user-plus"></i>
          <span>Register</span>
        </Link>
        <Link to="/login" className={active === "Login" ? styles.active : ""}>
          <i className="fal fa-user"></i>
          <span>Sign in</span>
        </Link>
      </div>
      <div className={styles.tabBody}>{children}</div>
    </div>
  );
}
