import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import styles from "../../App.module.scss";
import Header from "../../components/Header";
import Section from "../../components/Section/Section";
import Table from "../../components/Table";
import { Api, Endpoint } from "../../api/index";

import AddClient from "../../components/Forms/DMA/AddClient/Main";
import Modal from "../../components/Modal";
import DeleteUser from "../../components/Forms/DeleteUser";

import { connect } from "react-redux";

import Statistics from "../../components/Forms/DashboardStatistics";

import AddPaymentMethods from "../../components/Forms/DMA/AddPaymentMethods";
import LicensesSubscriptionStatus from "../../components/Forms/DMA/LicensesSubscriptionStatus";
import AddCardForClient from "../../components/Forms/DMA/AddCardForClient";

import Stripe from "../../utils/stripe";
import LoadingSpinner from "../../components/LoadingSpinner";
import TopBar from "../../components/Header/TopBar";
import PageHeader from "../../components/Header/PageHeader";

function DMADashboard({ Auth }) {
  const [temporaryClients, setTemporaryClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productPrices, setProductPrices] = useState({});
  const [subscribedClients, setSubscribedClients] = useState([]);
  const [unSubscribedClients, setUnSubscribedClients] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [navCollapsed, setNavCollapsed] = useState(false);
  const [modal, setModal] = useState({
    visible: false,
    Component: null,
    title: "",
    closeModal: () => setModal({ ...modal, visible: false }),
  });

  const loadTemporaryClients = async (query = {}) => {
    const response = await Api.call(Endpoint.DMA.clients.temporary, { query });
    if (response?.statusCode === 200) {
      setTemporaryClients(response?.message);
    }
  };

  const loadClientsSubscriptionsStatus = async () => {
    const response = await Api.call(Endpoint.DMA.clients.subscription_status);
    if (response?.statusCode === 200) {
      setSubscribedClients(response?.message?.subscribedClients);
      setUnSubscribedClients(response?.message?.unSubscribedClients);
    }
  };

  const showAddClientModal = () => {
    setModal({
      ...modal,
      dialogClassName: null,
      title: "Add a license",
      visible: true,
      Component: (
        <AddClient
          {...modal}
          closeModal={() => setModal({ ...modal, visible: false })}
          loadTemporaryClients={loadTemporaryClients}
          onSuccessCallback={() => loadClientsSubscriptionsStatus()}
          showPaymentMethod={true}
        />
      ),
    });
  };

  const loadStatistics = async () => {
    const response = await Api.call(Endpoint.DMA.clients.statistics);
    if (response?.statusCode === 200) {
      setStatistics(response?.message);
    }
  };

  const loadPaymentMethods = async () => {
    const response = await Api.call(Endpoint.subscription.customerPaymentMethods);
    if (response?.statusCode === 200) {
      setPaymentMethods(response?.message);
    }
  };

  const showCancelClientInvitationModal = async (user) => {
    setModal({
      ...modal,
      title: "Cancel invitation",
      visible: true,
      Component: (
        <DeleteUser
          {...modal}
          warning_message="Are you sure you want to cancel the invitation?"
          toast_complete_message="Invitation canceled successfully"
          user={user}
          submit={async () => {
            const response = await Api.call(Endpoint.DMA.clients.cancelInvitation(user?.token));
            await loadTemporaryClients(); // TODO: Check temporary clients pagination
            return response;
          }}
        />
      ),
    });
  };

  const addCardForClient = async (data = {}) => {
    return await addPaymentMethod(data);
  };

  const showAddCardForClientModal = async () => {
    setModal({
      ...modal,
      visible: true,
      title: "Add card for client",
      Component: <AddCardForClient unSubscribedClients={unSubscribedClients} addCard={addCardForClient} />,
    });
  };

  const updatePlans = async () => {
    const response = await Api.call(Endpoint.subscription.baseProduct);
    return response?.message || {};
  };

  const addPaymentMethod = async (data = {}) => {
    return await Api.call(Endpoint.subscription.savePayment, { data });
  };

  const handleAddPaymentMethod = async () => {
    const response = await addPaymentMethod({ url: window.location.href });
    if (response.statusCode === 200 && response?.message?.url) {
      await Stripe.redirectToCheckout(response.message.id);
      return;
    }
  };

  const manageSubscriptions = async (showLoading = true) => {
    const response = await Api.call(Endpoint.subscription.customerPortalSession, { data: { url: window.location.href } });
    if (response.statusCode === 200) {
      window.location.href = response?.message?.url;
      return;
    }
  };

  const loadProductPrices = async () => {
    const response = await Api.call(Endpoint.DMA.product_prices);
    if (response?.statusCode === 200) {
      setProductPrices(response?.message || {});
    }
  };

  const init = async () => {
    setLoading(true);
    handleResize();

    await loadClientsSubscriptionsStatus();
    await loadStatistics();
    await loadProductPrices();
    await loadPaymentMethods();

    setLoading(false);

    await loadTemporaryClients();
  };

  useEffect(() => {
    init();
    //eslint-disable-next-line
  }, []);

  const handleResize = () => {
    setNavCollapsed(window?.innerWidth <= 1200);
  };

  const columns = {
    paymentMethods: [
      {
        field: "Brand",
        render: ({ row }) => <span>{row?.card?.brand?.charAt(0).toUpperCase() + row?.card?.brand?.slice(1) || ""}</span>,
      },
      {
        field: "Credit Card",
        render: ({ row }) => (
          <span>
            {row?.billing_details?.name} - XXXX-XXXX-XXXX-{row?.card?.last4 || ""}
          </span>
        ),
      },
      {
        field: "Exp Month",
        render: ({ row }) => <span>{row?.card?.exp_month || ""}</span>,
      },
      {
        field: "Exp Year",
        render: ({ row }) => <span>{row?.card?.exp_year || ""}</span>,
      },
      {
        field: "Manage",
        render: ({ row }) => (
          <div>
            <Button onClick={async () => manageSubscriptions(false)} style={{ padding: "4px", fontSize: "14px" }}>
              Manage
            </Button>
          </div>
        ),
      },
    ],
    temporary_clients: [
      {
        field: "Email",
        render: ({ row, column, index }) => {
          return row?.data?.client?.email;
        },
      },
      {
        field: "Username",
        render: ({ row, column, index }) => {
          return row?.data?.client?.username;
        },
      },
      {
        field: "Status",
        render: ({ row, column, index }) => {
          return row?.data?.client?.status;
        },
      },
      {
        field: "Actions",
        render: ({ row, column, index }) => (
          <i
            onClick={() => showCancelClientInvitationModal(row)}
            style={{ color: "red", cursor: "pointer" }}
            className="far fa-trash-alt"
          ></i>
        ),
      },
    ],
  };

  return (
    <>
      <Modal {...modal} />
      <section className={styles.panel}>
        <TopBar />
        <Header navCollapsed={navCollapsed} setNavCollapsed={setNavCollapsed} />
        <div className={styles.mainWrapper}>
          <main>
            <div className="container">
              <PageHeader />
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <LoadingSpinner />
                </div>
              ) : (
                <div>
                  <Statistics statistics={statistics} />
                  <Card className="mb-5">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <div>
                        <p className="mb-0">
                          Add a new license. <br /> You will not be able to publish menus on behalf of licenses until a subscription is
                          active.
                        </p>
                      </div>
                      <div>
                        <button className="btn btn-primary" onClick={showAddClientModal}>
                          Add license
                        </button>
                      </div>
                    </Card.Body>
                  </Card>
                  {unSubscribedClients?.length > 0 && (
                    <Card className="mb-5">
                      <Card.Body className="d-flex justify-content-between align-items-center">
                        <div>
                          <p className="mb-0">
                            Add a client card. <br />
                            Subscribe on behalf of a client using their card.
                          </p>
                        </div>
                        <div>
                          <button className="btn btn-primary" onClick={showAddCardForClientModal}>
                            Add card
                          </button>
                        </div>
                      </Card.Body>
                    </Card>
                  )}
                  {paymentMethods && paymentMethods?.data?.length === 0 && <AddPaymentMethods addPaymentMethod={addPaymentMethod} />}

                  {temporaryClients?.length > 0 && (
                    <Section title={`Pending licenses (${temporaryClients?.length})`}>
                      <Table columns={columns?.temporary_clients} rows={temporaryClients} enableLocalPagination={true} />
                    </Section>
                  )}
                  <LicensesSubscriptionStatus
                    subscribedClients={subscribedClients}
                    unSubscribedClients={unSubscribedClients}
                    loadClientsSubscriptionsStatus={loadClientsSubscriptionsStatus}
                    paymentMethods={paymentMethods}
                    productPrices={productPrices}
                    updatePlans={updatePlans}
                    Auth={Auth}
                  />
                  <Section
                    title={`Payment methods (${paymentMethods?.data?.length || 0})`}
                    action={{ name: "Add payment method", funk: handleAddPaymentMethod }}
                  >
                    <Table columns={columns?.paymentMethods} rows={paymentMethods?.data} enableLocalPagination={true} />
                  </Section>
                </div>
              )}
            </div>
          </main>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => ({ Auth: state?.Auth });
export default connect(mapStateToProps)(DMADashboard);
