import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";

import { Api, Endpoint } from "../../api";

import Alert from "react-bootstrap/Alert";

import { toast } from "react-toastify";

import ReCaptchaComponent from "../../components/ReCaptcha";
import useReCaptcha from "../../hoc/useReCaptcha";

export default function AddDMA({ closeModal, loadDMASPending }) {
  const { reCaptchaRef, executeReCaptchaToken, onChangeReCaptcha } = useReCaptcha();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const { register, handleSubmit, errors } = useForm();

  const submit = async (data) => {
    setLoading(true);

    const reCaptchaToken = await executeReCaptchaToken();
    data.reCaptchaToken = reCaptchaToken;

    const response = await Api.call(Endpoint.admin.DMA.register, { data });
    if (response?.statusCode !== 200) {
      setLoading(false);
      setApiError(response?.message);
      return;
    }

    typeof loadDMASPending === "function" && (await loadDMASPending());
    setLoading(false);
    setApiError(null);

    toast(
      `DMA has been added successfully. We sent an email confirmation to ${data.email} to complete the registration process.`
    );
    closeModal();
  };
  return (
    <Form onSubmit={handleSubmit(submit)}>
      <ReCaptchaComponent innerRef={reCaptchaRef} onChange={onChangeReCaptcha} />
      <Alert variant="secondary">
        This DMA will receive an email with instructions on how to set up the account. <br />
        When the account is set up successfully, you will receive an email.
      </Alert>
      <Form.Group>
        <Form.Label>Email</Form.Label>
        <Form.Control
          ref={register({
            required: "Email cannot be empty",
            pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address" },
          })}
          type="email"
          name="email"
          placeholder="Email"
        />
        {(errors?.email?.message || apiError) && <small style={{ color: "red" }}>{errors?.email?.message || apiError}</small>}
      </Form.Group>

      <Form.Group style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button type="submit" disabled={loading || Object.keys(errors).length > 0 ? true : false}>
          {loading ? <LoadingSpinner size="sm" /> : "Submit"}
        </Button>
      </Form.Group>
    </Form>
  );
}
